import React from 'react';
import { observer } from 'mobx-react';

import { IPodParams } from 'models/Pod';
import { PodContainersStore } from 'stores/PodsPage/PodContainersStore';

import EmptyContent from 'components/EmptyContent/EmptyContent';
import CircleLoader from 'components/CircleLoader/CircleLoader';
import ContainerRow from 'pages/PodsPage/ContainerRow/ContainerRow';
import PodLogs from 'pages/PodsPage/PodLogs/PodLogs';

import style from './ContainersSection.module.scss';

interface IProps {
  podParams: IPodParams;
  containersList: object;
  toggleContainer: (key: string) => () => void;
  isUnschedulableError: boolean;
}

@observer
class ContainersSection extends React.Component<IProps> {
  containersStore = new PodContainersStore(this.props.podParams);

  async componentDidMount() {
    await this.containersStore.fetchPodContainers();
  }

  render() {
    const { podParams, containersList, toggleContainer, isUnschedulableError } =
      this.props;
    const hasContainers = !!this.containersStore.sortedContainers.length;
    const showPodLogs =
      !this.containersStore.isLoading && !hasContainers && isUnschedulableError;
    const showEmptyContainersListMessage =
      !this.containersStore.isLoading &&
      !hasContainers &&
      !isUnschedulableError;

    return (
      <tr className={style.extendedContent}>
        <td colSpan={6} className={style.extendedContentCell}>
          <div className={style.extendedContentContainer}>
            {this.containersStore.isLoading ? (
              <CircleLoader className={style.loader} />
            ) : (
              hasContainers &&
              this.containersStore.sortedContainers.map(container => {
                const containerKey =
                  podParams.podName + container.containerName;
                const isLogsOpened = containersList[containerKey];

                return (
                  <ContainerRow
                    key={container.containerName}
                    container={container}
                    podParams={this.containersStore.podParams}
                    isLogsOpened={isLogsOpened}
                    toggleLogs={toggleContainer(containerKey)}
                  />
                );
              })
            )}
            {showPodLogs && <PodLogs podParams={podParams} />}
            {showEmptyContainersListMessage && (
              <EmptyContent
                icon="emptyBox"
                text="containers.empty.message"
                className={style.emptyContent}
                messageClassName={style.emptyContentMessage}
              />
            )}
          </div>
        </td>
      </tr>
    );
  }
}

export default ContainersSection;
