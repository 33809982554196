import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { ActionId, ITemplate } from 'models/Template';
import TemplatesStore from 'stores/TemplatesStore';
import RootStore from 'stores/RootStore';
import ModalStore from 'stores/ModalStore';

import Icon from 'components/Icon/Icon';
import Button, { BtnType } from 'components/Button/Button';
import CircleLoader from 'components/CircleLoader/CircleLoader';
import ConfirmModal from 'components/Modals/ConfirmModal/ConfirmModal';
import TemplatesTable from 'pages/TemplatesPage/TemplatesList/TemplatesTable';
import { TemplateFormPayload } from 'pages/TemplatesPage/TemplatesPage';

import style from './TemplatesPage.module.scss';
import WithPopper from 'components/WithPopper/WithPopper';

const deleteModalTitleToActionId: Record<ActionId, string> = {
  [ActionId.create_environment]: 'templates.env.delete.title',
  [ActionId.create_microservice]: 'templates.ms.delete.title',
};

const resetModalTitleToActionId: Record<ActionId, string> = {
  [ActionId.create_environment]: 'templates.env.modal.reset.title',
  [ActionId.create_microservice]: 'templates.ms.modal.reset.title',
};

const resetModalTextToActionId: Record<ActionId, string> = {
  [ActionId.create_environment]: 'templates.env.modal.reset.text',
  [ActionId.create_microservice]: 'templates.ms.modal.reset.text',
};

interface Props {
  clusterName: string;
  actionId: ActionId;
  showTemplateForm: (payload?: TemplateFormPayload) => void;
  updateStatistics: () => void;
}

@observer
class TemplatesList extends Component<Props> {
  store: TemplatesStore;

  constructor(props: Props) {
    super(props);

    const { actionId, clusterName } = this.props;
    this.store = new TemplatesStore({
      clusterName,
      projectName: RootStore.currentProject,
      actionId,
    });
  }

  onCreateTemplate = () => {
    this.props.showTemplateForm({ actionId: this.props.actionId });
  };

  onViewTemplate = (template: ITemplate) => () => {
    this.props.showTemplateForm({
      actionId: this.props.actionId,
      template,
      isViewMode: true,
    });
  };

  onEditTemplate = (template: ITemplate) => () => {
    this.props.showTemplateForm({
      actionId: this.props.actionId,
      template,
    });
  };

  onDeleteTemplate = (name: string) => async () => {
    const { actionId, updateStatistics } = this.props;

    await ModalStore.showModal(ConfirmModal, {
      onConfirm: async () => this.store.deleteTemplate(name),
      title: deleteModalTitleToActionId[actionId],
      children: (
        <FormattedMessage
          id="templates.delete.message"
          values={{ templateName: name }}
        />
      ),
    });

    await this.store.fetchTemplates();
    updateStatistics();
  };

  onResetTemplates = async () => {
    const { actionId, updateStatistics } = this.props;

    await ModalStore.showModal(ConfirmModal, {
      onConfirm: async () => this.store.resetTemplates(),
      title: resetModalTitleToActionId[actionId],
      children: <FormattedMessage id={resetModalTextToActionId[actionId]} />,
      confirmBtnText: 'button.reset',
    });

    await this.store.fetchTemplates();
    updateStatistics();
  };

  async componentDidMount() {
    await this.store.fetchTemplates();
  }

  render() {
    const { sortedTemplates, pending } = this.store;

    return (
      <div className={style.content}>
        {pending ? (
          <CircleLoader className={style.loader} />
        ) : (
          <>
            <div className={style.resetBtnWrapper}>
              <WithPopper
                title={<FormattedMessage id="templates.all.default.tooltip" />}
                hidden={!this.store.isTemplatesDefault}
              >
                <Button
                  styleType={BtnType.Text}
                  className={style.resetBtn}
                  onClick={this.onResetTemplates}
                  disabled={this.store.isTemplatesDefault}
                >
                  <Icon type="reset" className={style.btnIcon} />
                  <FormattedMessage id="templates.reset.default" />
                </Button>
              </WithPopper>
            </div>

            {!!sortedTemplates.length && (
              <TemplatesTable
                templates={sortedTemplates}
                onViewTemplate={this.onViewTemplate}
                onEditTemplate={this.onEditTemplate}
                onDeleteTemplate={this.onDeleteTemplate}
              />
            )}

            <Button
              styleType={BtnType.Add}
              className={style.addButton}
              onClick={this.onCreateTemplate}
            >
              <Icon type="plus2" />
            </Button>
          </>
        )}
      </div>
    );
  }
}

export default TemplatesList;
