import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { timeSince } from 'helpers/datetime';
import { AlertType } from 'models/Alert';

import Alert from 'components/Alert/Alert';
import WithPopper from 'components/WithPopper/WithPopper';

import style from './RestartCountLabel.module.scss';

interface IProps {
  restartCount: number;
  messageId?: string;
  lastStart?: string;
  popperBoundary?: HTMLElement;
}

@observer
class RestartCountLabel extends Component<IProps> {
  @observable timeSinceLastStart;

  @action
  setTimeSinceLastStart = () => {
    const { lastStart } = this.props;
    this.timeSinceLastStart = lastStart && timeSince(lastStart);
  };

  render() {
    const { restartCount, messageId, popperBoundary } = this.props;

    if (!restartCount) {
      return null;
    }

    const lastStartMessage = this.timeSinceLastStart && messageId && (
      <FormattedMessage
        id={messageId}
        values={{
          restartCount,
          lastStartInterval: this.timeSinceLastStart.interval,
          lastStartType: <FormattedMessage id={this.timeSinceLastStart.type} />,
        }}
      />
    );

    return (
      <WithPopper
        title={lastStartMessage}
        position="absolute"
        onMouseEnterElement={this.setTimeSinceLastStart}
        boundary={popperBoundary}
      >
        <Alert
          type={AlertType.Info}
          iconType="infoSolid"
          className={style.alert}
        >
          {restartCount}
        </Alert>
      </WithPopper>
    );
  }
}

export default RestartCountLabel;
