import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react';
import { observable } from 'mobx';
import classNames from 'classnames';
import { Switch, RouteComponentProps } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import AccessDenied from 'components/Errors/AccessDenied/AccessDenied';
import AppHeader from 'components/Header/AppHeader';
import AlertDrawer from 'components/AlertDrawer/AlertDrawer';
import PrivateRoute from 'routes/PrivateRouter';
import { ROUTES } from 'routes/routes';
import RootStore from 'stores/RootStore';
import Project from 'pages/ProjectPage/Project';
import Cluster from 'pages/ClusterPage/ClusterPage';
import Templates from 'pages/TemplatesPage/TemplatesPage';
import Environment from 'pages/EnvironmentPage/Environment';
import Microservice from 'pages/MicroservicePage/MicroservicePage';
import Pods from 'pages/PodsPage/PodsPage';
import AlertsStore from 'stores/AlertsStore';

import styleToast from 'components/Notification/Notification.module.scss';
import style from './BaseLayout.module.scss';

interface Props
  extends RouteComponentProps<{
    id: string;
    clusterName: string;
  }> {}

@observer
class BaseLayout extends Component<Props> {
  @observable alertsStore: AlertsStore;

  constructor(props) {
    super(props);
    this.alertsStore = new AlertsStore();
    this.alertsStore.setPageFilter(props.match.params);
  }

  componentDidUpdate() {
    this.alertsStore.setPageFilter(this.props.match.params);
  }

  componentWillUnmount() {
    this.alertsStore.clearTimer();
  }

  render() {
    return (
      <Provider alertsStore={this.alertsStore}>
        <div className={style.mainContainer}>
          <div className={classNames(style.layout, style.layout__base)}>
            <AppHeader />

            {RootStore.accessDeniedError ? (
              <AccessDenied />
            ) : (
              <main className={style.main}>
                <Switch>
                  <PrivateRoute path={ROUTES.project} component={Project} />
                  <PrivateRoute path={ROUTES.cluster} component={Cluster} />
                  <PrivateRoute path={ROUTES.templates} component={Templates} />
                  <PrivateRoute
                    path={ROUTES.environment}
                    component={Environment}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.microservice}
                    component={Microservice}
                  />
                  <PrivateRoute path={ROUTES.podsLogs} component={Pods} />
                </Switch>
              </main>
            )}

            <ToastContainer
              bodyClassName={styleToast.toastBody}
              progressClassName={styleToast.toastProgress}
            />
          </div>
          <AlertDrawer />
        </div>
      </Provider>
    );
  }
}

export default BaseLayout;
