import React, { ChangeEvent, Component, TextareaHTMLAttributes } from 'react';
import classNames from 'classnames';

import style from './TextArea.module.scss';
import Icon from 'components/Icon/Icon';
import { Omit } from 'helpers/types';
import { Icons } from 'components/Icon';

interface Props
  extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> {
  iconType?: string;
  name?: string;
  hasErrors?: boolean;
  hasWarnings?: boolean;
  isSucceed?: boolean;
  hasInfo?: boolean;
  onIconClick?: () => void;
  onChange?: (v: string, event: ChangeEvent<HTMLTextAreaElement>) => void;
}

class TextArea extends Component<Props> {
  public onChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
    this.props.onChange && this.props.onChange(event.target.value, event);

  public render(): JSX.Element {
    const {
      name,
      iconType,
      hasErrors = false,
      isSucceed = false,
      hasWarnings = false,
      hasInfo = false,
      onIconClick = () => undefined,
      className,
      ...attrs
    } = this.props;

    const colors = {
      [style.wrapper__info]: hasInfo,
      [style.wrapper__warning]: hasWarnings,
      [style.wrapper__error]: hasErrors,
      [style.wrapper__success]: isSucceed,
    };

    return (
      <span className={classNames(style.wrapper, colors, className)}>
        <textarea
          id={name}
          {...attrs}
          className={style.field}
          onChange={this.onChange}
        />
        {iconType && (
          <Icon
            className={style.icon}
            type={iconType as keyof typeof Icons}
            onClick={onIconClick}
          />
        )}
      </span>
    );
  }
}

export default TextArea;
