import React, { ReactChild } from 'react';
import classNames from 'classnames';
import style from './ProgressBar.module.scss';
import { FormattedMessage } from 'react-intl';

export enum ProgressBarStatus {
  PRIMARY = 'primary',
  ERROR = 'error',
}

interface CustomStyles {
  mainContainer?: string;
  progress?: string;
  valueLabel?: string;
}

interface Props {
  name: string | JSX.Element;
  value: number;
  status?: ProgressBarStatus;
  description?: string | JSX.Element;
  children?: ReactChild | null;
  styles?: CustomStyles;
  isUnknown?: boolean;
  unknownDescription?: string;
}

const ProgressBar = ({
  name,
  value,
  status = ProgressBarStatus.PRIMARY,
  description = '',
  children = null,
  styles = {},
  isUnknown = false,
  unknownDescription = '',
}: Props) => {
  const width = { width: `${value}%` };

  return (
    <div className={classNames(style.mainContainer, styles.mainContainer)}>
      <div className={style.labelContainer}>
        <span className={style.mainLabel}>{name}</span>
        <div>
          <span
            className={classNames(
              style.valueLabel,
              style[status],
              styles.valueLabel,
              { [style[ProgressBarStatus.ERROR]]: isUnknown },
            )}
          >
            {`% ${!isNaN(value) ? value.toFixed(2) : '-'}`}
          </span>
          {children}
        </div>
      </div>
      <div className={classNames(style.progress, styles.progress)}>
        <div
          className={classNames(style.progressBar, style[status])}
          role="progressbar"
          style={width}
        />
      </div>
      {!isNaN(value) && (
        <span className={style.description}>{description}</span>
      )}
      {isUnknown && (
        <span className={style.description}>
          <FormattedMessage id={unknownDescription} />
        </span>
      )}
    </div>
  );
};

export default ProgressBar;
