import { DialogActionType, ModalProps } from 'stores/ModalStore';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { observable } from 'mobx';
import { bindFormControl } from '../../Form/FormControl/FormControl';
import { FormattedMessage } from 'react-intl';
import TextField from '../../Form/Fields/TextField/TextField';
import CreateClusterForm from './CreateClusterForm';
import style from './CreateClusterModal.module.scss';
import CommonModal from 'components/Modals/Modal/CommonModal';
import PasswordField from 'components/Form/Fields/PasswordField/PasswordField';
import classNames from 'classnames';
import NotificationService, {
  fullscreenNotificationOptions,
} from 'components/Notification/NotificationService';
import Icon from 'components/Icon/Icon';

@observer
class CreateClusterModal extends Component<ModalProps> {
  @observable createClusterForm = new CreateClusterForm();
  FormControl = bindFormControl(this.createClusterForm);
  upload;
  @observable file: File | null;

  onClose = () => {
    this.props.onClose(DialogActionType.cancel);
  };

  onConfirm = async () => {
    this.props.onClose(DialogActionType.submit);
    NotificationService.successMessage(
      { id: 'cluster.created' },
      fullscreenNotificationOptions,
    );
  };

  dropHandler = ev => {
    ev.preventDefault();
    if (ev.dataTransfer.items) {
      Array.from(ev.dataTransfer.items).forEach(item => {
        if ((item as DataTransferItem).kind === 'file') {
          this.file = (item as DataTransferItem).getAsFile();
        }
      });
    } else {
      Array.from(ev.dataTransfer.files).forEach(item => {
        this.file = item as File;
      });
    }
  };

  dragOverHandler = ev => {
    ev.preventDefault();
  };

  onChangeFile = event => {
    event.stopPropagation();
    event.preventDefault();
    this.file = event.target.files[0];
  };

  deleteFile = () => {
    this.file = null;
  };

  render() {
    const FormControl = this.FormControl;

    return (
      <CommonModal
        className={style.body}
        title="header.settings.cluster.create"
        cancel="button.cancel"
        confirm="button.create"
        onClose={this.onClose}
        onConfirm={this.onConfirm}
      >
        <div className={style.sectionWrapper}>
          <div className={style.text}>
            <FormattedMessage id="cluster.create.heading1" />
          </div>
          <div className={style.inputSection}>
            <div
              className={classNames(
                style.formControlWrapper,
                style.formControlWrapper__half,
              )}
            >
              <FormControl
                className={style.input}
                name="clusterName"
                render={props => <TextField {...props} />}
              />
            </div>
            <div
              className={classNames(
                style.formControlWrapper,
                style.formControlWrapper__half,
              )}
            >
              <FormControl
                className={style.input}
                name="clustersBaseDomain"
                render={props => <TextField {...props} />}
              />
            </div>
          </div>
          <div
            className={style.dragZone}
            onDrop={this.dropHandler}
            onDragOver={this.dragOverHandler}
          >
            <div className={style.dndText} onClick={() => this.upload.click()}>
              <FormattedMessage
                id="drag.drop.conf.file"
                values={{
                  browse: (
                    <span className={style.browseLink}>
                      <FormattedMessage id="browse" />
                    </span>
                  ),
                }}
              />
              <input
                type="file"
                ref={ref => (this.upload = ref)}
                style={{ display: 'none' }}
                onChange={this.onChangeFile}
              />
            </div>
          </div>
          {this.file && (
            <div className={style.selectedFileContainer}>
              <span className={style.fileTitle}>
                <FormattedMessage id="path.to.cfg.file" />
              </span>
              <div className={style.fileWrap}>
                {this.file.name}
                <Icon type="delete" onClick={this.deleteFile} />
              </div>
            </div>
          )}
        </div>
        <div className={style.sectionWrapper}>
          <div className={style.text}>
            <FormattedMessage id="cluster.create.heading2" />
          </div>
          <div className={style.inputSection}>
            <div
              className={classNames(
                style.formControlWrapper,
                style.formControlWrapper__half,
              )}
            >
              <FormControl
                className={style.input}
                name="gitURL"
                render={props => <TextField {...props} />}
              />
            </div>
            <div
              className={classNames(
                style.formControlWrapper,
                style.formControlWrapper__half,
              )}
            >
              <FormControl
                className={style.input}
                name="gitStateSubpath"
                render={props => <TextField {...props} />}
              />
            </div>
          </div>
          <div className={style.inputSection}>
            <div
              className={classNames(
                style.formControlWrapper,
                style.formControlWrapper__half,
              )}
            >
              <FormControl
                className={style.input}
                name="usernameToAccessGIT"
                render={props => <TextField {...props} />}
              />
            </div>
            <div
              className={classNames(
                style.formControlWrapper,
                style.formControlWrapper__half,
              )}
            >
              <FormControl
                className={style.input}
                name="userPasswordToAccessGIT"
                render={props => <PasswordField {...props} />}
              />
            </div>
          </div>
        </div>
      </CommonModal>
    );
  }
}

export default CreateClusterModal;
