import { FormattedMessage } from 'react-intl';
import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { DialogActionType, ModalProps } from 'stores/ModalStore';
import CommonModal from '../../Modals/Modal/CommonModal';

import NotificationService, {
  fullscreenNotificationOptions,
} from 'components/Notification/NotificationService';

import style from './UpdateToLastModal.module.scss';
import WithPopper from 'components/WithPopper/WithPopper';
import { lineBreakFunc } from 'helpers/string';

interface MsInfo {
  longName: string;
  image: string;
}

interface Props extends ModalProps {
  request: () => Promise<any>;
  microservices: MsInfo[];
}

@observer
class UpdateToLastModal extends Component<Props & ModalProps> {
  @observable isUIblocked: boolean = false;

  onClose = () => {
    this.props.onClose(DialogActionType.submit);
  };

  onConfirm = async () => {
    try {
      this.isUIblocked = true;
      await this.props.request();
      NotificationService.successMessage(
        { id: 'microservices.update.success' },
        fullscreenNotificationOptions,
      );
    } catch (e) {
      NotificationService.errorMessage({ id: 'microservices.update.error' });
    } finally {
      this.isUIblocked = false;
      this.props.onClose(DialogActionType.submit);
    }
  };

  render() {
    return (
      <CommonModal
        fullScreen
        bodyContentClassName={style.bodyContentClassName}
        title="Update to last image"
        confirm="UPDATE"
        cancel="button.cancel"
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        className={style.modalBody}
        isUIblocked={this.isUIblocked}
      >
        <table className={style.table}>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="Microservice name" />
              </th>
              <th>
                <FormattedMessage id="Last image for update" />
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.microservices.map(service => {
              const shortImageName = service.image.split('/').pop();

              return (
                <tr key={service.longName}>
                  <td>{service.longName}</td>
                  <td>
                    <WithPopper
                      title={
                        <span
                          dangerouslySetInnerHTML={{
                            __html: lineBreakFunc(service.image),
                          }}
                        />
                      }
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: lineBreakFunc(shortImageName || ''),
                        }}
                      />
                    </WithPopper>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </CommonModal>
    );
  }
}

export default UpdateToLastModal;
