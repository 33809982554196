import { DialogActionType, ModalProps } from 'stores/ModalStore';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { action, computed, observable } from 'mobx';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import { FormattedMessage } from 'react-intl';
import TextField from 'components/Form/Fields/TextField/TextField';
import AddSupportToMSForm from './AddSupportToMSForm';
import style from './AddSupportToMSModal.module.scss';
import CommonModal from 'components/Modals/Modal/CommonModal';
import { StorageProviderModes } from 'models/ExternalStorage';

import classNames from 'classnames';
import RadioField from './RadioField/RadioField';
import SelectWithSearch from 'components/Form/Fields/SelectWithSearch/SelectWithSearch';
import { ENV_VAR_REGEXP, testRegExp } from 'helpers/testRegexp';
import WithPopper from 'components/WithPopper/WithPopper';

interface Props {
  clusterName: string;
  projectName: string;
  environmentName: string;
  microserviceName: string;
  msLongName: string;
}

const invalidFieldsMap = {
  dbname: 'dbName',
  host: 'dbHost',
  port: 'dbPort',
  user: 'dbUser',
  password: 'dbPassword',
};

const uniqFields = ['dbName', 'dbHost', 'dbPort', 'dbUser', 'dbPassword'];

@observer
class AddSupportToMSModal extends Component<Props & ModalProps> {
  @observable
  addSupportToMSForm = new AddSupportToMSForm();
  FormControl = bindFormControl(this.addSupportToMSForm);

  @observable
  namesInUse: string[] = [];

  @computed
  get isSubmitBtnDisabled() {
    return !this.addSupportToMSForm.isFormValidByMode;
  }

  @computed
  get providersListOptions() {
    return this.addSupportToMSForm.providersList.map(el => {
      return el.name;
    });
  }

  @computed
  get envOptions() {
    return Object.keys(this.addSupportToMSForm.usageList);
  }

  @computed
  get serviceOptions() {
    return (
      this.addSupportToMSForm.usageList[this.addSupportToMSForm.copyEnv] || []
    );
  }

  @observable
  serverErrors: string[] = [];

  @observable
  serverWarnings: string[] = [];

  @observable
  isUIblocked: boolean = false;

  componentDidMount() {
    this.addSupportToMSForm.fetchAvailablePIForMsSupport(
      this.props.projectName,
      this.props.clusterName,
      this.props.environmentName,
      this.props.microserviceName,
    );
  }

  onModeChange = mode => {
    return () => {
      this.addSupportToMSForm.mode = mode;
      this.addSupportToMSForm.setValue('copyEnv', undefined);
      this.addSupportToMSForm.setValue('copyMicroservice', undefined);
    };
  };

  onProviderInstanceChange = value => {
    this.addSupportToMSForm.setValue('providerInstance', value);
    this.addSupportToMSForm.setValue('copyEnv', undefined);
    this.addSupportToMSForm.setValue('copyMicroservice', undefined);
    if (value) {
      this.addSupportToMSForm.fetchClusterProviderInstancesUsage(
        this.props.projectName,
        this.props.clusterName,
        value,
      );
    }
  };

  onEnvChange = value => {
    this.addSupportToMSForm.setValue('copyEnv', value);
    this.addSupportToMSForm.setValue('copyMicroservice', undefined);
  };

  onClose = () => {
    if (!this.isUIblocked) {
      this.props.onClose(DialogActionType.cancel);
    }
  };

  onConfirm = async () => {
    const {
      clusterName,
      projectName,
      environmentName,
      microserviceName,
      msLongName,
    } = this.props;
    const isFormValid = this.addSupportToMSForm.validate();
    if (isFormValid) {
      this.addSupportToMSForm.validated = false;
      try {
        this.isUIblocked = true;
        await this.addSupportToMSForm.submit(
          projectName,
          clusterName,
          environmentName,
          microserviceName,
          msLongName,
        );
        this.isUIblocked = false;
        this.serverErrors = [];
        this.serverWarnings = [];
        this.props.onClose(DialogActionType.submit);
      } catch (error) {
        if (error.response.status < 500) {
          const fieldNamesString =
            (error.response.data.id.match(/{.+}/) || [])[0] || '';
          if (fieldNamesString) {
            const fieldNames = fieldNamesString
              .substring(1, fieldNamesString.length - 1)
              .split(', ');
            let fields = fieldNames.map(fieldName => fieldName.match(/.+=/)[0]);
            let values = fieldNames.map(fieldName => fieldName.match(/=.+/)[0]);
            values = values.map(field => field.substring(1));
            this.setNamesInUse(values);
            fields = fields.map(field => field.substring(0, field.length - 1));
            fields = fields.map(field => invalidFieldsMap[field]);
            fields.forEach(field => {
              this.addSupportToMSForm.setFieldValid(field, false);
              this.addSupportToMSForm.setErrorsMap(field);
            });
          }
          this.serverErrors = [error.response.data.id] || [];
          this.serverWarnings = error.response.data.warningMessages || [];
        }
      } finally {
        this.isUIblocked = false;
      }
    }
  };

  onVarChange = onChange => (value: string, e) => {
    const length = value.length;
    const isValidRegexp = testRegExp(value, ENV_VAR_REGEXP);
    if (!isValidRegexp && length > 0) {
      return;
    }

    onChange(value, e);
    this.checkUniqFields();
  };

  @action.bound
  setNamesInUse = (names: string[]) => {
    this.namesInUse = names;
  };

  checkUniqFields = () => {
    uniqFields.forEach((uniqField, index) => {
      const uniqValue = this.addSupportToMSForm[uniqField].toUpperCase();
      const nameInUse = this.namesInUse.some(name => name === uniqValue);
      const isNotUniq = uniqFields
        .filter((item, itemIndex) => index !== itemIndex)
        .some(
          fieldItem =>
            this.addSupportToMSForm[fieldItem].length &&
            this.addSupportToMSForm[fieldItem].toUpperCase() === uniqValue,
        );
      if (nameInUse || isNotUniq) {
        this.addSupportToMSForm.setFieldValid(uniqField, false);
      } else {
        this.addSupportToMSForm.setFieldValid(uniqField, true);
      }
      this.addSupportToMSForm.setErrorsMap(uniqField);
    });
  };

  render() {
    const FormControl = this.FormControl;

    return (
      <CommonModal
        className={style.modal}
        bodyClassName={style.body}
        title="external.storage.ms.support.add.header"
        cancel="button.cancel"
        confirm="button.add"
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        isSubmitBtnDisabled={this.isSubmitBtnDisabled}
        serverErrors={this.serverErrors}
        serverWarnings={this.serverWarnings}
        isUIblocked={this.isUIblocked}
      >
        <div className={style.sectionWrapper}>
          <div className={style.formControlWrapper}>
            <FormControl
              withoutLabel
              className={style.input}
              name="providerInstance"
              render={props => (
                <SelectWithSearch
                  label={'provider.instance'}
                  {...props}
                  options={this.providersListOptions}
                  onChange={this.onProviderInstanceChange}
                />
              )}
            />
          </div>
        </div>
        <div className={style.sectionWrapper}>
          <div className={style.text}>
            <FormattedMessage id="external.storage.ms.support.add.text1" />
          </div>
          <div className={style.checkboxContainer}>
            <RadioField
              text1="empty"
              text2="empty.description"
              selected={
                this.addSupportToMSForm.mode === StorageProviderModes.EMPTY
              }
              onClick={this.onModeChange(StorageProviderModes.EMPTY)}
            />
            <RadioField
              text1="copy.based.on.env"
              text2="copy.based.on.env.description"
              selected={
                this.addSupportToMSForm.mode ===
                StorageProviderModes.COPY_BASED_ON_ENV
              }
              onClick={this.onModeChange(
                StorageProviderModes.COPY_BASED_ON_ENV,
              )}
            />
          </div>

          {this.addSupportToMSForm.mode ===
            StorageProviderModes.COPY_BASED_ON_ENV && (
            <>
              <div className={style.formControlWrapper}>
                <WithPopper
                  title={
                    <FormattedMessage id="external.storage.ms.select.provider.instance" />
                  }
                  hidden={!!this.addSupportToMSForm.providerInstance}
                  className={style.popperWrapper}
                >
                  <FormControl
                    withoutLabel
                    className={style.input}
                    name="copyEnv"
                    render={props => (
                      <SelectWithSearch
                        {...props}
                        label={'environment.name'}
                        options={this.envOptions}
                        onChange={this.onEnvChange}
                        disabled={!this.addSupportToMSForm.providerInstance}
                      />
                    )}
                  />
                </WithPopper>
              </div>
              <div className={style.formControlWrapper}>
                <WithPopper
                  title={
                    <FormattedMessage
                      id={
                        !this.addSupportToMSForm.providerInstance
                          ? 'external.storage.ms.select.provider.instance'
                          : 'external.storage.ms.select.environment'
                      }
                    />
                  }
                  hidden={!!this.addSupportToMSForm.copyEnv}
                  className={style.popperWrapper}
                >
                  <FormControl
                    withoutLabel
                    className={style.input}
                    name="copyMicroservice"
                    render={props => (
                      <SelectWithSearch
                        {...props}
                        label={'microservice.name'}
                        options={this.serviceOptions}
                        disabled={!this.addSupportToMSForm.copyEnv}
                      />
                    )}
                  />
                </WithPopper>
              </div>
            </>
          )}
        </div>
        <div className={style.sectionWrapper}>
          <div className={style.text}>
            <FormattedMessage id="external.storage.ms.support.add.text2" />
          </div>
          <div className={classNames(style.formControlWrapper)}>
            <FormControl
              className={style.input}
              name="dbName"
              render={props => (
                <TextField
                  {...props}
                  toUpperCase
                  onChange={this.onVarChange(props.onChange)}
                />
              )}
              immediateValidation={true}
            />
          </div>
          <div className={style.inputSection}>
            <div
              className={classNames(
                style.formControlWrapper,
                style.formControlWrapper__half,
              )}
            >
              <FormControl
                className={style.input}
                name="dbHost"
                render={props => (
                  <TextField
                    {...props}
                    toUpperCase
                    onChange={this.onVarChange(props.onChange)}
                  />
                )}
                immediateValidation={true}
              />
            </div>
            <div
              className={classNames(
                style.formControlWrapper,
                style.formControlWrapper__half,
              )}
            >
              <FormControl
                className={style.input}
                name="dbPort"
                render={props => (
                  <TextField
                    {...props}
                    toUpperCase
                    onChange={this.onVarChange(props.onChange)}
                  />
                )}
                immediateValidation={true}
              />
            </div>
          </div>
          <div className={style.inputSection}>
            <div
              className={classNames(
                style.formControlWrapper,
                style.formControlWrapper__half,
              )}
            >
              <FormControl
                className={style.input}
                name="dbUser"
                render={props => (
                  <TextField
                    {...props}
                    toUpperCase
                    onChange={this.onVarChange(props.onChange)}
                  />
                )}
                immediateValidation={true}
              />
            </div>
            <div
              className={classNames(
                style.formControlWrapper,
                style.formControlWrapper__half,
              )}
            >
              <FormControl
                className={style.input}
                name="dbPassword"
                render={props => (
                  <TextField
                    {...props}
                    toUpperCase
                    onChange={this.onVarChange(props.onChange)}
                  />
                )}
                immediateValidation={true}
              />
            </div>
          </div>
        </div>
      </CommonModal>
    );
  }
}

export default AddSupportToMSModal;
