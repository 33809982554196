import {
  BaseFormModel,
  displayName,
  isRequired,
  hasMinMaxLength,
  isMatchRegExp,
} from '../../../stores/BaseForm';
import { observable } from 'mobx';
import { KEY_REGEXP, MS_KEY_START_END_REGEXP } from 'helpers/testRegexp';

class CreateMicroserviceForm extends BaseFormModel {
  @observable
  @displayName('microservice.modal.form.label.name')
  @isRequired('form.field.is.required')
  longName: string = '';

  @observable
  @displayName('microservice.modal.form.label.key')
  @hasMinMaxLength(1, 63, 'validation.ms.key.length')
  @isMatchRegExp(KEY_REGEXP, 'validation.ms.key.characters')
  @isMatchRegExp(MS_KEY_START_END_REGEXP, 'validation.ms.key.startend')
  key: string = '';

  @observable
  @displayName('microservice.modal.form.label.image')
  @isRequired('form.field.is.required')
  image: string = '';

  @observable
  @displayName('container.port')
  port: string = '';

  @observable
  @displayName('host')
  host: string = '';

  @observable
  @displayName('path')
  path: string = '';

  @observable
  @displayName('certSecret')
  certSecret: string = '';

  @observable
  @displayName('clusterIssuer')
  clusterIssuer: string = '';
}

export default CreateMicroserviceForm;
