import React, { FC } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { stringToDateTime } from 'helpers/datetime';
import { EnvironmentDetails } from 'models/Environment';
import { AlertType } from 'models/Alert';
import RootStore from 'stores/RootStore';
import ModalStore from 'stores/ModalStore';
import EnvironmentStore from 'stores/EnvironmentStore';
import { DisplayedAlerts } from 'stores/AlertsStore';

import ExternalLinksMenu from 'components/ExternalLinksMenu/ExternalLinksMenu';
import Alert from 'components/Alert/Alert';
import BranchLabel from 'components/BranchLabel/BranchLabel';
import ActionMenu from 'components/ActionMenu/ActionMenu';
import { IActionItem } from 'components/ActionMenu/types';
import ApplicationLinks from './ApplicationLinks/ApplicationLinks';
import AutoUpdateCheckbox from './AutoUpdateCheckbox';
import CloneEnvironmentModal from './CloneEnvironmentModal/CloneEnvironmentModal';
import DeleteEnvironmentModal from './DeleteEnvironmentModal/DeleteEnvironmentModal';
import InfoLine from './InfoLine/InfoLine';

import style from './EnvironmentPageHeader.module.scss';

interface Props {
  environmentStore: EnvironmentStore;
  environment: EnvironmentDetails;
  clusterName?: string;
  deleteEnvironment: (onDelete: () => void) => Promise<any>;
  alertsStore?;
}

const EnvironmentPageHeader: FC<Props> = inject('alertsStore')(
  observer(
    ({
      environment,
      clusterName,
      deleteEnvironment,
      alertsStore,
      environmentStore,
    }) => {
      const {
        name,
        longName,
        links,
        createDate,
        updateDate,
        autoUpdate,
        branches,
      } = environment.info;

      const linksData = [
        {
          path: links.logging,
          name: 'link.logging',
          icon: 'kibana',
        },
        {
          path: links.monitoring,
          name: 'link.monitoring',
          icon: 'grafana',
        },
        {
          path: links.registry,
          name: 'link.registry',
          icon: 'docker',
        },
      ];

      const alerts = alertsStore.getAlertsByFilters(
        [
          {
            clusterReference: clusterName,
            environmentReference: environment.info.name,
          },
        ],
        alertsStore.alerts,
        DisplayedAlerts.Visible,
        alertsStore.hiddenAlertsCounters,
      );

      const openCloneEnvironmentModal = async () => {
        ModalStore.showModal(CloneEnvironmentModal, {
          clusterName: clusterName || '',
          projectName: RootStore.currentProject,
          basedOn: environment.info.name,
        });
      };

      const openDeleteModal = () => {
        ModalStore.showModal(DeleteEnvironmentModal, {
          clusterName: clusterName || '',
          projectName: RootStore.currentProject,
          environmentName: environment.info.longName,
          deleteEnvironment,
        });
      };

      const toggleAlertDrawer = () => {
        alertsStore.showAlertDrawer([], 'EnvironmentPageHeader');
      };

      const menuItems: IActionItem[] = [
        {
          label: <FormattedMessage id="environment.btn.clone" />,
          icon: 'copy',
          action: openCloneEnvironmentModal,
        },
        {
          label: <FormattedMessage id="environment.btn.delete" />,
          icon: 'deleteEmpty',
          action: openDeleteModal,
        },
      ];

      return (
        <div className={style.mainContainer}>
          <div className={style.containerLine1}>
            <div className={style.title}>
              <div className={style.firstLine}>
                <span>
                  {longName} <FormattedMessage id="environment.title" />
                </span>
                <BranchLabel
                  styles={{ container: style.branchLabel }}
                  branches={branches || []}
                />
                {!!alerts.length && (
                  <Alert
                    onClick={toggleAlertDrawer}
                    type={AlertType.Error}
                    className={style.alert}
                  >
                    {alerts.length}
                  </Alert>
                )}
              </div>
              <div className={style.secondLine}>
                <span>ID:</span>
                {name}
              </div>
            </div>
            <div className={style.delimiter} />
            <AutoUpdateCheckbox
              autoUpdate={autoUpdate}
              environmentStore={environmentStore}
            />
            <ActionMenu items={menuItems} styles={{ menu: style.actionMenu }} />
          </div>
          <div className={style.containerLine2}>
            <InfoLine
              className={style.infoLine}
              label="updated.time"
              value={updateDate && stringToDateTime(updateDate.toString())}
            />
            <div className={style.delimiter} />
            <InfoLine
              className={style.infoLine}
              label="created.time"
              value={createDate && stringToDateTime(createDate.toString())}
            />
            {links.entrypoints && links.entrypoints.length > 0 && (
              <>
                <div className={style.delimiter} />
                <ApplicationLinks links={links.entrypoints} />
              </>
            )}
            <div
              className={classNames(style.delimiter, style.delimiter__last)}
            />
            <ExternalLinksMenu links={linksData} />
          </div>
        </div>
      );
    },
  ),
);

export default EnvironmentPageHeader;
