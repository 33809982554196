import React from 'react';
import { observer, inject } from 'mobx-react';

import Status from '../../Status/Status';
import style from './ListItem.module.scss';
import { Alert } from 'models/Alert';
import Icon from 'components/Icon/Icon';
import AlertsStore from 'stores/AlertsStore';
import WithPopper from 'components/WithPopper/WithPopper';
import { FormattedMessage } from 'react-intl';

interface Props {
  alert: Alert;
  alertsStore?: AlertsStore;
  alertsGroupElement?: HTMLDivElement | null;
}

@inject('alertsStore')
@observer
class ListItem extends React.Component<Props> {
  render() {
    const { alert, alertsStore, alertsGroupElement } = this.props;

    if (!alertsStore) {
      return;
    }

    const isHidden = () => {
      return alertsStore.hiddenAlertsCounters.some(
        hiddenAlertsCounter =>
          JSON.stringify(alert) === hiddenAlertsCounter.alert,
      );
    };

    const isAlertHidden = isHidden();
    const iconTooltipMessage = isAlertHidden ? 'show.alert' : 'hide.alert';
    const iconType = isAlertHidden ? 'visible' : 'hidden';

    return (
      <div className={style.itemContainer}>
        <div className={style.statusContainer}>
          <Status statusType={alert.type} />
        </div>
        <span className={style.text}>{alert.message}</span>
        <WithPopper
          className={style.popper}
          title={<FormattedMessage id={iconTooltipMessage} />}
          position="absolute"
          boundary={alertsGroupElement}
        >
          <Icon
            className={style.icon}
            onClick={() => alertsStore.toggleAlertVisibility(this.props.alert)}
            type={iconType}
          />
        </WithPopper>
      </div>
    );
  }
}

export default ListItem;
