import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { observer, inject } from 'mobx-react';

import { Environment } from '../../../models/Environment';
import Status from '../../Status/Status';
import Alert from 'components/Alert/Alert';
import { AlertsFilter, AlertType } from 'models/Alert';

import style from './EnvironmentItem.module.scss';
import { DisplayedAlerts } from 'stores/AlertsStore';

interface Props {
  environment: Environment;
  gotoEnvironment: (environmentName: string) => void;
  alertsFilter: AlertsFilter;
  alertsStore?;
}

const EnvironmentItem: React.FC<Props> = inject('alertsStore')(
  observer(({ environment, gotoEnvironment, alertsFilter, alertsStore }) => {
    const onClick = () => gotoEnvironment(environment.name);

    let alerts = alertsStore.getAlertsByFilters(
      [alertsFilter],
      alertsStore.alerts,
      DisplayedAlerts.Visible,
      alertsStore.hiddenAlertsCounters,
    );

    React.useEffect(() => {
      alerts = alertsStore.getAlertsByFilters(
        [alertsFilter],
        alertsStore.alerts,
        DisplayedAlerts.Visible,
        alertsStore.hiddenAlertsCounters,
      );
    }, [alertsStore.alerts]);

    const toggleAlertDrawer = event => {
      event.stopPropagation();
      alertsStore.showAlertDrawer(
        [alertsFilter],
        `EnvironmentItem${environment.name}${environment.createDate}`,
      );
    };

    const isAlertsOpened =
      alertsStore.isOpen && _.isEqual(alertsStore.filter, alertsFilter);

    return (
      <>
        <div
          className={classNames({ [style.alertsOverlay]: isAlertsOpened })}
        />
        <div
          className={classNames(style.itemContainer, {
            [style.alertsOpened]: isAlertsOpened,
          })}
          onClick={onClick}
        >
          <Status className={style.status} />
          <span className={style.text}>{environment.longName}</span>
          <div className={style.alertContainer}>
            {!!alerts.length && (
              <Alert
                onClick={toggleAlertDrawer}
                type={AlertType.Error}
                className={style.alert}
                noIcon={true}
              >
                {alerts.length}
              </Alert>
            )}
          </div>
        </div>
      </>
    );
  }),
);

export default EnvironmentItem;
