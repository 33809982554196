import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PopoverStore from 'stores/PopoverStore';

export const Portal = ({ children }: React.PropsWithChildren<{}>) => {
  const [container] = useState<Element | null>(PopoverStore.getContainer());
  const [element] = useState(() => document.createElement('div'));

  useEffect(() => {
    container?.appendChild(element);

    return () => {
      container?.removeChild(element);
    };
  }, []);

  return ReactDOM.createPortal(children, element);
};
