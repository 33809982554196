export type ArtifactStatus = 'uptodate' | 'outofdate' | 'obsolete' | 'unknown';
export type SyncState =
  | 'synced'
  | 'not_in_git'
  | 'not_in_k8s'
  | 'diff'
  | 'no_hash'
  | 'unknown';

export enum PodRuntimeStatus {
  Initialization = 'Initialization',
  Alive = 'Alive',
  Warning = 'Warning',
  Error = 'Error',
}

export class RuntimeState {
  existing: number | string;
  desired: number | string;
  runtimeStatus: PodRuntimeStatus;
}
