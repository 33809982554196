import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import style from './InfoLine.module.scss';

interface InfoLineProps {
  label: string;
  value: string | null | undefined;
  className?: string;
  valueClassName?: string;
}

const InfoLine: React.FunctionComponent<InfoLineProps> = ({
  label,
  value,
  className,
  valueClassName,
}) => (
  <div className={classNames(className, style.container)}>
    <span className={style.label}>
      <FormattedMessage id={label} />:
    </span>
    <span className={classNames(style.value, valueClassName)}>{value}</span>
  </div>
);

export default InfoLine;
