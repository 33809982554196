import React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { ITemplate } from 'models/Template';

import TemplatesTableItem from './TemplatesTableItem';

import style from './TemplatesTable.module.scss';

interface Props {
  templates: ITemplate[];
  onViewTemplate: (template: ITemplate) => () => void;
  onEditTemplate: (template: ITemplate) => () => void;
  onDeleteTemplate: (name: string) => () => void;
}

const TemplatesTable = observer((props: Props) => {
  const { templates, onViewTemplate, onEditTemplate, onDeleteTemplate } = props;

  return (
    <table className={classNames(style.table, style.templatesTable)}>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="templates.name" />
          </th>
          <th>
            <FormattedMessage id="templates.description" />
          </th>
          <th />
        </tr>
      </thead>

      <tbody>
        {templates.map(template => (
          <TemplatesTableItem
            key={template.name}
            template={template}
            onViewTemplate={onViewTemplate(template)}
            onEditTemplate={onEditTemplate(template)}
            onDeleteTemplate={onDeleteTemplate(template.name)}
          />
        ))}
      </tbody>
    </table>
  );
});

export default TemplatesTable;
