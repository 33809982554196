import React, { FC } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import style from './MonitoringCard.module.scss';

import ClusterCardWrapper from '../ClusterCardWrapper/ClusterCardWrapper';
import ProgressBar, {
  ProgressBarStatus,
} from 'components/ProgressBar/ProgressBar';
import Metric from 'models/Metric';
import { ClusterDetails, ClusterState } from 'models/Cluster';

interface Props {
  cluster: ClusterDetails;
  className?: string;
  onCollapse?: () => void;
}

const MonitoringCard: FC<Props> = ({
  cluster,
  className = '',
  onCollapse = () => undefined,
}) => {
  const { cpu, memory } = cluster;
  const calculatePercent = (item: Metric | null | undefined) =>
    !item ? item : (item.used / item.available) * 100;

  const toGib = bytes => {
    return parseFloat((bytes / 1000000000).toFixed(2));
  };

  return (
    <ClusterCardWrapper
      className={classNames(className)}
      title="page.project.monitoring"
      onCollapse={onCollapse}
    >
      <ProgressBar
        isUnknown={cluster.state === ClusterState.Unknown}
        unknownDescription={'unknown.cores.used'}
        name={<FormattedMessage id="page.project.cpu.title" />}
        value={
          calculatePercent(
            cluster.state === ClusterState.Stopped ||
              cluster.state === ClusterState.Unknown
              ? undefined
              : cpu,
          ) as number
        }
        status={
          Number(calculatePercent(memory)) > 90
            ? ProgressBarStatus.ERROR
            : ProgressBarStatus.PRIMARY
        }
        description={
          <FormattedMessage
            id="page.project.cpu.used"
            values={{
              part: cpu ? parseFloat(cpu.used.toFixed(2)) : undefined,
              total: cpu ? parseFloat(cpu.available.toFixed(2)) : undefined,
            }}
          />
        }
        styles={{ mainContainer: style.progressBar }}
      />
      <ProgressBar
        isUnknown={cluster.state === ClusterState.Unknown}
        unknownDescription={'unknown.gib.used'}
        name={<FormattedMessage id="page.project.memory.title" />}
        value={
          calculatePercent(
            cluster.state === ClusterState.Stopped ||
              cluster.state === ClusterState.Unknown
              ? undefined
              : memory,
          ) as number
        }
        status={
          Number(calculatePercent(memory)) > 90
            ? ProgressBarStatus.ERROR
            : ProgressBarStatus.PRIMARY
        }
        description={
          <FormattedMessage
            id="page.project.gib.used"
            values={{
              part: memory ? toGib(memory.used) : undefined,
              total: memory ? toGib(memory.available) : undefined,
            }}
          />
        }
        styles={{ mainContainer: style.progressBar }}
      />
    </ClusterCardWrapper>
  );
};

export default MonitoringCard;
