export type Time = number;

export const MILLISECONDS_PER_SECOND = 1000;
export const MILLISECONDS_PER_MINUTE = 60 * 1000;
export const MILLISECONDS_PER_HOUR = 60 * MILLISECONDS_PER_MINUTE;
export const MILLISECONDS_PER_DAY = 24 * MILLISECONDS_PER_HOUR;

export const DATE_TIME_LOCALE = 'ru';

export const DATE_FORMAT = 'dd.MM.yyyy';

export enum DateIntervalType {
  years = 'date.years',
  months = 'date.months',
  days = 'date.days',
  hours = 'date.hours',
  minutes = 'date.minutes',
  seconds = 'date.seconds',
}

const dateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

const dateFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

function getLocalDate(dateTimeISO: string, options, locale = DATE_TIME_LOCALE) {
  return new Date(dateTimeISO).toLocaleString(locale, options);
}

export function stringToDateTime(dateTime: string): string {
  const dateTimeISO = dateTime.endsWith('Z') ? dateTime : dateTime + 'Z';

  return getLocalDate(dateTimeISO, dateTimeFormatOptions);
}

export function stringToDate(dateTime: string): string {
  const dateTimeISO = dateTime.endsWith('Z') ? dateTime : dateTime + 'Z';

  return getLocalDate(dateTimeISO, dateFormatOptions);
}

export function stringToTime(date: string, showSeconds?: boolean) {
  const options = {
    hour: '2-digit',
    minute: '2-digit',
    second: showSeconds ? '2-digit' : undefined,
  };

  return getLocalDate(date, options);
}

export function setParsedHoursToDate(str: string) {
  return `2020-02-02T${str}`;
}

export function getTime(
  hours: number,
  minutes: number = 0,
  seconds: number = 0,
  milliseconds: number = 0,
): Time {
  // return Date.UTC(1970, 0, 1, hours, minutes, seconds, milliseconds);
  return (
    hours * MILLISECONDS_PER_HOUR +
    minutes * MILLISECONDS_PER_MINUTE +
    seconds * MILLISECONDS_PER_SECOND +
    milliseconds
  );
}

export function getTimeFromDate(date: Date): Time {
  return getTime(date.getHours(), date.getMinutes(), date.getSeconds());
}

export function setTimeToDate(date: Date, time: Time): Date {
  const result = new Date(date);

  result.setHours(0);
  result.setMinutes(0);
  result.setSeconds(0);
  result.setMilliseconds(time);

  return result;
}

export function dateAdd(
  date: Date,
  count: number,
  period: 'year' | 'month' | 'week' | 'hour' | 'day' | 'minute' | 'second',
): Date {
  const result = new Date(date);

  switch (period) {
    case 'year':
      result.setFullYear(result.getFullYear() + count);
      break;
    case 'month':
      result.setMonth(result.getMonth() + count);
      break;
    case 'week':
      result.setDate(result.getDate() + 7 * count);
      break;
    case 'day':
      result.setDate(result.getDate() + count);
      break;
    case 'hour':
      result.setHours(result.getHours() + count);
      break;
    case 'minute':
      result.setMinutes(result.getMinutes() + count);
      break;
    case 'second':
      result.setSeconds(result.getSeconds() + count);
      break;
  }

  return result;
}

export function dateStartOf(
  date: Date,
  period: 'month' | 'week' | 'hour' | 'day' | 'minute' | 'second',
): Date {
  const result = new Date(date);

  if (period === 'week') {
    result.setDate(result.getDate() - ((result.getDay() + 6) % 7));
  }

  /* eslint-disable no-fallthrough */
  switch (period) {
    case 'month':
      result.setDate(1);
    case 'week':
    case 'day':
      result.setHours(0);
    case 'hour':
      result.setMinutes(0);
    case 'minute':
      result.setSeconds(0);
    case 'second':
      result.setMilliseconds(0);
  }
  /* eslint-enable no-fallthrough */

  return result;
}

export function dateEndOf(
  date: Date,
  period: 'month' | 'week' | 'hour' | 'day' | 'minute' | 'second',
): Date {
  const result = new Date(date);

  if (period === 'week') {
    result.setDate(result.getDate() + ((7 - result.getDay()) % 7));
  }

  /* eslint-disable no-fallthrough */
  switch (period) {
    case 'month':
      result.setDate(1);
      result.setMonth(result.getMonth() + 1);
      result.setDate(0);
    case 'week':
    case 'day':
      result.setHours(23);
    case 'hour':
      result.setMinutes(59);
    case 'minute':
      result.setSeconds(59);
    case 'second':
      result.setMilliseconds(999);
  }
  /* eslint-enable no-fallthrough */

  return result;
}

export function dateToString(
  dateTime: Date,
  delimiter = '.',
  revers = false,
): string {
  const day = dateTime.getDate().toString().padStart(2, '0');
  const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
  const year = dateTime.getFullYear();

  return revers
    ? [year, month, day].join(delimiter)
    : [day, month, year].join(delimiter);
}

export function getDayOfWeek(date: string | Date = new Date()) {
  const day = new Date(date).getDay() - 1;
  return day === -1 ? 6 : day;
}

export function timeSince(dateString) {
  const date = new Date(dateString);

  const seconds = Math.floor((Number(new Date()) - Number(date)) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval >= 1) {
    return { interval, type: DateIntervalType.years };
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return { interval, type: DateIntervalType.months };
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return { interval, type: DateIntervalType.days };
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return { interval, type: DateIntervalType.hours };
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return { interval, type: DateIntervalType.minutes };
  }
  return { interval: Math.floor(seconds), type: DateIntervalType.seconds };
}

// returns diff between now and dateString in milliseconds
export function timeDiff(dateString: Date): number {
  const now = +new Date(new Date().toUTCString());
  const dateInThePast = +new Date(new Date(dateString).toUTCString());
  return now - dateInThePast;
}
