import Metric from './Metric';
import { Environment } from './Environment';

export enum ClusterState {
  Running = 'running',
  Stopped = 'stopped',
  Unknown = 'unknown',
}

export enum ClusterType {
  Dev = 'dev',
  Prod = 'prod',
}

export enum ClusterAction {
  ON = 'on',
  OFF = 'off',
}

export class Cluster {
  name: string;
  type: ClusterType;
}

export class ClusterDetails {
  actions: {
    switchEnabled: boolean;
    scheduleEnabled: boolean;
  };
  info: Cluster;
  state: ClusterState;
  cpu?: Metric;
  memory?: Metric;
  storage?: Metric;
  requestCountPerHour?: number;
  errorCountPerHour?: number;
  nodeCount: number;
  environments: Environment[];
  links: {
    stateGit?: string;
    logging?: string;
    cloudProvider?: string;
    monitoring?: string;
    registry?: string;
    storageManagerLogging?: string;
  };
  externalStorageEnabled: boolean;
}

export enum Week {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday',
}

export class ClusterSchedule {
  enabled: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  startTime?: string;
  endTime?: string;
}

export const ClusterStateAlertTypes = {
  running: 'Info',
  stopped: 'Unset',
  unknown: 'Error',
};

export interface ExternalStorageStatus {
  enabled: boolean;
}
