import React from 'react';

import ActionMenuWithPopover from 'components/ActionMenu/ActionMenuWithPopover';
import { IActionItem } from 'components/ActionMenu/types';
import { BoundaryElementType } from 'components/FloatingComponents/types';
import Icon from 'components/Icon/Icon';

import style from 'components/ResourseLinks/ResourseLinks.module.scss';

interface IProps {
  links: string[];
  boundaryElement?: BoundaryElementType;
}

const ResourceLinks = ({ links, boundaryElement }: IProps) => {
  const onClick = link => () => {
    window.open(link);
  };

  const items: IActionItem[] = links.map(link => ({
    action: onClick(link),
    label: <span>{link}</span>,
  }));

  return (
    <ActionMenuWithPopover
      items={items}
      openMenuComponent={toggleList => (
        <Icon
          type="externalLink"
          onClick={toggleList}
          className={style.linkIcon}
        />
      )}
      styles={{
        menu: style.menu,
        menuItem: style.menuItem,
      }}
      boundaryElement={boundaryElement}
    />
  );
};

export default ResourceLinks;
