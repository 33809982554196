import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import style from './TemplateTabInfo.module.scss';

interface TemplateTabInfoProps {
  title: string;
  params: {
    templatesAmount: number;
    variablesAmount: number;
  };
  isActive?: boolean;
}

@observer
class TemplateTabInfo extends Component<TemplateTabInfoProps> {
  render() {
    const { title, params, isActive } = this.props;

    return (
      <>
        <h2 className={classNames(style.tab__title)}>
          <FormattedMessage id={title} />
        </h2>

        <div
          className={classNames(style.tab__info, { [style.active]: isActive })}
        >
          <div className={style.prop}>
            <span className={style.prop__name}>
              <FormattedMessage id="templates.title" />
            </span>
            <span className={style.prop__amount}>{params.templatesAmount}</span>
          </div>
          <div className={style.prop}>
            <span className={style.prop__name}>
              <FormattedMessage id="variables.title" />
            </span>
            <span className={style.prop__amount}>{params.variablesAmount}</span>
          </div>
        </div>
      </>
    );
  }
}

export default TemplateTabInfo;
