import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { OthersItem } from '../OthersResourcesItem/OthersItem';
import { EnvironmentOther } from 'models/Environment';

import style from './EnvironmentOthers.module.scss';

interface Props {
  others: EnvironmentOther[];
}

@observer
class EnvironmentOthers extends Component<Props> {
  render() {
    const { others } = this.props;

    return (
      <div className={style.tableWrapper}>
        <table className={style.table}>
          <thead className={style.tableHeader}>
            <tr>
              <th>
                <FormattedMessage id="environment.others.name" />
              </th>
              <th>
                <FormattedMessage id="environment.others.type" />
              </th>
              <th>
                <FormattedMessage id="environment.others.sync.state" />
              </th>
            </tr>
          </thead>
          {others && (
            <tbody>
              {others.map(other => (
                <OthersItem key={other.name} other={other} />
              ))}
            </tbody>
          )}
        </table>
      </div>
    );
  }
}

export default EnvironmentOthers;
