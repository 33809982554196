import React, { Component } from 'react';
import classNames from 'classnames';

import { Omit } from 'helpers/types';
import { NUMBER_REGEXP, testRegExp } from 'helpers/testRegexp';

import TextField from '../TextField/TextField';
import style from './NumberField.module.scss';

interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  invalid?: boolean;
  onChange?: (v: string, event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number;
  notEmpty?: boolean;
}

interface State {
  showValue: string | number;
  focused: boolean;
}

class NumberField extends Component<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    if (
      props.notEmpty &&
      state.showValue !== '' &&
      props.value !== state.showValue
    ) {
      return {
        showValue: props.value,
      };
    }

    return null;
  }

  state = {
    showValue: 0,
    focused: false,
  };

  onChange = (value: string, event) => {
    const targetElement = event.target;
    const ss = targetElement.selectionStart;
    const se = targetElement.selectionEnd;
    const length = value.length;
    const isValidRegexp = testRegExp(value, NUMBER_REGEXP);
    if (!isValidRegexp && length > 0) {
      setTimeout(() => {
        targetElement.selectionStart = ss - 1;
        targetElement.selectionEnd = se - 1;
      }, 0);
      return;
    }

    if (this.props.notEmpty) {
      this.setState({
        showValue: value,
      });
    }

    if (value !== '' || !this.props.notEmpty) {
      if (this.props.onChange) {
        this.props.onChange(value, event);
      }
    }
  };

  onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (this.props.notEmpty) {
      this.setState({ focused: false, showValue: this.props.value });
    }

    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (this.props.notEmpty) {
      this.setState({ focused: true });
    }

    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  };

  updateValue = v => event => {
    if (
      (this.props.min !== undefined &&
        Number(this.props.value) + v < this.props.min) ||
      (this.props.max !== undefined &&
        Number(this.props.value) + v > this.props.max)
    ) {
      return;
    }

    if (this.props.onChange) {
      this.props.onChange(Number(this.props.value) + v, event);
    }
  };

  render() {
    const { notEmpty, value, ...props } = this.props;

    return (
      <>
        <div className={style.container}>
          <div
            className={classNames(style.circleBtn, style.circleBtn1, {
              [style.disabled]: Number(this.props.value) === this.props.min,
            })}
            onClick={this.updateValue(-1)}
          >
            -
          </div>
          <TextField
            className={style.field}
            {...props}
            value={notEmpty ? this.state.showValue : value}
            onChange={this.onChange}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            iconType={undefined}
          />
          <div
            className={classNames(style.circleBtn, {
              [style.disabled]:
                this.props.max && Number(this.props.value) >= this.props.max,
            })}
            onClick={this.updateValue(1)}
          >
            +
          </div>
        </div>
      </>
    );
  }
}

export default NumberField;
