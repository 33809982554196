export class StorageProvider {
  providerId: string;
  providerName: string;
  providerVersion?: string;
  providerTag?: string;
  modes?: StorageProviderModes[];
}

export class DatabaseUsage {
  environmentName: string;
  environmentLongName: string;
  microserviceName: string;
  microserviceLongName: string;
}

export class StorageDatabaseInfo {
  databaseName: string;
  usages: DatabaseUsage[];
}

export class StorageProviderInstance {
  isHaveProblem: boolean;
  provider: StorageProvider;
  name: string;
  longName: string;
  host: string;
  port: number;
  userName: string;
  password: string;
  status: StorageProviderStatus;
  statusDescription?: string;
  createDate?: Date;
  databases: StorageDatabaseInfo[];
}

export enum StorageProviderStatus {
  CREATION_PENDING = 'CREATION_PENDING',
  SUCCESSFUL = 'SUCCESSFUL',
  FAILURE = 'FAILURE',
  DELETION_PENDING = 'DELETION_PENDING',
}

export enum StorageProviderModes {
  EMPTY = 'EMPTY',
  COPY_BASED_ON_ENV = 'COPY_BASED_ON_ENV',
  COPY_BASED_ON_BACKUP = 'COPY_BASED_ON_BACKUP',
  SAME_AS_ENV = 'SAME_AS_ENV',
}

export interface StorageInstanceParams {
  clusterName: string;
  projectName: string;
  providerId: string;
}

export interface StorageInstanceForm {
  name: string;
  longName: string;
  host: string;
  port: number;
  userName: string;
  password: string;
}

export interface StorageInstanceResponse {
  warningMessages?: string[];
  errorMessages?: string[];
}
