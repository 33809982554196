import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ValidationResult } from 'stores/BaseForm/types';

import style from './FormControlError.module.scss';

interface FormControlErrorsProps {
  errors: ValidationResult[];
}

const FormControlErrors = ({ errors }: FormControlErrorsProps) => {
  return (
    <>
      {errors &&
        errors.map((error, index) => (
          <div className={style.error} key={error.message}>
            {errors.length > 1 && (
              <span>
                {index + 1}
                {'.'}
              </span>
            )}
            <FormattedMessage
              id={error.message || 'Invalid field'}
              values={{
                ...error.data,
                name: (
                  <FormattedMessage
                    id={error.data ? error.data.name : ''}
                    defaultMessage={error.data ? error.data.name : ''}
                  />
                ),
              }}
            />
          </div>
        ))}
    </>
  );
};

export default FormControlErrors;
