import React from 'react';
import style from './ProviderUserField.module.scss';
import Icon from 'components/Icon/Icon';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

interface Props {
  label: string;
  value: string;
  withVisibility?: boolean;
  className?: string;
}

const Dots = ({ size }) => (
  <>
    {Array(size)
      .fill(0)
      .map(() => (
        <>&bull;</>
      ))}
  </>
);

const ProviderUserField: React.FC<Props> = ({
  label,
  value,
  withVisibility,
  className = '',
}) => {
  const [isVisible, setVisible] = React.useState(false);

  const toggleVisibility = () => {
    setVisible(!isVisible);
  };

  return (
    <div className={classNames(style.fieldContainer, className)}>
      <span className={style.fieldLabel}>
        <FormattedMessage id={label} />
      </span>
      <span className={style.fieldValue}>
        {withVisibility ? (
          isVisible ? (
            value
          ) : (
            <Dots size={value.length} />
          )
        ) : (
          value
        )}
      </span>
      {withVisibility && (
        <div onClick={toggleVisibility} className={style.showBtn}>
          <Icon type={isVisible ? 'visible' : 'hidden'} />
        </div>
      )}
    </div>
  );
};

export default ProviderUserField;
