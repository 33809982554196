import React from 'react';
import { observer } from 'mobx-react';
import { action, computed, observable } from 'mobx';
import { FormattedMessage } from 'react-intl';
import EnvironmentVariablesTable from '../EnvironmentVariablesTable/EnvironmentVariablesTable';
import EnvVariablesTableStore from '../../stores/EnvVariablesTableStore';
import Button, { BtnSize, BtnType } from '../Button/Button';
import Icon from '../Icon/Icon';
import ServiceStore from '../../stores/ServiceStore';
import CircleLoader from '../CircleLoader/CircleLoader';

import style from './MicroservicePageEnvVariablesList.module.scss';

interface Props {
  serviceStore: ServiceStore;
}

@observer
class MicroservicePageEnvVariablesList extends React.Component<Props> {
  @observable
  envVariablesStore: EnvVariablesTableStore;
  @observable
  isSaving = false;
  @observable
  namesInUse: string[] = [];

  constructor(props: Props) {
    super(props);
    this.props.serviceStore.fetchEnvVariables().then(() => {
      this.initEnvVariablesStore();
    });
  }

  @action
  initEnvVariablesStore = () => {
    this.envVariablesStore = new EnvVariablesTableStore(
      this.props.serviceStore.envVariables,
      true,
    );
  };

  onCancel = () => {
    this.initEnvVariablesStore();
    this.setNamesInUse([]);
  };

  @computed
  get saveDisabled() {
    return this.envVariablesStore
      ? !this.envVariablesStore.isEnvVariablesValid
      : false;
  }

  @computed
  get isEmptyTable() {
    if (this.envVariablesStore) {
      const { isPreviewMode, environmentVariablesForms } =
        this.envVariablesStore;
      return isPreviewMode && !environmentVariablesForms.length;
    } else {
      return true;
    }
  }

  setPreviewMode = () => {
    this.envVariablesStore.setPreviewMode(true);
  };

  @action.bound
  setNamesInUse = (names: string[]) => {
    this.namesInUse = names;
  };

  @action
  onClickEditButton = () => {
    if (this.isSaving) {
      return;
    }
    if (!this.envVariablesStore.isPreviewMode) {
      this.isSaving = true;
      this.props.serviceStore
        .updateEnvVariables(this.envVariablesStore.envVariablesData)
        .then(() => {
          this.envVariablesStore = new EnvVariablesTableStore(
            this.props.serviceStore.envVariables,
            true,
          );
          this.setNamesInUse([]);
          this.setPreviewMode();
        })
        .catch(error => {
          let names = (error.message.match(/{.+}/) || [])[0] || '';
          if (names) {
            names = names.substring(1, names.length - 1).split(',');
            names.forEach(name => name.trim());
            this.setNamesInUse(names);
          }
        })
        .finally(() => {
          this.isSaving = false;
        });
    } else {
      this.envVariablesStore.setPreviewMode(
        !this.envVariablesStore.isPreviewMode,
      );
    }
  };

  @action
  addEnvVariable = () => {
    if (this.isSaving) {
      return;
    }
    this.envVariablesStore.setPreviewMode(false);
    this.envVariablesStore.addEnvVariable();
  };

  render() {
    if (!this.envVariablesStore) {
      return (
        <div className={style.envVarPreloader}>
          <CircleLoader />
        </div>
      );
    }
    return (
      <div className={style.envVarContainer}>
        {!this.isEmptyTable && (
          <div className={style.modeButtonContainer}>
            {!this.envVariablesStore.isPreviewMode && !this.isSaving && (
              <Button
                className={style.cancelButton}
                size={BtnSize.Small}
                styleType={BtnType.Secondary}
                onClick={this.onCancel}
              >
                <Icon type={'close'} className={style.cancelIcon} />
                <FormattedMessage id={'button.cancel'} />
              </Button>
            )}
            <Button
              className={style.editButton}
              size={BtnSize.Small}
              styleType={BtnType.Secondary}
              onClick={this.onClickEditButton}
              disabled={this.saveDisabled}
            >
              {this.isSaving ? (
                <CircleLoader className={style.editIcon} />
              ) : (
                <Icon
                  type={this.envVariablesStore.isPreviewMode ? 'edit' : 'save'}
                  className={style.editIcon}
                />
              )}
              <FormattedMessage
                id={
                  this.envVariablesStore.isPreviewMode && !this.isSaving
                    ? 'button.edit'
                    : 'button.save'
                }
              />
            </Button>
          </div>
        )}
        <EnvironmentVariablesTable
          deleteEnvVariable={this.envVariablesStore.deleteEnvVariable}
          addEnvVariable={this.addEnvVariable}
          environmentVariablesForms={
            this.envVariablesStore.environmentVariablesForms
          }
          previewMode={this.envVariablesStore.isPreviewMode}
          className={'envVariablesPage'}
          namesInUse={this.namesInUse}
          isDisabled={this.isSaving}
        />
      </div>
    );
  }
}

export default MicroservicePageEnvVariablesList;
