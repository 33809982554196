import http from 'http/http';
import URLS from 'http/urls';

import { TokensResponse, UserAuthFormData } from './types';
import {
  createLoginParams,
  createRefreshTokenFormData,
  createLogoutParams,
} from './helpers';

export const userApi = {
  login: (data: UserAuthFormData) =>
    http.post<FormData, TokensResponse>(
      URLS.authToken,
      createLoginParams(data),
      {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        replaceParams: { projectName: data.project },
      },
    ),

  refreshToken: (refreshToken: string, projectName: string) =>
    http.post<FormData, TokensResponse>(
      URLS.authToken,
      createRefreshTokenFormData(refreshToken),
      {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        replaceParams: { projectName },
      },
    ),

  logout: (refreshToken: string, projectName: string) =>
    http.post<FormData, any>(URLS.logout, createLogoutParams(refreshToken), {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      replaceParams: { projectName },
    }),
};
