import React, { Component } from 'react';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { ComparingState } from 'models/Environment';
import { ComparingMsItemStore } from 'stores/ApplyMsTo/ComparingMsItemStore';
import { shortImage } from 'stores/helpers';

import Button, { BtnType } from 'components/Button/Button';
import { MsModifiedDetails } from 'components/EnvironmentPageServicesList/Compare/CompareMsItem/MsModifiedDetails';
import { MsNewDetails } from 'components/EnvironmentPageServicesList/Compare/CompareMsItem/MsNewDetails';
import { ComparingMsState } from 'components/EnvironmentPageServicesList/Compare/ComparingMsState/ComparingMsState';
import Switcher from 'components/Form/Fields/Switcher/Switcher';
import Icon from 'components/Icon/Icon';
import { TableBodyRow } from 'components/Table/TableBobyRow';
import TextBadge from 'components/TextBadge/TextBadge';
import WithPopper from 'components/WithPopper/WithPopper';

import style from './CompareMsItem.module.scss';

interface IProps {
  microserviceStore: ComparingMsItemStore;
  isUIDisabled: boolean;
}

@observer
class CompareMsItem extends Component<IProps> {
  disposer;

  constructor(props) {
    super(props);
    const { microserviceStore } = this.props;
    this.disposer = reaction(
      () => !microserviceStore.isValid,
      () => {
        microserviceStore.setIsChecked(false);
      },
    );
  }
  getImage = () => {
    const { microservice } = this.props.microserviceStore;
    const { imageSource, imageTarget } = microservice;
    if (imageSource && imageTarget && imageSource !== imageTarget) {
      return (
        <div className={classNames(style.cellContainer, style.cellImages)}>
          <WithPopper title={imageTarget}>
            <TextBadge>{shortImage(imageTarget)}</TextBadge>
          </WithPopper>
          <Icon type="arrowRight" className={style.iconArrow} />
          <WithPopper title={imageSource}>
            <TextBadge>{shortImage(imageSource)}</TextBadge>
          </WithPopper>
        </div>
      );
    } else if (microservice.msState === ComparingState.DELETED && imageTarget) {
      return (
        <WithPopper title={imageTarget}>
          <TextBadge>{shortImage(imageTarget)}</TextBadge>
        </WithPopper>
      );
    } else if (imageSource) {
      return (
        <WithPopper title={imageSource}>
          <TextBadge>{shortImage(imageSource)}</TextBadge>
        </WithPopper>
      );
    }
  };

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const { microserviceStore, isUIDisabled } = this.props;
    const { microservice, isOpened, toggleMSOpened } = microserviceStore;

    return (
      <>
        <TableBodyRow isOpened={isOpened}>
          <td>
            <Button
              styleType={BtnType.Text}
              onClick={microserviceStore.toggleChecked}
              className={classNames(style.btnIcon, style.btnCheckbox)}
              disabled={!microserviceStore.isValid || isUIDisabled}
            >
              <WithPopper
                title={<FormattedMessage id="comparing.check.warning" />}
                hidden={microserviceStore.isValid}
              >
                <Icon
                  type="checked"
                  className={classNames(style.iconChecked, {
                    [style.iconChecked__checked]: microserviceStore.isChecked,
                  })}
                />
              </WithPopper>
            </Button>
          </td>
          <td>
            <ComparingMsState msState={microservice.msState} />
          </td>
          <td>
            <div className={style.cellContainer}>
              <span className={style.msLongName}>{microservice.longName}</span>
              <span className={style.msName}>{microservice.name}</span>
            </div>
          </td>
          <td>{this.getImage()}</td>
          <td>
            <div className={style.controls}>
              {microservice.msState === ComparingState.NEW ||
              microservice.msState === ComparingState.MODIFIED ||
              microservice.msState === ComparingState.NOTHING_NEW ? (
                <Button
                  styleType={BtnType.Text}
                  onClick={toggleMSOpened}
                  className={style.btnIcon}
                  disabled={isUIDisabled}
                >
                  <Icon
                    type={isOpened ? 'arrowTop' : 'arrowBottom'}
                    className={style.iconArrow}
                  />
                </Button>
              ) : (
                <div className={style.switcherContainer}>
                  <span className={style.switcherLabel}>Delete</span>
                  <Switcher
                    className={style.switcher}
                    checked={!microserviceStore.deleted}
                    onChange={microserviceStore.toggleDelete}
                    disabled={isUIDisabled}
                    withCheck={false}
                  />
                  <span className={style.switcherLabel}>Keep</span>
                </div>
              )}
            </div>
          </td>
        </TableBodyRow>
        {(microservice.msState === ComparingState.MODIFIED ||
          microservice.msState === ComparingState.NOTHING_NEW) && (
          <MsModifiedDetails
            microserviceStore={microserviceStore}
            isOpened={isOpened}
          />
        )}
        {microservice.msState === ComparingState.NEW && (
          <MsNewDetails
            microserviceStore={microserviceStore}
            isOpened={isOpened}
          />
        )}
      </>
    );
  }
}

export { CompareMsItem };
