import { observer } from 'mobx-react';
import React, { Component } from 'react';
import classNames from 'classnames';
import Modal from 'rc-dialog';
import { FormattedMessage } from 'react-intl';

import Icon from '../../Icon/Icon';
import Button, { BtnType } from '../../Button/Button';
import { Icons } from 'components/Icon';

import style from './CommonModal.module.scss';

export enum ModalType {
  Common = 'Common',
  Delete = 'Delete',
}

interface Props {
  className?: string;
  bodyClassName?: string;
  bodyContentClassName?: string;
  footerClassName?: string;
  title?: string;
  headerIcon?: string;
  cancel?: string;
  confirm?: string;
  onLeftCancel?: () => void;
  onClose?: () => void;
  onConfirm?: (() => void) | null;
  withSteps?: boolean;
  onClickBack?: () => void;
  onClickNext?: () => void;
  backBtnText?: string;
  nextBtnText?: string;
  currentStepNumber?: number;
  stepsNumber?: number;
  isCancellable?: boolean;
  isSubmitBtnDisabled?: boolean;
  serverErrors?: string[];
  serverWarnings?: string[];
  isUIblocked?: boolean;
  modalType?: ModalType;
  advancedControls?: React.ReactElement;
  fullScreen?: boolean;
  isLeftCancel?: boolean;
}

@observer
class CommonModal extends Component<Props> {
  render() {
    const {
      className,
      bodyClassName,
      bodyContentClassName,
      footerClassName,
      title = '',
      headerIcon,
      cancel = 'button.cancel',
      confirm = 'button.confirm',
      onConfirm,
      onClose,
      onLeftCancel,
      withSteps,
      currentStepNumber,
      stepsNumber,
      onClickBack,
      onClickNext,
      children,
      isCancellable = true,
      isLeftCancel = false,
      isSubmitBtnDisabled = false,
      serverErrors,
      serverWarnings,
      backBtnText = 'button.back',
      nextBtnText = 'button.next',
      isUIblocked = false,
      modalType = ModalType.Common,
      advancedControls = null,
      fullScreen = false,
    } = this.props;

    return (
      <Modal
        className={classNames(className, style.modal, {
          [style.modalFullScreen]: fullScreen,
        })}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        onClose={onClose}
        visible
        closable={false}
        maskClosable={!isUIblocked}
      >
        <div className={style.mainContainer}>
          <div className={style.header}>
            <Icon
              className={style.headerIcon}
              type={headerIcon as keyof typeof Icons}
            />
            <FormattedMessage id={title as string} />
            <Button
              styleType={BtnType.Text}
              className={style.closeBtn}
              disabled={isUIblocked}
              onClick={onClose}
            >
              <Icon type="close" />
            </Button>
          </div>
          {serverErrors && !!serverErrors.length && (
            <div className={classNames(style.msgList, style.serverErrors)}>
              <Icon type={'exclamation'} className={style.infoBtn} />
              <ul className={style.errorsList}>
                {serverErrors.map((err: string) => (
                  <li key={err}>{err}</li>
                ))}
              </ul>
            </div>
          )}
          {serverWarnings && !!serverWarnings.length && (
            <div className={classNames(style.msgList, style.serverWarnings)}>
              <Icon type={'warning'} className={style.infoBtn} />
              <ul className={style.errorsList}>
                {serverWarnings.map((msg: string) => (
                  <li key={msg}>{msg}</li>
                ))}
              </ul>
            </div>
          )}

          <div className={classNames(style.body, bodyClassName)}>
            <div
              className={classNames(style.bodyContent, bodyContentClassName)}
            >
              {isUIblocked && <div className={style.overlay} />}
              {children}
            </div>
          </div>

          <div className={classNames(style.footer, footerClassName)}>
            <div>
              {withSteps && (
                <Button
                  styleType={BtnType.Secondary}
                  className={style.btn}
                  onClick={onClickBack}
                  disabled={currentStepNumber === 0 || isUIblocked}
                >
                  <FormattedMessage id={backBtnText} />
                </Button>
              )}
              {isLeftCancel && onLeftCancel && (
                <Button
                  className={classNames(style.btnCancel, style.btn)}
                  styleType={
                    modalType === ModalType.Delete
                      ? BtnType.DeleteGhost
                      : BtnType.Ghost
                  }
                  onClick={onLeftCancel}
                  disabled={isUIblocked}
                >
                  <FormattedMessage id={cancel} />
                </Button>
              )}
            </div>

            <div>
              {isCancellable && onClose && (
                <Button
                  className={classNames(style.btnCancel, style.btn)}
                  styleType={
                    modalType === ModalType.Delete
                      ? BtnType.DeleteGhost
                      : BtnType.Ghost
                  }
                  onClick={onClose}
                  disabled={isUIblocked}
                >
                  <FormattedMessage id={cancel} />
                </Button>
              )}

              {withSteps &&
              currentStepNumber !== (stepsNumber as number) - 1 ? (
                <Button
                  className={style.btn}
                  onClick={onClickNext}
                  disabled={isUIblocked}
                >
                  <FormattedMessage id={nextBtnText} />
                </Button>
              ) : (
                <>
                  {advancedControls}
                  {onConfirm && (
                    <Button
                      className={style.btn}
                      onClick={onConfirm}
                      disabled={isSubmitBtnDisabled || isUIblocked}
                      isLoading={isUIblocked}
                      styleType={
                        modalType === ModalType.Delete
                          ? BtnType.Delete
                          : BtnType.Primary
                      }
                    >
                      <FormattedMessage id={confirm} />
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default CommonModal;
