import React, { Component } from 'react';
import { computed, observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';

import RootStore from 'stores/RootStore';
import ClusterHeader from 'components/ClusterHeader/ClusterHeader';
import ClusterStore from 'stores/ClusterStore';

import style from './ClusterPage.module.scss';
import Icon from 'components/Icon/Icon';
import classNames from 'classnames';
import ClusterPageBody from 'components/ClusterPageBody/ClusterPageBody';

interface Props
  extends RouteComponentProps<{
    id: string;
    clusterName: string;
  }> {
  alertsStore?;
}

@inject('alertsStore')
@observer
class ClusterPage extends Component<Props> {
  @observable clusterStore: ClusterStore;

  constructor(props: Props) {
    super(props);

    const { clusterName, id: projectId } = this.props.match.params;
    this.clusterStore = new ClusterStore(clusterName, projectId);
  }

  componentDidUpdate(prevProps) {
    const { clusterName, id: projectId } = this.props.match.params;

    if (
      prevProps.match.params.clusterName !== clusterName ||
      prevProps.match.params.id !== projectId
    ) {
      if (this.clusterStore) {
        this.clusterStore.clearTimer();
      }
      this.clusterStore = new ClusterStore(clusterName, projectId);
    }
  }

  componentWillUnmount() {
    this.clusterStore.clearTimer();
  }

  public render() {
    const { clusterName } = this.props.match.params;

    return (
      <div
        className={classNames(style.wrapper, {
          [style.wrapper__openedAlert]: this.props.alertsStore.isOpen,
        })}
      >
        <div className={style.breadcrumbs}>
          <Link
            className={style.link}
            to={`/project/${RootStore.currentProject}/info`}
          >
            <FormattedMessage id="page.project.dashboard" />
          </Link>
          <Icon className={style.arrowIcon} type="arrowRight" />

          <span className={style.clusterName}>
            <FormattedMessage id="page.project.cluster" /> {clusterName}
          </span>
        </div>

        {!this.clusterStore.pending &&
          !this.clusterStore.cluster &&
          this.clusterNotFound}
        {this.clusterStore.pending ? '' : this.clusterRender}
      </div>
    );
  }

  @computed
  get clusterNotFound() {
    return (
      <div className={style.notFoundContainer}>
        <FormattedMessage id="cluster.not.found" />
      </div>
    );
  }

  @computed
  get clusterRender() {
    const { cluster, fetchCluster } = this.clusterStore;
    if (!cluster) {
      return null;
    }

    return (
      <>
        <ClusterHeader cluster={cluster} clusterStore={this.clusterStore} />
        <ClusterPageBody
          cluster={cluster}
          fetchCluster={fetchCluster}
          addEnvToFavorite={this.clusterStore.addEnvToFavorite}
        />
      </>
    );
  }
}

export default ClusterPage;
