import React, { useRef } from 'react';
import classNames from 'classnames';
import style from './ScopeGroup.module.scss';
import ListItem from '../ListItem/ListItem';
import Icon from 'components/Icon/Icon';

interface Props {
  scope: any;
  alertCount: number;
  scopeKey: any;
}

const sortAlerts = (alert1, alert2) => {
  if (alert1.key < alert2.key) {
    return -1;
  }
  if (alert1.key > alert2.key) {
    return 1;
  }
  if (alert1.message < alert2.message) {
    return -1;
  }
  if (alert1.message > alert2.message) {
    return 1;
  }
  return 0;
};

export const ScopeGroup: React.FC<Props> = ({
  scope,
  scopeKey,
  alertCount,
}) => {
  const [isHidden, setVisibility] = React.useState<boolean>(false);
  const alertsGroupRef = useRef(null);

  const toggleScopeVisibility = () => {
    setVisibility(!isHidden);
  };

  const alertsListRender = alerts => {
    return (
      <>
        {alerts.map((alert, index) => (
          <div key={index}>
            <ListItem
              alert={alert}
              alertsGroupElement={alertsGroupRef.current}
            />
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <div className={style.scopeHeaderWrapper} ref={alertsGroupRef}>
        <span className={style.chevron} onClick={toggleScopeVisibility}>
          <Icon
            className={style.icon}
            type={isHidden ? 'arrowBottom' : 'arrowTop'}
          />
        </span>
        <span className={style.scopeHeader}>{scopeKey}</span>
        <span className={style.countLabel}>{alertCount}</span>
      </div>
      <span className={style.line} />
      <div
        className={classNames(
          style.scopeItemsContainer,
          isHidden && style.scopeItemsContainer__hidden,
        )}
      >
        {scope.map((scopeItem, index) => {
          let alerts;
          if (scopeItem.name) {
            alerts = scopeItem.microservices
              .reduce(
                (acc, microservice) => acc.concat(microservice.alerts),
                [...scopeItem.alerts],
              )
              .sort(sortAlerts);
          }

          return (
            <React.Fragment key={index}>
              {scopeItem.name ? (
                <div className={style.itemContainer}>
                  <div className={style.itemHeader}>
                    <span>{scopeItem.name}</span>
                    <span className={style.countLabel}>{alerts.length}</span>
                  </div>
                  {alertsListRender(alerts)}
                </div>
              ) : (
                <div className={style.itemContainer}>
                  <ListItem alert={scopeItem} />
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};
