import React from 'react';
import classNames from 'classnames';
import style from './TextBadge.module.scss';

export enum TextBadgeTypes {
  ImageInfo = 'imageInfo',
}

interface Props {
  className?: string;
  type?: TextBadgeTypes;
  children?: React.ReactNode;
}

const TextBadge: React.FC<Props> = ({
  className = '',
  type = TextBadgeTypes.ImageInfo,
  children = '',
}) => {
  const classes: string = classNames(style.textBadge, className, {
    [style.textBadge__imageInfo]: type === TextBadgeTypes.ImageInfo,
  });

  return children ? (
    <span className={classes}>
      <span>{children}</span>
    </span>
  ) : null;
};

export default TextBadge;
