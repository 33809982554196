import { action, computed, observable } from 'mobx';
import { sortByField } from 'helpers/array';
import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';
import { IServicePod } from 'models/Pod';
import { IServiceParams } from 'models/Service';

export class PodsStore {
  @observable pods: IServicePod[] = [];
  @observable isLoading = false;
  @observable podsRequested = false;

  readonly serviceParams: IServiceParams;

  constructor(serviceParams: IServiceParams) {
    this.serviceParams = serviceParams;
  }

  @computed
  get sortedPods() {
    return sortByField([...this.pods], 'createDate', true);
  }

  @action.bound
  async fetchServicePods() {
    try {
      this.isLoading = true;

      const { data } = await httpFacade.pods.fetchPods(this.serviceParams);

      this.pods = data;
      this.podsRequested = true;
    } catch (error) {
      this.pods = [];
      Log.warn(error);
    } finally {
      this.isLoading = false;
    }
  }
}
