import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { ServiceDetails } from 'models/Service';
import Status, { StatusType } from 'components/Status/Status';
import Icon from 'components/Icon/Icon';

import style from './MicroservicePageResourceList.module.scss';
import { MicroserviceOthers } from 'components/MicroserviceOthers/MicroserviceOthers';
import WithPopper from 'components/WithPopper/WithPopper';

const syncStateMap = {
  synced: StatusType.Success,
  not_in_git: StatusType.Error,
  not_in_k8s: StatusType.Error,
  diff: StatusType.Error,
  no_hash: StatusType.Error,
  unknown: StatusType.Warning,
};

interface Props {
  service: ServiceDetails;
}

class MicroservicePageResourceList extends React.Component<Props> {
  state = {
    pathsList: {},
  };

  togglePaths = key => () => {
    this.setState({
      pathsList: {
        ...this.state.pathsList,
        [key]: !this.state.pathsList[key],
      },
    });
  };

  render() {
    const { service } = this.props;

    return (
      <>
        <div className={style.mainContainer}>
          <table className={style.table}>
            <tbody>
              {service.serviceIngresses.map(resource => {
                const pathsListIsOpen =
                  this.state.pathsList[resource.clusterUrl];

                const fisrtAndSecondPaths = (resource.paths || []).slice(0, 2);
                const otherPaths = (resource.paths || []).slice(2);

                return (
                  <tr
                    key={resource.clusterUrl}
                    className={classNames({
                      [style.pathsListIsOpen]: pathsListIsOpen,
                    })}
                  >
                    <td>
                      <div className={style.cellContainer}>
                        <span className={style.firstTitle}>
                          {resource.clusterUrl}
                        </span>
                        <span className={style.secondTitle} />
                      </div>
                    </td>
                    <td>
                      <div className={style.cellContainer}>
                        <span className={style.firstTitle}>
                          {resource.host && resource.tls && (
                            <Icon className={style.lockIcon} type="lock" />
                          )}
                          {resource.host || '-'}
                        </span>
                        <span className={style.secondTitle} />
                      </div>
                    </td>
                    <td>
                      <div className={style.cellContainer}>
                        {fisrtAndSecondPaths.map(path => (
                          <span key={path} className={style.firstTitle}>
                            <WithPopper title={path}>
                              <div className={style.pathContainer}>{path}</div>
                            </WithPopper>
                            <a
                              href={`${resource.tls ? 'https://' : 'http://'}${
                                resource.host
                              }${path}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Icon
                                className={style.linkIcon}
                                type="externalLink"
                              />
                            </a>
                          </span>
                        ))}
                        {!fisrtAndSecondPaths.length && <>-</>}
                      </div>
                      <div
                        className={classNames(style.pathsList, {
                          [style.isOpen]: pathsListIsOpen,
                        })}
                      >
                        {otherPaths.map(path => (
                          <span key={path} className={style.firstTitle}>
                            <WithPopper title={path}>
                              <div className={style.pathContainer}>{path}</div>
                            </WithPopper>
                            <a
                              href={`${resource.tls ? 'https://' : 'http://'}${
                                resource.host
                              }${path}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Icon
                                className={style.linkIcon}
                                type="externalLink"
                              />
                            </a>
                          </span>
                        ))}
                      </div>
                    </td>
                    <td>
                      <div className={style.cellContainer}>
                        <span className={style.firstTitle}>
                          <Status
                            statusType={syncStateMap[resource.syncState]}
                            className={style.status}
                          />
                          <span className={style.statusText}>
                            <FormattedMessage id={resource.syncState} />
                          </span>
                        </span>
                        <span className={style.secondTitle} />
                      </div>
                    </td>
                    <td>
                      <div className={style.cellContainer}>
                        {!!otherPaths.length && (
                          <Icon
                            className={style.pathsListArrow}
                            type={pathsListIsOpen ? 'arrowTop' : 'arrowBottom'}
                            onClick={this.togglePaths(resource.clusterUrl)}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <MicroserviceOthers
          others={this.props.service.others}
          deployment={this.props.service.deployment}
        />
      </>
    );
  }
}

export default MicroservicePageResourceList;
