import React from 'react';
import style from 'components/ModalLayout/ModalLayout.module.scss';

interface IProps {
  title: string | JSX.Element;
}

export const ModalLayoutTitle = ({ title }: IProps) => {
  return <div className={style.title}>{title}</div>;
};
