export const testRegExp = (value: string, regexp: RegExp) => regexp.test(value);

export const NAME_REGEXP =
  /^[a-zA-Z0-9!"#$%&'()*+,\-.\/\\:;<=>?@\[\]^_`{|}~№\s]+$/;
export const KEY_WITH_SPEC_CHARS_REGEXP =
  /^[a-z0-9!"#$%&'()*+,\-.\/\\:;<=>?@\[\]^_`{|}~№]+$/;
export const KEY_REGEXP = /^[a-z0-9-]+$/;
export const ENV_KEY_START_END_REGEXP = /^[a-z0-9](.*[a-z0-9])?$/;
export const MS_KEY_START_END_REGEXP = /^[a-z](.*[a-z0-9])?$/;

export const PORT_REGEXP = /^[0-9]+$/;
export const NUMBER_REGEXP = /^[0-9]+$/;
export const IMAGE_REGEXP =
  /^([a-z0-9][a-z0-9.:_-]*[a-z0-9]\/)*[a-z0-9][a-z0-9.:_-]*[a-z0-9]$/;

export const ENV_VAR_REGEXP = /^[a-zA-Z0-9_-]*$/;
export const ENV_VAR_START_REGEXP = /^[a-zA-Z].*$/;

export const TEMPLATE_VAR_REGEXP = /^[a-zA-Z0-9_]*$/;
export const TEMPLATE_NAME_REGEXP = /^[a-zA-Z0-9_-]*$/;
