import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { ILog, PodContainerLogType, WithId } from 'models/Pod';
import {
  EVENT_KEY_ARROW_DOWN,
  EVENT_KEY_ARROW_UP,
  EVENT_KEY_HOME,
  EVENT_KEY_PAGE_DOWN,
  EVENT_KEY_PAGE_UP,
  EVENT_KEY_SPACE,
} from 'types/constants';

import style from './LodsPanel.module.scss';

interface ISnapshot {
  isScrolledToBottom: boolean;
}

interface IProps {
  logs: Array<WithId<ILog>>;
  error?: string;
  isLogsReceivingInterrupted?: boolean;
  styles?: {
    panel?: string;
  };
}

@observer
class LogsPanel extends Component<IProps> {
  logsElementRef = React.createRef<HTMLDivElement>();
  isScrollStopped = false;

  stopScroll = () => {
    this.isScrollStopped = true;
  };

  scrollToBottom = () => {
    const logsElement = this.logsElementRef.current;
    if (logsElement) {
      logsElement.scroll({
        top: logsElement.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  checkIsScrolledToBottom = () => {
    const { current: logsElement } = this.logsElementRef;
    if (!logsElement) {
      return false;
    }

    return (
      logsElement.scrollHeight - logsElement.scrollTop ===
      logsElement.clientHeight
    );
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const navigationKeys = [
      EVENT_KEY_ARROW_DOWN,
      EVENT_KEY_ARROW_UP,
      EVENT_KEY_PAGE_DOWN,
      EVENT_KEY_PAGE_UP,
      EVENT_KEY_HOME,
    ];
    const isNavigationKey =
      navigationKeys.includes(event.key) ||
      (event.key === EVENT_KEY_SPACE && event.shiftKey);

    if (isNavigationKey) {
      this.stopScroll();
    }
  };

  getSnapshotBeforeUpdate(): ISnapshot {
    return { isScrolledToBottom: this.checkIsScrolledToBottom() };
  }

  componentDidUpdate(prevProps: IProps, prevState: any, snapshot: ISnapshot) {
    if (snapshot.isScrolledToBottom) {
      this.isScrollStopped = false;
    }
    if (!this.isScrollStopped) {
      this.scrollToBottom();
    }
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  render() {
    const { logs, error, isLogsReceivingInterrupted, styles } = this.props;

    return (
      <div className={classNames(style.panel, styles?.panel)}>
        <div
          ref={this.logsElementRef}
          tabIndex={0}
          onKeyDown={this.onKeyDown}
          onMouseDown={this.stopScroll}
          onWheel={this.stopScroll}
          className={style.logs}
        >
          {logs.map(it => (
            <p
              key={it.id}
              className={
                it.type === PodContainerLogType.event ? style.eventLog : ''
              }
            >
              {it.type === PodContainerLogType.event && <>{it.date}&nbsp;</>}
              {it.message}
            </p>
          ))}
          {error && <p className={style.errorLog}>{error}</p>}
          {isLogsReceivingInterrupted && (
            <p className={style.errorLog}>
              <FormattedMessage id="logs.receiving.interrupted" />
            </p>
          )}
        </div>
      </div>
    );
  }
}

export default LogsPanel;
