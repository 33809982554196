import React from 'react';
import style from './UsedDB.module.scss';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { StorageDatabaseInfo, DatabaseUsage } from 'models/ExternalStorage';
import { replaceUrlParams } from 'http/helpers';
import { ROUTES } from 'routes/routes';

interface Props
  extends RouteComponentProps<{
    id?: string;
    clusterName?: string;
  }> {
  database: StorageDatabaseInfo;
}

const UsedDB: React.FC<Props> = ({ database, match, history }) => {
  const gotoEnv = (usage: DatabaseUsage) => {
    history.push(
      replaceUrlParams(ROUTES.environment, {
        id: match.params.id,
        clusterName: match.params.clusterName,
        environmentName: usage.environmentName,
      }),
    );
  };

  const gotoMicroservice = (usage: DatabaseUsage) => {
    history.push(
      replaceUrlParams(ROUTES.microservice, {
        id: match.params.id,
        clusterName: match.params.clusterName,
        environmentName: usage.environmentName,
        microserviceName: usage.microserviceName,
      }),
    );
  };

  return (
    <div className={style.container}>
      {database.databaseName}
      {database.usages.map(usage => (
        <div key={usage.microserviceName} className={style.usageContainer}>
          <span onClick={() => gotoEnv(usage)} className={style.link}>
            {usage.environmentLongName}
          </span>
          {` / `}
          <span onClick={() => gotoMicroservice(usage)} className={style.link}>
            {usage.microserviceLongName}
          </span>
        </div>
      ))}
    </div>
  );
};

export default withRouter(UsedDB);
