const URLS = {
  config: '/assets/config.json',
  locale: `/assets/i18n/:locale.json`,

  authToken: '/auth/:projectName/token',

  projects: '/projects',
  project: '/projects/:projectName',
  projectDetails: '/projects/:projectName/details',
  artifacts: '/projects/:projectName/artifacts',

  projectSettings: '/projects/:projectName/settings',
  updateProjectSettings: '/projects/:projectName',
  clusters: '/projects/:projectName/clusters',
  cluster: '/projects/:projectName/clusters/:clusterName',
  clusterActions:
    '/projects/:projectName/clusters/:clusterName/actions/:switch',
  clusterDetails: '/projects/:projectName/clusters/:clusterName/details',
  clusterSchedule: '/projects/:projectName/clusters/:clusterName/schedule',
  clusterProviders:
    '/projects/:projectName/clusters/:clusterName/storage-providers',
  clusterProviderInstances:
    '/projects/:projectName/clusters/:clusterName/storage-providers/instances',

  availablePIForMsSupport:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/microservices/:microserviceName/storage-providers/available-instances',

  deleteProviderInstance:
    '/projects/:projectName/clusters/:clusterName/storage-providers/instances/:providerInstanceName',

  clusterProviderInstancesUsage:
    '/projects/:projectName/clusters/:clusterName/storage-providers/instances/:instanceName/usage',

  encryptMessage:
    '/projects/:projectName/clusters/:clusterName/message/encrypt',

  environments: '/projects/:projectName/clusters/:clusterName/environments',
  environment:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName',
  environmentDetails:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/details',
  environmentOthers:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/others',
  environmentBranches:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/branches',
  switchToBranch:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/branches',
  compare:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/compare',
  applyTo:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/apply',
  deleteEnvironment:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName',
  setAutoUpdateEnvironment:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName',
  updateMSsToLastVersion:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/images/latest',
  services:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/microservices',
  service:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/microservices/:serviceName',
  deleteService:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/microservices/:serviceName',
  scaleMS:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/microservices/:serviceName/scale',

  serviceDetails:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/microservices/:serviceName/details',

  serviceStorageDetails:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/microservices/:serviceName/storage-providers',

  createService:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/microservices',

  restartService:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/microservices/:serviceName/restart',

  updateService:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/microservices/:serviceName/image',

  addSupportToMS:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/microservices/:serviceName/storage-providers',

  serviceEnvVariables:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/microservices/:serviceName/envvars',

  pods: '/projects/:projectName/clusters/:clusterName/environments/:environmentName/microservices/:serviceName/pods',
  podLogsAndEvents:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/microservices/:serviceName/pods/:podName/logsAndEvents',
  podContainers:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/microservices/:serviceName/pods/:podName/containers',
  podContainerLogsAndEvents:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/microservices/:serviceName/pods/:podName/containers/:containerName/logsAndEvents',

  alerts: '/projects/:projectName/alerts',

  logout: '/auth/:projectName/logout',

  externalStorageStatus:
    '/projects/:projectName/clusters/:clusterName/storage-support/status',

  externalStorage:
    '/projects/:projectName/clusters/:clusterName/storage-providers/:providerId/instances',

  serviceStorageProviders:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/microservices/:microserviceName/storage-providers/:providerInstanceName',

  updateMSSupport:
    '/projects/:projectName/clusters/:clusterName/environments/:environmentName/microservices/:microserviceName/storage-providers/:providerInstanceName',

  templates:
    '/projects/:projectName/clusters/:clusterName/templates/:actionId/files',
  template:
    '/projects/:projectName/clusters/:clusterName/templates/:actionId/files/:templateName',
  templatesVariables:
    '/projects/:projectName/clusters/:clusterName/templates/:actionId/vars',
};

export default URLS;
