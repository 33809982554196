import React, { useState, createRef } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { useClickOutside } from 'hooks/useClickOutside';
import Icon from 'components/Icon/Icon';

import style from './SelectWithInput.module.scss';

export interface ISelectOption {
  id: string;
  title: string;
}

interface Props {
  className?: string;
  onChange: (val: ISelectOption) => void;
  options: ISelectOption[];
  value?: string;
  placeholder?: string;
  dropUp?: boolean;
  label?: string;
  disabled?: boolean;
}

export const SelectWithInput: React.FC<Props> = (props: Props) => {
  const {
    options = [],
    className,
    placeholder = '',
    value = '',
    onChange,
    dropUp,
    label,
    disabled,
  } = props;

  const wrapperRef = createRef<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);

  const handleClickOutside = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useClickOutside(wrapperRef, handleClickOutside);

  const onOptionSelect = (val: ISelectOption) => (e: React.SyntheticEvent) => {
    e.stopPropagation();
    onChange(val);
    setIsOpen(false);
  };

  const toggleProjectsList = () => {
    if (disabled) {
      return;
    }
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ id: e.target.value, title: e.target.value });
  };

  const onBlur = () => {
    setInputFocus(false);
  };

  const onFocus = () => {
    setInputFocus(true);
  };

  return (
    <div
      className={classNames(style.wrapper, className)}
      ref={wrapperRef}
      tabIndex={0}
      onClick={toggleProjectsList}
    >
      {label && (
        <label
          className={classNames(style.fieldLabel, {
            [style.fieldLabel__active]: Boolean(value) || inputFocus,
            [style.fieldLabel__inFocus]:
              isOpen && (Boolean(value) || inputFocus),
          })}
        >
          <FormattedMessage id={label} />
        </label>
      )}
      <div
        className={classNames(style.field, {
          [style.fieldActive]: isOpen,
          [style.disabled]: disabled,
        })}
      >
        <input
          onClick={e => isOpen && e.stopPropagation()}
          onFocus={onFocus}
          onBlur={onBlur}
          className={classNames(style.textInput, {
            [style.disabled]: disabled,
          })}
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={inputChangeHandler}
          disabled={disabled}
        />
      </div>
      <Icon
        className={classNames(style.selectIcon, {
          [style.selectIcon__active]: isOpen,
          [style.disabled]: disabled,
        })}
        type={isOpen ? 'arrowTop' : 'arrowBottom'}
      />
      {isOpen && (
        <div
          className={classNames(style.menu, {
            [style.dropUp]: dropUp,
            [style.dropDown]: !dropUp,
          })}
        >
          <ul>
            {options
              .filter(item =>
                item.title.toUpperCase().startsWith(value.toUpperCase()),
              )
              .map(option => (
                <li
                  key={option.title}
                  className={classNames(style.listItem, {
                    [style.active]: value === option.title,
                  })}
                  onClick={onOptionSelect(option)}
                >
                  {option.title}
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};
