import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action, computed, observable } from 'mobx';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import httpFacade from 'http/httpFacade';
import Log from 'helpers/log';
import {
  KEY_WITH_SPEC_CHARS_REGEXP,
  NAME_REGEXP,
  testRegExp,
} from 'helpers/testRegexp';
import { generateKey } from 'helpers/generateKey';
import { sortByName } from 'helpers/array';
import { ActionId, ITemplateVariable } from 'models/Template';
import { IEnvironmentParams } from 'models/Environment';
import EnvVariablesTableStore from 'stores/EnvVariablesTableStore';
import TemplateVarsTableStore, {
  TemplateVarsTableModes,
} from 'stores/TemplateVarsTableStore';
import { ValidationResult } from 'stores/BaseForm/types';
import ModalStore from 'stores/ModalStore';

import CreateMicroserviceForm from './CreateMicroserviceForm';
import NotificationService, {
  fullscreenNotificationOptions,
} from 'components/Notification/NotificationService';
import TextField from 'components/Form/Fields/TextField/TextField';
import Button, { BtnType } from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import { InfoButton, RulePrefix } from 'components/InfoButton/InfoButton';
import RadioField from 'components/AddSupportToMSModal/RadioField/RadioField';
import EnvironmentVariablesTable from 'components/EnvironmentVariablesTable/EnvironmentVariablesTable';
import MSTemplateVariablesTable from 'components/MSTemplateVariablesTable/MSTemplateVariablesTable';
import SelectImageModal from 'components/SelectImageModal/SelectImageModal';
import { bindFormControl } from '../../Form/FormControl/FormControl';

import style from './CreateMicroserviceModal.module.scss';

const VAR_NAME_CERT_SECRET = 'ingressCertSecretName';
const VAR_NAME_CLUSTER_ISSUER = 'clusterIssuer';

interface Props {
  params: IEnvironmentParams;
  onClose: () => void;
}

export enum NetworkConfiguration {
  WithoutServiceAndIngress = 'withoutAll',
  WithServiceOnly = 'onlyService',
  WithServiceAndDefaultIngress = 'withDefaultIngress',
  WithServiceAndSpecIngress = 'withSpecIngress',
  WithServiceAndSecuredIngress = 'withSecuredIngress',
}

@observer
class CreateMicroserviceModal extends Component<Props> {
  @observable createMicroserviceForm = new CreateMicroserviceForm();
  FormControl = bindFormControl(this.createMicroserviceForm);

  @observable
  envVariablesStore: EnvVariablesTableStore = new EnvVariablesTableStore();

  @observable templateVariablesStore: TemplateVarsTableStore;
  @observable templateVariables: ITemplateVariable[];

  @observable selectedNetworkConfiguration =
    NetworkConfiguration.WithoutServiceAndIngress;
  @observable
  isCertSecretManuallyEntered: boolean = false;
  @observable
  isClusterIssuerManuallyEntered: boolean = false;

  certSecretVarDefaultValue: string = '';

  @computed
  get createDisabled() {
    if (!this.createMicroserviceForm.isFormValid) {
      return true;
    }
    const fields: string[] = [];
    fields.push(this.createMicroserviceForm.longName);
    fields.push(this.createMicroserviceForm.key);
    fields.push(this.createMicroserviceForm.image);
    if (
      this.selectedNetworkConfiguration !==
      NetworkConfiguration.WithoutServiceAndIngress
    ) {
      fields.push(this.createMicroserviceForm.port);
    }
    if (
      this.selectedNetworkConfiguration ===
      NetworkConfiguration.WithServiceAndSpecIngress
    ) {
      fields.push(this.createMicroserviceForm.host);
      fields.push(this.createMicroserviceForm.path);
    }
    if (
      this.selectedNetworkConfiguration ===
      NetworkConfiguration.WithServiceAndSecuredIngress
    ) {
      fields.push(this.createMicroserviceForm.host);
      fields.push(this.createMicroserviceForm.path);
      fields.push(this.createMicroserviceForm.certSecret);
      fields.push(this.createMicroserviceForm.clusterIssuer);
    }
    return (
      !fields.every(el => !!el) || !this.envVariablesStore.isEnvVariablesValid
    );
  }

  @computed
  get userDefinedTemplateVars() {
    return sortByName(
      [...this.templateVariables.filter(templateVar => !templateVar.system)],
      false,
    );
  }

  @observable
  isKeyChangedManually: boolean = false;

  @observable
  keyFieldErrors: ValidationResult[] = [];

  @observable
  isUIblocked: boolean = false;

  @observable
  validationMessages = [
    {
      msgKey: 'validation.ms.key.characters.rule',
      id: 0,
      isValid: true,
    },
    {
      msgKey: 'validation.ms.key.length.rule',
      id: 1,
      isValid: true,
    },
    {
      msgKey: 'validation.ms.key.startend.rule',
      id: 2,
      isValid: true,
    },
  ];

  @observable
  serverErrors: string[] = [];

  @observable
  serverWarnings: string[] = [];

  constructor(props) {
    super(props);

    this.fetchTemplateVars().then(() => {
      this.templateVariablesStore = new TemplateVarsTableStore(
        this.userDefinedTemplateVars,
        TemplateVarsTableModes.SET_VAR_VALUE,
      );
    });
  }

  @action.bound
  async fetchTemplateVars() {
    try {
      const { projectName, clusterName } = this.props.params;

      const { data } = await httpFacade.templates.fetchTemplatesVariables({
        projectName,
        clusterName,
        actionId: ActionId.create_microservice,
      });

      this.templateVariables = data;
    } catch (error) {
      Log.warn(error);
    }
  }

  onClickSecuredIngress = () => {
    this.initCertSecretDefaultValue();
    this.initClusterIssuerDefaultValue();
  };

  initCertSecretDefaultValue = () => {
    this.certSecretVarDefaultValue =
      this.templateVariables.find(
        templateVar => templateVar.name === VAR_NAME_CERT_SECRET,
      )?.value ?? '';

    this.updateCertSecret();
  };

  initClusterIssuerDefaultValue = () => {
    if (!this.isClusterIssuerManuallyEntered) {
      const clusterIssuerVarValue = this.templateVariables.find(
        templateVar => templateVar.name === VAR_NAME_CLUSTER_ISSUER,
      )?.value;

      if (clusterIssuerVarValue) {
        this.createMicroserviceForm.setValue(
          'clusterIssuer',
          clusterIssuerVarValue,
        );
      }
    }
  };

  updateCertSecret = () => {
    if (!this.isCertSecretManuallyEntered) {
      const value =
        this.createMicroserviceForm.host || this.createMicroserviceForm.key
          ? `${
              this.createMicroserviceForm.host ||
              this.createMicroserviceForm.key
            }-tls`
          : this.certSecretVarDefaultValue;

      this.createMicroserviceForm.setValue('certSecret', value);
    }
  };

  setImage = (image: string) => {
    this.createMicroserviceForm.setValue('image', image);
  };

  onSearchOpen = () => {
    ModalStore.showModal(SelectImageModal, {
      setImage: this.setImage,
      selectedImage: this.createMicroserviceForm.image,
    });
  };

  onClose = () => {
    if (!this.isUIblocked) {
      this.props.onClose();
    }
  };

  onConfirm = async () => {
    try {
      const isValid =
        this.createMicroserviceForm.validate() &&
        this.envVariablesStore.isEnvVariablesValid;
      if (isValid) {
        this.isUIblocked = true;
        await httpFacade.service.createService(this.props.params, {
          name: this.createMicroserviceForm.key,
          longName: this.createMicroserviceForm.longName,
          image: this.createMicroserviceForm.image,
          environmentVariables: this.envVariablesStore.envVariablesData,
          templateVariables: this.templateVariablesStore.templateVarsData,
          applicationRoutes:
            Number(this.createMicroserviceForm.port) &&
            this.selectedNetworkConfiguration !==
              NetworkConfiguration.WithoutServiceAndIngress
              ? [
                  {
                    containerPort: Number(this.createMicroserviceForm.port),
                    defaultIngress:
                      this.selectedNetworkConfiguration ===
                      NetworkConfiguration.WithServiceAndDefaultIngress,
                    ingress:
                      this.selectedNetworkConfiguration ===
                        NetworkConfiguration.WithServiceAndDefaultIngress ||
                      this.selectedNetworkConfiguration ===
                        NetworkConfiguration.WithServiceOnly
                        ? undefined
                        : {
                            host: this.createMicroserviceForm.host,
                            path: this.createMicroserviceForm.path,
                            tls:
                              this.selectedNetworkConfiguration ===
                              NetworkConfiguration.WithServiceAndSecuredIngress,
                            certSecret:
                              this.selectedNetworkConfiguration ===
                              NetworkConfiguration.WithServiceAndSecuredIngress
                                ? this.createMicroserviceForm.certSecret
                                : undefined,
                            clusterIssuer:
                              this.selectedNetworkConfiguration ===
                              NetworkConfiguration.WithServiceAndSecuredIngress
                                ? this.createMicroserviceForm.clusterIssuer
                                : undefined,
                          },
                  },
                ]
              : [],
        });
        this.props.onClose();

        NotificationService.successMessage(
          { id: 'microservice.created' },
          fullscreenNotificationOptions,
        );
      } else {
        const keyErrors = this.createMicroserviceForm.errorFor('key');
        keyErrors.forEach(err => {
          const rule = this.validationMessages.find(
            msg => msg.msgKey === `${err.message}.rule`,
          );
          if (rule) {
            rule.isValid = false;
          }
        });
      }
    } catch (error) {
      if (error.response.data.type) {
        NotificationService.errorMessage(
          { id: error.response.data.type },
          fullscreenNotificationOptions,
        );
      } else {
        this.serverErrors = error.response.data.errorMessages || [];
        this.serverWarnings = error.response.data.warningMessages || [];
      }
    } finally {
      this.isUIblocked = false;
    }
  };

  onHostChange = (value: string) => {
    this.createMicroserviceForm.setValue('host', value);
    this.updateCertSecret();
  };

  onCertSecretChange = (value: string) => {
    this.createMicroserviceForm.setValue('certSecret', value);
    this.isCertSecretManuallyEntered = true;
  };

  onClusterIssuerChange = (value: string) => {
    this.createMicroserviceForm.setValue('clusterIssuer', value);
    this.isClusterIssuerManuallyEntered = true;
  };

  onNameChange = (value: string) => {
    const length = value.length;
    const isValidRegexp = testRegExp(value, NAME_REGEXP);

    if (!isValidRegexp && length > 0) {
      return;
    }

    if (length > 0) {
      this.createMicroserviceForm.setValue('longName', value);
      if (!this.isKeyChangedManually) {
        this.createMicroserviceForm.setValue('key', generateKey(value));
      }
    } else {
      this.createMicroserviceForm.setValue('longName', '');
      if (!this.isKeyChangedManually) {
        this.createMicroserviceForm.setValue('key', '');
      }
    }
    this.updateCertSecret();
    this.validateKey();
  };

  onNameBlur = () => {
    if (!this.isKeyChangedManually) {
      this.createMicroserviceForm.setValue(
        'key',
        this.createMicroserviceForm.key.replace(/^-|-$/g, ''),
      );
    }
  };

  onKeyChange = (value: string, e) => {
    e.persist();

    this.isKeyChangedManually = true;
    const length = value.length;

    const isValidRegexp = testRegExp(
      value.toLowerCase(),
      KEY_WITH_SPEC_CHARS_REGEXP,
    );

    if (!isValidRegexp && length > 0) {
      return;
    }

    const ss = e.target.selectionStart;
    const se = e.target.selectionEnd;

    if (!this.isKeyChangedManually) {
      this.isKeyChangedManually = true;
    }

    if (length > 0) {
      this.createMicroserviceForm.setValue('key', value.toLowerCase());
    } else {
      this.createMicroserviceForm.setValue('key', '');
    }

    setTimeout(() => {
      e.target.selectionStart = ss;
      e.target.selectionEnd = se;
    }, 0);

    this.updateCertSecret();
    this.validateKey();
  };

  @action
  validateKey = () => {
    setTimeout(() => {
      // we use setTimeout 0 to delay this work and not block input rerender
      this.keyFieldErrors = this.createMicroserviceForm.errorFor('key');
      this.validationMessages.forEach(msg => (msg.isValid = true));
      if (this.keyFieldErrors.length) {
        this.keyFieldErrors.forEach(err => {
          const rule = this.validationMessages.find(
            msg => msg.msgKey === `${err.message}.rule`,
          );
          if (rule) {
            rule.isValid = false;
          }
        });
      }
    }, 0);
  };

  blockUIcallback = (isBlocked: boolean) => {
    this.isUIblocked = isBlocked;
  };

  render() {
    const FormControl = this.FormControl;
    return (
      <div className={style.wrap}>
        <div className={style.mainTitle}>
          <FormattedMessage id="microservice.modal.create.microservice.title" />
        </div>
        {this.isUIblocked && <div className={style.overlay} />}
        {this.serverErrors && !!this.serverErrors.length && (
          <div className={classNames(style.msgList, style.serverErrors)}>
            <Icon type={'exclamation'} className={style.infoBtn} />
            <ul className={style.errorsList}>
              {this.serverErrors.map((err: string) => (
                <li key={err}>{err}</li>
              ))}
            </ul>
          </div>
        )}
        {this.serverWarnings && !!this.serverWarnings.length && (
          <div className={classNames(style.msgList, style.serverWarnings)}>
            <Icon type={'warning'} className={style.infoBtn} />
            <ul className={style.errorsList}>
              {this.serverWarnings.map((msg: string) => (
                <li key={msg}>{msg}</li>
              ))}
            </ul>
          </div>
        )}
        <div className={style.wrapper}>
          <div className={style.title}>
            <FormattedMessage id="microservice.modal.step1.title" />
          </div>

          <div className={style.formWrapper}>
            <FormControl
              className={style.inputName}
              name="longName"
              render={props => (
                <TextField
                  {...props}
                  onBlur={this.onNameBlur}
                  onChange={this.onNameChange}
                />
              )}
            />
            <div className={style.keyWrapper}>
              <FormControl
                className={style.inputKey}
                name="key"
                errors={this.keyFieldErrors}
                immediateValidation={true}
                description={<FormattedMessage id="auto.generated.field" />}
                render={props => (
                  <TextField {...props} onChange={this.onKeyChange} />
                )}
              />
              <InfoButton
                className={style.infoBtnWrapper}
                rules={this.validationMessages}
                rulePrefix={RulePrefix.ms}
              />
            </div>
          </div>
        </div>
        <div className={style.wrapper}>
          <div className={style.title}>
            <FormattedMessage id="microservice.modal.step2.title" />
          </div>

          <div className={style.formImageWrapper}>
            <FormControl
              className={style.formImage}
              name="image"
              render={props => <TextField {...props} />}
            />
            <Button
              styleType={BtnType.Add}
              className={style.searchButton}
              onClick={this.onSearchOpen}
            >
              <Icon type="search2" />
            </Button>
          </div>
        </div>

        {this.templateVariablesStore &&
          !!this.templateVariablesStore.templateVarsForms.length && (
            <div className={style.wrapper}>
              <div className={style.title}>
                <FormattedMessage id="microservice.modal.template.variables" />
              </div>
              <MSTemplateVariablesTable
                templateVariablesForms={
                  this.templateVariablesStore.templateVarsForms
                }
              />
            </div>
          )}

        <div className={style.wrapper}>
          <div className={style.title}>
            <FormattedMessage id="microservice.modal.environment.variables" />
          </div>
          <EnvironmentVariablesTable
            deleteEnvVariable={this.envVariablesStore.deleteEnvVariable}
            addEnvVariable={this.envVariablesStore.addEnvVariable}
            environmentVariablesForms={
              this.envVariablesStore.environmentVariablesForms
            }
            className={'envVariablesMSForm'}
          />
        </div>

        <div className={style.networkMainContainer}>
          <div className={style.title}>
            <FormattedMessage id="network.configuration" />
          </div>
          <div className={style.networkConfContainer}>
            {Object.keys(NetworkConfiguration).map(item => (
              <RadioField
                key={item}
                text1={NetworkConfiguration[item]}
                text2={`${NetworkConfiguration[item]}Description`}
                selected={
                  this.selectedNetworkConfiguration ===
                  NetworkConfiguration[item]
                }
                onClick={() => {
                  this.selectedNetworkConfiguration =
                    NetworkConfiguration[item];
                  if (
                    NetworkConfiguration[item] ===
                    NetworkConfiguration.WithServiceAndSecuredIngress
                  ) {
                    this.onClickSecuredIngress();
                  }
                }}
              />
            ))}
          </div>

          {this.selectedNetworkConfiguration !==
            NetworkConfiguration.WithoutServiceAndIngress && (
            <>
              <div className={style.title}>
                <FormattedMessage id="microservice.modal.step3.title" />
              </div>
              <FormControl
                name="port"
                render={props => <TextField {...props} type="number" />}
              />
            </>
          )}

          {(this.selectedNetworkConfiguration ===
            NetworkConfiguration.WithServiceAndSpecIngress ||
            this.selectedNetworkConfiguration ===
              NetworkConfiguration.WithServiceAndSecuredIngress) && (
            <>
              <FormControl
                name="host"
                render={props => (
                  <TextField {...props} onChange={this.onHostChange} />
                )}
              />
              <FormControl
                name="path"
                render={props => <TextField {...props} />}
              />
            </>
          )}

          {this.selectedNetworkConfiguration ===
            NetworkConfiguration.WithServiceAndSecuredIngress && (
            <>
              <FormControl
                name="certSecret"
                render={props => (
                  <TextField {...props} onChange={this.onCertSecretChange} />
                )}
              />
              <FormControl
                name="clusterIssuer"
                render={props => (
                  <TextField {...props} onChange={this.onClusterIssuerChange} />
                )}
              />
            </>
          )}
        </div>

        <div className={style.btnContainer}>
          <Button styleType={BtnType.Ghost} onClick={this.onClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <Button
            disabled={this.isUIblocked || this.createDisabled}
            onClick={this.onConfirm}
            isLoading={this.isUIblocked}
          >
            <FormattedMessage id="button.create" />
          </Button>
        </div>
      </div>
    );
  }
}

export default CreateMicroserviceModal;
