import { action, observable } from 'mobx';
import httpFacade, { IntervalFetch } from 'http/httpFacade';
import {
  EnvironmentDetails,
  EnvironmentOther,
  IComparingEnvironment,
  IEnvironmentParams,
} from 'models/Environment';
import { sortByName } from 'helpers/array';
import { sortClusterEntities } from 'stores/helpers';
import NotificationService, {
  fullscreenNotificationOptions,
} from 'components/Notification/NotificationService';
import { EnvPageState } from 'pages/EnvironmentPage/Environment';

export default class EnvironmentStore {
  @observable pending = true;
  @observable environment?: EnvironmentDetails;
  @observable params: IEnvironmentParams;
  @observable environmentOthers: EnvironmentOther[];
  @observable intervalFetch: IntervalFetch = new IntervalFetch();
  @observable favoriteMS: string[] = [];
  @observable pageState = EnvPageState.MSS_LIST;
  @observable comparingEnvironmentData: IComparingEnvironment | null = null;
  targetEnvironmentName;

  constructor(
    clusterName: string,
    projectName: string,
    environmentName: string,
  ) {
    this.params = {
      clusterName,
      projectName,
      environmentName,
    };
    this.init();
  }

  clearTimer() {
    this.intervalFetch.disable();
  }

  init = async () => {
    this.pending = true;
    this.intervalFetch.setRequest(() => this.fetchData());
    await this.intervalFetch.enable();
    this.pending = false;
  };

  fetchData = async () => {
    await Promise.all([this.fetchEnvironment(), this.fetchEnvironmentOthers()]);
  };

  @action.bound
  async fetchEnvironmentOthers() {
    try {
      const response = await httpFacade.environment.fetchEnvironmentOthers(
        this.params,
      );
      response.data.forEach(other => {
        other.isHaveProblem = other.syncState !== 'synced';
      });
      sortByName(response.data);
      response.data.sort((a, b) => {
        return +b.isHaveProblem - +a.isHaveProblem;
      });
      this.environmentOthers = response.data;
    } catch (error) {
      this.environmentOthers = [];
    }
  }

  @action
  initFavoriteMSs = () => {
    this.favoriteMS =
      JSON.parse(
        localStorage.getItem(
          `favoriteMS${this.params.projectName}${this.params.clusterName}${this.params.environmentName}`,
        ) || 'null',
      ) || [];
  };

  @action
  initMSsProperties = (environment: EnvironmentDetails) => {
    environment.microservices.forEach(microservice => {
      microservice.isHaveProblem = !(
        microservice.syncState === 'synced' &&
        microservice.artifactStatus === 'uptodate' &&
        microservice.runtimeState.desired === microservice.runtimeState.existing
      );
      microservice.favorite = Boolean(
        this.favoriteMS.find(el => el === microservice.name),
      );
      microservice.isShutdown = !microservice.runtimeState.desired;
    });
  };

  @action.bound
  async fetchEnvironment() {
    try {
      const response = await httpFacade.environment.fetchEnvironmentDetails(
        this.params,
      );
      this.initFavoriteMSs();
      this.initMSsProperties(response.data);
      response.data.microservices = sortClusterEntities(
        response.data.microservices,
      );
      this.environment = response.data;
    } catch (error) {
      this.environment = undefined;
    }
  }

  @action.bound
  async deleteEnvironment(onDelete: () => void) {
    await httpFacade.environment.deleteEnvironment(this.params);
    onDelete();
    NotificationService.successMessage(
      { id: 'env.delete.success' },
      {
        ...fullscreenNotificationOptions,
        icon: 'crossTrash',
      },
    );
  }

  @action.bound
  async setAutoUpdateEnvironment(autoUpdate: boolean) {
    await httpFacade.environment.setAutoUpdateEnvironment(
      autoUpdate,
      this.params,
    );
  }

  @action.bound
  deleteService() {
    // TODO: service delete action
  }

  @action
  addMSToFavorite = (microserviceName: string) => {
    if (!this.environment) {
      return;
    }

    this.environment.microservices.find(microservice => {
      if (microserviceName === microservice.name) {
        microservice.favorite = !microservice.favorite;
        if (microservice.favorite) {
          this.favoriteMS.push(microserviceName);
        } else {
          this.favoriteMS = this.favoriteMS.filter(
            el => el !== microserviceName,
          );
        }
        localStorage.setItem(
          `favoriteMS${this.params.projectName}${this.params.clusterName}${this.params.environmentName}`,
          JSON.stringify(this.favoriteMS),
        );
        return microservice;
      }
      return null;
    });
  };

  @action
  changePageState = (
    pageState: EnvPageState,
    payload?: IComparingEnvironment,
  ) => {
    this.pageState = pageState;
    if (payload) {
      this.comparingEnvironmentData = payload;
    }
  };

  updateEnvironment = (
    clusterName: string,
    projectName: string,
    environmentName: string,
  ) => {
    this.params = {
      clusterName,
      projectName,
      environmentName,
    };
    this.clearTimer();
    this.environment = undefined;
    this.init();
  };

  setTargetEnvironment = targetEnvironmentName => {
    this.targetEnvironmentName = targetEnvironmentName;
  };
}
