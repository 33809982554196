import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Button, { BtnType } from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import NotificationService from 'components/Notification/NotificationService';

import style from './TemplateFileUpload.module.scss';

const BYTES_IN_MB = 1000000;
const ALLOWED_FILE_SIZE = 1;

interface Props {
  onValueChange: (value: string) => void;
}

@observer
class TemplateFileUpload extends Component<Props> {
  fileUploadRef = React.createRef<HTMLInputElement>();

  onUploadClick = () => {
    if (this.fileUploadRef.current) {
      this.fileUploadRef.current.click();
    }
  };

  onFileChange = event => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];

    if (file) {
      if (
        file.size / BYTES_IN_MB > ALLOWED_FILE_SIZE &&
        this.fileUploadRef.current
      ) {
        NotificationService.errorMessage({ id: 'templates.file.is.too.big' });
        this.fileUploadRef.current.value = '';
        return;
      }

      const reader = new FileReader();

      reader.readAsText(file);

      reader.onload = () => {
        this.props.onValueChange(reader.result as string);
      };
    }
  };

  render() {
    return (
      <Button
        styleType={BtnType.Text}
        className={style.btnUpload}
        onClick={this.onUploadClick}
        type="button"
      >
        <input
          ref={this.fileUploadRef}
          type="file"
          style={{ display: 'none' }}
          onChange={this.onFileChange}
        />
        <Icon type="upload" className={style.icon} />
        <FormattedMessage id={'button.upload'} />
      </Button>
    );
  }
}

export default TemplateFileUpload;
