import { observable, action } from 'mobx';

import { BaseFormModel, displayName } from 'stores/BaseForm';
import httpFacade from 'http/httpFacade';

class EditProjectForm extends BaseFormModel {
  @observable
  @displayName('project.edit.projectName')
  projectName: string = '';

  @observable
  @displayName('project.edit.issueTrackerURL')
  issueTrackerURL: string = '';

  @observable
  @displayName('project.edit.issueTrackerUserName')
  issueTrackerUserName: string = '';

  @observable
  @displayName('project.edit.issueTrackerPassword')
  issueTrackerPassword: string = '';

  @observable
  @displayName('project.edit.registryURL')
  registryURL: string = '';

  @observable
  @displayName('project.edit.registryUserName')
  registryUserName: string = '';

  @observable
  @displayName('project.edit.registryPassword')
  registryPassword: string = '';

  @action.bound
  async fetchProjectData(projectName: string) {
    try {
      const response: any = await httpFacade.projects.fetchProjectSettings(
        projectName,
      );

      this.projectName = projectName;
      this.issueTrackerURL = response.data.issueTrackerURL;
      this.issueTrackerUserName = response.data.issueTrackerUserName;
      this.registryURL = response.data.registryURL;
      this.registryUserName = response.data.registryUserName;
      this.issueTrackerPassword = response.data.issueTrackerPassword;
      this.registryPassword = response.data.registryPassword;
    } catch (e) {
      throw e;
    }
  }

  @action.bound
  async updateProjectSettings() {
    try {
      await httpFacade.projects.updateProjectSettings(this.projectName, {
        issueTrackerURL: this.issueTrackerURL,
        issueTrackerUserName: this.issueTrackerUserName,
        issueTrackerPassword: this.issueTrackerPassword,
        registryURL: this.registryURL,
        registryUserName: this.registryUserName,
        registryPassword: this.registryPassword,
      });
    } catch (e) {
      throw e;
    }
  }
}

export default EditProjectForm;
