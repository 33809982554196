import { DialogActionType, ModalProps } from '../../../stores/ModalStore';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import classNames from 'classnames';
import { observable, computed, action } from 'mobx';
import { bindFormControl } from '../../Form/FormControl/FormControl';
import { ValidationResult } from 'stores/BaseForm/types';
import { FormattedMessage } from 'react-intl';
import TextField from '../../Form/Fields/TextField/TextField';
import CloneEnvironmentForm from './CloneEnvironmentForm';
import style from './CloneEnvironmentModal.module.scss';
import CommonModal from '../../Modals/Modal/CommonModal';
import { InfoButton, RulePrefix } from 'components/InfoButton/InfoButton';
import {
  testRegExp,
  NAME_REGEXP,
  KEY_WITH_SPEC_CHARS_REGEXP,
} from 'helpers/testRegexp';
import { generateKey } from 'helpers/generateKey';
import httpFacade from 'http/httpFacade';
import RadioCard from './RadioCard/RadioCard';
import { EnvCopyMode } from 'models/Environment';

interface Props {
  clusterName: string;
  projectName: string;
  basedOn: string;
}

@observer
class CloneEnvironmentModal extends Component<Props & ModalProps> {
  @observable cloneEnvironmentForm = new CloneEnvironmentForm();
  FormControl = bindFormControl(this.cloneEnvironmentForm);

  @computed
  get isSubmitBtnDisabled() {
    return !this.cloneEnvironmentForm.isFormValid;
  }

  @observable
  isKeyChangedManually: boolean = false;

  @observable
  keyFieldErrors: ValidationResult[] = [];

  @observable
  isValidationTriggered: boolean = false;

  @observable
  serverErrors: string[] = [];

  @observable
  serverWarnings: string[] = [];

  @observable
  isUIblocked: boolean = false;

  @observable
  externalStorageEnabled: boolean = false;

  @observable
  envCopyMode: EnvCopyMode = EnvCopyMode.CLONE_BASED_ON_ENV;

  @observable
  validationMessages = [
    {
      msgKey: 'validation.env.key.characters.rule',
      id: 0,
      isValid: true,
    },
    {
      msgKey: 'validation.env.key.length.rule',
      id: 1,
      isValid: true,
    },
    {
      msgKey: 'validation.env.key.startend.rule',
      id: 2,
      isValid: true,
    },
    {
      msgKey: 'validation.env.key.reserved.rule',
      id: 3,
      isValid: true,
    },
    {
      msgKey: 'validation.env.key.visibility.rule',
      id: 4,
      isValid: true,
    },
  ];

  toggleEnvCopyMode = mode => () => {
    this.envCopyMode = mode;
  };

  checkExternalStorage = async () => {
    const response = await httpFacade.cluster.checkAvailability(
      this.props.projectName,
      this.props.clusterName,
    );
    this.externalStorageEnabled = response.data.enabled;
  };

  componentDidMount() {
    this.cloneEnvironmentForm.setValue(
      'envName',
      'Clone ' + this.props.basedOn,
    );
    this.cloneEnvironmentForm.setValue(
      'key',
      'cln-' + generateKey(this.props.basedOn),
    );

    this.checkExternalStorage();
  }

  onClose = () => {
    if (!this.isUIblocked) {
      this.props.onClose(DialogActionType.cancel);
    }
  };

  onConfirm = async () => {
    const { clusterName, projectName, basedOn } = this.props;
    const isFormValid = this.cloneEnvironmentForm.validate();
    if (isFormValid) {
      this.validationMessages.forEach(msg => (msg.isValid = true));
      this.cloneEnvironmentForm.validated = false;
      try {
        this.isUIblocked = true;
        await this.cloneEnvironmentForm.submit(
          projectName,
          clusterName,
          basedOn,
          this.externalStorageEnabled ? this.envCopyMode : undefined,
        );
        this.isUIblocked = false;
        this.serverErrors = [];
        this.props.onClose(DialogActionType.submit);
      } catch (error) {
        this.serverErrors = error.response.data.errorMessages || [];
        this.serverWarnings = error.response.data.warningMessages || [];
        this.isUIblocked = false;
      }
    } else {
      const keyErrors = this.cloneEnvironmentForm.errorFor('key');
      keyErrors.forEach(err => {
        const rule = this.validationMessages.find(
          msg => msg.msgKey === `${err.message}.rule`,
        );
        if (rule) {
          rule.isValid = false;
        }
      });
    }
  };

  onEnvNameChange = (value: string) => {
    const length = value.length;
    const isValidRegexp = testRegExp(value, NAME_REGEXP);

    if (!isValidRegexp && length > 0) {
      return;
    }

    if (length > 0) {
      this.cloneEnvironmentForm.setValue('envName', value);
      if (!this.isKeyChangedManually) {
        this.cloneEnvironmentForm.setValue('key', generateKey(value));
      }
    } else {
      this.cloneEnvironmentForm.setValue('envName', '');
      if (!this.isKeyChangedManually) {
        this.cloneEnvironmentForm.setValue('key', '');
      }
    }

    this.validateEnvKey();
  };

  onEnvNameBlur = () => {
    if (!this.isKeyChangedManually) {
      this.cloneEnvironmentForm.setValue(
        'key',
        this.cloneEnvironmentForm.key.replace(/^-|-$/g, ''),
      );
    }
  };

  onEnvKeyChange = (value: string, e) => {
    e.persist();

    const length = value.length;

    const isValidRegexp = testRegExp(
      value.toLowerCase(),
      KEY_WITH_SPEC_CHARS_REGEXP,
    );

    if (!isValidRegexp && length > 0) {
      return;
    }

    if (!this.isKeyChangedManually) {
      this.isKeyChangedManually = true;
    }

    const ss = e.target.selectionStart;
    const se = e.target.selectionEnd;

    if (!this.isValidationTriggered) {
      this.cloneEnvironmentForm.validate();
      this.isValidationTriggered = true;
    }

    if (length > 0) {
      this.cloneEnvironmentForm.setValue('key', value.toLowerCase());
    } else {
      this.cloneEnvironmentForm.setValue('key', '');
    }

    setTimeout(() => {
      e.target.selectionStart = ss;
      e.target.selectionEnd = se;
    }, 0);

    this.validateEnvKey();
  };

  @action
  validateEnvKey = () => {
    setTimeout(() => {
      // we use setTimeout 0 to delay this work and not block input rerender
      this.validationMessages.forEach(msg => (msg.isValid = true));
      this.keyFieldErrors = this.cloneEnvironmentForm.errorFor('key');
      if (this.keyFieldErrors.length) {
        this.keyFieldErrors.forEach(err => {
          const rule = this.validationMessages.find(
            msg => msg.msgKey === `${err.message}.rule`,
          );
          if (rule) {
            rule.isValid = false;
          }
        });
      }
    }, 0);
  };

  render() {
    const FormControl = this.FormControl;

    return (
      <CommonModal
        className={style.modal}
        bodyContentClassName={style.bodyContent}
        title="environment.modal.clone.environment.title"
        cancel="environment.modal.clone.environment.cancel"
        confirm="environment.modal.clone.environment.confirm"
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        isSubmitBtnDisabled={this.isSubmitBtnDisabled}
        serverErrors={this.serverErrors}
        serverWarnings={this.serverWarnings}
        isUIblocked={this.isUIblocked}
      >
        <div className={style.text}>
          <FormattedMessage id="environment.modal.clone.environment.text" />
        </div>

        <div className={style.formControlWrapper}>
          <FormControl
            className={style.input}
            name="envName"
            render={props => (
              <TextField
                {...props}
                onBlur={this.onEnvNameBlur}
                onChange={this.onEnvNameChange}
              />
            )}
          />
        </div>

        <div
          className={classNames(style.formControlWrapper, style.withInfoButton)}
        >
          <FormControl
            className={style.input}
            name="key"
            errors={this.keyFieldErrors}
            immediateValidation={true}
            render={props => (
              <TextField {...props} onChange={this.onEnvKeyChange} />
            )}
          />{' '}
          <InfoButton
            className={style.infoBtnWrapper}
            rules={this.validationMessages}
            rulePrefix={RulePrefix.env}
          />
        </div>
        {this.externalStorageEnabled && (
          <div className={style.modesContainer}>
            <RadioCard
              selected={this.envCopyMode === EnvCopyMode.CLONE_BASED_ON_ENV}
              text1="clone.use.source"
              text2="clone.use.source.description"
              onClick={this.toggleEnvCopyMode(EnvCopyMode.CLONE_BASED_ON_ENV)}
            />
            <RadioCard
              selected={this.envCopyMode === EnvCopyMode.EMPTY}
              text1="empty"
              text2="empty.description.clone"
              onClick={this.toggleEnvCopyMode(EnvCopyMode.EMPTY)}
            />
          </div>
        )}
      </CommonModal>
    );
  }
}

export default CloneEnvironmentModal;
