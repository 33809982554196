export function copyToClipboard(text: string) {
  let resolvePromise;
  let rejectPromise;

  const copyWithExecCommand = () => {
    // copy via create a hidden text area
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    let successful = false;
    successful = document.execCommand('copy');
    // clear textarea
    document.body.removeChild(textArea);
    if (successful) {
      resolvePromise(true);
    } else {
      rejectPromise(false);
    }
  };

  if (!navigator.clipboard) {
    copyWithExecCommand();
  } else {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        resolvePromise(true);
      })
      .catch(() => {
        // try to copy with text area
        copyWithExecCommand();
      });
  }

  return new Promise((resolve, reject) => {
    resolvePromise = resolve;
    rejectPromise = reject;
  });
}
