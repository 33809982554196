import http from '../http';

import SETTINGS from 'settings';
import { VersionResponse } from 'http/types';
import { appUrl } from 'http/helpers';

interface FetchConfigResponse {
  appURL: string;
}

export const rootApi = {
  fetchConfig() {
    return http.get<FetchConfigResponse>(`${SETTINGS.publicURL}/config.json`);
  },

  fetchFrontVersion() {
    return http.get<VersionResponse>(`/version.json`);
  },

  fetchBackVersion() {
    return http.get<VersionResponse>(appUrl(`/version`));
  },
};
