import classNames from 'classnames';
import React, { ReactNode } from 'react';
import style from 'components/Table/Table.module.scss';

interface IProps {
  children: ReactNode;
  className?: string;
  isOpened?: boolean;
}

export const TableBodyRow = ({ children, className, isOpened }: IProps) => {
  return (
    <tr
      className={classNames(className, style.tableBodyRow, {
        [style.tableBodyRow__opened]: isOpened,
      })}
    >
      {children}
    </tr>
  );
};
