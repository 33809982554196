import React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { Environment } from 'models/Environment';
import { timeSince } from 'helpers/datetime';

import Icon from 'components/Icon/Icon';
import Status, { StatusType } from 'components/Status/Status';
import FavoriteStar from 'components/FavoriteStar/FavoriteStar';
import { RuntimeStatus } from 'components/RuntimeStatus/RuntimeStatus';
import WithPopper from 'components/WithPopper/WithPopper';
import BranchLabel from 'components/BranchLabel/BranchLabel';
import ResourceLinks from 'components/ResourseLinks/ResourceLinks';

import style from './EnvironmentItem.module.scss';

interface Props {
  environment: Environment;
  gotoEnvironment: (environmentName: string) => void;
  addEnvToFavorite: (environmentName: string) => void;
  tableWrapper: HTMLDivElement | null;
}

const syncStateMap = {
  synced: StatusType.Success,
  not_in_git: StatusType.Error,
  not_in_k8s: StatusType.Error,
  diff: StatusType.Error,
  no_hash: StatusType.Error,
  unknown: StatusType.Warning,
};

const artifactStatusMap = {
  uptodate: StatusType.Success,
  outofdate: StatusType.Warning,
  obsolete: StatusType.Error,
  unknown: StatusType.Warning,
};

@observer
class EnvironmentItem extends React.Component<Props> {
  @computed
  get sortedLinks() {
    if (!this.props.environment.links.entrypoints) {
      return [];
    }
    return this.props.environment.links.entrypoints.slice().sort((a, b) => {
      const lenDiff = a.length - b.length;
      if (lenDiff) {
        return lenDiff;
      } else {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      }
    });
  }

  toFavorite = (e: React.MouseEvent) => {
    const { environment, addEnvToFavorite } = this.props;
    e.stopPropagation();
    addEnvToFavorite(environment.name);
  };

  render() {
    const { environment, gotoEnvironment, tableWrapper } = this.props;
    const {
      name,
      longName,
      updateDate,
      artifactStatus,
      syncState,
      runtimeState,
      branches,
    } = environment;
    const envUpdateTime = timeSince(updateDate);

    return (
      <tr
        className={classNames(style.mainContainer, {
          [style.scaleOff]: !runtimeState.desired,
        })}
        onClick={() => gotoEnvironment(name)}
      >
        <td>
          <FavoriteStar
            active={environment.favorite}
            onClick={this.toFavorite}
          />
        </td>
        <td className={style.notOpaque}>
          <div className={style.cellContainer}>
            <span className={style.firstTitle}>
              <span className={style.text}>{longName} </span>
              <BranchLabel
                styles={{
                  container: style.branchLabel,
                  text: style.branchLabelText,
                }}
                branches={branches || []}
              />
            </span>
            <span className={style.secondTitle}>
              {updateDate && (
                <FormattedMessage
                  id="updated.ago"
                  values={{
                    interval: envUpdateTime.interval,
                    type: <FormattedMessage id={envUpdateTime.type} />,
                  }}
                />
              )}
            </span>
          </div>
        </td>
        <td>
          <div className={style.cellContainer}>
            <span className={style.firstTitle}>
              <Status
                statusType={syncStateMap[syncState]}
                className={style.icon}
              />
              <span className={style.statusText}>
                <FormattedMessage id={syncState} />
              </span>
            </span>
            <span className={classNames(style.secondTitle)} />
          </div>
        </td>
        <td>
          <div className={style.cellContainer}>
            <span className={style.firstTitle}>
              <Status
                statusType={artifactStatusMap[artifactStatus]}
                className={style.icon}
              />
              <span className={style.statusText}>
                <FormattedMessage id={artifactStatus} />
              </span>
            </span>
            <span className={style.secondTitle} />
          </div>
        </td>
        <td>
          <div className={style.cellContainer}>
            <span className={style.firstTitle}>
              <RuntimeStatus
                runtimeState={runtimeState}
                podsStyles={{ pods: style.pods }}
              />
            </span>
            <span className={style.secondTitle} />
          </div>
        </td>
        <td className={style.notOpaque}>
          {!this.sortedLinks.length && (
            <WithPopper title={<FormattedMessage id={'no.ms'} />}>
              <Icon
                onClick={e => {
                  if (e) {
                    e.stopPropagation();
                  }
                }}
                className={classNames(style.linkIcon, style.linkIconDisabled)}
                type="externalLink"
              />
            </WithPopper>
          )}
          {this.sortedLinks.length > 5 && (
            <WithPopper title={<FormattedMessage id={'to.many.ms'} />}>
              <Icon
                onClick={e => {
                  if (e) {
                    e.stopPropagation();
                  }
                }}
                className={classNames(style.linkIcon, style.linkIconDisabled)}
                type="externalLink"
              />
            </WithPopper>
          )}
          {this.sortedLinks.length > 1 && this.sortedLinks.length <= 5 && (
            <ResourceLinks
              links={this.sortedLinks}
              boundaryElement={tableWrapper}
            />
          )}
          {this.sortedLinks.length === 1 && (
            <a
              onClick={e => {
                e.stopPropagation();
              }}
              className={style.applinkValue}
              target="_blank"
              href={this.sortedLinks[0]}
              rel="noopener noreferrer"
            >
              <Icon className={style.linkIcon} type="externalLink" />
            </a>
          )}
        </td>
      </tr>
    );
  }
}

export default EnvironmentItem;
