import React, { useState } from 'react';
import { usePopper } from 'react-popper';

import { BoundaryElementType } from 'components/FloatingComponents/types';
import classNames from 'classnames';

import style from './WithPopper.module.scss';

interface Props {
  position?: 'fixed' | 'absolute';
  children?: React.ReactNode;
  className?: string;
  title?: string | JSX.Element;
  boundary?: BoundaryElementType;
  hidden?: boolean;
  onMouseEnterElement?: () => void;
}

const WithPopper: React.FC<Props> = ({
  title,
  className = '',
  children,
  position = 'fixed',
  boundary,
  hidden,
  onMouseEnterElement,
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null,
  );
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const [isVisible, toggleVisibility] = useState<boolean>(false);
  const boundaryElem: BoundaryElementType | 'clippingParents' =
    boundary ?? 'clippingParents';

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
    strategy: position,
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'preventOverflow',
        options: {
          boundary: boundaryElem,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
    ],
  });

  const getTooltip = (): React.ReactNode =>
    !hidden && isVisible && title ? (
      <div
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className={style.popper}
      >
        {title}
        <div
          ref={setArrowElement}
          style={styles.arrow}
          className={classNames(style.arrow)}
        />
      </div>
    ) : null;

  const hideTooltip = (): void => {
    toggleVisibility(false);
  };

  const showTooltip = (): void => {
    toggleVisibility(true);
  };

  const onMouseEnter = () => {
    if (onMouseEnterElement) {
      onMouseEnterElement();
    }
  };

  return (
    <>
      <div
        className={classNames(className, style.wrapper)}
        ref={setReferenceElement}
        onMouseOver={showTooltip}
        onMouseOut={hideTooltip}
        onMouseEnter={onMouseEnter}
      >
        {children}
        {getTooltip()}
      </div>
    </>
  );
};

export default WithPopper;
