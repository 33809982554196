import React from 'react';
import classNames from 'classnames';

import { useClickOutside } from '../../../hooks/useClickOutside';

import style from './HeaderButton.module.scss';

interface Props {
  onClose?: () => void;
  onClick?: () => void;
  className?: string;
  isDotVisible?: boolean;
  isClickOutsideOn?: boolean;
}

const HeaderButton: React.FC<Props> = ({
  onClick = () => undefined,
  onClose = () => undefined,
  children,
  className = '',
  isDotVisible = false,
  isClickOutsideOn = false,
}) => {
  const wrapperRef = React.createRef<HTMLDivElement>();
  useClickOutside(wrapperRef, onClose, isClickOutsideOn);

  return (
    <div
      ref={wrapperRef}
      className={classNames(style.wrap, className)}
      onClick={onClick}
    >
      {isDotVisible && <span className={style.dot} />}
      {children}
    </div>
  );
};

export default HeaderButton;
