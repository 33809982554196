import React, { Component, ReactNode } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { DialogActionType, ModalProps } from 'stores/ModalStore';

import CommonModal, { ModalType } from 'components/Modals/Modal/CommonModal';

import style from 'components/Modals/ConfirmModal/ConfirmModal.module.scss';

interface Props extends ModalProps {
  title: string;
  children?: ReactNode;
  confirmBtnText?: string;
  onConfirm: () => Promise<void>;
}

@observer
class ConfirmModal extends Component<Props & ModalProps> {
  @observable loading = false;

  onClose = () => {
    this.props.onClose(DialogActionType.cancel);
  };

  onConfirm = async () => {
    try {
      this.loading = true;
      await this.props.onConfirm();
    } finally {
      this.loading = false;
      this.props.onClose(DialogActionType.submit);
    }
  };

  render() {
    const { title, children, confirmBtnText = 'button.delete' } = this.props;

    return (
      <CommonModal
        className={style.modal}
        bodyClassName={style.body}
        bodyContentClassName={style.bodyContent}
        title={title}
        headerIcon="modalError"
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        modalType={ModalType.Delete}
        confirm={confirmBtnText}
        isUIblocked={this.loading}
      >
        {children}
      </CommonModal>
    );
  }
}

export default ConfirmModal;
