import React, { Component } from 'react';

import DefaultTabsItem, {
  DefaultTab,
} from 'components/Tabs/DefaultTabs/DefaultTabsItem';
import { TabsProps } from 'components/Tabs/TabsContainer';

import style from './DefaultTabs.module.scss';

interface DefaultTabsProps extends TabsProps {
  tabs: DefaultTab[];
}

class DefaultTabsList extends Component<DefaultTabsProps> {
  render() {
    const { tabs, activeTabIndex, onTabClick } = this.props;

    if (!tabs.length) {
      return null;
    }

    return (
      <ul className={style.tabsList}>
        {tabs.map((tab, index) => (
          <DefaultTabsItem
            key={tab.label}
            isActive={index === activeTabIndex}
            onClick={onTabClick(index)}
            label={tab.label}
            labelContent={tab.labelContent}
            counter={tab.counter}
            onCounterClick={tab.onCounterClick}
          />
        ))}
      </ul>
    );
  }
}

export default DefaultTabsList;
