import React from 'react';
import style from './NotUseDB.module.scss';

interface Props {
  label: string;
}

const NotUseDB: React.FC<Props> = ({ label }) => (
  <div className={style.container}>{label}</div>
);

export default NotUseDB;
