import { observable } from 'mobx';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { IComparingEnvironment, IEnvironmentParams } from 'models/Environment';
import { ComparingModalStore } from 'stores/ApplyMsTo/ComparingModalStore';

import Button, { BtnType } from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import { ModalLayout } from 'components/ModalLayout/ModalLayout';
import { ModalLayoutActions } from 'components/ModalLayout/ModalLayoutActions';
import { ModalLayoutTitle } from 'components/ModalLayout/ModalLayoutTitle';
import WithPopper from 'components/WithPopper/WithPopper';
import { CompareMsItem } from 'components/EnvironmentPageServicesList/Compare/CompareMsItem/CompareMsItem';
import { Table } from 'components/Table/Table';
import { TableHeaderRow } from 'components/Table/TableHeaderRow';
import CircleLoader from '../../CircleLoader/CircleLoader';

import style from './Compare.module.scss';

interface IProps extends RouteComponentProps {
  comparingEnvironment: IComparingEnvironment;
  targetEnvironmentName: string;
  onCancel: () => void;
  envParams: IEnvironmentParams;
}

@observer
class CompareModal extends Component<IProps> {
  @observable comparingModalStore: ComparingModalStore;

  constructor(props: IProps) {
    super(props);
    this.comparingModalStore = new ComparingModalStore(
      props.comparingEnvironment,
      props.envParams,
      props.history,
    );
  }

  render() {
    const { targetEnvironmentName, onCancel } = this.props;
    const { isApplyDisabled, isUIDisabled, isMerging } =
      this.comparingModalStore;

    return (
      <ModalLayout>
        <ModalLayoutTitle
          title={
            <FormattedMessage
              id="title.apply.ms.to.env"
              values={{ env: targetEnvironmentName }}
            />
          }
        />
        <div className={style.body}>
          <Table>
            <thead>
              <TableHeaderRow className={style.tableHeaderRow}>
                <th />
                <th>
                  <FormattedMessage id="comparing.state.title" />
                </th>
                <th>
                  <FormattedMessage id="microservice.name" />
                </th>
                <th>
                  <FormattedMessage id="image" />
                </th>
                <th />
              </TableHeaderRow>
            </thead>
            <tbody>
              {this.comparingModalStore.comparingMsStores.map(msStore => (
                <CompareMsItem
                  key={msStore.microservice.name}
                  microserviceStore={msStore}
                  isUIDisabled={isUIDisabled}
                />
              ))}
            </tbody>
          </Table>
        </div>
        <ModalLayoutActions>
          <Button
            styleType={BtnType.Secondary}
            className={style.btnCancel}
            disabled={isUIDisabled}
            onClick={onCancel}
          >
            <FormattedMessage id={'button.cancel'} />
          </Button>
          <WithPopper
            title={<FormattedMessage id={'apply.via.merge.hint'} />}
            className={style.iconInfoWrapper}
          >
            <Icon type="info" className={style.iconInfo} />
          </WithPopper>
          <WithPopper
            title={<FormattedMessage id={'comparing.ready.warning'} />}
            hidden={!isApplyDisabled}
          >
            <Button
              disabled={isApplyDisabled || isUIDisabled}
              onClick={() => this.comparingModalStore.applyTo(true, onCancel)}
              className={style.applyButton}
            >
              {isUIDisabled && isMerging && (
                <CircleLoader className={style.loader} />
              )}
              <FormattedMessage id={'button.apply.via.merge'} />
            </Button>
          </WithPopper>
          <WithPopper
            title={<FormattedMessage id={'comparing.ready.warning'} />}
            hidden={!isApplyDisabled}
            className={style.lastButtonWrapper}
          >
            <Button
              disabled={isApplyDisabled || isUIDisabled}
              onClick={() => this.comparingModalStore.applyTo(false, onCancel)}
              className={style.applyButton}
            >
              {isUIDisabled && !isMerging && (
                <CircleLoader className={style.loader} />
              )}
              <FormattedMessage id={'button.apply'} />
            </Button>
          </WithPopper>
        </ModalLayoutActions>
      </ModalLayout>
    );
  }
}

export default withRouter(CompareModal);
