import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ROUTES } from 'routes/routes';
import { replaceUrlParams } from 'http/helpers';
import { IServiceParams } from 'models/Service';
import { ModalProps, DialogActionType } from 'stores/ModalStore';
import TextField from 'components/Form/Fields/TextField/TextField';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import DeleteMicroserviceForm from './DeleteMicroserviceForm';

import style from './DeleteMicroserviceModal.module.scss';
import CommonModal, { ModalType } from '../../Modals/Modal/CommonModal';

interface Props extends ModalProps {
  serviceLongName: string;
  serviceParams: IServiceParams;
  deleteMicroservice: (onDelete: () => void) => Promise<any>;
}

@observer
class DeleteMicroserviceModal extends Component<Props & RouteComponentProps> {
  @observable deleteMicroserviceForm = new DeleteMicroserviceForm();
  FormControl = bindFormControl(this.deleteMicroserviceForm);
  @observable
  serverErrors: string[] = [];

  @observable
  serverWarnings: string[] = [];

  @observable
  isUIblocked: boolean = false;

  onClose = () => {
    if (!this.isUIblocked) {
      this.props.onClose(DialogActionType.cancel);
    }
  };

  gotoEnvironmentPage = () => {
    const { history, serviceParams } = this.props;

    history.push(
      replaceUrlParams(ROUTES.environment, {
        id: serviceParams.projectName,
        clusterName: serviceParams.clusterName,
        environmentName: serviceParams.environmentName,
      }),
    );
  };

  onConfirm = async () => {
    try {
      const isValid = this.deleteMicroserviceForm.validate();
      if (isValid) {
        this.isUIblocked = true;
        await this.props.deleteMicroservice(this.gotoEnvironmentPage);
        this.isUIblocked = false;
        this.props.onClose(DialogActionType.submit);
      }
    } catch (error) {
      this.serverErrors = error.response.data.errorMessages || [];
      this.serverWarnings = error.response.data.warningMessages || [];
      this.isUIblocked = false;
    }
  };

  componentDidMount() {
    const { serviceLongName } = this.props;

    this.deleteMicroserviceForm.setServiceNameExample(serviceLongName);
  }

  onServiceNameChange = (value: string) => {
    this.deleteMicroserviceForm.validate();
    if (value.length > 0) {
      this.deleteMicroserviceForm.setValue('serviceName', value);
    } else {
      this.deleteMicroserviceForm.setValue('serviceName', '');
    }
  };

  render() {
    const { serviceLongName } = this.props;
    const FormControl = this.FormControl;

    return (
      <CommonModal
        className={style.modal}
        bodyContentClassName={style.bodyContent}
        title="microservice.delete.title"
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        serverErrors={this.serverErrors}
        serverWarnings={this.serverWarnings}
        isSubmitBtnDisabled={!this.deleteMicroserviceForm.isFormValid}
        isUIblocked={this.isUIblocked}
        modalType={ModalType.Delete}
        headerIcon="modalError"
        confirm={'button.delete'}
      >
        <div className={style.text1}>
          <FormattedMessage
            id="service.modal.delete.text1"
            values={{
              serviceName: serviceLongName,
            }}
          />
        </div>
        <div className={style.text2}>
          <FormattedMessage id="service.modal.delete.text2" />
        </div>
        <div className={style.text3}>
          <FormattedMessage
            id="cluster.modal.shutdown.text3"
            values={{
              clusterName: (
                <span className={style.example}>{serviceLongName}</span>
              ),
            }}
          />
        </div>
        <FormControl
          className={style.input}
          name="serviceName"
          render={props => (
            <TextField {...props} onChange={this.onServiceNameChange} />
          )}
        />
      </CommonModal>
    );
  }
}

export default withRouter(DeleteMicroserviceModal);
