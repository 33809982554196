import { action, computed, observable } from 'mobx';
import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';
import { TemplatesParams, ITemplateVariable } from 'models/Template';
import NotificationService, {
  fullscreenNotificationOptions,
} from '../components/Notification/NotificationService';

class TemplatesVariablesStore {
  @observable templatesVariables: ITemplateVariable[] = [];
  @observable pending = false;

  private readonly params: TemplatesParams;

  constructor(params: TemplatesParams) {
    this.params = params;
  }

  @computed
  get systemTemplatesVariables() {
    return this.templatesVariables.filter(variable => variable.system);
  }

  @computed
  get userDefinedTemplatesVariables() {
    return this.templatesVariables.filter(variable => !variable.system);
  }

  @action
  fetchTemplatesVariables = async () => {
    try {
      this.pending = true;
      const { data } = await httpFacade.templates.fetchTemplatesVariables(
        this.params,
      );
      this.templatesVariables = data.sort((varItem1, varItem2) => {
        return varItem1.name.toLowerCase() > varItem2.name.toLowerCase()
          ? 1
          : -1;
      });
    } catch (error) {
      Log.warn(error);
    } finally {
      this.pending = false;
    }
  };

  @action
  updateTemplateVariables = async (templateVarsData: ITemplateVariable[]) => {
    try {
      await httpFacade.templates.updateTemplateVariables(
        this.params,
        templateVarsData,
      );
      NotificationService.successMessage(
        { id: 'templates.variables.userDefined.success.notification' },
        fullscreenNotificationOptions,
      );
    } catch (error) {
      let errorString;
      if (
        (error.response.data.errorMessages &&
          error.response.data.errorMessages.length) ||
        error.response.data.length
      ) {
        const errorMessagesArray = error.response.data.errorMessages
          ? error.response.data.errorMessages
          : error.response.data
              .map(errorItem => errorItem.message)
              .filter(errorMessage => !!errorMessage);
        const errorMessages = new Set(errorMessagesArray);
        errorString = [...errorMessages].join(' ');
      }
      errorString = errorString || 'templates.variables.edit.error';
      NotificationService.errorMessage(
        { id: errorString },
        fullscreenNotificationOptions,
      );
      throw Error(errorString);
    }
    await this.fetchTemplatesVariables();
  };
}

export default TemplatesVariablesStore;
