import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { TabsProps } from 'components/Tabs/TabsContainer';
import { TrapezoidTabsItem } from 'components/Tabs/TrapezoidTabs/TrapezoidTabsItem';

import style from './TrapezoidTabs.module.scss';

export interface TrapezoidTab {
  title: string;
  content: (isActive?: boolean) => JSX.Element;
}

interface TrapezoidTabsProps extends TabsProps {
  tabs: TrapezoidTab[];
}

@observer
class TrapezoidTabsList extends Component<TrapezoidTabsProps> {
  render() {
    const { onTabClick, activeTabIndex, tabs } = this.props;

    if (!tabs.length) {
      return null;
    }

    return (
      <div className={style.tabs}>
        {tabs.map((tab, index) => (
          <TrapezoidTabsItem
            key={tab.title}
            index={index}
            tabsNumber={tabs.length}
            onClick={onTabClick(index)}
            activeTabIndex={activeTabIndex}
            tabInfo={tab.content}
          />
        ))}
      </div>
    );
  }
}

export { TrapezoidTabsList };
