import React from 'react';
import classNames from 'classnames';

import style from './Status.module.scss';
import { AlertType } from 'models/Alert';

export enum StatusType {
  Error = 'error',
  Warning = 'warning',
  Normal = 'normal',
  Success = 'success',
  Info = 'info',
  Unset = 'unset',
}

interface Props {
  className?: string;
  statusType?: StatusType | AlertType;
}

const Status = ({
  className = '',
  statusType = StatusType.Normal,
  ...props
}: Props) => (
  <span
    className={classNames(style.status, className, {
      [style.status__error]: statusType === StatusType.Error,
      [style.status__warning]: statusType === StatusType.Warning,
      [style.status__normal]:
        statusType === StatusType.Normal || statusType === StatusType.Info,
      [style.status__success]: statusType === StatusType.Success,
      [style.status__unset]: statusType === StatusType.Unset,
    })}
    {...props}
  >
    <span />
  </span>
);

export default Status;
