import { action, observable, runInAction } from 'mobx';

import { ROUTES } from 'routes/routes';
import { replaceUrlParams } from 'http/helpers';
import UserService from 'services/UserService';
import RootStore from 'stores/RootStore';
import { BaseFormModel, isRequired, displayName } from '../BaseForm';

class LoginForm extends BaseFormModel {
  @observable pending = false;

  @observable
  @displayName('USERNAME')
  @isRequired('form.field.is.required')
  username: string = '';

  @observable
  @displayName('PASSWORD')
  @isRequired('form.field.is.required')
  password: string = '';

  @observable
  @displayName('PROJECT')
  @isRequired('form.field.is.required')
  project: string = RootStore.currentProject;

  @observable message: string;

  @action.bound
  async submit(history: any) {
    this.pending = true;

    const isFormValid = this.validate();

    if (isFormValid) {
      try {
        await UserService.login(this.username, this.password, this.project);
        history.push(replaceUrlParams(ROUTES.project, { id: this.project }));
      } catch (error) {
        runInAction(() => {
          this.password = '';
          this.message = 'page.login.incorrect';
        });
      }
    }

    this.pending = false;
  }
}

export default LoginForm;
