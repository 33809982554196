import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { isElementOverflowed } from 'helpers/domElement';
import WithPopper from 'components/WithPopper/WithPopper';

import style from './OverflowedText.module.scss';

interface IProps {
  title: JSX.Element | string;
  text?: string;
  showTooltipOnlyOnOverflow?: boolean;
  styles?: {
    popper?: string;
    text?: string;
  };
}

export function OverflowedText({
  title,
  text,
  showTooltipOnlyOnOverflow = true,
  styles,
}: IProps) {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isTextOverflowed, setIsTextOverflowed] = useState(false);
  const textRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const iOverflowed =
      !!textRef.current && isElementOverflowed(textRef.current);
    setIsTextOverflowed(iOverflowed);
    setIsTooltipVisible(showTooltipOnlyOnOverflow ? iOverflowed : true);
  }, [title, text]);

  return (
    <WithPopper
      title={isTooltipVisible ? title : ''}
      className={classNames(
        style.popper,
        styles?.popper,
        !isTextOverflowed && style.notOverflowed,
      )}
    >
      <span ref={textRef} className={classNames(style.text, styles?.text)}>
        {text ? text : title}
      </span>
    </WithPopper>
  );
}
