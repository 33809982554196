import React from 'react';
import classNames from 'classnames';

import Icon from 'components/Icon/Icon';

import style from './FavoriteStar.module.scss';

interface Props {
  active: boolean;
  onClick: (e: React.MouseEvent) => void;
}

const FavoriteStar: React.FC<Props> = ({
  active,
  onClick = () => undefined,
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(style.container, { [style.active]: active })}
    >
      <Icon
        className={style.starIcon}
        type={active ? 'star2Filled' : 'star2Unfilled'}
      />
    </div>
  );
};

export default FavoriteStar;
