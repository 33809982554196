import React from 'react';
import { observer } from 'mobx-react';
import { action, computed, observable } from 'mobx';
import { FormattedMessage } from 'react-intl';

import TemplatesVariablesStore from 'stores/TemplaleVariablesStore';
import TemplateVarsTableStore, {
  TemplateVarsTableModes,
} from 'stores/TemplateVarsTableStore';

import CircleLoader from 'components/CircleLoader/CircleLoader';
import Icon from 'components/Icon/Icon';
import Button, { BtnSize, BtnType } from 'components/Button/Button';
import TemplateVariablesTable from './TemplateVariablesTable';

import style from './TemplateVariablesList.module.scss';

interface Props {
  templatesVariablesStore: TemplatesVariablesStore;
  updateStatistics: () => void;
}

@observer
class TemplateVariablesList extends React.Component<Props> {
  @observable
  templateVarsTableStore: TemplateVarsTableStore;
  @observable
  isSaving = false;
  @observable
  namesInUse: string[] = [];

  constructor(props: Props) {
    super(props);
    this.initTemplateVarsStore();
  }

  @action
  initTemplateVarsStore = () => {
    this.templateVarsTableStore = new TemplateVarsTableStore(
      this.props.templatesVariablesStore.userDefinedTemplatesVariables,
      TemplateVarsTableModes.PREVIEW,
    );
  };

  onCancel = () => {
    this.initTemplateVarsStore();
    this.setNamesInUse([]);
  };

  @computed
  get saveDisabled() {
    return this.templateVarsTableStore
      ? !this.templateVarsTableStore.isTemplateVarsValid
      : false;
  }

  @computed
  get isEmptyTable() {
    if (this.templateVarsTableStore) {
      const { isPreviewMode, templateVarsForms } = this.templateVarsTableStore;
      return isPreviewMode && !templateVarsForms.length;
    } else {
      return true;
    }
  }

  setPreviewMode = () => {
    this.templateVarsTableStore.setPreviewMode(true);
  };

  @action.bound
  setNamesInUse = (names: string[]) => {
    this.namesInUse = names;
  };

  @action
  onClickEditButton = () => {
    if (this.isSaving) {
      return;
    }
    if (!this.templateVarsTableStore.isPreviewMode) {
      this.isSaving = true;
      this.props.templatesVariablesStore
        .updateTemplateVariables(this.templateVarsTableStore.templateVarsData)
        .then(() => {
          this.initTemplateVarsStore();
          this.setNamesInUse([]);
          this.setPreviewMode();
          this.props.updateStatistics();
        })
        .catch(error => {
          let names = (error.message.match(/{.+}/) || [])[0] || '';
          if (names) {
            names = names.substring(1, names.length - 1).split(',');
            names.forEach(name => name.trim());
            this.setNamesInUse(names);
          }
        })
        .finally(() => {
          this.isSaving = false;
        });
    } else {
      this.templateVarsTableStore.setPreviewMode(
        !this.templateVarsTableStore.isPreviewMode,
      );
    }
  };

  @action
  addTemplateVariable = () => {
    if (this.isSaving) {
      return;
    }
    this.templateVarsTableStore.setPreviewMode(false);
    this.templateVarsTableStore.addTemplateVariable();
  };

  render() {
    if (!this.templateVarsTableStore) {
      return (
        <div className={style.templateVarPreloader}>
          <CircleLoader />
        </div>
      );
    }
    return (
      <div className={style.templateVarContainer}>
        {!this.isEmptyTable && (
          <div className={style.modeButtonContainer}>
            {!this.templateVarsTableStore.isPreviewMode && !this.isSaving && (
              <Button
                className={style.cancelButton}
                size={BtnSize.Small}
                styleType={BtnType.Secondary}
                onClick={this.onCancel}
              >
                <Icon type={'close'} className={style.cancelIcon} />
                <FormattedMessage id={'button.cancel'} />
              </Button>
            )}
            <Button
              className={style.editButton}
              size={BtnSize.Small}
              styleType={BtnType.Secondary}
              onClick={this.onClickEditButton}
              disabled={this.saveDisabled}
            >
              {this.isSaving ? (
                <CircleLoader className={style.editIcon} />
              ) : (
                <Icon
                  type={
                    this.templateVarsTableStore.isPreviewMode ? 'edit' : 'save'
                  }
                  className={style.editIcon}
                />
              )}
              <FormattedMessage
                id={
                  this.templateVarsTableStore.isPreviewMode && !this.isSaving
                    ? 'button.edit'
                    : 'button.save'
                }
              />
            </Button>
          </div>
        )}
        <TemplateVariablesTable
          deleteTemplateVariable={
            this.templateVarsTableStore.deleteTemplateVariable
          }
          addTemplateVariable={this.addTemplateVariable}
          templateVariableForms={this.templateVarsTableStore.templateVarsForms}
          previewMode={this.templateVarsTableStore.isPreviewMode}
          namesInUse={this.namesInUse}
          isDisabled={this.isSaving}
        />
      </div>
    );
  }
}

export default TemplateVariablesList;
