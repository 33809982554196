import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { ComparingState } from 'models/Environment';

import style from './ComparingMsState.module.scss';

const comparingStateToTextMap: Record<ComparingState, string> = {
  [ComparingState.NEW]: 'comparing.state.new',
  [ComparingState.MODIFIED]: 'comparing.state.modified',
  [ComparingState.DELETED]: 'comparing.state.deleted',
  [ComparingState.NOTHING_NEW]: 'comparing.state.nothingNew',
};

interface IProps {
  msState: ComparingState;
}

export const ComparingMsState = ({ msState }: IProps) => {
  const styles = classNames(style.state, {
    [style.state__new]: msState === ComparingState.NEW,
    [style.state__modified]: msState === ComparingState.MODIFIED,
    [style.state__deleted]: msState === ComparingState.DELETED,
  });

  return (
    <div className={styles}>
      <FormattedMessage id={comparingStateToTextMap[msState]} />
    </div>
  );
};
