import { getDisplayName } from '../helpers';
import { addValidation } from '../Validation';

export function hasNoSpecialWords(words: string[] = [], msg?: string) {
  return (target: any, fieldName: string) => {
    const validation = {
      fieldName,
      validateFunction: (obj: any) => {
        const name = getDisplayName(obj, fieldName);
        const value = obj[fieldName];

        const isValid = !words.includes(value);
        return isValid
          ? undefined
          : {
              message: msg || 'VM_HAS_SPECIAL_WORDS',
              data: {
                name,
                value,
              },
            };
      },
    };

    addValidation(target, fieldName, validation);
  };
}
