import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';

import { ActionId, ITemplate } from 'models/Template';
import RootStore from 'stores/RootStore';
import TemplatesPageStatistics from 'stores/TemplatesPageStatistics';

import TabsContainer, { TabsProps } from 'components/Tabs/TabsContainer';
import {
  TrapezoidTab,
  TrapezoidTabsList,
} from 'components/Tabs/TrapezoidTabs/TrapezoidTabsList';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import TemplateFormComponent from 'components/TemplateForm/TemplateFormComponent';
import TemplatesVariables from 'pages/TemplatesPage/TemplatesVariables';
import TemplatesList from 'pages/TemplatesPage/TemplatesList';
import TemplateTabInfo from 'pages/TemplatesPage/TemplateTabInfo/TemplateTabInfo';

import style from './TemplatesPage.module.scss';

enum TemplatesPageTabs {
  templates = 'templates.title',
  variables = 'variables.title',
}

export enum TemplatesPageStates {
  templatesList = 'templatesList',
  templateCreation = 'templateCreation',
}

export interface TemplateFormPayload {
  actionId: ActionId;
  template?: ITemplate;
  isViewMode?: boolean;
}

interface Props
  extends RouteComponentProps<{
    id: string;
    clusterName: string;
  }> {
  alertsStore?;
}

@inject('alertsStore')
@observer
class TemplatesPage extends Component<Props> {
  @observable statistics: TemplatesPageStatistics;
  @observable pageState = TemplatesPageStates.templatesList;
  @observable templateFormPayload: TemplateFormPayload | undefined | null;

  constructor(props) {
    super(props);

    this.statistics = new TemplatesPageStatistics({
      projectName: RootStore.currentProject,
      clusterName: this.props.match.params.clusterName,
    });
  }

  @action
  showTemplatesList = () => {
    this.pageState = TemplatesPageStates.templatesList;
  };

  @action
  showTemplateForm = (payload?: TemplateFormPayload) => {
    this.pageState = TemplatesPageStates.templateCreation;
    this.templateFormPayload = payload;
  };

  getActiveTabIndex = () => {
    if (this.templateFormPayload) {
      return this.templateFormPayload.actionId === ActionId.create_environment
        ? 0
        : 1;
    }

    return 0;
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      prevProps.match.params.clusterName !== this.props.match.params.clusterName
    ) {
      this.statistics = new TemplatesPageStatistics({
        projectName: RootStore.currentProject,
        clusterName: this.props.match.params.clusterName,
      });
      this.statistics.fetchStatistics();
      this.templateFormPayload = null;
      this.showTemplatesList();
    }
  }

  async componentDidMount() {
    await this.statistics.fetchStatistics();
  }

  render() {
    const { clusterName } = this.props.match.params;
    const tabs: TrapezoidTab[] = [
      {
        title: 'environment.title',
        content: (isActive?: boolean) => (
          <TemplateTabInfo
            title="environment.title"
            params={this.statistics.envStat}
            isActive={isActive}
          />
        ),
      },
      {
        title: 'microservice.title',
        content: (isActive?: boolean) => (
          <TemplateTabInfo
            title="microservice.title"
            params={this.statistics.msStat}
            isActive={isActive}
          />
        ),
      },
    ];

    return (
      <div
        key={clusterName}
        className={classNames(style.wrapper, {
          [style.wrapper__openedAlert]: this.props.alertsStore.isOpen,
        })}
      >
        <Breadcrumbs
          links={[
            {
              name: <FormattedMessage id="page.project.dashboard" />,
              to: `/project/${RootStore.currentProject}/info`,
            },
            {
              name: (
                <FormattedMessage id="link.cluster" values={{ clusterName }} />
              ),
              to: `/project/${RootStore.currentProject}/cluster/${clusterName}/info`,
            },
            {
              name: <FormattedMessage id="templates.title" />,
            },
          ]}
        />

        {this.pageState === TemplatesPageStates.templateCreation && (
          <TemplateFormComponent
            clusterName={clusterName}
            actionId={ActionId.create_environment}
            onClose={this.showTemplatesList}
            updateStatistics={this.statistics.fetchStatistics}
            {...this.templateFormPayload}
          />
        )}

        {this.pageState === TemplatesPageStates.templatesList && (
          <div className={style.tabsWrapper}>
            <TabsContainer
              CustomTabs={(props: TabsProps) => (
                <TrapezoidTabsList tabs={tabs} {...props} />
              )}
              activeTabIndex={this.getActiveTabIndex()}
              tabsComponents={[
                {
                  component: (
                    <div className={style.body}>
                      <TabsContainer
                        tabsComponents={[
                          {
                            component: (
                              <TemplatesList
                                key={`${ActionId.create_environment}${TemplatesPageTabs.templates}`}
                                clusterName={clusterName}
                                actionId={ActionId.create_environment}
                                showTemplateForm={this.showTemplateForm}
                                updateStatistics={
                                  this.statistics.fetchStatistics
                                }
                              />
                            ),
                            label: TemplatesPageTabs.templates,
                          },
                          {
                            component: (
                              <TemplatesVariables
                                key={`${ActionId.create_environment}${TemplatesPageTabs.variables}`}
                                clusterName={clusterName}
                                actionId={ActionId.create_environment}
                                updateStatistics={
                                  this.statistics.fetchStatistics
                                }
                              />
                            ),
                            label: TemplatesPageTabs.variables,
                          },
                        ]}
                      />
                    </div>
                  ),
                  label: ActionId.create_environment,
                },
                {
                  component: (
                    <div className={style.body}>
                      <TabsContainer
                        tabsComponents={[
                          {
                            component: (
                              <TemplatesList
                                key={`${ActionId.create_microservice}${TemplatesPageTabs.templates}`}
                                clusterName={clusterName}
                                actionId={ActionId.create_microservice}
                                showTemplateForm={this.showTemplateForm}
                                updateStatistics={
                                  this.statistics.fetchStatistics
                                }
                              />
                            ),
                            label: TemplatesPageTabs.templates,
                          },
                          {
                            component: (
                              <TemplatesVariables
                                key={`${ActionId.create_microservice}${TemplatesPageTabs.variables}`}
                                clusterName={clusterName}
                                actionId={ActionId.create_microservice}
                                updateStatistics={
                                  this.statistics.fetchStatistics
                                }
                              />
                            ),
                            label: TemplatesPageTabs.variables,
                          },
                        ]}
                      />
                    </div>
                  ),
                  label: ActionId.create_microservice,
                },
              ]}
            />
          </div>
        )}
      </div>
    );
  }
}

export default TemplatesPage;
