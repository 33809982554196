import React from 'react';
import { FormattedMessage } from 'react-intl';

import WithPopper from 'components/WithPopper/WithPopper';
import Icon from 'components/Icon/Icon';

import style from './FilterChip.module.scss';

interface Props {
  params;
  paramsName: string;
  deleteFilter: (name: string) => () => void;
  options?: string[];
  chipText?: string;
  textTitle?: string;
}

const getChipText = (title: string, options: string[], params: string[]) => (
  <>
    {title}
    {options
      .filter(el => !params.includes(el))
      .map((el, idx, arr) => (
        <span key={idx}>
          <FormattedMessage id={el} />
          {arr.length !== idx + 1 && <>,&nbsp;</>}
        </span>
      ))}
  </>
);

const FilterChip: React.FunctionComponent<Props> = ({
  deleteFilter,
  options,
  params,
  paramsName,
  chipText,
  textTitle,
}) =>
  params[paramsName] ? (
    <div className={style.filterChip}>
      <WithPopper
        title={
          chipText ||
          (textTitle &&
            options &&
            params &&
            getChipText(textTitle, options, params[paramsName]))
        }
      >
        <div className={style.filterChipText}>
          {chipText ||
            (textTitle &&
              options &&
              params &&
              getChipText(textTitle, options, params[paramsName]))}
        </div>
      </WithPopper>
      <Icon
        className={style.filterChipIcon}
        type="close"
        onClick={deleteFilter(paramsName)}
      />
    </div>
  ) : null;

export default FilterChip;
