import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { replaceUrlParams } from 'http/helpers';
import { ROUTES } from 'routes/routes';
import RootStore from 'stores/RootStore';
import EnvironmentItem from './EnvironmentItem/EnvironmentItem';
import { Environment } from 'models/Environment';

import style from './EnvironmentList.module.scss';

interface Props {
  environments: Environment[];
  clusterName: string;
  hideTitle?: boolean;
}

const EnvironmentList: React.FC<Props> = ({
  environments,
  clusterName,
  hideTitle = false,
}) => {
  const history = useHistory();
  const gotoEnvironment = environmentName => {
    history.push(
      replaceUrlParams(ROUTES.environment, {
        id: RootStore.currentProject,
        clusterName,
        environmentName,
      }),
    );
  };

  return (
    <div>
      <div className={style.titleContainer}>
        {!hideTitle && (
          <div className={style.title}>
            <FormattedMessage id="page.project.environments.title" />
          </div>
        )}
        <div
          className={classNames(style.itemCount, {
            [style.itemCount__withoutTitle]: hideTitle,
          })}
        >
          {environments.length}
        </div>
      </div>
      <div className={style.scrollContainer}>
        {environments
          .slice()
          .sort((a, b) => Number(!!b.favorite) - Number(!!a.favorite))
          .map(environment => (
            <EnvironmentItem
              key={environment.name}
              environment={environment}
              gotoEnvironment={gotoEnvironment}
              alertsFilter={{
                clusterReference: clusterName,
                environmentReference: environment.name,
              }}
            />
          ))}
      </div>
    </div>
  );
};

export default EnvironmentList;
