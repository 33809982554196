import { action, observable } from 'mobx';
import {
  BaseFormModel,
  displayName,
  hasMaxLength,
  isMatchRegExp,
  isValueValid,
} from '../BaseForm';
import {
  TEMPLATE_VAR_REGEXP,
  ENV_VAR_START_REGEXP,
} from '../../helpers/testRegexp';

const fieldsRequiredMap = {
  variableName: 'isNameRequiredValid',
  variableValue: 'isValueRequiredValid',
};

class TemplateVariableForm extends BaseFormModel {
  @observable
  isNameValid = true;
  @observable
  isNameRequiredValid = true;
  @observable
  isValueRequiredValid = true;

  @observable
  @displayName('template.variable.name')
  @isMatchRegExp(TEMPLATE_VAR_REGEXP, 'validation.template.var')
  @isMatchRegExp(ENV_VAR_START_REGEXP, 'validation.template.var.name.start')
  @hasMaxLength(253, 'validation.template.var.name.max.length')
  @isValueValid('isNameValid', 'validation.template.var.name.uniq')
  @isValueValid('isNameRequiredValid', 'form.field.is.required')
  variableName: string = '';

  @observable
  @displayName('template.variable.value')
  @isValueValid('isValueRequiredValid', 'form.field.is.required')
  variableValue: string = '';

  @observable
  @displayName('template.variable.description')
  variableDescription: string = '';

  system = false;

  @action
  setValidName = (isValid: boolean) => {
    this.isNameValid = isValid;
  };

  @action
  setFieldRequiredValid = (
    fieldName: 'variableName' | 'variableValue',
    required: boolean,
  ) => {
    this[fieldsRequiredMap[fieldName]] = required;
    this.setErrorsMap(fieldName);
  };

  @action
  checkRequiredValid = (
    changeField: 'variableName' | 'variableValue' | 'variableDescription',
  ) => {
    if (changeField === 'variableName') {
      if (this.variableName) {
        this.setFieldRequiredValid('variableName', true);
        return;
      }
      if (
        !this.variableName &&
        (this.variableValue || this.variableDescription)
      ) {
        this.setFieldRequiredValid('variableName', false);
      }
    } else if (changeField === 'variableValue') {
      if (this.variableValue) {
        this.setFieldRequiredValid('variableValue', true);
        return;
      }
      if (
        (this.variableName || this.variableDescription) &&
        !this.variableValue
      ) {
        this.setFieldRequiredValid('variableValue', false);
      }
    }

    if (
      !this.variableName &&
      !this.variableValue &&
      !this.variableDescription
    ) {
      this.setFieldRequiredValid('variableValue', true);
      this.setFieldRequiredValid('variableName', true);
    }
  };
}

export default TemplateVariableForm;
