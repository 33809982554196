import http from '../http';
import { appUrl } from 'http/helpers';
import URLS from 'http/urls';
import {
  IServiceParams,
  ServiceDetails,
  Service,
  ServiceCreate,
  ServiceUpdate,
  IInstanceProviderParams,
  MsStorageProviderDetails,
  IEnvVariable,
} from 'models/Service';
import { IEnvironmentParams } from 'models/Environment';

export const serviceApi = {
  fetchServices(params: IEnvironmentParams) {
    return http.get<Service[]>(appUrl(URLS.services), {
      replaceParams: params,
    });
  },

  fetchService(params: IServiceParams) {
    return http.get<Service>(appUrl(URLS.service), {
      replaceParams: params,
    });
  },

  fetchServiceDetails(params: IServiceParams) {
    return http.get<ServiceDetails>(appUrl(URLS.serviceDetails), {
      replaceParams: params,
    });
  },

  fetchServiceStorageDetails(params: IServiceParams) {
    return http.get<MsStorageProviderDetails[]>(
      appUrl(URLS.serviceStorageDetails),
      {
        replaceParams: params,
      },
    );
  },

  createService(params: IEnvironmentParams, data: ServiceCreate) {
    return http.post<ServiceCreate, any>(appUrl(URLS.createService), data, {
      replaceParams: params,
    });
  },

  restartService(params: IServiceParams) {
    return http.put(appUrl(URLS.restartService), null, {
      replaceParams: params,
    });
  },

  updateService(params: IServiceParams, data: ServiceUpdate) {
    return http.put<ServiceUpdate>(appUrl(URLS.updateService), data, {
      replaceParams: params,
    });
  },

  deleteService(params: IServiceParams) {
    return http.delete(appUrl(URLS.deleteService), {
      replaceParams: params,
    });
  },

  deleteServiceProviderInstance(params: IInstanceProviderParams) {
    return http.delete(appUrl(URLS.serviceStorageProviders), {
      replaceParams: params,
    });
  },

  addSupportToMS(params: IServiceParams, data) {
    return http.post<any, any>(appUrl(URLS.addSupportToMS), data, {
      replaceParams: params,
    });
  },

  updateMSSupport(params: IInstanceProviderParams, data) {
    return http.put(appUrl(URLS.updateMSSupport), data, {
      replaceParams: params,
    });
  },

  scaleMS(params: IServiceParams, replicas) {
    return http.put(
      appUrl(URLS.scaleMS),
      { replicas },
      {
        replaceParams: params,
      },
    );
  },
  fetchServiceEnvVariables(params: IServiceParams) {
    return http.get<IEnvVariable[]>(appUrl(URLS.serviceEnvVariables), {
      replaceParams: params,
    });
  },
  updateServiceEnvVariables(params: IServiceParams, envVars: IEnvVariable[]) {
    return http.put(appUrl(URLS.serviceEnvVariables), envVars, {
      replaceParams: params,
    });
  },
};
