import React, { Component } from 'react';
import { observer } from 'mobx-react';
import ModalStore, {
  DialogActionType,
  ModalProps,
} from '../../../stores/ModalStore';
import { observable } from 'mobx';
import classNames from 'classnames';
import CommonModal from '../../Modals/Modal/CommonModal';
import ScaleMSForm from './ScaleMSForm';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import NotificationService, {
  fullscreenNotificationOptions,
} from 'components/Notification/NotificationService';

import style from './ScaleMSModal.module.scss';
import { FormattedMessage } from 'react-intl';
import { IServiceParams } from 'models/Service';
import httpFacade from 'http/httpFacade';
import ScaleMSConfirmModal from '../ScaleMSConfirmModal/ScaleMSConfirmModal';
import NumberField from 'components/Form/Fields/NumberField/NumberField';

interface Props extends ModalProps {
  params: IServiceParams;
  msLongName: string;
  value: number;
}

@observer
class ScaleMSModal extends Component<Props> {
  @observable
  serverErrors: string[] = [];

  @observable
  serverWarnings: string[] = [];

  @observable
  isUIblocked: boolean = false;

  @observable
  isHidden: boolean = false;

  @observable scaleMsForm = new ScaleMSForm();
  FormControl = bindFormControl(this.scaleMsForm);

  componentDidMount() {
    this.scaleMsForm.setValue('scale', this.props.value);
  }

  onClose = () => {
    this.props.onClose(DialogActionType.cancel);
  };

  request = async () => {
    try {
      this.isUIblocked = true;
      await httpFacade.service.scaleMS(
        this.props.params,
        Number(this.scaleMsForm.scale),
      );
      NotificationService.successMessage(
        { id: 'scale.ms.success' },
        fullscreenNotificationOptions,
      );
      this.onClose();
    } catch (error) {
      const errMsg = error.response.data.id;

      NotificationService.errorMessage(
        { id: errMsg || 'scale.ms.error' },
        fullscreenNotificationOptions,
      );
      if (!errMsg) {
        this.onClose();
      }
    } finally {
      this.isUIblocked = false;
    }
  };

  onConfirmCancel = () => {
    this.isHidden = false;
  };

  onConfirm = async () => {
    if (!Number(this.scaleMsForm.scale)) {
      ModalStore.showModal(ScaleMSConfirmModal, {
        request: this.request,
        onConfirmCancel: this.onConfirmCancel,
        msLongName: this.props.msLongName,
      });
      this.isHidden = true;
      return;
    }
    const isValid = this.scaleMsForm.validate();
    if (isValid) {
      await this.request();
    }
  };

  render() {
    const FormControl = this.FormControl;

    return (
      <CommonModal
        className={classNames(style.body, {
          [style.isHidden]: this.isHidden,
        })}
        bodyContentClassName={style.bodyContent}
        title="scale.ms"
        cancel="button.cancel"
        confirm="service.scale"
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        serverErrors={this.serverErrors}
        serverWarnings={this.serverWarnings}
        isUIblocked={this.isUIblocked}
        isSubmitBtnDisabled={!this.scaleMsForm.isFormValid}
      >
        <div className={style.container}>
          <span className={style.textContainer}>
            <FormattedMessage id="service.scale" />
          </span>
          <div className={style.fieldWrapper}>
            <FormControl
              withoutLabel
              immediateValidation
              name="scale"
              render={props => <NumberField {...props} min={0} max={100} />}
              className={style.field}
              messageClassName={style.fieldMessage}
            />
            <div className={style.text}>
              <FormattedMessage id="range.0.100" />
            </div>
          </div>
        </div>
      </CommonModal>
    );
  }
}

export default ScaleMSModal;
