import React, { Component } from 'react';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { TEMPLATE_NAME_REGEXP, testRegExp } from 'helpers/testRegexp';
import { ActionId, ITemplate } from 'models/Template';
import TemplateForm from 'stores/Forms/TemplateForm';
import TemplateStore from 'stores/TemplateStore';
import RootStore from 'stores/RootStore';

import Button, { BtnType } from 'components/Button/Button';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import TextField from 'components/Form/Fields/TextField/TextField';
import NotificationService, {
  fullscreenNotificationOptions,
} from 'components/Notification/NotificationService';
import TemplateEditor from 'components/TemplateForm/TemplateEditor';
import TemplateFileUpload from 'components/TemplateFileUpload/TemplateFileUpload';
import Icon from 'components/Icon/Icon';

import style from './TemplateFormComponent.module.scss';
import {
  CreateTemplateFormTitleToActionId,
  EditTemplateFormTitleToActionId,
  getUniqNameErrorMessage,
  ViewTemplateFormTitleToActionId,
} from 'components/TemplateForm/helpers';

interface Props {
  clusterName: string;
  actionId: ActionId;
  onClose: () => void;
  template?: ITemplate;
  updateStatistics: () => void;
  isViewMode?: boolean;
}

@observer
class TemplateFormComponent extends Component<Props> {
  store: TemplateStore;
  templateForm: TemplateForm;
  FormControl;

  @observable isViewMode = false;

  constructor(props: Props) {
    super(props);
    const { clusterName, actionId, template, isViewMode = false } = this.props;
    this.store = new TemplateStore({
      projectName: RootStore.currentProject,
      clusterName,
      actionId,
    });

    if (template) {
      this.templateForm = new TemplateForm(template.name);

      this.templateForm.name = template.name;
      this.templateForm.description = template.description || '';
      this.templateForm.content = template.content;
    } else {
      this.templateForm = new TemplateForm();
    }

    this.FormControl = bindFormControl(this.templateForm);
    this.isViewMode = isViewMode;
  }

  @action
  onEdit = () => {
    this.isViewMode = false;
  };

  onClose = () => {
    this.props.onClose();
  };

  onSubmit = async () => {
    const isCreation = !this.templateForm.templateId;
    try {
      await this.store.createUpdateTemplate(
        this.templateForm.data,
        this.templateForm.templateId,
      );

      if (isCreation) {
        this.props.updateStatistics();
      }

      this.onClose();
      NotificationService.successMessage(
        {
          id: isCreation
            ? 'template.creation.success'
            : 'template.editing.success',
        },
        fullscreenNotificationOptions,
      );
    } catch (error) {
      const errorId = error.response.data.id;
      NotificationService.errorMessage(
        {
          id: `${errorId || 'template.creation.error'}`,
        },
        fullscreenNotificationOptions,
      );

      if (errorId === getUniqNameErrorMessage(this.templateForm.name)) {
        this.templateForm.setUniqNameError();
      }
    }
  };

  onTemplateNameChange = onChange => (value: string, e) => {
    const targetElement = e.target;
    const ss = targetElement.selectionStart;
    const se = targetElement.selectionEnd;
    const length = value.length;
    const isValidRegexp = testRegExp(value, TEMPLATE_NAME_REGEXP);
    if (!isValidRegexp && length > 0) {
      setTimeout(() => {
        targetElement.selectionStart = ss - 1;
        targetElement.selectionEnd = se - 1;
      }, 0);
      return;
    }

    onChange(value, e);
    if (!this.templateForm.isNameUniq) {
      this.templateForm.resetUniqNameError();
    }
    this.templateForm.validate();
  };

  onContentChange = (value: string) => {
    this.templateForm.content = value;
  };

  async componentDidMount() {
    await this.store.init();
  }

  @computed
  get formTitleId() {
    const { actionId } = this.store.templateParams;

    if (this.isViewMode) {
      return ViewTemplateFormTitleToActionId[actionId];
    }

    if (this.templateForm.isEditing) {
      return EditTemplateFormTitleToActionId[actionId];
    }

    return CreateTemplateFormTitleToActionId[actionId];
  }

  render() {
    const FormControl = this.FormControl;
    const { templatesVariables, isUIblocked, fetchingVariables } = this.store;

    return (
      <div className={style.wrapper}>
        {isUIblocked && <div className={style.overlay} />}
        <h2 className={style.title}>
          <FormattedMessage id={this.formTitleId} />
        </h2>
        <form className={style.form}>
          <div className={style.formFields}>
            <FormControl
              className={style.formControl}
              name="name"
              immediateValidation
              render={props => (
                <TextField
                  {...props}
                  onChange={this.onTemplateNameChange(props.onChange)}
                  disabled={this.isViewMode}
                />
              )}
            />
            <FormControl
              className={style.formControl}
              name="description"
              render={props => (
                <TextField {...props} disabled={this.isViewMode} />
              )}
            />
            {this.isViewMode ? (
              <Button
                styleType={BtnType.Text}
                className={style.btnEdit}
                onClick={this.onEdit}
                type="button"
              >
                <Icon type="edit" className={style.icon} />
                <FormattedMessage id="button.edit" />
              </Button>
            ) : (
              <TemplateFileUpload onValueChange={this.onContentChange} />
            )}
          </div>
          <TemplateEditor
            variables={templatesVariables}
            value={this.templateForm.content}
            onValueChange={this.onContentChange}
            loading={fetchingVariables}
            readOnly={this.isViewMode}
          />
        </form>
        <div className={style.buttons}>
          <Button styleType={BtnType.Ghost} onClick={this.onClose}>
            <FormattedMessage
              id={this.isViewMode ? 'button.back' : 'button.cancel'}
            />
          </Button>
          {!this.isViewMode && (
            <Button
              className={classNames(style.button, {
                [style.button__loading]: isUIblocked,
              })}
              disabled={!this.templateForm.isFormValid || isUIblocked}
              onClick={this.onSubmit}
              isLoading={isUIblocked}
            >
              <FormattedMessage
                id={
                  this.templateForm.isEditing ? 'button.save' : 'button.create'
                }
              />
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default TemplateFormComponent;
