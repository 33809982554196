import React, { ChangeEvent, InputHTMLAttributes, Component } from 'react';
import classNames from 'classnames';

import style from './TextField.module.scss';
import Icon from 'components/Icon/Icon';
import { Omit } from 'helpers/types';
import { Icons } from 'components/Icon';
import WithPopper from 'components/WithPopper/WithPopper';
import { FormattedMessage } from 'react-intl';

interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  iconType?: string;
  iconTooltip?: string;
  name?: string;
  id?: string;
  type?: string;
  hasErrors?: boolean;
  hasWarnings?: boolean;
  isSucceed?: boolean;
  hasInfo?: boolean;
  onIconClick?: () => void;
  onChange?: (v: string, event: ChangeEvent<HTMLInputElement>) => void;
  onAnimationStart?: any;
  toUpperCase?: boolean;
}

class TextField extends Component<Props> {
  public onChange = (event: ChangeEvent<HTMLInputElement>) =>
    this.props.onChange && this.props.onChange(event.target.value, event);

  public render(): JSX.Element {
    const {
      name,
      id,
      iconType,
      iconTooltip,
      type = 'text',
      hasErrors = false,
      isSucceed = false,
      hasWarnings = false,
      hasInfo = false,
      onIconClick = () => undefined,
      className,
      onAnimationStart,
      toUpperCase,
      ...attrs
    } = this.props;

    const colors = {
      [style.wrapper__info]: hasInfo,
      [style.wrapper__warning]: hasWarnings,
      [style.wrapper__error]: hasErrors,
      [style.wrapper__success]: isSucceed,
    };
    const hasIconClickHandler = !!this.props.onIconClick;

    return (
      <span
        className={classNames(
          { [style.toUpperCase]: toUpperCase },
          style.wrapper,
          colors,
          className,
        )}
      >
        <input
          onAnimationStart={onAnimationStart}
          id={id ? id : name}
          type={type}
          {...attrs}
          className={style.field}
          onChange={this.onChange}
        />
        {iconTooltip ? (
          <WithPopper
            className={classNames(style.icon, {
              [style.icon__clickable]: hasIconClickHandler,
            })}
            title={<FormattedMessage id={iconTooltip} />}
          >
            <Icon type={iconType as keyof typeof Icons} onClick={onIconClick} />
          </WithPopper>
        ) : (
          <div
            className={classNames(style.icon, {
              [style.icon__clickable]: hasIconClickHandler,
            })}
          >
            <Icon type={iconType as keyof typeof Icons} onClick={onIconClick} />
          </div>
        )}
      </span>
    );
  }
}

export default TextField;
