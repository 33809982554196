import React from 'react';
import classNames from 'classnames';

import Icon from 'components/Icon/Icon';
import { Icons } from 'components/Icon';
import { FormattedMessage } from 'react-intl';

import style from './MenuItem.module.scss';

interface Props {
  title: string;
  icon?: keyof typeof Icons;
  hoveredIcon?: keyof typeof Icons;
  onClick?: () => void;
  active?: boolean;
  isList?: boolean;
  isListOpen?: boolean;
}

const MenuItem: React.FC<Props> = ({
  title,
  onClick,
  icon,
  active = false,
  isList = false,
  hoveredIcon,
  isListOpen,
}) => {
  const [hovered, setHovered] = React.useState(false);

  const onMouseHover = () => {
    setHovered(true);
  };

  const onMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div
      onMouseEnter={onMouseHover}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      className={classNames(style.menuItem, {
        [style.active]: active,
        [style.witoutIcon]: !icon,
      })}
    >
      {icon && (
        <Icon
          className={style.icon}
          type={(hovered || active) && hoveredIcon ? hoveredIcon : icon}
        />
      )}
      <FormattedMessage id={title} />
      {isList && (
        <Icon
          className={style.arrowIcon}
          type={isListOpen ? 'arrowTop' : 'arrowBottom'}
        />
      )}
    </div>
  );
};

export default MenuItem;
