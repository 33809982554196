import { getDisplayName } from '../helpers';
import { addValidation } from '../Validation';

export function isRange(min: number, max: number, msg?: string) {
  return (target: any, fieldName: string) => {
    const validation = {
      fieldName,
      validateFunction: (obj: any) => {
        const name = getDisplayName(obj, fieldName);
        const value = obj[fieldName];
        const numberValue = Number(value);

        const isValid =
          (!value && value !== 0) ||
          (!isNaN(numberValue) && numberValue >= min && numberValue <= max);
        return isValid
          ? undefined
          : {
              message: msg || 'VM_IS_RANGE',
              data: {
                name,
                value,
                min,
                max,
              },
            };
      },
    };

    addValidation(target, fieldName, validation);
  };
}
