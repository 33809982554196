import { observable, action } from 'mobx';

import {
  BaseFormModel,
  displayName,
  hasMinMaxLength,
  hasNoSpecialWords,
  isMatchRegExp,
  isRequired,
} from 'stores/BaseForm';
import { KEY_REGEXP, ENV_KEY_START_END_REGEXP } from 'helpers/testRegexp';
import httpFacade from 'http/httpFacade';
import NotificationService, {
  fullscreenNotificationOptions,
} from '../../Notification/NotificationService';
import { EnvCopyMode } from 'models/Environment';

class CloneEnvironmentForm extends BaseFormModel {
  @observable
  @displayName('environment.modal.form.label.environment.name')
  @isRequired()
  envName: string = '';

  @observable
  @displayName('environment.modal.form.label.key')
  @hasMinMaxLength(1, 63, 'validation.env.key.length')
  @isMatchRegExp(KEY_REGEXP, 'validation.env.key.characters')
  @isMatchRegExp(ENV_KEY_START_END_REGEXP, 'validation.env.key.startend')
  @hasNoSpecialWords(
    ['default', 'kube-system', 'kube-public', 'kube-node-lease'],
    'validation.env.key.reserved',
  )
  key: string = '';

  @action.bound
  async submit(
    projectName: string,
    clusterName: string,
    basedOn: string,
    envCopyMode?: EnvCopyMode,
  ) {
    const isFormValid = this.validate();

    if (isFormValid) {
      try {
        await httpFacade.environment.cloneEnvironment(
          {
            name: this.key,
            longName: this.envName,
            basedOn,
            mode: envCopyMode,
          },
          { projectName, clusterName },
        );
        NotificationService.successMessage(
          { id: 'env.created' },
          fullscreenNotificationOptions,
        );
      } catch (error) {
        if (
          error.response.data.errorMessages ||
          error.response.data.warningMessages
        ) {
          throw error;
        } else {
          const { data } = error.response;
          const errorString = data.id || 'env.fail';
          NotificationService.errorMessage(
            { id: errorString },
            fullscreenNotificationOptions,
          );
        }
      }
    }
  }
}

export default CloneEnvironmentForm;
