import { action, computed, observable } from 'mobx';
import { ComparingState, IEnvVarComparing } from 'models/Environment';
import {
  BaseFormModel,
  hasMaxLength,
  isMatchRegExp,
  isValueValid,
} from 'stores/BaseForm';
import { ENV_VAR_REGEXP, ENV_VAR_START_REGEXP } from '../../helpers/testRegexp';

const fieldsRequiredMap = {
  name: 'isNameRequiredValid',
  value: 'isValueRequiredValid',
};

export class ComparingEnvVarsForm extends BaseFormModel {
  @observable
  isNameUniq = true;
  @observable
  isNameRequiredValid = true;
  @observable
  isValueRequiredValid = true;

  @observable
  @isMatchRegExp(ENV_VAR_REGEXP, 'validation.env.var')
  @isMatchRegExp(ENV_VAR_START_REGEXP, 'validation.env.var.name.start')
  @hasMaxLength(253, 'validation.env.var.name.max.length')
  @isValueValid('isNameUniq', 'validation.env.var.name.uniq')
  @isValueValid('isNameRequiredValid', 'form.field.is.required')
  name: string = '';

  @observable
  @isValueValid('isValueRequiredValid', 'form.field.is.required')
  value: string = '';

  @observable secure: boolean = false;
  @observable envSpecific: boolean = false;
  @observable deleted: boolean = false;

  envVar: IEnvVarComparing & { isAddedByUser: boolean };

  @computed
  get isValueEqualToSource(): boolean {
    return this.value === this.envVar.valueSource;
  }

  @computed
  get isValueEqualToTarget(): boolean {
    return this.value === this.envVar.valueTarget;
  }

  constructor(
    envVar: IEnvVarComparing & {
      isAddedByUser: boolean;
      msState: ComparingState;
    },
  ) {
    super();
    this.init(envVar);
  }

  @action
  init = (
    envVar: IEnvVarComparing & {
      isAddedByUser: boolean;
      msState: ComparingState;
    },
  ) => {
    this.envVar = envVar;
    this.name = envVar.name;
    this.value = envVar.valueSource ?? envVar.valueTarget ?? '';
    if (
      envVar.envVarState === ComparingState.MODIFIED ||
      envVar.envVarState === ComparingState.DELETED
    ) {
      this.value = '';
    }
    if (envVar.envSpecific && envVar.envVarState !== ComparingState.NEW) {
      this.value = envVar.valueTarget ?? '';
    }

    this.secure = envVar.secureTarget ?? false;
    if (envVar.envVarState === ComparingState.NEW) {
      this.secure = envVar.secureSource ?? false;
    }
    this.envSpecific = envVar.envSpecific ?? false;
    const isNewAndMSModified =
      envVar.envVarState === ComparingState.NEW &&
      (envVar.msState === ComparingState.MODIFIED ||
        envVar.msState === ComparingState.NOTHING_NEW);
    const isDeletedAndEmpty =
      envVar.envVarState === ComparingState.DELETED &&
      !envVar.valueTarget &&
      !envVar.valueSource;
    this.deleted =
      envVar.envSpecific && (isNewAndMSModified || isDeletedAndEmpty);
  };

  @action
  useSourceValue = () => {
    this.value = this.envVar.valueSource ?? '';
    this.checkRequiredValid();
  };

  @action
  useTargetValue = () => {
    this.value = this.envVar.valueTarget ?? '';
    this.checkRequiredValid();
  };

  @action
  toggleSecure = () => {
    this.secure = !this.secure;
  };

  @action
  toggleEnvSpecific = () => {
    this.envSpecific = !this.envSpecific;
  };

  @action
  toggleDeleted = () => {
    this.deleted = !this.deleted;
    const isValueRequiredValid = this.deleted ? true : !!this.value.length;
    this.setFieldRequiredValid('value', isValueRequiredValid);
  };

  @action
  setNameUniq = (isNameUniq: boolean) => {
    this.isNameUniq = isNameUniq;
  };

  @action
  setFieldRequiredValid = (fieldName: 'name' | 'value', required: boolean) => {
    this[fieldsRequiredMap[fieldName]] = required;
    this.setErrorsMap(fieldName);
  };

  @action
  checkRequiredValid = (isNameChanged?: boolean) => {
    if (isNameChanged) {
      if (this.name) {
        this.setFieldRequiredValid('name', true);
        return;
      }
      if (!this.name && this.value) {
        this.setFieldRequiredValid('name', false);
      }
    } else {
      if (this.value) {
        this.setFieldRequiredValid('value', true);
        return;
      }
      if (this.name && !this.value) {
        this.setFieldRequiredValid('value', false);
      }
    }

    if (!this.name && !this.value) {
      this.setFieldRequiredValid('value', true);
      this.setFieldRequiredValid('name', true);
    }
  };
}
