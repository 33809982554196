import React from 'react';

import style from './MicroservicePageStorageList.module.scss';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/Icon/Icon';
import { observer } from 'mobx-react';
import Menu from './Menu/Menu';
import { MsStorageProviderDetails, ServiceDetails } from 'models/Service';
import BorderedText, {
  BorderedTextType,
} from 'components/BorderedText/BorderedText';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Status, { StatusType } from 'components/Status/Status';
import Button, { BtnType } from 'components/Button/Button';
import { StorageProviderInstance } from 'models/ExternalStorage';
import AddSupportToMSModal from 'components/AddSupportToMSModal/AddSupportToMSModal';
import ModalStore from 'stores/ModalStore';
import WithPopper from 'components/WithPopper/WithPopper';

interface Props
  extends RouteComponentProps<{
    id: string;
    clusterName: string;
    environmentName: string;
    microserviceName: string;
  }> {
  serviceProviders: MsStorageProviderDetails[];
  externalStorageStatus: boolean;
  providerInstances: StorageProviderInstance[];
  service: ServiceDetails;
}

const statusMap = {
  CREATION_PENDING: StatusType.Warning,
  SUCCESSFUL: StatusType.Success,
  FAILURE: StatusType.Error,
  DELETION_PENDING: StatusType.Warning,
};

@observer
class MicroservicePageStorageList extends React.Component<Props> {
  openAddSupportToMSModal = () => {
    const {
      id: projectName,
      clusterName,
      environmentName,
      microserviceName,
    } = this.props.match.params;
    ModalStore.showModal(AddSupportToMSModal, {
      projectName,
      clusterName,
      environmentName,
      microserviceName,
      msLongName: this.props.service.info.longName,
    });
  };

  render() {
    const routeParams = this.props.match.params;
    return (
      <div>
        {!this.props.externalStorageStatus && (
          <div className={style.disabledExternalStorageContainer}>
            <Icon type="storageDisabled" />
            <span className={style.storageDisabledText1}>
              <FormattedMessage id="externalStorageDisabledText1" />
            </span>
            <span className={style.storageDisabledText2}>
              <FormattedMessage id="externalStorageDisabledText2" />
            </span>
          </div>
        )}
        {this.props.externalStorageStatus &&
          !this.props.providerInstances.length && (
            <div className={style.disabledExternalStorageContainer}>
              <Icon type="emptyProviderList" />
              <span className={style.storageDisabledText1}>
                <FormattedMessage id="emptyListText1" />
              </span>
              <span className={style.storageDisabledText2}>
                <FormattedMessage id="emptyListText2" />
              </span>
            </div>
          )}
        {!!(
          this.props.externalStorageStatus &&
          this.props.providerInstances.length &&
          !this.props.serviceProviders.length
        ) && (
          <div className={style.disabledExternalStorageContainer}>
            <Icon type="noMSSupport" />
            <span className={style.storageDisabledText1}>
              <FormattedMessage id="no.have.ms.support" />
            </span>
            <Button onClick={this.openAddSupportToMSModal}>
              <FormattedMessage id="add.external.storage.support" />
            </Button>
          </div>
        )}
        {!!(
          this.props.externalStorageStatus &&
          this.props.providerInstances.length &&
          this.props.serviceProviders.length
        ) && (
          <>
            <table className={style.table}>
              <thead>
                <tr className={style.tableHeader}>
                  <th>
                    <FormattedMessage id="provider.instance" />
                  </th>
                  <th>
                    <FormattedMessage id="database.host" />
                  </th>
                  <th>
                    <FormattedMessage id="database.port" />
                  </th>
                  <th>
                    <FormattedMessage id="database.name" />
                  </th>
                  <th>
                    <FormattedMessage id="username" />
                  </th>
                  <th>
                    <FormattedMessage id="users.password" />
                  </th>
                  <th>
                    <FormattedMessage id="status" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.serviceProviders.map(serviceProvider => {
                  return (
                    <tr key={serviceProvider.providerInstanceName}>
                      <td>
                        <div className={style.cellContainer}>
                          <span className={style.firstTitle}>
                            {serviceProvider.providerInstanceName}
                          </span>
                          <span className={style.secondTitle}>
                            <BorderedText className={style.borderedText}>
                              <FormattedMessage id={serviceProvider.mode} />
                            </BorderedText>
                            {serviceProvider.providerInstanceObsolete && (
                              <BorderedText
                                className={style.borderedText}
                                type={BorderedTextType.Error}
                              >
                                <FormattedMessage id="obsolete" />
                              </BorderedText>
                            )}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className={style.cellContainer}>
                          <span className={style.firstTitle}>
                            {serviceProvider.environmentVariablesMapping &&
                              serviceProvider.environmentVariablesMapping.host}
                          </span>
                          <span className={style.secondTitle} />
                        </div>
                      </td>
                      <td>
                        <div className={style.cellContainer}>
                          <span className={style.firstTitle}>
                            {serviceProvider.environmentVariablesMapping &&
                              serviceProvider.environmentVariablesMapping.port}
                          </span>
                          <span className={style.firstTitle} />
                        </div>
                      </td>
                      <td>
                        <div className={style.cellContainer}>
                          <span className={style.firstTitle}>
                            {serviceProvider.environmentVariablesMapping &&
                              serviceProvider.environmentVariablesMapping
                                .dbname}
                          </span>
                          <span className={style.secondTitle} />
                        </div>
                      </td>
                      <td>
                        <div className={style.cellContainer}>
                          <span className={style.firstTitle}>
                            {serviceProvider.environmentVariablesMapping &&
                              serviceProvider.environmentVariablesMapping.user}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className={style.cellContainer}>
                          <span className={style.firstTitle}>
                            {serviceProvider.environmentVariablesMapping &&
                              serviceProvider.environmentVariablesMapping
                                .password}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className={style.cellContainer}>
                          <span className={style.firstTitle}>
                            <Status
                              statusType={statusMap[serviceProvider.status]}
                              className={style.statusIcon}
                            />
                            <FormattedMessage id={serviceProvider.status} />
                          </span>
                          <span className={style.secondTitle}>
                            {serviceProvider.statusDescription && (
                              <WithPopper
                                title={serviceProvider.statusDescription}
                              >
                                <BorderedText
                                  className={style.statusMessage}
                                  type={BorderedTextType.Error}
                                >
                                  {serviceProvider.statusDescription}
                                </BorderedText>
                              </WithPopper>
                            )}
                          </span>
                        </div>
                      </td>
                      <td>
                        <Menu
                          serviceProvider={serviceProvider}
                          params={routeParams}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Button
              styleType={BtnType.Add}
              className={style.addButton}
              onClick={this.openAddSupportToMSModal}
            >
              <Icon type="plus2" />
            </Button>
          </>
        )}
      </div>
    );
  }
}

export default withRouter(MicroservicePageStorageList);
