import { DialogActionType, ModalProps } from '../../../stores/ModalStore';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { action, computed, observable } from 'mobx';
import { bindFormControl } from '../../Form/FormControl/FormControl';
import { ValidationResult } from 'stores/BaseForm/types';
import { FormattedMessage } from 'react-intl';
import TextField from '../../Form/Fields/TextField/TextField';
import { ProviderSearchSelect } from '../../Form/Fields/ProviderSearchSelect/ProviderSearchSelect';
import CreateExternalStorageProviderForm from './CreateExternalStorageProviderForm';
import style from './CreateExternalStorageProviderModal.module.scss';
import CommonModal from '../../Modals/Modal/CommonModal';
import { InfoButton, RulePrefix } from 'components/InfoButton/InfoButton';
import PasswordField from 'components/Form/Fields/PasswordField/PasswordField';
import { StorageProvider } from 'models/ExternalStorage';
import {
  KEY_WITH_SPEC_CHARS_REGEXP,
  NAME_REGEXP,
  PORT_REGEXP,
  testRegExp,
} from 'helpers/testRegexp';
import { generateKey } from 'helpers/generateKey';
import classNames from 'classnames';

interface Props {
  clusterName: string;
  projectName: string;
  providersList: StorageProvider[];
  instanceId?: string;
}

@observer
class CreateExternalStorageProviderModal extends Component<Props & ModalProps> {
  @observable
  createExternalStorageProviderForm = new CreateExternalStorageProviderForm();
  FormControl = bindFormControl(this.createExternalStorageProviderForm);

  @computed
  get isSubmitBtnDisabled() {
    return !this.createExternalStorageProviderForm.isFormValid;
  }

  @observable
  isKeyChangedManually: boolean = false;

  @observable
  keyFieldErrors: ValidationResult[] = [];

  @observable
  serverErrors: string[] = [];

  @observable
  serverWarnings: string[] = [];

  @observable
  isUIblocked: boolean = false;

  @observable
  validationMessages = [
    {
      msgKey: 'validation.provider.key.unique.rule',
      id: 0,
      isValid: true,
    },
    {
      msgKey: 'validation.provider.key.characters.rule',
      id: 1,
      isValid: true,
    },
    {
      msgKey: 'validation.provider.key.length.rule',
      id: 2,
      isValid: true,
    },
    {
      msgKey: 'validation.provider.key.startend.rule',
      id: 3,
      isValid: true,
    },
  ];

  onClose = () => {
    if (!this.isUIblocked) {
      this.props.onClose(DialogActionType.cancel);
    }
  };

  onConfirm = async () => {
    const { clusterName, projectName } = this.props;
    const isFormValid = this.createExternalStorageProviderForm.validate();
    if (isFormValid) {
      this.validationMessages.forEach(msg => (msg.isValid = true));
      this.createExternalStorageProviderForm.validated = false;
      try {
        this.isUIblocked = true;
        await this.createExternalStorageProviderForm.submit(
          projectName,
          clusterName,
        );
        this.serverErrors = [];
        this.serverWarnings = [];
        this.props.onClose(DialogActionType.submit);
      } catch (error) {
        if (error.response.status < 500) {
          if (
            error.response.data.errorMessages ||
            error.response.data.warningMessages
          ) {
            this.serverErrors = error.response.data.errorMessages || [];
            this.serverWarnings = error.response.data.warningMessages || [];
          } else {
            this.serverErrors = error ? [error.response.data.id] : [];
          }
        }
      } finally {
        this.isUIblocked = false;
      }
    } else {
      const keyErrors =
        this.createExternalStorageProviderForm.errorFor('instanceKey');
      keyErrors.forEach(err => {
        const rule = this.validationMessages.find(
          msg => msg.msgKey === `${err.message}.rule`,
        );
        if (rule) {
          rule.isValid = false;
        }
      });
    }
  };

  onPortChange = (value: string) => {
    const length = value.length;
    const isValidRegexp = testRegExp(value, PORT_REGEXP);

    if (!isValidRegexp && length > 0) {
      return;
    }

    if (length > 0) {
      this.createExternalStorageProviderForm.setValue('port', value);
    } else {
      this.createExternalStorageProviderForm.setValue('port', '');
    }
  };

  onEnvNameChange = (value: string) => {
    const length = value.length;
    const isValidRegexp = testRegExp(value, NAME_REGEXP);

    if (!isValidRegexp && length > 0) {
      return;
    }

    if (length > 0) {
      this.createExternalStorageProviderForm.setValue('instanceName', value);
      if (!this.isKeyChangedManually) {
        this.createExternalStorageProviderForm.setValue(
          'instanceKey',
          generateKey(value),
        );
      }
    } else {
      this.createExternalStorageProviderForm.setValue('instanceName', '');
      if (!this.isKeyChangedManually) {
        this.createExternalStorageProviderForm.setValue('instanceKey', '');
      }
    }

    this.validateEnvKey();
  };

  onEnvNameBlur = () => {
    if (!this.isKeyChangedManually) {
      this.createExternalStorageProviderForm.setValue(
        'instanceKey',
        this.createExternalStorageProviderForm.instanceKey.replace(
          /^-|-$/g,
          '',
        ),
      );
    }
  };

  onEnvKeyChange = (value: string) => {
    const length = value.length;
    const isValidRegexp = testRegExp(
      value.toLowerCase(),
      KEY_WITH_SPEC_CHARS_REGEXP,
    );

    if (!isValidRegexp && length > 0) {
      return;
    }

    if (!this.isKeyChangedManually) {
      this.isKeyChangedManually = true;
    }

    if (length > 0) {
      this.createExternalStorageProviderForm.setValue(
        'instanceKey',
        value.toLowerCase(),
      );
    } else {
      this.createExternalStorageProviderForm.setValue('instanceKey', '');
    }

    this.validateEnvKey();
  };

  @action
  validateEnvKey = () => {
    setTimeout(() => {
      // we use setTimeout 0 to delay this work and not block input rerender
      this.validationMessages.forEach(msg => (msg.isValid = true));
      this.keyFieldErrors =
        this.createExternalStorageProviderForm.errorFor('instanceKey');
      if (this.keyFieldErrors.length) {
        this.keyFieldErrors.forEach(err => {
          const rule = this.validationMessages.find(
            msg => msg.msgKey === `${err.message}.rule`,
          );
          if (rule) {
            rule.isValid = false;
          }
        });
      }
    }, 0);
  };

  render() {
    const FormControl = this.FormControl;
    const { instanceId, providersList } = this.props;

    return (
      <CommonModal
        className={style.body}
        title="external.storage.provider.create.head"
        cancel="button.cancel"
        confirm="button.add"
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        isSubmitBtnDisabled={this.isSubmitBtnDisabled}
        serverErrors={this.serverErrors}
        serverWarnings={this.serverWarnings}
        isUIblocked={this.isUIblocked}
      >
        <div className={style.sectionWrapper}>
          <div className={style.formControlWrapper}>
            <FormControl
              withoutLabel
              className={style.input}
              name="providerName"
              render={props => (
                <ProviderSearchSelect
                  {...props}
                  options={providersList}
                  value={instanceId}
                />
              )}
            />
          </div>
        </div>
        <div className={style.sectionWrapper}>
          <div className={style.text}>
            <FormattedMessage id="external.storage.provider.create.required.info" />
          </div>
          <div className={style.formControlWrapper}>
            <FormControl
              className={style.input}
              name="instanceName"
              render={props => (
                <TextField
                  {...props}
                  onBlur={this.onEnvNameBlur}
                  onChange={this.onEnvNameChange}
                />
              )}
            />
          </div>
          <div className={style.formControlWrapper}>
            <FormControl
              className={style.input}
              name="instanceKey"
              errors={this.keyFieldErrors}
              immediateValidation={true}
              render={props => (
                <TextField {...props} onChange={this.onEnvKeyChange} />
              )}
            />{' '}
            <InfoButton
              className={style.infoBtnWrapper}
              rules={this.validationMessages}
              rulePrefix={RulePrefix.env}
            />
          </div>
          <div className={style.inputSection}>
            <div
              className={classNames(
                style.formControlWrapper,
                style.formControlWrapper__half,
              )}
            >
              <FormControl
                className={style.input}
                name="host"
                render={props => <TextField {...props} />}
              />
            </div>
            <div
              className={classNames(
                style.formControlWrapper,
                style.formControlWrapper__half,
              )}
            >
              <FormControl
                className={style.input}
                name="port"
                render={props => (
                  <TextField {...props} onChange={this.onPortChange} />
                )}
              />
            </div>
          </div>
        </div>
        <div className={style.sectionWrapper}>
          <div className={style.text}>
            <FormattedMessage id="external.storage.provider.create.personal.info" />
          </div>
          <div className={style.inputSection}>
            <div
              className={classNames(
                style.formControlWrapper,
                style.formControlWrapper__half,
              )}
            >
              <FormControl
                className={style.input}
                name="userName"
                render={props => <TextField {...props} />}
              />
            </div>
            <div
              className={classNames(
                style.formControlWrapper,
                style.formControlWrapper__half,
              )}
            >
              <FormControl
                className={style.input}
                name="password"
                render={props => <PasswordField {...props} />}
              />
            </div>
          </div>
        </div>
      </CommonModal>
    );
  }
}

export default CreateExternalStorageProviderModal;
