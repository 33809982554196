import React from 'react';
import style from './ProviderInstanceTab.module.scss';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

interface Props {
  label: string;
}

const ProviderInstanceTab: React.FC<Props> = ({ label, children }) => {
  const [isOpen, setOpen] = React.useState(false);

  const toggleOpen = () => {
    setOpen(!isOpen);
  };

  return (
    <div className={style.container}>
      <div className={style.providerInfoSection} onClick={toggleOpen}>
        <span className={style.providerInstanceInfoTitle}>
          <FormattedMessage id={label} />
        </span>
        <span className={style.openIndicator}>{isOpen ? '-' : '+'}</span>
      </div>
      <div
        className={classNames(style.providerInfoContent, {
          [style.providerInfoIsOpen]: isOpen,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default ProviderInstanceTab;
