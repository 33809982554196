import { action, computed, observable } from 'mobx';
import { ENV_VAR_START_REGEXP, TEMPLATE_NAME_REGEXP } from 'helpers/testRegexp';
import { ITemplate } from 'models/Template';
import {
  BaseFormModel,
  displayName,
  hasMaxLength,
  isMatchRegExp,
  isRequired,
  isValueValid,
} from 'stores/BaseForm';

class TemplateForm extends BaseFormModel {
  @observable isNameUniq = true;

  @observable
  @displayName('name')
  @isRequired()
  @hasMaxLength(253, 'validation.template.name.max.length')
  @isMatchRegExp(TEMPLATE_NAME_REGEXP, 'validation.template.name')
  @isMatchRegExp(ENV_VAR_START_REGEXP, 'validation.template.name.start')
  @isValueValid('isNameUniq', 'validation.template.name.uniq')
  name = '';

  @observable
  @displayName('description')
  description = '';

  @observable
  @isRequired()
  content = '';

  readonly templateId: string | undefined;

  constructor(templateId?: string) {
    super();

    this.templateId = templateId;
  }

  @computed
  get isEditing() {
    return !!this.templateId;
  }

  @computed
  get data(): ITemplate {
    return {
      name: this.name,
      description: this.description,
      content: this.content,
    };
  }

  @action.bound
  setUniqNameError() {
    this.isNameUniq = false;
    this.setErrorsMap('name');
  }

  @action.bound
  resetUniqNameError() {
    this.isNameUniq = true;
    this.setErrorsMap('name');
  }
}

export default TemplateForm;
