import React from 'react';
import { observer } from 'mobx-react';

import TextField from 'components/Form/Fields/TextField/TextField';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import { HostForm } from '../../../../stores/ApplyMsTo/HostForm';

import style from 'components/EnvironmentPageServicesList/Compare/CompareMsItem/CompareMsItem.module.scss';

interface IProps {
  hostForm: HostForm;
}

export const HostItem = observer(({ hostForm }: IProps) => {
  const FormControl = bindFormControl(hostForm);
  return (
    <FormControl
      withoutLabel
      name="host"
      className={style.hostItem}
      render={props => <TextField {...props} />}
      immediateValidation
    />
  );
});
