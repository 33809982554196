import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { stringToDateTime } from 'helpers/datetime';
import { copyToClipboard } from 'helpers/copyToClipboard';
import { IServiceParams, ServiceDetails } from 'models/Service';
import { AlertType } from 'models/Alert';
import ServiceStore from 'stores/ServiceStore';
import ModalStore from 'stores/ModalStore';

import DeleteMicroserviceModal from './DeleteMicroserviceModal/DeleteMicroserviceModal';
import ScaleMSModal from './ScaleMSModal/ScaleMSModal';
import UpdateServiceModal from '../MicroservicePageArtifact/UpdateServiceModal/UpdateServiceModal';
import InfoLine from 'components/EnvironmentPageHeader/InfoLine/InfoLine';
import Status, { StatusType } from 'components/Status/Status';
import WithPopper from 'components/WithPopper/WithPopper';
import Icon from 'components/Icon/Icon';
import Alert from 'components/Alert/Alert';
import ActionMenu from 'components/ActionMenu/ActionMenu';
import { IActionItem } from 'components/ActionMenu/types';
import ExternalLinksMenu from 'components/ExternalLinksMenu/ExternalLinksMenu';

import style from './MicroservicePageHeader.module.scss';

const syncStateMap = {
  synced: StatusType.Success,
  not_in_git: StatusType.Error,
  not_in_k8s: StatusType.Error,
  diff: StatusType.Error,
  no_hash: StatusType.Error,
  unknown: StatusType.Warning,
};

interface Props {
  params: IServiceParams;
  service: ServiceDetails;
  alertsStore?;
  serviceStore: ServiceStore;
}

@inject('alertsStore')
@observer
class MicroservicePageHeader extends React.Component<Props> {
  @observable copyTitle = 'Click to Copy';

  openDeleteModal = () => {
    ModalStore.showModal(DeleteMicroserviceModal, {
      serviceParams: this.props.params,
      serviceLongName: this.props.service.info.longName,
      deleteMicroservice: this.props.serviceStore.deleteMicroservice,
    });
  };

  openUpdateMicroserviceModal = async () => {
    const artefactNameMatchArray = this.props.service.info.image.match(/\/.+:/);
    ModalStore.showModal(UpdateServiceModal, {
      updateMicroservice: this.props.serviceStore.updateMicroservice,
      service: this.props.service.info.name,
      installedImage: this.props.service.info.image,
      installedArtefactName: artefactNameMatchArray
        ? artefactNameMatchArray[0].slice(1, -1)
        : undefined,
      installedBranchName: this.props.service.artifact.buildInfo
        ? this.props.service.artifact.buildInfo.git.branch
        : undefined,
    });
  };

  openScaleMSModal = async () => {
    ModalStore.showModal(ScaleMSModal, {
      params: this.props.params,
      msLongName: this.props.service.info.longName,
      value: Number(this.props.service.info.runtimeState.desired),
    });
  };

  copyImageToClipboard = () => {
    copyToClipboard(this.props.service.info.image);
    this.copyTitle = 'Copied';
    setTimeout(() => {
      this.copyTitle = 'Click to Copy';
    }, 2000);
  };

  toggleAlertDrawer = () => {
    const { alertsStore } = this.props;
    alertsStore.showAlertDrawer([], 'MicroservicePageHeader');
  };

  render() {
    const { service, alertsStore } = this.props;
    const { pageAlertsCount } = alertsStore;
    const { name, longName, syncState, image, updateDate } = service.info;
    const { links } = service.info;

    const linksData = [
      {
        path: links.logging,
        name: 'link.logging',
        icon: 'kibana',
      },
      {
        path: links.monitoring,
        name: 'link.monitoring',
        icon: 'grafana',
      },
    ];

    if (links.storageManagerLogging) {
      linksData.push({
        path: links.storageManagerLogging,
        name: 'link.storageSupportLogging',
        icon: 'kibana',
      });
    }

    const actionMenuData: IActionItem[] = [
      {
        action: this.props.serviceStore.restartMicroservice,
        label: <FormattedMessage id="service.restart" />,
        icon: 'restart',
        disabled: !Number(this.props.service.info.runtimeState.desired),
        popper: !Number(this.props.service.info.runtimeState.desired)
          ? 'service.restart.disabled.message'
          : '',
      },
      {
        action: this.openScaleMSModal,
        label: <FormattedMessage id="service.scale" />,
        icon: 'scale',
      },
      {
        action: this.openUpdateMicroserviceModal,
        label: <FormattedMessage id="service.install.specific.artefact" />,
        icon: 'install',
      },
      {
        action: this.openDeleteModal,
        label: <FormattedMessage id="environment.btn.delete" />,
        icon: 'deleteEmpty',
      },
    ];

    return (
      <div className={style.mainContainer}>
        <div className={style.containerLine1}>
          <div className={style.wrap}>
            <div className={style.title}>
              <div className={style.titleName}>
                <span>{longName}</span>
                {!!pageAlertsCount && (
                  <Alert
                    onClick={this.toggleAlertDrawer}
                    type={AlertType.Error}
                    className={style.alert}
                  >
                    {pageAlertsCount}
                  </Alert>
                )}
              </div>
              <div className={style.titleId}>
                <span>ID:</span>
                {name}
              </div>
            </div>

            <div className={style.imageContainer}>
              <WithPopper title={this.copyTitle} className={style.popperCopy}>
                <Icon
                  className={style.copyIcon}
                  type={'copy2'}
                  onClick={this.copyImageToClipboard}
                />
              </WithPopper>
              <div className={style.fullImage}>{image}</div>
            </div>
          </div>
          <ActionMenu items={actionMenuData} />
        </div>

        <div className={style.containerLine2}>
          <Status
            statusType={syncStateMap[syncState]}
            className={style.status}
          />
          <span className={style.statusText}>
            <FormattedMessage id={syncState} />
          </span>
          <div className={style.delimiter} />
          {updateDate && (
            <InfoLine
              className={style.infoLine}
              label="updated.time"
              value={updateDate && stringToDateTime(updateDate.toString())}
            />
          )}
          <div className={style.delimiter} />
          <ExternalLinksMenu links={linksData} />
        </div>
      </div>
    );
  }
}

export default MicroservicePageHeader;
