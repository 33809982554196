import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Spinner } from 'components/Spinner/Spinner';
import Modals from 'components/Modals/Modals';

import { ROUTES } from 'routes/routes';

import RootStore from 'stores/RootStore';

import Login from 'pages/Login/Login';
import BaseLayout from './components/Layout/BaseLayout';

@observer
class App extends React.Component {
  async componentDidMount() {
    await RootStore.init();
    document.body.onresize = () => {
      RootStore.onResize();
    };
  }

  render() {
    const { isLoading } = RootStore;

    return isLoading ? (
      <Spinner fullscreen message="loader.in.progress" />
    ) : (
      <Suspense fallback={<Spinner fullscreen message="loader.in.progress" />}>
        <Switch>
          <Route
            path={[
              ROUTES.project,
              ROUTES.environment,
              ROUTES.cluster,
              ROUTES.templates,
              ROUTES.microservice,
              ROUTES.podsLogs,
            ]}
            component={BaseLayout}
          />
          <Route path={ROUTES.login} exact component={Login} />
          <Redirect to={ROUTES.login} />
        </Switch>

        <Modals />
        <div id="popovers" />
      </Suspense>
    );
  }
}

export default App;
