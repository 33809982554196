import React, { useRef, useState } from 'react';
import classNames from 'classnames';

import { useClickOutside } from 'hooks/useClickOutside';

import { IActionItem } from 'components/ActionMenu/types';
import { Popover } from 'components/FloatingComponents/Popover/Popover';
import { BoundaryElementType } from 'components/FloatingComponents/types';
import Icon from 'components/Icon/Icon';
import { ActionMenuItem } from 'components/ActionMenu/ActionMenuItem/ActionMenuItem';

import style from './ActionMenuWithPopover.module.scss';

export interface IProps {
  items: IActionItem[];
  openMenuComponent?: (
    toggleList: () => void,
    isOpened: boolean,
  ) => JSX.Element;
  boundaryElement?: BoundaryElementType;
  styles?: {
    wrapper?: string;
    menu?: string;
    menuItem?: string;
  };
}

const ActionMenuWithPopover = ({
  items,
  openMenuComponent,
  styles = {},
  boundaryElement,
}: IProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const wrapperRef = React.createRef<HTMLDivElement>();
  const referenceElement = useRef<HTMLDivElement>(null);

  useClickOutside(wrapperRef, () => {
    setTimeout(() => setIsOpened(false), 0);
  });

  const toggleList = () => {
    setIsOpened(!isOpened);
  };

  const onAction = handler => () => {
    setIsOpened(false);
    handler();
  };

  return (
    <div
      ref={wrapperRef}
      onClick={e => e.stopPropagation()}
      className={classNames(style.mainContainer, styles.wrapper)}
    >
      <div className={style.triggerElementWrapper} ref={referenceElement}>
        {openMenuComponent ? (
          openMenuComponent(toggleList, isOpened)
        ) : (
          <Icon
            className={classNames(style.openMenuIcon, isOpened && style.opened)}
            type="menuDots"
            onClick={toggleList}
          />
        )}
      </div>
      {isOpened && (
        <Popover
          referenceElement={referenceElement}
          onClose={toggleList}
          boundaryElement={boundaryElement}
        >
          <ul className={classNames(style.menu, styles.menu)}>
            {items.map((item, index) => (
              <ActionMenuItem
                key={index}
                action={item}
                onAction={onAction(item.action)}
                className={styles.menuItem}
              />
            ))}
          </ul>
        </Popover>
      )}
    </div>
  );
};

export default ActionMenuWithPopover;
