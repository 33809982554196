import React from 'react';
import classNames from 'classnames';

import style from './InstallCheckbox.module.scss';
import Icon from 'components/Icon/Icon';
import { FormattedMessage } from 'react-intl';

interface Props {
  className?: string;
  selected: boolean;
  label: string;
}

class InstallCheckbox extends React.Component<Props> {
  render() {
    const { selected, className, label } = this.props;

    return (
      <div
        className={classNames(style.container, className, {
          [style.selected]: selected,
        })}
      >
        <div className={classNames(style.circle)}>
          {selected && <Icon className={style.check} type="check" />}
        </div>
        <FormattedMessage id={label} />
      </div>
    );
  }
}

export default InstallCheckbox;
