import { action, observable } from 'mobx';
import { BaseFormModel, displayName, isSameAs } from 'stores/BaseForm';
import httpFacade from 'http/httpFacade';
import NotificationService, {
  fullscreenNotificationOptions,
} from 'components/Notification/NotificationService';
import { StorageProviderModes } from 'models/ExternalStorage';

class CleanDBForm extends BaseFormModel {
  @observable
  providerInstanceNameExample = '';

  @observable
  @displayName('instance.provider.modal.provider.name')
  @isSameAs('providerInstanceNameExample', 'wrong.provider.instance.msg')
  providerInstanceName: string = '';

  @action
  setProviderInstanceNameExample = name => {
    this.providerInstanceNameExample = name;
  };

  @action.bound
  async cleanDB(params) {
    const isFormValid = this.validate();
    if (isFormValid) {
      try {
        await httpFacade.service.updateMSSupport(params, {
          mode: StorageProviderModes.EMPTY,
        });
        NotificationService.successMessage(
          { id: 'clena.db.success' },
          fullscreenNotificationOptions,
        );
      } catch (error) {
        if (
          error.response.data.errorMessages ||
          error.response.data.warningMessages
        ) {
          throw error;
        }
      }
    }
  }
}

export default CleanDBForm;
