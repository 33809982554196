import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Editor from '@monaco-editor/react';

import { ITemplateVariable } from 'models/Template';

interface Props {
  variables: ITemplateVariable[];
  value?: string;
  onValueChange: (value: string) => void;
  loading?: boolean;
  readOnly?: boolean;
}

@observer
class TemplateEditor extends Component<Props> {
  editor;
  completionItemProvider;

  handleEditorChange = value => {
    this.props.onValueChange(value);
  };

  handleEditorDidMount = editor => {
    this.editor = editor;
  };

  handleEditorWillMount = monaco => {
    const { variables } = this.props;

    this.completionItemProvider =
      monaco.languages.registerCompletionItemProvider('yaml', {
        triggerCharacters: ['.'],
        provideCompletionItems(model, position) {
          const range = {
            startLineNumber: position.lineNumber,
            startColumn: position.column,
            endLineNumber: position.lineNumber,
            endColumn: position.column,
          };

          return {
            suggestions: variables.map(variable => ({
              label: variable.name,
              kind: monaco.languages.CompletionItemKind.Variable,
              insertText: variable.name,
              range,
            })),
          };
        },
      });
  };

  componentWillUnmount() {
    if (this.completionItemProvider) {
      this.completionItemProvider.dispose();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.readOnly !== this.props.readOnly) {
      this.editor.updateOptions({ readOnly: this.props.readOnly });
    }
  }

  render() {
    const { value, loading } = this.props;

    return (
      !loading && (
        <Editor
          height="100%"
          defaultLanguage="yaml"
          defaultValue={value}
          value={value}
          onChange={this.handleEditorChange}
          beforeMount={this.handleEditorWillMount}
          onMount={this.handleEditorDidMount}
          options={{
            readOnly: this.props.readOnly,
            suggestSelection: 'first',
          }}
        />
      )
    );
  }
}

export default TemplateEditor;
