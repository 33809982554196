import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import RootStore from 'stores/RootStore';
import { ClusterDetails } from 'models/Cluster';
import ExrternalStorageStore from 'stores/ExternalStorageStore';
import ProviderInstances from './ProviderInstances/ProviderInstances';

import style from './ClusterExternalStoragePage.module.scss';
import ProvidersList from './ProvidersList/ProvidersList';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/Icon/Icon';

interface Props {
  cluster: ClusterDetails;
}

@observer
class ClusterExternalStoragePage extends React.Component<Props> {
  @observable exrternalStorageStore: ExrternalStorageStore;

  constructor(props: Props) {
    super(props);

    const { cluster } = this.props;
    this.exrternalStorageStore = new ExrternalStorageStore(
      cluster.info.name,
      RootStore.currentProject,
    );
  }

  componentWillUnmount() {
    this.exrternalStorageStore.clearTimer();
  }

  render() {
    return (
      <div className={style.mainContainer}>
        {this.props.cluster.externalStorageEnabled ? (
          <>
            {this.exrternalStorageStore.providers && (
              <ProvidersList
                providers={this.exrternalStorageStore.groupedProviders}
                cluster={this.props.cluster}
              />
            )}
            {this.exrternalStorageStore.providerInstances && (
              <ProviderInstances
                providerInstances={this.exrternalStorageStore.providerInstances}
                providers={this.exrternalStorageStore.providers || []}
                cluster={this.props.cluster}
              />
            )}
          </>
        ) : (
          <div className={style.disabledExternalStorageContainer}>
            <Icon type="storageDisabled" />
            <span className={style.storageDisabledText1}>
              <FormattedMessage id="externalStorageDisabledText1" />
            </span>
            <span className={style.storageDisabledText2}>
              <FormattedMessage id="externalStorageDisabledText2" />
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default ClusterExternalStoragePage;
