import React from 'react';
import classNames from 'classnames';
import { RuntimeState, PodRuntimeStatus } from 'models/StatusTypes';
import style from './RuntimeStatus.module.scss';

interface PodsStyles {
  pods?: string;
  desiredNumber?: string;
  existingNumber?: string;
}

interface Props {
  runtimeState: RuntimeState;
  podsStyles?: PodsStyles;
}

class RuntimeStatus extends React.Component<Props> {
  render() {
    const { runtimeState, podsStyles = {} } = this.props;
    const runtimePodsStyle = {
      [style.existingPodNumber__warning]:
        runtimeState.runtimeStatus === PodRuntimeStatus.Warning,
      [style.existingPodNumber__error]:
        runtimeState.runtimeStatus === PodRuntimeStatus.Error,
      [style.existingPodNumber__info]:
        runtimeState.runtimeStatus === PodRuntimeStatus.Initialization,
    };

    return (
      <div className={classNames(style.pods, podsStyles.pods)}>
        <span
          className={classNames(
            style.existingPodNumber,
            runtimePodsStyle,
            podsStyles.existingNumber,
          )}
        >
          {runtimeState.existing}
        </span>
        <span
          className={classNames(
            style.desiredPodNumber,
            podsStyles.desiredNumber,
          )}
        >
          /{runtimeState.desired}
        </span>
      </div>
    );
  }
}

export { RuntimeStatus };
