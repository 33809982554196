export class Artifact {
  fullImage: string;
  buildInfo?: {
    date: Date;
    git: {
      branch: string;
      commit: string;
    };
  };
  artifactIssues: IssueItem[];
  collectedIssues: IssueItem[];
  links?: {
    build: string;
    git: string;
    registry: string;
  };
}

export class ArtifactUpdate {
  incoming: IssueItem[];
  obsolete: IssueItem[];
  latest: Artifact;
}

export class IssueItem {
  id: string;
  status: IssueItemStatus;
  description?: string;
  link?: string;
  obsolete?: boolean;
}

export enum IssueItemStatus {
  InProgress = 'InProgress',
  ReadyForQA = 'ReadyForQA',
  Resolved = 'Resolved',
  NotWorkedYet = 'NotWorkedYet',
  Unknown = 'Unknown',
}

export enum IssueItemStatusTranslationKeys {
  InProgress = 'inProgress',
  ReadyForQA = 'readyForQA',
  Resolved = 'resolved',
  NotWorkedYet = 'notWorkedYet',
  Unknown = 'unknown',
}

export class ArtifactSearchParams {
  artifactName: string | undefined;
  branch: string | undefined;
  since: string | undefined;
  topics: string | undefined;
}
