import {
  IPodContainer,
  ILog,
  IPodContainerParams,
  IPodParams,
  IServicePod,
} from 'models/Pod';
import { sortContainers } from 'stores/PodsPage/helpers';
import http from '../http';
import { appUrl } from 'http/helpers';
import URLS from 'http/urls';
import { IServiceParams } from 'models/Service';

interface IPodLogsReqParams {
  tail: number;
}

interface IContainerLogsReqParams {
  tail: number;
  old: boolean;
}

export const podsApi = {
  fetchPods(serviceParams: IServiceParams) {
    return http.get<IServicePod[]>(appUrl(URLS.pods), {
      replaceParams: serviceParams,
    });
  },

  fetchPodLogsAndEvents(
    podContainerParams: IPodParams,
    params: IPodLogsReqParams,
  ) {
    return http.get<ILog[]>(appUrl(URLS.podLogsAndEvents), {
      replaceParams: podContainerParams,
      params,
    });
  },

  fetchPodContainers(podParams: IPodParams) {
    return http
      .get<IPodContainer[]>(appUrl(URLS.podContainers), {
        replaceParams: podParams,
      })
      .then(response => ({
        ...response,
        data: sortContainers(response.data),
      }));
  },

  fetchPodContainerLogsAndEvents(
    podContainerParams: IPodContainerParams,
    params: IContainerLogsReqParams,
  ) {
    return http.get<ILog[]>(appUrl(URLS.podContainerLogsAndEvents), {
      replaceParams: podContainerParams,
      params,
    });
  },
};
