import { DialogActionType, ModalProps } from '../../stores/ModalStore';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import style from './EnvVariablesModal.module.scss';
import CommonModal from '../Modals/Modal/CommonModal';
import { CopyTextBox } from '../Form/Fields/CopyTextBox/CopyTextBox';
import EnvVariablesModalForm from './EnvVariablesModalForm';

interface Props {
  host: string;
  port: string;
  dbname: string;
  user: string;
  password: string;
}

@observer
class EnvVariablesModal extends Component<Props & ModalProps> {
  form = new EnvVariablesModalForm();

  onClose = async () => {
    this.props.onClose(DialogActionType.cancel);
  };

  componentDidMount() {
    const {
      host = '',
      port = '',
      dbname = '',
      user = '',
      password = '',
    } = this.props;
    this.form.setValue('host', host);
    this.form.setValue('port', port);
    this.form.setValue('name', dbname);
    this.form.setValue('userName', user);
    this.form.setValue('password', password);
  }

  render() {
    return (
      <CommonModal
        className={style.body}
        title="env.variables.title"
        confirm="button.ok"
        onClose={this.onClose}
        onConfirm={this.onClose}
        isCancellable={false}
      >
        <div className={style.textBoxWrapper}>
          <CopyTextBox label="host" form={this.form} />
        </div>
        <div className={style.textBoxWrapper}>
          <CopyTextBox label="port" form={this.form} />
        </div>
        <div className={style.textBoxWrapper}>
          <CopyTextBox label="name" form={this.form} />
        </div>
        <div className={style.textBoxWrapper}>
          <CopyTextBox label="userName" form={this.form} />
        </div>
        <div className={style.textBoxWrapper}>
          <CopyTextBox label="password" form={this.form} isPassword={true} />
        </div>
      </CommonModal>
    );
  }
}

export default EnvVariablesModal;
