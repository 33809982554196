import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DialogActionType, ModalProps } from 'stores/ModalStore';
import { observable } from 'mobx';
import CommonModal from '../../Modals/Modal/CommonModal';

import style from './UpdateServiceModal.module.scss';
import { ServiceUpdate } from 'models/Service';
import SearchImage from 'components/SearchImage/SearchImage';
import ImageForm from 'stores/Forms/ImageForm';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import TextField from 'components/Form/Fields/TextField/TextField';
import NotificationService, {
  fullscreenNotificationOptions,
} from 'components/Notification/NotificationService';

interface Props extends ModalProps {
  updateMicroservice: (data: ServiceUpdate) => Promise<any>;
  service: string;
  installedImage?: string;
  installedArtefactName?: string;
  installedBranchName?: string;
}

@observer
class UpdateServiceModal extends Component<Props> {
  @observable
  serverErrors: string[] = [];

  @observable
  serverWarnings: string[] = [];

  @observable
  isUIblocked: boolean = false;

  @observable updateServiceForm = new ImageForm();
  FormControl = bindFormControl(this.updateServiceForm);

  onClose = () => {
    this.props.onClose(DialogActionType.cancel);
  };

  onConfirm = async () => {
    try {
      this.isUIblocked = true;
      await this.props.updateMicroservice({
        image: this.updateServiceForm.image,
      });
      NotificationService.successMessage(
        { id: 'artefact.updated' },
        fullscreenNotificationOptions,
      );
      this.props.onClose(DialogActionType.cancel);
      this.isUIblocked = false;
    } catch (error) {
      this.serverErrors = error.response.data.errorMessages || [];
      this.serverWarnings = error.response.data.warningMessages || [];
      this.isUIblocked = false;
    }
  };

  setImage = image => {
    this.updateServiceForm.setValue('image', image);
  };

  afterSearchHook = error => {
    this.serverErrors = error ? [error.response.data.message] : [];
  };

  render() {
    const FormControl = this.FormControl;

    return (
      <CommonModal
        className={style.body}
        bodyContentClassName={style.bodyContent}
        footerClassName={style.footer}
        title="update.service.modal.title"
        cancel="button.cancel"
        confirm="update.service.modal.confirm"
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        isSubmitBtnDisabled={!this.updateServiceForm.image}
        serverErrors={this.serverErrors}
        serverWarnings={this.serverWarnings}
        isUIblocked={this.isUIblocked}
      >
        <div className={style.imageInputContainer}>
          <FormControl
            className={style.imageInput}
            name="image"
            render={props => <TextField {...props} />}
          />
        </div>
        <SearchImage
          withWidth
          setImage={this.setImage}
          selectedImage={this.updateServiceForm.image}
          installedImage={this.props.installedImage}
          installedArtefactName={this.props.installedArtefactName}
          installedBranchName={this.props.installedBranchName}
          afterSearchHook={this.afterSearchHook}
        />
      </CommonModal>
    );
  }
}

export default UpdateServiceModal;
