import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

import { DialogActionType, ModalProps } from 'stores/ModalStore';
import ImageForm from 'stores/Forms/ImageForm';

import SearchImage from 'components/SearchImage/SearchImage';
import CommonModal from 'components/Modals/Modal/CommonModal';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import TextField from 'components/Form/Fields/TextField/TextField';

import style from 'components/SelectImageModal/SelectImageModal.module.scss';

interface Props extends ModalProps {
  setImage: (image: string) => void;
  selectedImage: string;
}

@observer
class SelectImageModal extends Component<Props> {
  @observable serverErrors: string[] = [];
  @observable isUIBlocked: boolean = false;

  @observable imageForm = new ImageForm();
  FormControl = bindFormControl(this.imageForm);

  setImage = image => {
    this.imageForm.setValue('image', image);
  };

  onClose = () => {
    this.props.onClose(DialogActionType.cancel);
  };

  onConfirm = () => {
    this.props.setImage(this.imageForm.image);
    this.props.onClose(DialogActionType.submit);
  };

  componentDidMount() {
    this.imageForm.image = this.props.selectedImage;
  }

  @action
  afterSearchHook = error => {
    this.serverErrors = error ? [error.response.data.message] : [];
  };

  render() {
    const FormControl = this.FormControl;

    return (
      <CommonModal
        className={style.body}
        bodyContentClassName={style.bodyContent}
        footerClassName={style.footer}
        title="modal.image.search.title"
        cancel="button.back"
        confirm="button.save"
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        isSubmitBtnDisabled={!this.imageForm.image}
        serverErrors={this.serverErrors}
        isUIblocked={this.isUIBlocked}
      >
        <div className={style.imageInputContainer}>
          <FormControl
            className={style.imageInput}
            name="image"
            render={props => <TextField {...props} />}
          />
        </div>
        <SearchImage
          setImage={this.setImage}
          selectedImage={this.imageForm.image}
          afterSearchHook={this.afterSearchHook}
          withWidth
        />
      </CommonModal>
    );
  }
}

export default SelectImageModal;
