import React, { useState } from 'react';
import classNames from 'classnames';
import { NavLink, useHistory } from 'react-router-dom';

import { replaceUrlParams } from 'http/helpers';
import RootStore from 'stores/RootStore';
import { ROUTES } from 'routes/routes';
import Icon from 'components/Icon/Icon';

import style from './ProjectSelect.module.scss';
import { useClickOutside } from 'hooks/useClickOutside';

interface ProjectOption {
  value: string;
  hasAuth: boolean;
}

interface Props {
  currentProject: string;
  projects: ProjectOption[];
}

const ProjectSelect = ({ currentProject, projects }: Props) => {
  const [isOpened, setIsOpened] = useState(false);
  const history = useHistory();
  const wrapperRef = React.createRef<HTMLDivElement>();
  useClickOutside(wrapperRef, () => setIsOpened(false));

  const toggleList = () => {
    setIsOpened(!isOpened);
  };

  const selectProject = (project: ProjectOption) => () => {
    RootStore.currentProject = project.value;
    history.push(
      replaceUrlParams(ROUTES.project, { id: RootStore.currentProject }),
    );
  };

  return (
    <div className={style.content} onClick={toggleList} ref={wrapperRef}>
      <div className={classNames(style.item)}>
        {currentProject}
        <Icon
          className={style.icon}
          type={isOpened ? 'arrowTop' : 'arrowBottom'}
        />
      </div>
      {isOpened && (
        <div className={classNames(style.menu)}>
          <ul>
            {projects.map((project: ProjectOption) => (
              <li key={project.value} onClick={selectProject(project)}>
                <NavLink
                  to={`/project/${project.value}/info`}
                  isActive={(match, location) =>
                    location.pathname.startsWith(`/project/${project.value}/`)
                  }
                  activeClassName={style.active}
                >
                  {project.value}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ProjectSelect;
