import React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { ServiceOther } from 'models/Service';
import { EnvironmentOther } from 'models/Environment';
import { timeSince } from 'helpers/datetime';

import Status, { StatusType } from 'components/Status/Status';

import style from './OthersItem.module.scss';

interface Props {
  other: ServiceOther | EnvironmentOther;
}

const syncStateMap = {
  synced: StatusType.Success,
  not_in_git: StatusType.Error,
  not_in_k8s: StatusType.Error,
  diff: StatusType.Error,
  no_hash: StatusType.Error,
  unknown: StatusType.Warning,
};

@observer
class OthersItem extends React.Component<Props> {
  render() {
    const { other } = this.props;

    const otherServiceUpdateTime = timeSince(other.updateDate || null);

    return (
      <tr>
        <td>
          <div className={style.cellContainer}>
            <span className={style.firstTitle}>{other.name}</span>
            <span className={style.secondTitle}>
              {other.updateDate && (
                <FormattedMessage
                  id="updated.ago"
                  values={{
                    interval: otherServiceUpdateTime.interval,
                    type: <FormattedMessage id={otherServiceUpdateTime.type} />,
                  }}
                />
              )}
            </span>
          </div>
        </td>
        <td>
          <div className={style.cellContainer}>
            <span className={style.firstTitle}>{other.kind}</span>
          </div>
        </td>
        <td>
          <div className={style.cellContainer}>
            <span className={style.firstTitle}>
              <Status
                statusType={syncStateMap[other.syncState]}
                className={style.icon}
              />
              <span className={style.statusText}>
                <FormattedMessage id={other.syncState} />
              </span>
            </span>
          </div>
        </td>
      </tr>
    );
  }
}

export { OthersItem };
