import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import style from './Spinner.module.scss';

interface Props {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  fullscreen?: boolean;
  contain?: boolean;
  message: string;
}

export const Spinner = (props: Props) => (
  <div
    className={classNames(style.container, props.className, {
      [style.fullscreen]: props.fullscreen,
      [style.contain]: props.contain,
    })}
    style={props.style}
  >
    <div className={style.spinner}>
      <div className={classNames(style.block, style.mover)} />
      <div className={classNames(style.block, style.jumper)} />
      <div className={classNames(style.block, style.jumper)} />
      <div className={classNames(style.block, style.jumper)} />
      <div className={classNames(style.block, style.jumper)} />
      <div className={style.text}>
        <FormattedMessage id={props.message} />
      </div>
    </div>

    {props.children}
  </div>
);
