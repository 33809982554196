import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { DialogActionType, ModalProps } from 'stores/ModalStore';
import { ServiceUpdate } from 'models/Service';

import CommonModal from 'components/Modals/Modal/CommonModal';
import NotificationService, {
  fullscreenNotificationOptions,
} from 'components/Notification/NotificationService';

import style from './InstallArtifactModal.module.scss';

interface Props extends ModalProps {
  image: string;
  updateMicroservice: (data: ServiceUpdate) => Promise<any>;
}

@observer
class InstallArtifactModal extends Component<Props> {
  @observable
  isUIblocked: boolean = false;

  onClose = () => {
    if (!this.isUIblocked) {
      this.props.onClose(DialogActionType.cancel);
    }
  };

  onConfirm = async () => {
    const { image, updateMicroservice } = this.props;

    try {
      this.isUIblocked = true;
      await updateMicroservice({
        image,
      });
      this.isUIblocked = false;
      this.props.onClose(DialogActionType.submit);
      NotificationService.successMessage(
        { id: 'update.service.updated' },
        fullscreenNotificationOptions,
      );
    } catch (error) {
      NotificationService.errorMessage({ id: 'update.service.error' });
      this.isUIblocked = false;
    }
  };

  render() {
    const { image } = this.props;

    return (
      <CommonModal
        className={style.body}
        title="cluster.modal.shutdown.title"
        headerIcon="modalWarning"
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        isUIblocked={this.isUIblocked}
      >
        <div className={style.info}>
          <FormattedMessage id="ms.update.confirmation.text" /> -
          <span className={style.textBold}>{image.split('/').pop()}</span>
        </div>
      </CommonModal>
    );
  }
}

export default InstallArtifactModal;
