import { action, computed, observable } from 'mobx';
import {
  ComparingState,
  IApplyToData,
  IComparingEnvironment,
  IEnvironmentParams,
} from 'models/Environment';
import { ComparingMsItemStore } from 'stores/ApplyMsTo/ComparingMsItemStore';
import httpFacade from '../../http/httpFacade';
import NotificationService, {
  fullscreenNotificationOptions,
} from '../../components/Notification/NotificationService';
import { replaceUrlParams } from '../../http/helpers';
import { ROUTES } from '../../routes/routes';
import RootStore from '../RootStore';

class ComparingModalStore {
  @observable
  isUIDisabled = false;
  @observable
  isMerging = false;
  @observable
  comparingMsStores: ComparingMsItemStore[] = [];
  @observable
  history;
  private initialComparingEnvironment: IComparingEnvironment;
  private envParams: IEnvironmentParams;

  constructor(
    comparingEnvironment: IComparingEnvironment,
    envParams: IEnvironmentParams,
    history,
  ) {
    this.initialComparingEnvironment = comparingEnvironment;
    this.envParams = envParams;
    this.history = history;
    this.initComparingMsStores();
  }

  @computed
  get isApplyDisabled(): boolean {
    return this.comparingMsStores.some(store => !store.isChecked);
  }

  @action
  initComparingMsStores = () => {
    this.comparingMsStores = this.initialComparingEnvironment.microservices
      .slice()
      .sort((a, b) => {
        if (a.longName && b.longName) {
          return a.longName.toLowerCase() < b.longName.toLowerCase() ? -1 : 1;
        } else {
          return a.longName ? 1 : -1;
        }
      })
      .map(microservice => new ComparingMsItemStore(microservice));
  };

  getApplyToData = (): IApplyToData => {
    const { clusterTarget, envTarget, envTargetCommitId, envSourceCommitId } =
      this.initialComparingEnvironment;
    const microservices = this.comparingMsStores
      .filter(msStore => {
        const { microservice, deleted } = msStore;
        return !(!deleted && microservice.msState === ComparingState.DELETED);
      })
      .map(msStore => {
        const { microservice, envVarsForms, hostsForms } = msStore;
        const { hostsSource, name } = microservice;
        const hosts = hostsForms
          ? hostsForms.map(hostsForm => hostsForm.host)
          : hostsSource || [];
        const envVariables = envVarsForms
          .map(varForm => {
            const { value, secure, envSpecific, deleted } = varForm;
            return {
              name: varForm.name,
              value,
              secure,
              envSpecific,
              deleted,
            };
          })
          .filter(envVar => envVar.name);
        return {
          name,
          hosts,
          envVariables,
        };
      });
    return {
      clusterTarget,
      envTarget,
      envTargetCommitId,
      envSourceCommitId,
      merge: false,
      microservices,
    };
  };

  applyTo = async (merge: boolean, successCallback: () => void) => {
    try {
      const applyToData = this.getApplyToData();
      applyToData.merge = merge || false;
      this.isUIDisabled = true;
      this.isMerging = merge;
      this.comparingMsStores.forEach(msStore => {
        msStore.closeMS();
      });
      await httpFacade.environment.applyTo(this.envParams, applyToData);
      const successMessage = merge
        ? 'apply.via.merge.success.message'
        : 'apply.success.message';
      NotificationService.successMessage(
        { id: successMessage },
        fullscreenNotificationOptions,
      );
      this.isUIDisabled = false;
      this.isMerging = false;
      successCallback();
      this.gotoEnvironment();
    } catch (error) {
      let errorString;
      const { data } = error.response;
      if ((data.errorMessages && data.errorMessages.length) || data.length) {
        const errorMessagesArray = error.response.data.errorMessages
          ? error.response.data.errorMessages
          : error.response.data
              .map(errorItem => errorItem.message)
              .filter(errorMessage => !!errorMessage);
        const errorMessages = new Set(errorMessagesArray);
        errorString = [...errorMessages].join(' ');
      }
      errorString = errorString || data.id || 'response.error';
      NotificationService.errorMessage(
        { id: errorString },
        fullscreenNotificationOptions,
      );
      this.isUIDisabled = false;
      this.isMerging = false;
    }
  };

  gotoEnvironment = () => {
    const { envTarget, envSource, clusterTarget, clusterSource } =
      this.initialComparingEnvironment;
    const goToTarget = envTarget && clusterTarget;
    const clusterName = goToTarget ? clusterTarget : clusterSource;
    const environmentName = goToTarget ? envTarget : envSource;
    this.history.push(
      replaceUrlParams(ROUTES.environment, {
        id: RootStore.currentProject,
        clusterName,
        environmentName,
      }),
    );
  };
}

export { ComparingModalStore };
