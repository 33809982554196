import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { PodsStore } from 'stores/PodsPage/PodsStore';
import RootStore from 'stores/RootStore';

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import PodsTable from 'pages/PodsPage/PodsTable/PodsTable';

import style from './PodsPage.module.scss';

interface Props
  extends RouteComponentProps<{
    id: string;
    clusterName: string;
    environmentName: string;
    microserviceName: string;
  }> {
  alertsStore?;
}

@inject('alertsStore')
@observer
class PodsPage extends Component<Props> {
  podsStore: PodsStore;

  constructor(props) {
    super(props);

    const {
      id: projectName,
      clusterName,
      environmentName,
      microserviceName: serviceName,
    } = this.props.match.params;
    this.podsStore = new PodsStore({
      projectName,
      clusterName,
      environmentName,
      serviceName,
    });
  }

  render() {
    const { clusterName, environmentName, microserviceName } =
      this.props.match.params;
    const navigationLinks = [
      {
        name: <FormattedMessage id="page.project.dashboard" />,
        to: `/project/${RootStore.currentProject}/info`,
      },
      {
        name: <FormattedMessage id="link.cluster" values={{ clusterName }} />,
        to: `/project/${RootStore.currentProject}/cluster/${clusterName}/info`,
      },
      {
        name: (
          <FormattedMessage
            id="link.environment"
            values={{ environmentName }}
          />
        ),
        to: `/project/${RootStore.currentProject}/cluster/${clusterName}/environment/${environmentName}/info`,
      },
      {
        name: microserviceName,
        to: `/project/${RootStore.currentProject}/cluster/${clusterName}/environment/${environmentName}/microservice/${microserviceName}`,
      },
      {
        name: <FormattedMessage id="link.pods" />,
      },
    ];

    return (
      <div
        className={classNames(style.wrapper, {
          [style.wrapper__openedAlert]: this.props.alertsStore.isOpen,
        })}
      >
        <Breadcrumbs links={navigationLinks} />

        <PodsTable podsStore={this.podsStore} />
      </div>
    );
  }
}

export default PodsPage;
