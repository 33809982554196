import React from 'react';
import { observable } from 'mobx';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { StorageProvider } from 'models/ExternalStorage';

import style from './ProvidersList.module.scss';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/Icon/Icon';
import BorderedText from 'components/BorderedText/BorderedText';
import Button, { BtnSize, BtnType } from 'components/Button/Button';
import CreateExternalStorageProviderModal from 'components/ClusterHeader/CreateExternalStorageProviderModal/CreateExternalStorageProviderModal';
import RootStore from 'stores/RootStore';
import ModalStore from 'stores/ModalStore';
import { ClusterDetails } from 'models/Cluster';
import WithPopper from 'components/WithPopper/WithPopper';
import { lineBreakFunc } from 'helpers/string';

interface ProviderGroups<T = StorageProvider[]> {
  [key: string]: T;
}

interface Props {
  providers: ProviderGroups;
  cluster: ClusterDetails;
}

class ProvidersList extends React.Component<Props> {
  state = {
    isOpenMenu: {},
  };

  @observable
  allProviders: StorageProvider[] = Object.values(this.props.providers).reduce(
    (acc, group: StorageProvider[]) => acc.concat(group),
    [],
  );

  toggleMenu = key => () => {
    this.setState({
      isOpenMenu: {
        [key]: !this.state.isOpenMenu[key],
      },
    });
  };

  openCreateProviderModal = (id: string) => {
    const { cluster } = this.props;
    ModalStore.showModal(CreateExternalStorageProviderModal, {
      clusterName: cluster.info.name,
      projectName: RootStore.currentProject,
      providersList: this.allProviders,
      instanceId: id,
    });
  };

  render() {
    return (
      <div className={style.mainContainer}>
        <div className={style.header}>
          <FormattedMessage id="providers" />
        </div>
        <div className={style.scrollContainer}>
          <PerfectScrollbar>
            {Object.keys(this.props.providers).map(providerGroupKey => (
              <div key={providerGroupKey}>
                <div
                  className={style.groupBtnContainer}
                  onClick={this.toggleMenu(providerGroupKey)}
                >
                  <div className={style.labelContainer}>
                    <WithPopper
                      className={style.groupTitle}
                      title={
                        <span
                          dangerouslySetInnerHTML={{
                            __html: lineBreakFunc(providerGroupKey || ''),
                          }}
                        />
                      }
                    >
                      {providerGroupKey}
                    </WithPopper>
                    <BorderedText>
                      <>
                        {this.props.providers[providerGroupKey].length}&nbsp;
                        <FormattedMessage id="providers.versions" />
                      </>
                    </BorderedText>
                  </div>
                  <Icon
                    type={
                      this.state.isOpenMenu[providerGroupKey]
                        ? 'arrowTop'
                        : 'arrowBottom'
                    }
                  />
                </div>
                {this.state.isOpenMenu[providerGroupKey] && (
                  <div className={style.groupListMainContainer}>
                    {this.props.providers[providerGroupKey].map(provider => (
                      <div
                        key={provider.providerId}
                        className={style.providerItemCard}
                      >
                        <div className={style.versionLabel}>
                          {provider.providerVersion}
                        </div>
                        <BorderedText className={style.tag}>
                          {provider.providerTag}
                        </BorderedText>
                        {provider.modes && (
                          <div className={style.modesContainer}>
                            <span className={style.modesTitle}>
                              <FormattedMessage id="providers.modes" />:
                            </span>
                            {provider.modes.map(mode => (
                              <span key={mode} className={style.mode}>
                                - {mode};
                              </span>
                            ))}
                          </div>
                        )}
                        <Button
                          className={style.useProviderBtn}
                          size={BtnSize.ExtraTiny}
                          styleType={BtnType.Secondary}
                          onClick={this.openCreateProviderModal.bind(
                            null,
                            provider.providerId,
                          )}
                        >
                          <FormattedMessage id="use.provider" />
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </PerfectScrollbar>
        </div>
      </div>
    );
  }
}

export default ProvidersList;
