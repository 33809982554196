import { action, computed, observable } from 'mobx';
import { sortByField } from 'helpers/array';
import httpFacade from 'http/httpFacade';
import Log from 'helpers/log';
import { IPodContainer, IPodParams } from 'models/Pod';

export class PodContainersStore {
  @observable containers: IPodContainer[] = [];
  @observable activeContainerTabIndex = 0;
  @observable disappearedContainerName: string;
  @observable isLoading = true;

  podParams: IPodParams;

  constructor(podParams: IPodParams) {
    this.podParams = podParams;
  }

  @computed
  get sortedContainers() {
    return sortByField([...this.containers], 'containerName');
  }

  @action
  async fetchPodContainers() {
    try {
      this.isLoading = true;

      const { data } = await httpFacade.pods.fetchPodContainers(this.podParams);

      this.containers = data;
    } catch (error) {
      Log.warn(error);
    } finally {
      this.isLoading = false;
    }
  }
}
