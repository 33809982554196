import { BaseFormModel, displayName, isRequired } from '../../stores/BaseForm';
import { observable, action } from 'mobx';

class SearchImageForm extends BaseFormModel {
  @observable
  @displayName('artifact.name')
  @isRequired()
  artifactName: string = '';

  @observable
  @displayName('branch.name')
  @isRequired()
  branchName: string = '';

  @observable
  @displayName('since.date')
  @isRequired()
  date: string | undefined = '';

  @observable
  @displayName('included.topics')
  topics: string[] = [];

  @action
  clearForm = () => {
    this.artifactName = '';
    this.branchName = '';
    this.date = undefined;
    this.topics = [];
  };
}

export default SearchImageForm;
