import React, { ReactNode } from 'react';
import style from './TableExtendedContent.module.scss';

interface IProps {
  isOpened: boolean;
  children: ReactNode;
  colSpan?: number;
}

export const TableExtendedContent = ({
  isOpened,
  children,
  colSpan = 6,
}: IProps) => {
  if (!isOpened) {
    return null;
  }

  return (
    <tr className={style.extendedContent}>
      <td colSpan={colSpan} className={style.extendedContentCell}>
        <div className={style.extendedContentContainer}>{children}</div>
      </td>
    </tr>
  );
};
