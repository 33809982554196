import { action, observable } from 'mobx';
import {
  BaseFormModel,
  displayName,
  hasMaxLength,
  isMatchRegExp,
  isValueValid,
} from '../../../stores/BaseForm';
import {
  ENV_VAR_REGEXP,
  ENV_VAR_START_REGEXP,
} from '../../../helpers/testRegexp';

const fieldsRequiredMap = {
  variableName: 'isNameRequiredValid',
  variableValue: 'isValueRequiredValid',
};

class EnvironmentVariableForm extends BaseFormModel {
  @observable
  isNameValid = true;
  @observable
  isNameRequiredValid = true;
  @observable
  isValueRequiredValid = true;

  @observable
  @displayName('environment.variables.name')
  @isMatchRegExp(ENV_VAR_REGEXP, 'validation.env.var')
  @isMatchRegExp(ENV_VAR_START_REGEXP, 'validation.env.var.name.start')
  @hasMaxLength(253, 'validation.env.var.name.max.length')
  @isValueValid('isNameValid', 'validation.env.var.name.uniq')
  @isValueValid('isNameRequiredValid', 'form.field.is.required')
  variableName: string = '';

  @observable
  @displayName('environment.variables.value')
  @isValueValid('isValueRequiredValid', 'form.field.is.required')
  variableValue: string = '';

  @observable
  @displayName('environment.variables.secure')
  variableSecure: boolean = false;

  @action
  setValidName = (isValid: boolean) => {
    this.isNameValid = isValid;
  };

  @action
  setFieldRequiredValid = (
    fieldName: 'variableName' | 'variableValue',
    required: boolean,
  ) => {
    this[fieldsRequiredMap[fieldName]] = required;
    this.setErrorsMap(fieldName);
  };

  @action
  checkRequiredValid = (isNameChanged?: boolean) => {
    if (isNameChanged) {
      if (this.variableName) {
        this.setFieldRequiredValid('variableName', true);
        return;
      }
      if (!this.variableName && this.variableValue) {
        this.setFieldRequiredValid('variableName', false);
      }
    } else {
      if (this.variableValue) {
        this.setFieldRequiredValid('variableValue', true);
        return;
      }
      if (this.variableName && !this.variableValue) {
        this.setFieldRequiredValid('variableValue', false);
      }
    }

    if (!this.variableName && !this.variableValue) {
      this.setFieldRequiredValid('variableValue', true);
      this.setFieldRequiredValid('variableName', true);
    }
  };
}

export default EnvironmentVariableForm;
