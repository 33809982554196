import React, { useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Portal } from 'components/Portal';
import Checkbox from 'components/Form/Fields/Checkbox/Checkbox';
import { useClickOutside } from 'hooks/useClickOutside';

import style from './CheckboxDropdown.module.scss';

interface Props {
  className?: string;
  options: string[];
  unselected: string[];
  onClick: (name) => void;
  control: React.ReactElement;
}

const CheckboxDropdown = ({
  className,
  options,
  unselected,
  onClick,
  control,
}: Props) => {
  const [isOpened, setIsOpened] = useState(false);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);
  useClickOutside(menuRef, () => {
    setTimeout(() => setIsOpened(false), 0);
  });

  const toggleList = () => {
    setIsOpened(!isOpened);
  };

  const getList = () => {
    const wrapperRect = wrapperRef.current?.getBoundingClientRect();
    const menuStyles = wrapperRect && {
      top: wrapperRect.bottom + 5 + 'px',
      right: window.innerWidth - wrapperRect.right - 9 + 'px',
    };

    return (
      <Portal>
        <div
          ref={menuRef}
          style={menuStyles}
          className={classNames(style.menu)}
        >
          <ul>
            {options.map(el => (
              <li key={el}>
                <label className={style.label}>
                  <Checkbox
                    className={style.checkbox}
                    defaultChecked={!unselected.includes(el)}
                    onClick={() => onClick(el)}
                  />
                  <FormattedMessage id={el} />
                </label>
              </li>
            ))}
          </ul>
        </div>
      </Portal>
    );
  };

  return (
    <div
      ref={wrapperRef}
      className={classNames(style.mainContainer, className)}
    >
      <div className={style.pointer} onClick={toggleList}>
        {control}
      </div>
      {isOpened && getList()}
    </div>
  );
};

export default CheckboxDropdown;
