import React from 'react';
import classNames from 'classnames';
import { ClusterDetails } from 'models/Cluster';
import {
  StorageProviderInstance,
  StorageProvider,
} from 'models/ExternalStorage';

import style from './ProviderInstances.module.scss';
import Icon from 'components/Icon/Icon';
import Status, { StatusType } from 'components/Status/Status';
import { FormattedMessage } from 'react-intl';
import ProviderUserField from './ProviderUserField/ProviderUserField';
import BorderedText, {
  BorderedTextType,
} from 'components/BorderedText/BorderedText';
import Button, { BtnSize, BtnType } from 'components/Button/Button';
import WithPopper from 'components/WithPopper/WithPopper';
import { stringToDateTime } from 'helpers/datetime';
import CreateExternalStorageProviderModal from 'components/ClusterHeader/CreateExternalStorageProviderModal/CreateExternalStorageProviderModal';
import RootStore from 'stores/RootStore';
import ModalStore from 'stores/ModalStore';
import DeleteProviderInstanceModal from 'components/DeleteProviderInstanceModal/DeleteProviderInstanceModal';
import ProviderInstanceTab from './ProviderInstanceTab/ProviderInstanceTab';
import NotUseDB from './NotUseDB/NotUseDB';
import UsedDB from './UsedDB/UsedDB';
import { lineBreakFunc } from 'helpers/string';

interface Props {
  providerInstances: StorageProviderInstance[];
  providers: StorageProvider[];
  cluster: ClusterDetails;
}

const DBStatusMap = {
  CREATION_PENDING: StatusType.Warning,
  SUCCESSFUL: StatusType.Success,
  FAILURE: StatusType.Error,
  DELETION_PENDING: StatusType.Warning,
};

class ProviderInstances extends React.Component<Props> {
  state = {
    pathsList: {},
  };

  togglePaths = key => () => {
    this.setState({
      pathsList: {
        ...this.state.pathsList,
        [key]: !this.state.pathsList[key],
      },
    });
  };

  createProviderModalOpen = () => {
    const { cluster, providers } = this.props;
    ModalStore.showModal(CreateExternalStorageProviderModal, {
      clusterName: cluster.info.name,
      projectName: RootStore.currentProject,
      providersList: providers,
    });
  };

  deleteProviderInstanceModalOpen =
    (providerInstanceLongName, providerInstanceName) => () => {
      const { cluster } = this.props;
      ModalStore.showModal(DeleteProviderInstanceModal, {
        clusterName: cluster.info.name,
        projectName: RootStore.currentProject,
        providerInstanceName,
        providerInstanceLongName,
      });
    };

  render() {
    return (
      <>
        {this.props.providerInstances.length ? (
          <div className={style.mainContainer}>
            <table className={style.table}>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="provider.instance" />
                  </th>
                  <th>
                    <FormattedMessage id="host.port" />
                  </th>
                  <th>
                    <FormattedMessage id="status" />
                  </th>
                  <th className={style.cell__fixWidth}>
                    <FormattedMessage id="data.bases" />
                  </th>
                  <th className={style.cell__fixWidth}>
                    <FormattedMessage id="microservices" />
                  </th>
                  <th className={style.cell__fixWidth} />
                </tr>
              </thead>
              <tbody>
                {this.props.providerInstances.map(instance => {
                  const instanceIsOpen = this.state.pathsList[instance.name];

                  return (
                    <>
                      <tr
                        key={instance.name}
                        className={classNames(style.rowContainer, {
                          [style.pathsListIsOpen]: instanceIsOpen,
                        })}
                      >
                        <td>
                          <div className={style.cellContainer}>
                            <span className={style.firstTitle}>
                              <WithPopper
                                title={
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: lineBreakFunc(
                                        instance.longName || '',
                                      ),
                                    }}
                                  />
                                }
                                className={style.popperWrapper}
                              >
                                {instance.longName}
                              </WithPopper>
                            </span>
                            <span className={style.secondTitle}>
                              <WithPopper
                                title={`${instance.provider.providerName} - ${instance.provider.providerVersion} - ${instance.provider.providerTag}`}
                                className={style.popperWrapper}
                              >
                                <BorderedText className={style.borderedText}>
                                  {`${instance.provider.providerName} - ${instance.provider.providerVersion} - ${instance.provider.providerTag}`}
                                </BorderedText>
                              </WithPopper>
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className={style.cellContainer}>
                            <span className={style.firstTitle}>
                              <WithPopper
                                title={instance.host}
                                className={style.popperWrapper}
                              >
                                {instance.host}
                              </WithPopper>
                            </span>
                            <span className={style.firstTitle}>
                              {instance.port}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className={style.cellContainer}>
                            <span className={style.firstTitle}>
                              <Status
                                statusType={DBStatusMap[instance.status]}
                                className={style.status}
                              />
                              <span className={style.statusText}>
                                <FormattedMessage id={instance.status} />
                              </span>
                            </span>
                            <span className={style.firstTitle}>
                              {instance.statusDescription && (
                                <WithPopper
                                  title={instance.statusDescription}
                                  className={style.popperWrapper}
                                >
                                  <BorderedText
                                    className={style.borderedText}
                                    type={BorderedTextType.Error}
                                  >
                                    {instance.statusDescription}
                                  </BorderedText>
                                </WithPopper>
                              )}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className={style.cellContainer}>
                            <span className={style.firstTitle}>
                              {instance.databases ? (
                                <>
                                  {
                                    instance.databases.filter(
                                      db => db.usages && db.usages.length,
                                    ).length
                                  }
                                  /{instance.databases.length}
                                </>
                              ) : (
                                <>0/0</>
                              )}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className={style.cellContainer}>
                            <span className={style.firstTitle}>
                              {instance.databases
                                ? instance.databases
                                    .filter(db => db.usages)
                                    .map(el => el.usages)
                                    .reduce((acc, el) => acc.concat(el), [])
                                    .length
                                : 0}
                            </span>
                          </div>
                        </td>
                        <td className={style.cellArrow}>
                          <Icon
                            className={style.pathsListArrow}
                            type={instanceIsOpen ? 'arrowTop' : 'arrowBottom'}
                            onClick={this.togglePaths(instance.name)}
                          />
                        </td>
                      </tr>
                      <tr
                        className={classNames(style.extendedContent, {
                          [style.isClose]: !instanceIsOpen,
                        })}
                      >
                        <td colSpan={6}>
                          <div className={style.extendedContentContainer}>
                            <ProviderInstanceTab label="provider.instance.information">
                              {instance.createDate && (
                                <ProviderUserField
                                  className={style.flexWidth}
                                  label={'data.created'}
                                  value={stringToDateTime(
                                    instance.createDate.toString(),
                                  )}
                                />
                              )}
                              <div className={style.flexWidth}>
                                <Button
                                  size={BtnSize.ExtraTiny}
                                  styleType={BtnType.Secondary}
                                  onClick={this.deleteProviderInstanceModalOpen(
                                    instance.longName,
                                    instance.name,
                                  )}
                                >
                                  <FormattedMessage id="delete.provider.instance" />
                                </Button>
                              </div>

                              <ProviderUserField
                                className={style.flexWidth}
                                label={'username'}
                                value={instance.userName}
                              />

                              <ProviderUserField
                                className={classNames(
                                  style.flexWidth,
                                  style.withDelemiter,
                                )}
                                label={'password'}
                                value={instance.password}
                                withVisibility
                              />
                            </ProviderInstanceTab>
                            {instance.databases && (
                              <>
                                <ProviderInstanceTab label="microservises.with.data.bases">
                                  <div className={style.usedDBContainer}>
                                    {instance.databases
                                      .filter(
                                        db => db.usages && db.usages.length,
                                      )
                                      .map(db => (
                                        <UsedDB
                                          key={db.databaseName}
                                          database={db}
                                        />
                                      ))}
                                  </div>
                                </ProviderInstanceTab>
                                <ProviderInstanceTab label="not.in.use.data.bases">
                                  {instance.databases
                                    .filter(db => !db.usages)
                                    .map(db => (
                                      <NotUseDB
                                        key={`NotUseDB${db.databaseName}`}
                                        label={db.databaseName}
                                      />
                                    ))}
                                </ProviderInstanceTab>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
            <Button
              styleType={BtnType.Add}
              className={style.addButton}
              onClick={this.createProviderModalOpen}
            >
              <Icon type="plus2" />
            </Button>
          </div>
        ) : (
          <div className={style.emptyListContainer}>
            <Icon type="emptyProviderList" />
            <span className={style.emptyListText1}>
              <FormattedMessage id="emptyListText1" />
            </span>
            <span className={style.emptyListText2}>
              <FormattedMessage id="emptyListText2" />
            </span>
            <Button onClick={this.createProviderModalOpen}>
              <FormattedMessage id="add.new.provider.instance" />
            </Button>
          </div>
        )}
      </>
    );
  }
}

export default ProviderInstances;
