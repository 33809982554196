import { observer } from 'mobx-react';
import React, { Component } from 'react';
import classNames from 'classnames';

import style from 'components/Tabs/TrapezoidTabs/TrapezoidTabs.module.scss';

export const getTabElement = (index: number) => {
  return document.querySelector(`#${getTabId(index)}`);
};

export const getTabId = (index: number) => {
  return `tab-${index}`;
};

interface TrapezoidTabsItemProps {
  index: number;
  activeTabIndex: number;
  onClick: () => void;
  tabInfo: (isActive?: boolean) => JSX.Element;
  tabsNumber: number;
}

@observer
class TrapezoidTabsItem extends Component<TrapezoidTabsItemProps> {
  render() {
    const { index, activeTabIndex, tabsNumber, onClick, tabInfo } = this.props;

    return (
      <div
        className={classNames(style.tab, {
          [style.tab__selected]: index === activeTabIndex,
        })}
        style={{ zIndex: tabsNumber - index }}
        onClick={onClick}
        id={getTabId(index)}
      >
        {tabInfo(index === activeTabIndex)}
        <div className={style.marker} />
      </div>
    );
  }
}

export { TrapezoidTabsItem };
