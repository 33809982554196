import { ReactComponent as accept } from 'components/Icon/icons/accept.svg';
import { ReactComponent as arrow } from 'components/Icon/icons/arrowLeft.svg';
import { ReactComponent as search } from 'components/Icon/icons/search.svg';
import { ReactComponent as jira1 } from 'components/Icon/icons/jira1.svg';
import { ReactComponent as copy2 } from 'components/Icon/icons/copy2.svg';
import { ReactComponent as copy } from 'components/Icon/icons/copy.svg';
import { ReactComponent as alert } from 'components/Icon/icons/alert.svg';
import { ReactComponent as play } from 'components/Icon/icons/play.svg';
import { ReactComponent as collapseBtnOpened } from 'components/Icon/icons/collapse-btn-opened.svg';
import { ReactComponent as collapseBtnClosed } from 'components/Icon/icons/collapse-btn-closed.svg';
import { ReactComponent as close } from 'components/Icon/icons/close.svg';
import { ReactComponent as hidden } from 'components/Icon/icons/hidden.svg';
import { ReactComponent as visible } from 'components/Icon/icons/visible.svg';
import { ReactComponent as warning } from 'components/Icon/icons/warning.svg';
import { ReactComponent as info } from 'components/Icon/icons/info.svg';
import { ReactComponent as profile } from 'components/Icon/icons/profile.svg';
import { ReactComponent as settings } from 'components/Icon/icons/settings.svg';
import { ReactComponent as notifications } from 'components/Icon/icons/notifications.svg';
import { ReactComponent as apps } from 'components/Icon/icons/apps.svg';
import { ReactComponent as appsActive } from 'components/Icon/icons/apps-active.svg';
import { ReactComponent as clusters } from 'components/Icon/icons/clusters.svg';
import { ReactComponent as clustersActive } from 'components/Icon/icons/clusters-active.svg';
import { ReactComponent as dashboard } from 'components/Icon/icons/dashboard.svg';
import { ReactComponent as dashboardActive } from 'components/Icon/icons/dashboard-active.svg';
import { ReactComponent as help } from 'components/Icon/icons/help.svg';
import { ReactComponent as helpActive } from 'components/Icon/icons/help-active.svg';
import { ReactComponent as accountSettings } from 'components/Icon/icons/account-settings.svg';
import { ReactComponent as logout2 } from 'components/Icon/icons/logout2.svg';
import { ReactComponent as check } from 'components/Icon/icons/check.svg';
import { ReactComponent as check2 } from 'components/Icon/icons/check2.svg';
import { ReactComponent as scheduleClock } from 'components/Icon/icons/schedule-clock.svg';
import { ReactComponent as externalLink } from 'components/Icon/icons/external-link.svg';
import { ReactComponent as logo2White } from 'components/Icon/icons/logo2-white.svg';
import { ReactComponent as logo2WhiteSmall } from 'components/Icon/icons/logo2-white-small.svg';
import { ReactComponent as star2Unfilled } from 'components/Icon/icons/star2-unfilled.svg';
import { ReactComponent as star2Filled } from 'components/Icon/icons/star2-filled.svg';
import { ReactComponent as plus2 } from 'components/Icon/icons/plus2.svg';
import { ReactComponent as modalWarning } from 'components/Icon/icons/modal-warning.svg';
import { ReactComponent as power } from 'components/Icon/icons/power.svg';
import { ReactComponent as stop } from 'components/Icon/icons/stop-circle.svg';
import { ReactComponent as grafana } from 'components/Icon/icons/grafana.svg';
import { ReactComponent as kibana } from 'components/Icon/icons/kibana.svg';
import { ReactComponent as issueProgress } from 'components/Icon/icons/issue-progress.svg';
import { ReactComponent as issueClock } from 'components/Icon/icons/issue-clock.svg';
import { ReactComponent as issueEye } from 'components/Icon/icons/issue-eye.svg';
import { ReactComponent as issueResolve } from 'components/Icon/icons/issue-resolve.svg';
import { ReactComponent as lock } from 'components/Icon/icons/lock.svg';
import { ReactComponent as updates } from 'components/Icon/icons/updates.svg';
import { ReactComponent as stateInfo } from 'components/Icon/icons/stateInfo.svg';
import { ReactComponent as stateError } from 'components/Icon/icons/stateError.svg';
import { ReactComponent as stateWarning } from 'components/Icon/icons/stateWarning.svg';
import { ReactComponent as stateSuccess } from 'components/Icon/icons/stateSuccess.svg';
import { ReactComponent as stateHelp } from 'components/Icon/icons/stateHelp.svg';
import { ReactComponent as search2 } from 'components/Icon/icons/search2.svg';
import { ReactComponent as noResults } from 'components/Icon/icons/noResults.svg';
import { ReactComponent as crossWrong } from 'components/Icon/icons/cross-wrong.svg';
import { ReactComponent as unknown } from 'components/Icon/icons/unknown.svg';
import { ReactComponent as infoBtn } from 'components/Icon/icons/info-btn.svg';
import { ReactComponent as infoSolid } from 'components/Icon/icons/info-solid.svg';
import { ReactComponent as editProject } from 'components/Icon/icons/edit-project.svg';
import { ReactComponent as createCluster } from 'components/Icon/icons/create-cluster.svg';
import { ReactComponent as _delete } from 'components/Icon/icons/delete.svg';
import { ReactComponent as deleteEmpty } from 'components/Icon/icons/delete-empty.svg';
import { ReactComponent as git } from 'components/Icon/icons/git.svg';
import { ReactComponent as kubernetes } from 'components/Icon/icons/kubernetes.svg';
import { ReactComponent as docker } from 'components/Icon/icons/docker.svg';
import { ReactComponent as exclamation } from 'components/Icon/icons/exclamation.svg';
import { ReactComponent as storageDisabled } from 'components/Icon/icons/storage-disabled.svg';
import { ReactComponent as emptyProviderList } from 'components/Icon/icons/empty-provider-list.svg';
import { ReactComponent as systemVersion } from 'components/Icon/icons/system-version.svg';
import { ReactComponent as modalError } from 'components/Icon/icons/modal-error.svg';
import { ReactComponent as db } from 'components/Icon/icons/db.svg';
import { ReactComponent as menuDots } from 'components/Icon/icons/menu-dots.svg';
import { ReactComponent as message } from 'components/Icon/icons/message.svg';
import { ReactComponent as noMSSupport } from 'components/Icon/icons/no-ms-support.svg';
import { ReactComponent as calendar } from 'components/Icon/icons/calendar.svg';
import { ReactComponent as crossTrash } from 'components/Icon/icons/cross-trash.svg';
import { ReactComponent as oneBranch } from 'components/Icon/icons/one-branch.svg';
import { ReactComponent as noBranches } from 'components/Icon/icons/no-branches.svg';
import { ReactComponent as branches } from 'components/Icon/icons/branches.svg';
import { ReactComponent as filter } from 'components/Icon/icons/filter.svg';
import { ReactComponent as switchBranch } from 'components/Icon/icons/switch-branch.svg';
import { ReactComponent as ok } from 'components/Icon/icons/ok.svg';
import { ReactComponent as esmCopy } from 'components/Icon/icons/esm-copy.svg';
import { ReactComponent as esmDelete } from 'components/Icon/icons/esm-delete.svg';
import { ReactComponent as save } from 'components/Icon/icons/save.svg';
import { ReactComponent as edit } from 'components/Icon/icons/edit.svg';
import { ReactComponent as emptyBox } from 'components/Icon/icons/empty-box.svg';
import { ReactComponent as upload } from 'components/Icon/icons/upload.svg';
import { ReactComponent as reset } from 'components/Icon/icons/reset.svg';
import { ReactComponent as install } from 'components/Icon/icons/install.svg';
import { ReactComponent as scale } from 'components/Icon/icons/scale.svg';
import { ReactComponent as restart } from 'components/Icon/icons/restart.svg';
import { ReactComponent as rocket } from 'components/Icon/icons/rocket.svg';
import { ReactComponent as checked } from 'components/Icon/icons/checked.svg';

export const Icons = {
  arrowBottom: arrow,
  arrowLeft: arrow,
  arrowRight: arrow,
  arrowTop: arrow,
  accept,
  alert,
  search,
  jira1,
  copy2,
  copy,
  play,
  collapseBtnOpened,
  collapseBtnClosed,
  close,
  visible,
  hidden,
  warning,
  info,
  profile,
  settings,
  notifications,
  apps,
  appsActive,
  clusters,
  clustersActive,
  dashboard,
  dashboardActive,
  help,
  helpActive,
  accountSettings,
  logout2,
  check,
  check2,
  scheduleClock,
  externalLink,
  logo2White,
  logo2WhiteSmall,
  star2Unfilled,
  plus2,
  modalWarning,
  power,
  stop,
  kibana,
  grafana,
  issueProgress,
  issueClock,
  issueEye,
  issueResolve,
  lock,
  updates,
  stateInfo,
  stateError,
  stateWarning,
  stateHelp,
  stateSuccess,
  star2Filled,
  search2,
  noResults,
  crossWrong,
  unknown,
  infoBtn,
  infoSolid,
  editProject,
  createCluster,
  delete: _delete,
  deleteEmpty,
  git,
  kubernetes,
  docker,
  exclamation,
  storageDisabled,
  emptyProviderList,
  systemVersion,
  modalError,
  db,
  menuDots,
  message,
  noMSSupport,
  calendar,
  crossTrash,
  oneBranch,
  noBranches,
  branches,
  filter,
  switchBranch,
  ok,
  esmCopy,
  esmDelete,
  save,
  edit,
  emptyBox,
  upload,
  reset,
  install,
  scale,
  restart,
  rocket,
  checked,
};
