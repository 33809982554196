import React, { Component } from 'react';
import { computed, observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';

import RootStore from 'stores/RootStore';
import ServiceStore from 'stores/ServiceStore';
import { IServiceParams } from 'models/Service';
import Icon from 'components/Icon/Icon';
import MicroservicePageHeader from 'components/MicroservicePageHeader/MicroservicePageHeader';
import MicroservicePageArtifact from 'components/MicroservicePageArtifact/MicroservicePageArtifact';
import MicroservicePageBody from 'components/MicroservicePageBody/MicroservicePageBody';

import style from './MicroservicePage.module.scss';

interface Props
  extends RouteComponentProps<{
    id: string;
    clusterName: string;
    environmentName: string;
    microserviceName: string;
  }> {
  alertsStore?;
}

@inject('alertsStore')
@observer
class MicroservicePage extends Component<Props> {
  @observable store: ServiceStore;

  constructor(props: Props) {
    super(props);

    const { id, clusterName, environmentName, microserviceName } =
      this.props.match.params;
    const params: IServiceParams = {
      clusterName,
      projectName: id,
      environmentName,
      serviceName: microserviceName,
    };

    this.store = new ServiceStore(params);
  }

  componentWillUnmount() {
    this.store.clearTimer();
  }

  render() {
    const { clusterName, environmentName, microserviceName } =
      this.props.match.params;

    return (
      <div
        className={classNames(style.wrapper, {
          [style.wrapper__openedAlert]: this.props.alertsStore.isOpen,
        })}
      >
        <div className={style.breadcrumbs}>
          <Link
            className={style.link}
            to={`/project/${RootStore.currentProject}/info`}
          >
            <FormattedMessage id="page.project.dashboard" />
          </Link>
          <Icon className={style.arrowIcon} type="arrowRight" />

          <Link
            className={style.link}
            to={`/project/${RootStore.currentProject}/cluster/${clusterName}/info`}
          >
            <FormattedMessage id="page.project.cluster" /> {clusterName}
          </Link>
          <Icon className={style.arrowIcon} type="arrowRight" />

          <Link
            className={style.link}
            to={`/project/${RootStore.currentProject}/cluster/${clusterName}/environment/${environmentName}/info`}
          >
            {environmentName} <FormattedMessage id="environment.title" />
          </Link>
          <Icon className={style.arrowIcon} type="arrowRight" />

          <span className={style.microserviceName}>{microserviceName}</span>
        </div>
        {!this.store.pending && !this.store.service && (
          <div className={style.notFoundContainer}>
            <FormattedMessage id="microservice.not.found" />
          </div>
        )}
        {this.store.pending ? '' : this.microserviceRender}
      </div>
    );
  }

  @computed
  get microserviceRender() {
    const {
      params,
      service,
      serviceProviders,
      externalStorageStatus,
      providerInstances,
      envVariables,
    } = this.store;
    if (!service) {
      return null;
    }

    return (
      <>
        <MicroservicePageHeader
          params={params}
          service={service}
          serviceStore={this.store}
        />
        <MicroservicePageArtifact
          serviceParams={params}
          service={service}
          updateMicroservice={this.store.updateMicroservice}
        />
        <MicroservicePageBody
          service={service}
          serviceProviders={serviceProviders}
          externalStorageStatus={externalStorageStatus}
          providerInstances={providerInstances}
          envVariables={envVariables}
          serviceStore={this.store}
        />
      </>
    );
  }
}

export default MicroservicePage;
