import React from 'react';

import classNames from 'classnames';

import { copyToClipboard } from 'helpers/copyToClipboard';
import { useDomElement } from 'hooks/useDomElement';
import { BaseFormModel } from 'stores/BaseForm/BaseFormModel';

import WithPopper from 'components/WithPopper/WithPopper';
import Icon from 'components/Icon/Icon';

import { FormControl } from 'components/Form/FormControl/FormControl';
import TextField from 'components/Form/Fields/TextField/TextField';
import PasswordField from 'components/Form/Fields/PasswordField/PasswordField';

import style from './CopyTextBox.module.scss';

interface Props {
  form: BaseFormModel;
  label: string;
  className?: string;
  isPassword?: boolean;
}

export const CopyTextBox = ({
  className,
  form,
  isPassword = false,
  label,
}: Props) => {
  const textWrapperElement = useDomElement('[data-text-wrapper]');
  const copyText = () => {
    copyToClipboard(form[label]);
  };

  return (
    <div className={classNames(style.wrapper, className)} data-text-wrapper>
      <div className={style.textWrapper}>
        <FormControl
          form={form}
          name={label}
          className={style.formControl}
          render={props =>
            isPassword ? (
              <PasswordField
                disabled={true}
                className={style.input}
                {...props}
              />
            ) : (
              <TextField disabled={true} className={style.input} {...props} />
            )
          }
        />
      </div>
      <WithPopper title="Click to Copy" boundary={textWrapperElement}>
        <Icon className={style.icon} type={'copy2'} onClick={copyText} />
      </WithPopper>
    </div>
  );
};
