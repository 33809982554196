import React, { Component } from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';

import { replaceUrlParams } from 'http/helpers';
import { ROUTES } from 'routes/routes';
import ClusterStore from 'stores/ClusterStore';

import EnvironmentList from 'components/EnvironmentList/EnvironmentList';
import ClusterCard from './ClusterCard/ClusterCard';
import ClusterCardWrapper from './ClusterCardWrapper/ClusterCardWrapper';
import MonitoringCard from './MonitoringCard/MonitoringCard';

import style from './ClusterContainer.module.scss';

interface Props {
  name: string;
  projectName: string;
  changeRootState: (name: string) => void;
  className?: string;
  history: RouteComponentProps['history'];
}

interface WithForwardedRef {
  forwardedRef: any;
}

interface State {
  isAllCollapsed: boolean;
  [key: string]: boolean;
}

@observer
class ClusterContainer extends Component<Props & WithForwardedRef, State> {
  public state = {
    isAllCollapsed: false,
  };
  private clusterStore: ClusterStore;
  private tabs = {
    environments: 'environments',
    monitoring: 'monitoring',
  };

  constructor(props: Props & WithForwardedRef) {
    super(props);
    this.clusterStore = new ClusterStore(props.name, props.projectName, true);
  }

  @action
  gotoClusterPage = () => {
    const { history } = this.props;
    const { projectName, clusterName } = this.clusterStore.params;
    history.push(
      replaceUrlParams(ROUTES.cluster, { id: projectName, clusterName }),
    );
  };

  componentWillUnmount() {
    this.clusterStore.clearTimer();
  }

  public changeCollapsedState = (tab: string): void => {
    const value = this.state[tab] ? !this.state[tab] : Boolean(tab);

    this.setState({ [tab]: value }, () => {
      const isMonitoringCollapsed = this.state[this.tabs.monitoring];
      const isEnvironmentsCollapsed = this.state[this.tabs.environments];

      isMonitoringCollapsed && isEnvironmentsCollapsed
        ? this.setState({ isAllCollapsed: true })
        : this.setState({ isAllCollapsed: false });
    });
  };

  public render(): JSX.Element | string {
    if (this.clusterStore.pending || !this.clusterStore.cluster) {
      return '';
    }

    const { cluster } = this.clusterStore;
    const { className = '' } = this.props;
    const { isAllCollapsed } = this.state;
    const classes: string = classNames(style.cluster, className, {
      [style.cluster__fullWidth]: !isAllCollapsed,
    });

    return (
      <div ref={this.props.forwardedRef} className={classes}>
        <ClusterCard
          className={style.tab}
          cluster={cluster}
          isExternalStorageAvailable={
            this.clusterStore.isExternalStorageAvailable
          }
          getClusterDataFromLink={this.clusterStore.getDataFromLink}
          gotoClusterPage={this.gotoClusterPage}
        />
        <MonitoringCard
          className={classNames(style.tab, style.tab__monitoring)}
          cluster={cluster}
          onCollapse={this.toggleMonitoringTab}
        />
        <ClusterCardWrapper
          className={style.tab}
          title="page.project.environments"
          label={cluster.environments.length.toString()}
          onCollapse={this.toggleEnvironmentsTab}
        >
          <EnvironmentList
            environments={cluster.environments}
            clusterName={cluster.info.name}
            hideTitle
          />
        </ClusterCardWrapper>
      </div>
    );
  }

  private toggleMonitoringTab = (): void => {
    const { changeRootState } = this.props;
    this.changeCollapsedState(this.tabs.monitoring);
    changeRootState(this.tabs.monitoring);
  };

  private toggleEnvironmentsTab = (): void => {
    const { changeRootState } = this.props;

    this.changeCollapsedState(this.tabs.environments);
    changeRootState(this.tabs.environments);
  };
}

export default React.forwardRef((props: Props, ref) => (
  <ClusterContainer forwardedRef={ref} {...props} />
));
