import React, { useState, createRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { useClickOutside } from '../../../../hooks/useClickOutside';
import Icon from 'components/Icon/Icon';

import style from './SelectWithSearch.module.scss';

interface Props {
  className?: string;
  onChange: (val: string) => void;
  options: string[];
  value?: string;
  placeholder?: string;
  label: string;
  disabled?: boolean;
  menuMaxHeight?: string;
}

const SelectWithSearch: React.FC<Props> = (props: Props) => {
  const {
    options = [],
    className,
    placeholder = '',
    value = '',
    onChange,
    label,
    disabled,
    menuMaxHeight,
  } = props;

  const wrapperRef = createRef<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [list, setList] = useState(options);
  const [selectedOption, setSelectedOption] = useState('');

  const handleClickOutside = () => {
    if (isOpen) {
      setIsOpen(false);
      if (!list.length || !selectedOption) {
        onChange('');
        setInputValue('');
        setList(options);
      }
    }
  };

  useEffect(() => {
    setList(options);
  }, [options]);

  useClickOutside(wrapperRef, handleClickOutside);

  const onOptionSelect = (val: string, e: React.SyntheticEvent) => {
    e.stopPropagation();
    onChange(val);
    setInputValue(val);
    setSelectedOption(val);
    setList(options);
    setIsOpen(false);
  };

  const toggleProjectsList = () => {
    if (disabled) {
      return;
    }
    if (isOpen) {
      setIsOpen(false);
      if (!list.length || !selectedOption) {
        onChange('');
      }
    } else {
      setIsOpen(true);
    }
  };

  const filterList = (val: string) => {
    const optionsCopy = [...options];
    setList(
      optionsCopy.filter(item =>
        item.toUpperCase().startsWith(val.toUpperCase()),
      ),
    );
  };

  const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = e.target.value;
    if (!targetValue) {
      onChange('');
      setSelectedOption('');
      setList(options);
    }
    if (selectedOption) {
      setSelectedOption('');
    }
    setInputValue(targetValue);
    filterList(targetValue);
  };

  useEffect(() => {
    setInputValue(value);
    onChange(value);
    setSelectedOption(value);
  }, [value, onChange]);

  return (
    <div
      className={classNames({
        [style.container]: !disabled,
        [style.disabled]: disabled,
      })}
    >
      <span
        className={classNames(style.fieldLabel, {
          [style.fieldLabel__active]: Boolean(value) || inputValue,
          [style.disabled]: disabled,
        })}
      >
        <FormattedMessage id={label} />
      </span>
      <div
        className={classNames(style.wrapper, className)}
        ref={wrapperRef}
        tabIndex={0}
        onClick={toggleProjectsList}
      >
        <div
          className={classNames(style.field, {
            [style.fieldActive]: isOpen,
            [style.disabled]: disabled,
          })}
        >
          <input
            onClick={e => isOpen && e.stopPropagation()}
            className={classNames(style.textInput, {
              [style.disabled]: disabled,
            })}
            type="text"
            value={inputValue}
            placeholder={placeholder}
            onChange={inputChangeHandler}
            disabled={disabled}
          />
        </div>
        <Icon
          className={classNames(style.selectIcon, {
            [style.selectIcon__active]: isOpen,
            [style.disabled]: disabled,
          })}
          type={isOpen ? 'arrowTop' : 'arrowBottom'}
        />
        {isOpen && (
          <div className={style.menu}>
            <ul style={{ maxHeight: menuMaxHeight ? menuMaxHeight : '400px' }}>
              {list.map(option => (
                <li
                  key={option}
                  className={classNames(style.listItem, {
                    [style.active]: inputValue === option,
                  })}
                  onClick={onOptionSelect.bind(null, option)}
                >
                  {option}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectWithSearch;
