import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, reaction } from 'mobx';
import classNames from 'classnames';
import { bindFormControl } from '../../../components/Form/FormControl/FormControl';
import { TEMPLATE_VAR_REGEXP, testRegExp } from '../../../helpers/testRegexp';
import { localization } from '../../../index';
import RootStore from '../../../stores/RootStore';
import TemplateVariableForm from '../../../stores/Forms/TemplateVariableForm';
import { isElementOverflowedById } from 'helpers/domElement';
import TextField from '../../../components/Form/Fields/TextField/TextField';
import Icon from 'components/Icon/Icon';
import WithPopper from '../../../components/WithPopper/WithPopper';

import style from './TemplateVariablesList.module.scss';

interface Props {
  templateVariableItem: TemplateVariableForm;
  deleteVariable: (index) => void;
  checkNameUniq: () => void;
  index: number;
  previewMode?: boolean;
  isDisabled?: boolean;
}

@observer
class TemplateVarTableItem extends Component<Props> {
  @observable
  isNameTooltipVisible = true;
  @observable
  isValueTooltipVisible = true;
  @observable
  isDescriptionTooltipVisible = true;
  disposer;
  resizeTimeout;

  constructor(props) {
    super(props);
    this.disposer = reaction(() => RootStore.resizeEvent, this.onResize);
  }

  onResize = () => {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = undefined;
    }
    this.resizeTimeout = setTimeout(this.setTooltips, 700);
  };

  getElementId = fieldName => {
    const { index } = this.props;
    return `${fieldName}${index}`;
  };

  onVariableNameChange = onChange => (value: string, e) => {
    const { checkNameUniq, templateVariableItem } = this.props;
    const targetElement = e.target;
    const ss = targetElement.selectionStart;
    const se = targetElement.selectionEnd;
    const length = value.length;
    const isValidRegexp = testRegExp(value, TEMPLATE_VAR_REGEXP);
    if (!isValidRegexp && length > 0) {
      setTimeout(() => {
        targetElement.selectionStart = ss - 1;
        targetElement.selectionEnd = se - 1;
      }, 0);
      return;
    }

    onChange(value, e);
    templateVariableItem.checkRequiredValid('variableName');
    templateVariableItem.validate();
    checkNameUniq();
  };

  onVarFieldChange = (onChange, field) => (value: string, e) => {
    const { templateVariableItem } = this.props;
    onChange(value, e);
    templateVariableItem.checkRequiredValid(field);
    templateVariableItem.validate();
  };

  setTooltips = () => {
    const isNameOverflowed = isElementOverflowedById(
      this.getElementId('variableName'),
    );
    this.isNameTooltipVisible = !(
      isNameOverflowed !== undefined && !isNameOverflowed
    );
    const isValueOverflowed = isElementOverflowedById(
      this.getElementId('variableValue'),
    );
    this.isValueTooltipVisible = !(
      isValueOverflowed !== undefined && !isValueOverflowed
    );
    const isDescriptionOverflowed = isElementOverflowedById(
      this.getElementId('variableDescription'),
    );
    this.isDescriptionTooltipVisible = !(
      isDescriptionOverflowed !== undefined && !isDescriptionOverflowed
    );
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { previewMode } = this.props;
    if (previewMode && !prevProps.previewMode) {
      this.setTooltips();
    }
  }

  componentDidMount() {
    this.setTooltips();
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const { templateVariableItem, index, previewMode, isDisabled } = this.props;
    const FormControl = bindFormControl(templateVariableItem);
    return (
      <tr>
        <td>
          <div className={style.cellContainer}>
            {previewMode ? (
              <WithPopper
                className={classNames(style.varPopper, style.varTooltip)}
                title={templateVariableItem.variableName}
                hidden={!this.isNameTooltipVisible}
              >
                <div
                  className={style.inputText}
                  id={this.getElementId('variableName')}
                >
                  <span>{templateVariableItem.variableName}</span>
                </div>
              </WithPopper>
            ) : (
              <FormControl
                withoutLabel
                className={style.variableInput}
                name={'variableName'}
                immediateValidation={true}
                render={props => (
                  <TextField
                    {...props}
                    id={'variableName' + index}
                    placeholder={localization.formatMessage(
                      'template.variable.name',
                    )}
                    onChange={this.onVariableNameChange(props.onChange)}
                    disabled={isDisabled}
                  />
                )}
              />
            )}
          </div>
        </td>
        <td>
          <div className={style.cellContainer}>
            {previewMode ? (
              <WithPopper
                className={classNames(
                  style.varPopper,
                  style.varTooltip,
                  style.description,
                )}
                title={templateVariableItem.variableDescription}
                hidden={!this.isDescriptionTooltipVisible}
              >
                <div
                  className={style.inputText}
                  id={this.getElementId('variableDescription')}
                >
                  <span>{templateVariableItem.variableDescription}</span>
                </div>
              </WithPopper>
            ) : (
              <FormControl
                withoutLabel
                className={style.variableInput}
                name={'variableDescription'}
                immediateValidation={true}
                render={props => (
                  <TextField
                    {...props}
                    id={'variableDescription' + index}
                    placeholder={localization.formatMessage(
                      'template.variable.description',
                    )}
                    onChange={this.onVarFieldChange(
                      props.onChange,
                      'variableDescription',
                    )}
                    disabled={isDisabled}
                  />
                )}
              />
            )}
          </div>
        </td>
        <td>
          <div className={style.cellContainer}>
            {previewMode ? (
              <WithPopper
                className={classNames(
                  style.varPopper,
                  style.envVarTooltip,
                  style.value,
                )}
                title={templateVariableItem.variableValue}
                hidden={!this.isValueTooltipVisible}
              >
                <div
                  id={this.getElementId('variableValue')}
                  className={classNames(style.inputText)}
                >
                  <span>{templateVariableItem.variableValue}</span>
                </div>
              </WithPopper>
            ) : (
              <FormControl
                withoutLabel
                className={style.variableInput}
                name="variableValue"
                immediateValidation={true}
                render={props => (
                  <TextField
                    {...props}
                    id={'variableValue' + index}
                    placeholder={localization.formatMessage(
                      'template.variable.value',
                    )}
                    onChange={this.onVarFieldChange(
                      props.onChange,
                      'variableValue',
                    )}
                    disabled={isDisabled}
                  />
                )}
              />
            )}
          </div>
        </td>
        <td>
          <div
            className={classNames(style.cellContainer, style.buttonsContainer)}
          >
            {!previewMode && (
              <Icon
                type={'deleteEmpty'}
                className={classNames(
                  style.deleteBtn,
                  isDisabled && style.disabled,
                )}
                onClick={() => this.props.deleteVariable(this.props.index)}
                disabled={isDisabled}
              />
            )}
          </div>
        </td>
      </tr>
    );
  }
}

export default TemplateVarTableItem;
