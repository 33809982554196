import { action, computed, observable, runInAction } from 'mobx';
import { ActionId, TemplatesParams } from 'models/Template';
import httpFacade from 'http/httpFacade';
import Log from 'helpers/log';

class TemplatesPageStatistics {
  @observable envTemplatesAmount = 0;
  @observable msTemplatesAmount = 0;
  @observable envTemplateVariablesAmount = 0;
  @observable msTemplateVariablesAmount = 0;

  readonly params: Omit<TemplatesParams, 'actionId'>;

  constructor(params: Omit<TemplatesParams, 'actionId'>) {
    this.params = params;
  }

  @computed
  get envStat() {
    return {
      templatesAmount: this.envTemplatesAmount,
      variablesAmount: this.envTemplateVariablesAmount,
    };
  }

  @computed
  get msStat() {
    return {
      templatesAmount: this.msTemplatesAmount,
      variablesAmount: this.msTemplateVariablesAmount,
    };
  }

  @action.bound
  async fetchStatistics() {
    const envParams: TemplatesParams = {
      ...this.params,
      actionId: ActionId.create_environment,
    };
    const msParams: TemplatesParams = {
      ...this.params,
      actionId: ActionId.create_microservice,
    };

    try {
      const [
        envTemplates,
        msTemplates,
        envTemplateVariables,
        msTemplateVariables,
      ] = await Promise.all([
        httpFacade.templates.fetchTemplates(envParams),
        httpFacade.templates.fetchTemplates(msParams),
        httpFacade.templates.fetchTemplatesVariables(envParams),
        httpFacade.templates.fetchTemplatesVariables(msParams),
      ]);

      runInAction(() => {
        this.envTemplatesAmount = envTemplates.data.length;
        this.msTemplatesAmount = msTemplates.data.length;
        this.envTemplateVariablesAmount = envTemplateVariables.data.length;
        this.msTemplateVariablesAmount = msTemplateVariables.data.length;
      });
    } catch (error) {
      Log.warn(error);
    }
  }
}

export default TemplatesPageStatistics;
