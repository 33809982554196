import { observable } from 'mobx';
import { BaseFormModel, isRequired } from 'stores/BaseForm';

export class HostForm extends BaseFormModel {
  @observable
  @isRequired()
  host: string = '';

  constructor(host: string) {
    super();
    this.host = host;
  }
}
