import { action, observable } from 'mobx';
import { Project } from 'models/Project';
import httpFacade from 'http/httpFacade';
import { Cluster } from 'models/Cluster';
import RootStore from 'stores/RootStore';

export class ProjectStore {
  @observable project?: Project;
  @observable clusterList: Cluster[] = [];
  @observable pending = true;
  @observable projectName: string;

  constructor(projectName: string) {
    this.init(projectName);
  }

  init = async (projectName: string) => {
    try {
      this.projectName = projectName;
      this.pending = true;
      await this.fetchProjectDetails(projectName);
      this.pending = false;
    } catch (error) {
      // TODO: message
    }
  };

  @action.bound
  async fetchProjectDetails(projectName: string) {
    try {
      const response = await httpFacade.projects.fetchProjectDetails(
        projectName,
      );
      this.project = response.data.info;
      this.clusterList = response.data.clusters;
      RootStore.setClusterList(response.data.clusters);
    } catch (error) {
      this.project = undefined;
      this.clusterList = [];
      RootStore.setClusterList([]);
    }
  }
}
