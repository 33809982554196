import axios from 'axios';

import {
  unauthorizedInterceptor,
  setAuthInterceptor,
  updateAuthInterceptor,
  internalServerErrorInterceptor,
  accessDeniedErrorInterceptor,
} from './interceptors';
import settings from 'settings';

const instance = axios.create({
  baseURL: settings.baseUrl,
  timeout: 5 * 60 * 1000,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

instance.interceptors.request.use(setAuthInterceptor);
instance.interceptors.response.use(undefined, updateAuthInterceptor);
instance.interceptors.response.use(undefined, unauthorizedInterceptor);
instance.interceptors.response.use(undefined, accessDeniedErrorInterceptor);
instance.interceptors.response.use(undefined, internalServerErrorInterceptor);

export { instance };
