import style from 'pages/PodsPage/PodsTable/PodsTable.module.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export function PodsTableHeader() {
  return (
    <thead className={style.tableHeader}>
      <tr>
        <th>
          <FormattedMessage id="pod.status.and.name" />
        </th>
        <th>
          <FormattedMessage id="pod.sync.status" />
        </th>
        <th>
          <FormattedMessage id="pod.phase" />
        </th>
        <th className={style.cell__fixWidth}>
          <FormattedMessage id="pod.restarts" />
        </th>
        <th className={style.cell__fixWidth}>
          <FormattedMessage id="pod.containers" />
        </th>
        <th className={style.cell__fixWidth} />
      </tr>
    </thead>
  );
}
