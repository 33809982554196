import { action } from 'mobx';
import { appWSSUrl } from 'http/helpers';
import Log from 'helpers/log';
import RootStore from 'stores/RootStore';
import { wss } from 'stores/Socket/helpers';

const SOCKET_MESSAGE_TYPE_AUTHENTICATE = 'authenticate';

interface IConnectOptions {
  url: string;
  onClose?: () => void;
}

class Socket {
  private socket: WebSocket;

  @action.bound
  connect(options: IConnectOptions) {
    const { url, onClose } = options;
    this.socket = new WebSocket(wss(appWSSUrl(url)));

    this.socket.onopen = () => {
      const message = {
        type: SOCKET_MESSAGE_TYPE_AUTHENTICATE,
        payload: { token: RootStore.user.getAuthTokens().accessToken },
      };

      this.socket.send(JSON.stringify(message));
      Log.info('Socket connected');
    };
    this.socket.onerror = Log.info;
    this.socket.onclose = event => {
      if (onClose) {
        onClose();
      }

      if (event.wasClean) {
        Log.info('Socket was closed');
      } else {
        Log.info(event);
      }
    };
  }

  subscribe(callback) {
    this.socket?.addEventListener('message', callback);
  }

  unsubscribe(callback) {
    this.socket?.removeEventListener('message', callback);
  }

  disconnect() {
    this.socket?.close();
  }
}

export default Socket;
