import React from 'react';
import { FormattedMessage } from 'react-intl';
import { VersionResponse } from 'http/types';
import httpFacade from 'http/httpFacade';

import { useDomElement } from 'hooks/useDomElement';

import Icon from 'components/Icon/Icon';
import WithPopper from 'components/WithPopper/WithPopper';

import style from './SystemVersion.module.scss';

export const SystemVersion = () => {
  const projectHeaderElement = useDomElement('[data-project-header]');
  const [frontVersion, setFrontVersion] = React.useState<VersionResponse>({
    image: '-',
    tag: '-',
  });
  const [backVersion, setBackVersion] = React.useState<VersionResponse>({
    image: '-',
    tag: '-',
  });

  React.useEffect(() => {
    httpFacade.root.fetchFrontVersion().then(res => {
      setFrontVersion(res.data);
    });
    httpFacade.root.fetchBackVersion().then(res => {
      setBackVersion(res.data);
    });
  }, []);

  return (
    <WithPopper
      title={`Front: ${frontVersion.tag} | Back: ${backVersion.tag}`}
      className={style.wrapper}
      position={'absolute'}
      boundary={projectHeaderElement}
    >
      <Icon className={style.icon} type="systemVersion" />{' '}
      <span className={style.label}>
        <FormattedMessage id={'system.version'} />
      </span>
    </WithPopper>
  );
};
