import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import style from './ClusterEnvironmentList.module.scss';
import EnvironmentItem from './EnvironmentItem/EnvironmentItem';
import { Environment } from 'models/Environment';
import Icon from 'components/Icon/Icon';
import { replaceUrlParams } from 'http/helpers';
import RootStore from 'stores/RootStore';
import { ROUTES } from 'routes/routes';
import ModalStore from '../../stores/ModalStore';
import CreateEnvironmentModal from './CreateEnvironmentModal';
import Button, { BtnType } from '../Button/Button';

interface Props extends RouteComponentProps {
  environments: Environment[];
  clusterName: string;
  fetchCluster: (projectName: string, clusterName: string) => void;
  addEnvToFavorite: (environmentName: string) => void;
}

@observer
class ClusterEnvironmentList extends React.Component<Props> {
  @observable tableBodyRef = React.createRef<HTMLTableSectionElement>();

  openCreateEnvironmentModal = async () => {
    ModalStore.showModal(CreateEnvironmentModal, {
      clusterName: this.props.clusterName,
      projectName: RootStore.currentProject,
      fetchCluster: this.props.fetchCluster,
    });
  };

  gotoEnvironment = environmentName => {
    const { clusterName, history } = this.props;
    history.push(
      replaceUrlParams(ROUTES.environment, {
        id: RootStore.currentProject,
        clusterName,
        environmentName,
      }),
    );
  };

  render() {
    const { environments, addEnvToFavorite } = this.props;

    return (
      <div className={style.mainContainer}>
        <div className={style.tableWrapper}>
          <table>
            <thead className={style.tableHeader}>
              <tr>
                <th>
                  <FormattedMessage id="favorite" />
                </th>
                <th>
                  <FormattedMessage id="env.name" />
                </th>
                <th>
                  <FormattedMessage id="sync.state" />
                </th>
                <th>
                  <FormattedMessage id="artefact.status" />
                </th>
                <th>
                  <FormattedMessage id="runtime.status" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody ref={this.tableBodyRef}>
              {environments
                .slice()
                .sort((a, b) => Number(!!b.favorite) - Number(!!a.favorite))
                .map(environment => (
                  <EnvironmentItem
                    key={environment.name}
                    environment={environment}
                    gotoEnvironment={this.gotoEnvironment}
                    addEnvToFavorite={addEnvToFavorite}
                    tableWrapper={this.tableBodyRef.current}
                  />
                ))}
            </tbody>
          </table>
        </div>
        <div className={style.addButtonWrapper}>
          <Button
            styleType={BtnType.Add}
            className={style.addButton}
            onClick={this.openCreateEnvironmentModal}
          >
            <Icon type="plus2" />
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(ClusterEnvironmentList);
