import React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { uniqueId } from 'helpers/uniqueId';
import { ComparingState } from 'models/Environment';
import {
  VarsGroups,
  ComparingMsItemStore,
} from 'stores/ApplyMsTo/ComparingMsItemStore';

import Button, { BtnType } from 'components/Button/Button';
import { CompareEnvVar } from 'components/EnvironmentPageServicesList/Compare/CompareMsItem/CompareEnvVar';
import Icon from 'components/Icon/Icon';
import { Table } from 'components/Table/Table';
import { TableHeaderRow } from 'components/Table/TableHeaderRow';
import { TableExtendedContent } from 'components/TableExtendedContent/TableExtendedContent';
import WithPopper from '../../../WithPopper/WithPopper';
import style from 'components/EnvironmentPageServicesList/Compare/CompareMsItem/CompareMsItem.module.scss';

const varsGroupTitleMap: { [key in VarsGroups]?: string } = {
  [VarsGroups.UNMODIFIED_VARS]: 'comparing.unmodified.env.vars',
  [VarsGroups.ENV_SPECIFIC_VARS]: 'comparing.env.specific.vars',
};

interface IProps {
  microserviceStore: ComparingMsItemStore;
  isOpened: boolean;
}

export const MsModifiedDetails = observer(
  ({ microserviceStore, isOpened }: IProps) => {
    const { openedMap } = microserviceStore;
    return (
      <TableExtendedContent isOpened={isOpened}>
        <div className={style.tableActions}>
          <h2 className={style.tableTitle}>
            <FormattedMessage id="comparing.changes.in.existing.env.vars" />
          </h2>
          <Button
            styleType={BtnType.Text}
            onClick={microserviceStore.initEnvVarsForms}
            className={style.btnIcon}
          >
            <Icon type="reset" className={style.icon} />
            <FormattedMessage id="comparing.env.vars.restore.initial" />
          </Button>
        </div>
        {Object.entries(microserviceStore.groupedEnvVarsForms).map(
          ([group, envVarsForms]) => {
            const isOneValueInHeader = group === VarsGroups.UNMODIFIED_VARS;

            return (
              <div key={group} className={style.group}>
                {group !== VarsGroups.ALL_VARS && (
                  <div className={style.envVarsHeader}>
                    <h3 className={style.groupTitle}>
                      <FormattedMessage id={varsGroupTitleMap[group]} />
                    </h3>
                    {group === VarsGroups.ENV_SPECIFIC_VARS && (
                      <WithPopper
                        title={
                          <FormattedMessage
                            id={'comparing.env.specific.tooltip'}
                          />
                        }
                        className={style.iconWrapper}
                      >
                        <Icon type="info" className={style.iconInfo} />
                      </WithPopper>
                    )}
                    {group !== VarsGroups.ALL_VARS && (
                      <Button
                        styleType={BtnType.Text}
                        onClick={() =>
                          microserviceStore.toggleOpened(group as VarsGroups)
                        }
                        className={style.btnIcon}
                      >
                        <Icon
                          type={openedMap[group] ? 'arrowTop' : 'arrowBottom'}
                          className={style.iconArrow}
                        />
                      </Button>
                    )}
                  </div>
                )}
                {openedMap[group] && !!envVarsForms.length && (
                  <Table fixedLayout>
                    <thead>
                      <TableHeaderRow className={style.tableHeaderRowModified}>
                        <th className={style.nameHeader}>
                          <FormattedMessage id="comparing.env.var.name" />
                        </th>
                        {isOneValueInHeader ? (
                          <>
                            <th colSpan={2} className={style.valueHeader}>
                              <FormattedMessage id="comparing.env.var.value" />
                            </th>
                          </>
                        ) : (
                          <>
                            <th className={style.sourceValueHeader}>
                              <FormattedMessage id="comparing.env.var.value.source" />
                            </th>
                            <th className={style.targetValueHeader}>
                              <FormattedMessage id="comparing.env.var.value.target" />
                            </th>
                          </>
                        )}
                        <th className={style.inputValueHeader}>
                          <FormattedMessage id="comparing.env.var.value.be.applied" />
                        </th>
                        <th className={style.checkboxHeader}>
                          <FormattedMessage id="comparing.env.var.delete" />
                        </th>
                        <th className={style.checkboxHeader}>
                          <FormattedMessage id="comparing.env.var.env.specific" />
                        </th>
                      </TableHeaderRow>
                    </thead>
                    <tbody>
                      {!!envVarsForms.length &&
                        envVarsForms.map(envVarForm => {
                          const isShowOnlySourceValue =
                            envVarForm.envVar.envVarState ===
                            ComparingState.NOTHING_NEW;

                          return (
                            <CompareEnvVar
                              key={uniqueId()}
                              envVarForm={envVarForm}
                              isShowOnlySourceValue={isShowOnlySourceValue}
                            />
                          );
                        })}
                    </tbody>
                  </Table>
                )}
                {openedMap[group] && !envVarsForms.length && (
                  <div className={style.emptyList}>
                    <FormattedMessage id="comparing.no.env.vars" />
                  </div>
                )}
              </div>
            );
          },
        )}
      </TableExtendedContent>
    );
  },
);
