import React, { Component } from 'react';
import { isEqual } from 'lodash';

interface Props {
  myRefs: object;
  className?: string;
}

interface State {
  [key: string]: any;
}

interface Coordinates {
  left?: number;
  top?: number;
}

class FlipMove extends Component<Props, State> {
  static getCoordinates(coordinates: Coordinates): Coordinates {
    return {
      left: coordinates.left,
      top: coordinates.top,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState): Coordinates | null {
    const result: Coordinates = {};

    nextProps.children.forEach(child => {
      const domNode: Element | Text | null = nextProps.myRefs[child.key];

      if (!domNode) {
        return;
      }

      const boundingBox = (domNode as HTMLDivElement).getBoundingClientRect();

      if (!prevState[child.key]) {
        result[child.key] = {
          oldBox: FlipMove.getCoordinates(boundingBox),
          newBox: FlipMove.getCoordinates(boundingBox),
        };
      } else {
        result[child.key] = {
          oldBox: FlipMove.getCoordinates(prevState[child.key].newBox),
          newBox: FlipMove.getCoordinates(boundingBox),
        };
      }
    });

    return !isEqual(result, prevState) ? result : null;
  }

  public state = {};

  public componentDidUpdate = (prevProps): void => {
    prevProps.children.forEach(child => {
      const domNode: HTMLDivElement | null = this.props.myRefs[child.key];

      if (!domNode) {
        return;
      }

      const { oldBox, newBox } = this.state[child.key];

      const deltaX = newBox.left - oldBox.left;
      const deltaY = newBox.top - oldBox.top;

      if (deltaX > Math.abs(50) || deltaY > Math.abs(50)) {
        requestAnimationFrame(() => {
          (
            domNode as HTMLDivElement
          ).style.transform = `translate(${deltaX}px, ${deltaY}px)`;
          (domNode as HTMLDivElement).style.transition = 'transform 0s';

          requestAnimationFrame(() => {
            domNode.style.transform = '';
            domNode.style.transition = 'transform 0.2s';
            domNode.style.transitionDelay = '0.2s';
          });
        });
      }
    });
  };

  public render(): JSX.Element {
    const { className, children } = this.props;

    return <div className={className}>{children}</div>;
  }
}

export default FlipMove;
