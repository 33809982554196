import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { RawIntlProvider } from 'react-intl';

import App from './App';

import 'styles/main.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Localization from './stores/Localization';

// import * as serviceWorker from './serviceWorker';

export const localization = new Localization();

localization.init().then(() =>
  ReactDOM.render(
    <Router>
      <RawIntlProvider value={localization.intl}>
        <App />
      </RawIntlProvider>
    </Router>,
    document.getElementById('root'),
  ),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
