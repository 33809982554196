import { action, observable } from 'mobx';
import { IEnvironmentParams } from '../models/Environment';
import httpFacade, { IntervalFetch } from '../http/httpFacade';
import { IServiceParams, ServiceDetails } from 'models/Service';

export default class ServiceItemStore {
  @observable pending = true;
  @observable serviceInfo?: ServiceDetails;
  @observable params: IServiceParams;
  @observable intervalFetch: IntervalFetch = new IntervalFetch();

  constructor(params: IEnvironmentParams, name: string) {
    this.params = {
      ...params,
      serviceName: name,
    };
    this.init();
  }

  clearTimer() {
    this.intervalFetch.disable();
  }

  init = async () => {
    this.pending = true;
    this.intervalFetch.setRequest(() => this.fetchServiceItem());
    await this.intervalFetch.enable();
    this.pending = false;
  };

  @action.bound
  async fetchServiceItem() {
    try {
      const response = await httpFacade.service.fetchServiceDetails(
        this.params,
      );
      this.serviceInfo = response.data;
    } catch (error) {
      this.serviceInfo = undefined;
    }
  }
}
