import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, observable, reaction } from 'mobx';

import { isElementOverflowedById } from 'helpers/domElement';
import { localization } from '../../index';
import RootStore from 'stores/RootStore';
import TemplateVariableForm from 'stores/Forms/TemplateVariableForm';

import { bindFormControl } from 'components/Form/FormControl/FormControl';
import WithPopper from 'components/WithPopper/WithPopper';
import TextField from 'components/Form/Fields/TextField/TextField';
import Icon from 'components/Icon/Icon';

import style from 'components/MSTemplateVariablesTable/MSTemplateVariablesTable.module.scss';

interface Props {
  templateVariableItem: TemplateVariableForm;
  index: number;
  isDisabled?: boolean;
}

@observer
class MSTemplateVariablesTableItem extends Component<Props> {
  @observable
  isNameTooltipVisible = true;
  disposer;
  resizeTimeout;

  constructor(props) {
    super(props);
    this.disposer = reaction(() => RootStore.resizeEvent, this.onResize);
  }

  onResize = () => {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = undefined;
    }
    this.resizeTimeout = setTimeout(this.setTooltips, 700);
  };

  @computed
  get variableNameTooltipTitle() {
    const { templateVariableItem } = this.props;
    return this.isNameTooltipVisible ? templateVariableItem.variableName : '';
  }

  getElementId = fieldName => {
    const { index } = this.props;
    return `${fieldName}${index}`;
  };

  onVarValueChange = onChange => (value: string, e) => {
    const { templateVariableItem } = this.props;
    onChange(value, e);
    templateVariableItem.setErrorsMap('variableValue');
    templateVariableItem.validate();
  };

  setTooltips = () => {
    const isNameOverflowed = isElementOverflowedById(
      this.getElementId('variableName'),
    );
    this.isNameTooltipVisible = !(
      isNameOverflowed !== undefined && !isNameOverflowed
    );
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    this.setTooltips();
  }

  componentDidMount() {
    this.setTooltips();
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const { templateVariableItem, index, isDisabled } = this.props;
    const FormControl = bindFormControl(templateVariableItem);
    return (
      <tr>
        <td>
          <div className={style.cellContainer}>
            <WithPopper
              className={style.varTooltip}
              title={this.variableNameTooltipTitle}
            >
              <div
                className={style.inputText}
                id={this.getElementId('variableName')}
              >
                <span>{templateVariableItem.variableName}</span>
              </div>
            </WithPopper>
          </div>
        </td>
        <td>
          <div className={style.cellContainer}>
            {templateVariableItem.variableDescription && (
              <WithPopper
                className={style.varTooltip}
                title={templateVariableItem.variableDescription}
              >
                <Icon type="info" className={style.infoIcon} />
              </WithPopper>
            )}
          </div>
        </td>
        <td>
          <div className={style.cellContainer}>
            <FormControl
              withoutLabel
              className={style.variableValueInput}
              name="variableValue"
              immediateValidation={true}
              render={props => (
                <TextField
                  {...props}
                  id={'variableValue' + index}
                  placeholder={localization.formatMessage(
                    'template.variable.value',
                  )}
                  onChange={this.onVarValueChange(props.onChange)}
                  disabled={isDisabled}
                />
              )}
            />
          </div>
        </td>
      </tr>
    );
  }
}

export default MSTemplateVariablesTableItem;
