import React from 'react';
import { computed, observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import RootStore from 'stores/RootStore';
import { isElementOverflowedById } from 'helpers/domElement';

import Icon from 'components/Icon/Icon';
import WithPopper from 'components/WithPopper/WithPopper';

import style from './BranchLabel.module.scss';

interface Props {
  styles?: {
    container?: string;
    text?: string;
  };
  branches?: string[];
}

@observer
class BranchLabel extends React.Component<Props> {
  @observable
  isBranchNameTooltipVisible = true;
  disposer;
  resizeTimeout;

  @computed
  get branchNameTooltipTitle() {
    const { branches } = this.props;
    return this.isBranchNameTooltipVisible && branches ? branches[0] : '';
  }

  constructor(props) {
    super(props);
    this.disposer = reaction(() => RootStore.resizeEvent, this.onResize);
  }

  onResize = () => {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = undefined;
    }
    this.resizeTimeout = setTimeout(this.setTooltips, 700);
  };

  getElementId = fieldName => {
    const { branches } = this.props;
    const branchName = branches ? branches[0] : '';
    return `${fieldName}${branchName}`;
  };

  setTooltips = () => {
    const isBranchNameOverflowed = isElementOverflowedById(
      this.getElementId('branchName'),
    );
    this.isBranchNameTooltipVisible = !(
      isBranchNameOverflowed !== undefined && !isBranchNameOverflowed
    );
  };

  componentDidMount() {
    this.setTooltips();
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const { styles = {}, branches } = this.props;
    const classes: string = classNames(style.container, styles.container);

    return (
      <span className={classes}>
        {!branches ||
          (branches && !branches.length && (
            <div className={classNames(style.center, styles.text)}>
              <Icon type="noBranches" />
              <FormattedMessage id="no.branches" />
            </div>
          ))}
        {branches && branches.length === 1 && (
          <WithPopper
            className={style.popper}
            title={this.branchNameTooltipTitle}
            hidden={!this.isBranchNameTooltipVisible}
          >
            <div className={classNames(style.center, styles.text)}>
              <Icon type="oneBranch" />
              <span
                id={this.getElementId('branchName')}
                className={style.branchName}
              >
                {branches[0]}
              </span>
            </div>
          </WithPopper>
        )}
        {branches && branches.length > 1 && (
          <WithPopper title={branches.join(', ')} className={style.popper}>
            <div className={classNames(style.center, styles.text)}>
              <Icon type="branches" />
              <FormattedMessage
                id="branches"
                values={{ count: branches.length }}
              />
            </div>
          </WithPopper>
        )}
      </span>
    );
  }
}

export default BranchLabel;
