export const ROUTES = {
  root: '/',
  login: '/login',
  project: '/project/:id/info',
  cluster: '/project/:id/cluster/:clusterName/info',
  templates: '/project/:id/cluster/:clusterName/templates',
  environment:
    '/project/:id/cluster/:clusterName/environment/:environmentName/info',
  microservice:
    '/project/:id/cluster/:clusterName/environment/:environmentName/microservice/:microserviceName',
  podsLogs:
    '/project/:id/cluster/:clusterName/environment/:environmentName/microservice/:microserviceName/pods-logs',
};
