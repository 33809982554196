import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, reaction } from 'mobx';
import classNames from 'classnames';

import { isElementOverflowedById } from 'helpers/domElement';
import { ITemplate } from 'models/Template';
import RootStore from 'stores/RootStore';

import Icon from 'components/Icon/Icon';
import WithPopper from 'components/WithPopper/WithPopper';

import style from './TemplatesTable.module.scss';

interface Props {
  template: ITemplate;
  onViewTemplate: () => void;
  onEditTemplate: () => void;
  onDeleteTemplate: () => void;
}

@observer
class TemplatesTableItem extends Component<Props> {
  @observable isNameTooltipVisible = true;
  @observable isDescriptionTooltipVisible = true;
  disposer;
  resizeTimeout;

  constructor(props) {
    super(props);
    this.disposer = reaction(() => RootStore.resizeEvent, this.onResize);
  }

  onEdit = event => {
    event.stopPropagation();
    this.props.onEditTemplate();
  };

  onDelete = event => {
    event.stopPropagation();
    this.props.onDeleteTemplate();
  };

  onResize = () => {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = undefined;
    }
    this.resizeTimeout = setTimeout(this.setTooltips, 700);
  };

  getElementId = fieldName => {
    const { template } = this.props;
    return `${fieldName}${template.name}`;
  };

  setTooltips = () => {
    const isNameOverflowed = isElementOverflowedById(this.getElementId('name'));
    this.isNameTooltipVisible = !(
      isNameOverflowed !== undefined && !isNameOverflowed
    );
    const isDescriptionOverflowed = isElementOverflowedById(
      this.getElementId('description'),
    );
    this.isDescriptionTooltipVisible = !(
      isDescriptionOverflowed !== undefined && !isDescriptionOverflowed
    );
  };

  componentDidMount() {
    this.setTooltips();
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const { template, onViewTemplate } = this.props;

    return (
      <tr onClick={onViewTemplate} className={style.row__template}>
        <td>
          <div className={style.cellContainer}>
            <WithPopper
              className={style.popper}
              title={template.name}
              hidden={!this.isNameTooltipVisible}
            >
              <div className={style.inputText} id={this.getElementId('name')}>
                <span>{template.name}</span>
              </div>
            </WithPopper>
          </div>
        </td>
        <td>
          <div className={style.cellContainer}>
            <WithPopper
              className={classNames(style.popper, style.description)}
              title={template.description}
              hidden={!this.isDescriptionTooltipVisible}
            >
              <div
                className={style.inputText}
                id={this.getElementId('description')}
              >
                <span>{template.description}</span>
              </div>
            </WithPopper>
          </div>
        </td>
        <td>
          <div className={style.buttonsContainer}>
            <Icon
              type={'edit'}
              className={style.actionBtn}
              onClick={this.onEdit}
            />

            <Icon
              type={'deleteEmpty'}
              className={style.actionBtn}
              onClick={this.onDelete}
            />
          </div>
        </td>
      </tr>
    );
  }
}

export default TemplatesTableItem;
