import { action, observable } from 'mobx';
import httpFacade, { IntervalFetch } from 'http/httpFacade';
import { sortClusterEntities } from 'stores/helpers';
import { ClusterAction, ClusterDetails, ClusterSchedule } from 'models/Cluster';
import { Project } from 'models/Project';

export default class ClusterStore {
  @observable cluster?: ClusterDetails;
  @observable project: Project;
  @observable pending = true;
  @observable isExternalStorageAvailable: boolean;
  @observable favoriteEnv: string[] = [];
  @observable clusterSchedule: ClusterSchedule;
  @observable isDashboard: boolean = false;
  @observable intervalFetch: IntervalFetch = new IntervalFetch();

  params: { clusterName: string; projectName: string };

  constructor(
    clusterName: string,
    projectName: string,
    isDashboard: boolean = false,
  ) {
    this.isDashboard = isDashboard;
    this.init(clusterName, projectName);
  }

  @action
  clearTimer() {
    this.intervalFetch.disable();
  }

  @action
  init = async (clusterName: string, projectName: string) => {
    this.params = { clusterName, projectName };
    this.pending = true;
    this.intervalFetch.setRequest(() =>
      this.fetchCluster(this.params.projectName, this.params.clusterName),
    );
    await this.intervalFetch.enable();
    this.pending = false;
  };

  @action.bound
  async fetchCluster(projectName: string, clusterName: string) {
    try {
      const response = await httpFacade.cluster.fetchClusterDetails(
        projectName,
        clusterName,
      );
      const externalStorageStatus = await httpFacade.cluster.checkAvailability(
        projectName,
        clusterName,
      );
      if (!this.isDashboard) {
        const scheduleResponse = await httpFacade.cluster.fetchClusterSchedule(
          projectName,
          clusterName,
        );
        this.clusterSchedule = scheduleResponse.data;
      }

      this.isExternalStorageAvailable = externalStorageStatus.data.enabled;
      this.initFavoriteEnvs(response.data);
      this.initEnvsProblem(response.data);
      this.initEnvsShutdown(response.data);

      response.data.environments = sortClusterEntities(
        response.data.environments,
      );
      this.cluster = response.data;
    } catch (error) {
      this.cluster = undefined;
    }
  }

  @action
  switchCluster = async (clusterAction: ClusterAction) => {
    try {
      await httpFacade.cluster.switchCluster({
        projectName: this.params.projectName,
        clusterName: this.params.clusterName,
        action: clusterAction,
      });
    } catch (error) {
      throw error;
    }
  };

  @action
  initEnvsProblem = (cluster: ClusterDetails) => {
    cluster.environments.forEach(environment => {
      environment.isHaveProblem = !(
        environment.syncState === 'synced' &&
        environment.artifactStatus === 'uptodate' &&
        environment.runtimeState.desired === environment.runtimeState.existing
      );
    });
  };

  @action
  initEnvsShutdown = (cluster: ClusterDetails) => {
    cluster.environments.forEach(environment => {
      environment.isShutdown = !environment.runtimeState.desired;
    });
  };

  @action
  initFavoriteEnvs = (cluster: ClusterDetails) => {
    this.favoriteEnv =
      JSON.parse(
        localStorage.getItem(
          `favoriteEnv${this.params.projectName}${this.params.clusterName}`,
        ) || 'null',
      ) || [];
    cluster.environments.forEach(environment => {
      environment.favorite = Boolean(
        this.favoriteEnv.find(el => el === environment.name),
      );
    });
  };

  @action.bound
  async addEnvToFavorite(environmentName: string) {
    if (!this.cluster) {
      return;
    }

    this.cluster.environments.find(environment => {
      if (environmentName === environment.name) {
        environment.favorite = !environment.favorite;
        if (environment.favorite) {
          this.favoriteEnv.push(environmentName);
        } else {
          this.favoriteEnv = this.favoriteEnv.filter(
            el => el !== environmentName,
          );
        }
        localStorage.setItem(
          `favoriteEnv${this.params.projectName}${this.params.clusterName}`,
          JSON.stringify(this.favoriteEnv),
        );
        return environment;
      }
      return null;
    });
  }

  @action.bound
  async getDataFromLink() {
    const response = await httpFacade.cluster.fetchClusterInfo(this.params);
    const data = JSON.stringify(response.data, null, 2);
    const tab: Window | null = window.open('', '_blank');

    if (tab) {
      const preElem = document.createElement('pre');
      preElem.textContent = data;

      tab.document.body.append(preElem);
      tab.focus();
    }
  }

  @action.bound
  async fetchProject() {
    try {
      const response = await httpFacade.projects.fetchProject(
        this.params.projectName,
      );
      this.project = response.data;
    } catch (error) {
      // TODO: message
    }
  }
}
