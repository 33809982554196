import axios, { AxiosError } from 'axios';

import ModalStore from 'stores/ModalStore';
import RootStore from 'stores/RootStore';
import { isAuthTokenExpired, isAuthRequest } from './helpers';
import NotificationService from 'components/Notification/NotificationService';

export const setAuthInterceptor = (config: any) => {
  const { user } = RootStore;
  const tokens = user.getAuthTokens();

  if (tokens.accessToken) {
    config.headers.Authorization = `Bearer ${tokens.accessToken}`;
  } else {
    delete config.headers.Authorization;
  }

  return config;
};

export const updateAuthInterceptor = async (error: AxiosError) => {
  const { refreshTokens, getAuthTokens } = RootStore.user;
  const { response } = error;
  const { refreshToken } = getAuthTokens();

  if (
    refreshToken &&
    response &&
    response.status === 401 &&
    !isAuthRequest(response.config.url) &&
    isAuthTokenExpired(response.headers)
  ) {
    try {
      await refreshTokens(refreshToken);
      setAuthInterceptor(response.config);

      return axios.request(response.config);
    } catch (refreshErr) {
      RootStore.reset();
      return Promise.reject(refreshErr);
    }
  }

  return Promise.reject(error);
};

export function unauthorizedInterceptor(error: AxiosError) {
  const { response } = error;

  if (response && response.status === 401) {
    RootStore.reset();
  }

  return Promise.reject(error);
}

export function internalServerErrorInterceptor(error: AxiosError) {
  const { response } = error;

  if (response && response.status >= 500) {
    NotificationService.errorMessage(
      { id: '500.error' },
      {
        toastId: '500.error',
      },
    );
  }

  return Promise.reject(error);
}

export function accessDeniedErrorInterceptor(error: AxiosError) {
  const { response } = error;

  if (
    response &&
    response.status === 403 &&
    response.status < 500 &&
    response.config.method &&
    response.config.method.toUpperCase() === 'GET'
  ) {
    ModalStore.closeAllModal();
    NotificationService.errorMessage({ id: '403.error' });
  }

  return Promise.reject(error);
}
