import React, { ReactNode } from 'react';
import classNames from 'classnames';

import style from 'components/Table/Table.module.scss';

interface IProps {
  children: ReactNode;
  fixedLayout?: boolean;
}

export const Table = ({ children, fixedLayout }: IProps) => {
  return (
    <table className={classNames(style.table, fixedLayout && style.fixedTable)}>
      {children}
    </table>
  );
};
