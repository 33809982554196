import React, { MutableRefObject } from 'react';

export const useClickOutside = (
  ref: MutableRefObject<HTMLElement | null>,
  callback: any,
  condition: boolean = true,
) => {
  React.useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (
        ref.current &&
        !ref.current.contains(target) &&
        condition &&
        callback
      ) {
        callback();
      }
    };

    document.addEventListener('click', handleClick, {
      capture: true,
    });
    return () => {
      document.removeEventListener('click', handleClick, {
        capture: true,
      });
    };
  }, [ref, callback, condition]);
};
