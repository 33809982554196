import { action, observable } from 'mobx';
import httpFacade, { IntervalFetch } from 'http/httpFacade';
import {
  IServiceParams,
  ServiceDetails,
  ServiceUpdate,
  MsStorageProviderDetails,
  MsStorageStatusEnum,
  IEnvVariable,
} from 'models/Service';
import NotificationService, {
  fullscreenNotificationOptions,
} from 'components/Notification/NotificationService';
import { StorageProviderInstance } from 'models/ExternalStorage';
import { sortByName } from 'helpers/array';

export default class ServiceStore {
  @observable pending = true;
  @observable service?: ServiceDetails;
  @observable externalStorageStatus: boolean = false;
  @observable serviceProviders: MsStorageProviderDetails[] = [];
  @observable providerInstances: StorageProviderInstance[] = [];
  @observable envVariables: IEnvVariable[] = [];
  params: IServiceParams;
  @observable intervalFetch: IntervalFetch = new IntervalFetch();

  constructor(params: IServiceParams) {
    this.params = params;
    this.init();
  }

  clearTimer() {
    this.intervalFetch.disable();
  }

  init = async () => {
    this.pending = true;
    this.intervalFetch.setRequest(() => this.fetchData());
    await this.intervalFetch.enable();
    this.pending = false;
  };

  @action
  async fetchData() {
    await Promise.all([this.fetchService(), this.fetchExternalStorageStatus()]);
  }

  @action.bound
  async fetchExternalStorageStatus() {
    try {
      const response = await httpFacade.cluster.checkAvailability(
        this.params.projectName,
        this.params.clusterName,
      );
      this.externalStorageStatus = response.data.enabled;
      if (this.externalStorageStatus) {
        this.fetchServiceProviders();
        this.fetchProvidersInstances();
      }
    } catch (error) {
      this.externalStorageStatus = false;
    }
  }

  @action.bound
  async fetchEnvVariables() {
    try {
      const response = await httpFacade.service.fetchServiceEnvVariables(
        this.params,
      );
      this.envVariables = sortByName([...response.data], false);
    } catch (error) {
      this.envVariables = [];
    }
  }

  @action.bound
  async updateEnvVariables(envVars: IEnvVariable[]) {
    try {
      await httpFacade.service.updateServiceEnvVariables(this.params, envVars);
      NotificationService.successMessage(
        { id: 'env.var.success.notification' },
        fullscreenNotificationOptions,
      );
    } catch (error) {
      const errorString =
        error.response.data.id || 'env.var.error.notification';
      NotificationService.errorMessage(
        { id: errorString },
        fullscreenNotificationOptions,
      );
      if (
        error.response.data.id ||
        (error.response.data.type &&
          error.response.data.type.toLowerCase() === 'name')
      ) {
        throw Error(errorString);
      }
    }
    await this.fetchEnvVariables();
  }

  @action
  initProblem = (storageSupports: MsStorageProviderDetails[]) => {
    storageSupports.forEach(storageSupport => {
      storageSupport.isHaveProblem =
        storageSupport.status !== MsStorageStatusEnum.SUCCESSFUL;
    });
  };

  @action
  async fetchProvidersInstances() {
    try {
      const response = await httpFacade.cluster.fetchClusterProviderInstances(
        this.params.projectName,
        this.params.clusterName,
      );
      this.providerInstances = response.data;
    } catch (error) {
      this.providerInstances = [];
    }
  }

  @action.bound
  async fetchServiceProviders() {
    try {
      const response = await httpFacade.service.fetchServiceStorageDetails(
        this.params,
      );
      this.initProblem(response.data);
      response.data.sort((a, b) => {
        return (
          +b.isHaveProblem - +a.isHaveProblem ||
          +(b.createDate ? new Date(b.createDate) : new Date()) -
            +(a.createDate ? new Date(a.createDate) : new Date())
        );
      });
      this.serviceProviders = response.data;
    } catch (error) {
      this.serviceProviders = [];
    }
  }

  @action.bound
  async fetchService() {
    try {
      const response = await httpFacade.service.fetchServiceDetails(
        this.params,
      );
      response.data.others.forEach(other => {
        other.isHaveProblem = other.syncState !== 'synced';
      });
      sortByName(response.data.others);
      response.data.others.sort((a, b) => {
        return +b.isHaveProblem - +a.isHaveProblem;
      });

      this.service = response.data;
    } catch (error) {
      this.service = undefined;
    }
  }

  @action.bound
  async deleteMicroservice(onDelete: () => void) {
    await httpFacade.service.deleteService(this.params);
    onDelete();
    NotificationService.successMessage(
      { id: 'service.delete.success' },
      {
        ...fullscreenNotificationOptions,
        icon: 'crossTrash',
      },
    );
  }

  @action.bound
  async updateMicroservice(data: ServiceUpdate) {
    try {
      await httpFacade.service.updateService(this.params, data);
    } catch (e) {
      throw e;
    }
  }

  @action.bound
  async restartMicroservice() {
    try {
      await httpFacade.service.restartService(this.params);
      NotificationService.successMessage(
        { id: 'service.restart.success.message' },
        fullscreenNotificationOptions,
      );
    } catch (e) {
      NotificationService.errorMessage(
        { id: 'service.restart.error.message' },
        fullscreenNotificationOptions,
      );
    }
  }
}
