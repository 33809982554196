import React, { useState } from 'react';
import classNames from 'classnames';

import { useClickOutside } from 'hooks/useClickOutside';

import { IActionItem } from 'components/ActionMenu/types';
import Icon from 'components/Icon/Icon';
import { ActionMenuItem } from 'components/ActionMenu/ActionMenuItem/ActionMenuItem';

import style from './ActionMenu.module.scss';

export interface IProps {
  items: IActionItem[];
  openMenuComponent?: (
    toggleList: () => void,
    isOpened: boolean,
  ) => JSX.Element;
  styles?: {
    wrapper?: string;
    menu?: string;
    menuItem?: string;
  };
}

const ActionMenu = ({ items, openMenuComponent, styles = {} }: IProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const wrapperRef = React.createRef<HTMLDivElement>();
  useClickOutside(wrapperRef, () => setIsOpened(false));

  const toggleList = () => {
    setIsOpened(!isOpened);
  };

  const onAction = handler => () => {
    setIsOpened(false);
    handler();
  };

  return (
    <div
      ref={wrapperRef}
      onClick={e => e.stopPropagation()}
      className={classNames(style.mainContainer, styles.wrapper)}
    >
      {openMenuComponent ? (
        openMenuComponent(toggleList, isOpened)
      ) : (
        <Icon
          className={classNames(style.openMenuIcon, isOpened && style.opened)}
          type="menuDots"
          onClick={toggleList}
        />
      )}
      {isOpened && (
        <ul className={classNames(style.menu, styles.menu)}>
          {items.map((item, index) => (
            <ActionMenuItem
              key={index}
              action={item}
              onAction={onAction(item.action)}
              className={styles.menuItem}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

export default ActionMenu;
