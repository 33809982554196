import { action, computed, observable } from 'mobx';
import { ComparingState, IComparingMicroservice } from 'models/Environment';
import { ComparingEnvVarsForm } from 'stores/ApplyMsTo/ComparingEnvVarsForm';
import { HostForm } from './HostForm';

export enum VarsGroups {
  ALL_VARS = 'ALL_VARS',
  UNMODIFIED_VARS = 'UNMODIFIED_VARS',
  ENV_SPECIFIC_VARS = 'ENV_SPECIFIC_VARS',
}

export class ComparingMsItemStore {
  @observable isChecked: boolean = false;
  @observable isOpened = false;
  @observable deleted: boolean = false;
  @observable envVarsForms: ComparingEnvVarsForm[] = [];
  @observable hostsForms: HostForm[];
  @observable
  openedMap = {
    ALL_VARS: true,
    UNMODIFIED_VARS: true,
    ENV_SPECIFIC_VARS: false,
  };

  @observable microservice: IComparingMicroservice;

  constructor(microservice: IComparingMicroservice) {
    this.microservice = microservice;
    if (microservice.msState === ComparingState.NOTHING_NEW) {
      this.setIsChecked(true);
    }
    this.initEnvVarsForms();
    if (
      microservice.hostsSource?.length &&
      microservice.msState === ComparingState.NEW
    ) {
      this.initHostsForms();
    }
  }

  @computed
  get groupedEnvVarsForms(): Record<VarsGroups, ComparingEnvVarsForm[]> {
    const initialData = {
      [VarsGroups.ALL_VARS]: [] as ComparingEnvVarsForm[],
      [VarsGroups.UNMODIFIED_VARS]: [] as ComparingEnvVarsForm[],
      [VarsGroups.ENV_SPECIFIC_VARS]: [] as ComparingEnvVarsForm[],
    };

    return this.envVarsForms.reduce((acc, form) => {
      if (form.envVar.envSpecific) {
        acc[VarsGroups.ENV_SPECIFIC_VARS].push(form);
        return acc;
      }
      if (form.envVar.envVarState === ComparingState.NOTHING_NEW) {
        acc[VarsGroups.UNMODIFIED_VARS].push(form);
        return acc;
      }
      acc[VarsGroups.ALL_VARS].push(form);
      return acc;
    }, initialData);
  }

  @computed
  get isValid(): boolean {
    if (this.microservice.msState === ComparingState.DELETED) {
      return true;
    }
    const isInvalid = this.envVarsForms.some(
      form =>
        (form.value && !form.name) ||
        (!form.value && form.name && !form.deleted),
    );
    return (
      !isInvalid &&
      this.envVarsForms.every(form => form.isFormValid) &&
      (this.hostsForms?.length
        ? this.hostsForms.every(form => form.isFormValid)
        : true)
    );
  }

  @action
  initEnvVarsForms = () => {
    this.envVarsForms = this.microservice.envVars
      .slice()
      .sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      })
      .map(
        envVar =>
          new ComparingEnvVarsForm({
            ...envVar,
            isAddedByUser: false,
            msState: this.microservice.msState,
          }),
      );
  };

  @action
  initHostsForms = () => {
    if (this.microservice.hostsSource?.length) {
      this.hostsForms = this.microservice.hostsSource.map(
        host => new HostForm(host),
      );
    }
  };

  restoreInitialForms = () => {
    this.initEnvVarsForms();
    this.initHostsForms();
  };

  @action
  addNewEnvVar = () => {
    this.envVarsForms.forEach(form => {
      if (form.envVar.isAddedByUser) {
        form.checkRequiredValid(true);
        form.checkRequiredValid();
      }
    });
    this.envVarsForms.push(
      new ComparingEnvVarsForm({
        name: '',
        envSpecific: false,
        envVarState: ComparingState.NEW,
        isAddedByUser: true,
        msState: this.microservice.msState,
      }),
    );
  };

  @action
  deleteEnvVar = (index: number) => {
    this.envVarsForms = this.envVarsForms.filter(
      (form, formIndex) => index !== formIndex,
    );
  };

  @action
  toggleChecked = () => {
    this.isChecked = !this.isChecked;
  };

  @action
  setIsChecked = (isChecked: boolean) => {
    this.isChecked = isChecked;
  };

  @action
  toggleDelete = () => {
    this.deleted = !this.deleted;
    this.setIsChecked(true);
  };

  @action
  toggleMSOpened = () => {
    this.isOpened = !this.isOpened;
  };

  @action
  closeMS = () => {
    this.isOpened = false;
  };

  checkEnvNameUniq = () => {
    const allVarNames = this.envVarsForms.map(form => form.name);
    this.envVarsForms.forEach((form, index) => {
      const name = form.name;
      if (!form.validated) {
        return;
      }
      if (
        allVarNames
          .filter((item, itemIndex) => index !== itemIndex)
          .some(varName => varName.length && name === varName)
      ) {
        form.setNameUniq(false);
        form.setErrorsMap('name');
      } else {
        form.setNameUniq(true);
        form.setErrorsMap('name');
      }
    });
  };

  toggleOpened = (group: VarsGroups) => {
    this.openedMap[group] = !this.openedMap[group];
  };
}
