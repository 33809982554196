import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { stringToDate, stringToTime } from 'helpers/datetime';
import { PodPhase, PodSyncStatus, UNSCHEDULABLE_ERROR } from 'models/Pod';
import { PodRuntimeStatus } from 'models/StatusTypes';
import { PodsStore } from 'stores/PodsPage/PodsStore';

import BorderedText, {
  BorderedTextType,
} from 'components/BorderedText/BorderedText';
import Button, { BtnType } from 'components/Button/Button';
import EmptyContent from 'components/EmptyContent/EmptyContent';
import Icon from 'components/Icon/Icon';
import { OverflowedText } from 'components/OverflowedText/OverflowedText';
import { PodStatusCube } from 'components/PodStatusCube/PodStatusCube';
import Status, { StatusType } from 'components/Status/Status';
import ContainersSection from 'pages/PodsPage/ContainersSection/ContainersSection';
import { PodsTableHeader } from 'pages/PodsPage/PodsTable/PodsTableHeader';

import style from './PodsTable.module.scss';

export const podPhasesToStatusType: Record<PodPhase, StatusType> = {
  [PodPhase.Failed]: StatusType.Error,
  [PodPhase.Unknown]: StatusType.Error,
  [PodPhase.Pending]: StatusType.Info,
  [PodPhase.Running]: StatusType.Success,
  [PodPhase.Succeeded]: StatusType.Success,
};

export const podSyncStatusesToStatusType: Record<PodSyncStatus, StatusType> = {
  [PodSyncStatus.Diff]: StatusType.Error,
  [PodSyncStatus.Synced]: StatusType.Success,
};

const runtimeStatusToBorderedTextType: Record<
  PodRuntimeStatus,
  BorderedTextType
> = {
  [PodRuntimeStatus.Error]: BorderedTextType.Error,
  [PodRuntimeStatus.Warning]: BorderedTextType.Warning,
  [PodRuntimeStatus.Initialization]: BorderedTextType.Info,
  [PodRuntimeStatus.Alive]: BorderedTextType.Info,
};

interface IProps {
  podsStore: PodsStore;
}

@observer
class PodsTable extends Component<IProps> {
  @observable podsList = {};
  @observable containersList = {};

  @action
  togglePod = podKey => () => {
    const isPodOpened = this.podsList[podKey];
    this.podsList = {
      ...this.podsList,
      [podKey]: !isPodOpened,
    };
    if (isPodOpened) {
      this.closePodContainers(podKey);
    }
  };

  @action
  toggleContainer = key => () => {
    this.containersList = {
      ...this.containersList,
      [key]: !this.containersList[key],
    };
  };

  @action
  closePodContainers = podKey => {
    Object.keys(this.containersList).forEach(containerKey => {
      if (containerKey.startsWith(podKey)) {
        this.containersList[containerKey] = false;
      }
    });
  };

  updatePods = async () => {
    await this.props.podsStore.fetchServicePods();
  };

  @action
  onRefresh = async () => {
    this.podsList = {};
    this.containersList = {};
    await this.updatePods();
  };

  async componentDidMount() {
    await this.props.podsStore.fetchServicePods();
  }

  render() {
    const { podsStore } = this.props;
    const hasPods = !!podsStore.pods.length;

    return (
      <div className={style.mainContainer}>
        {hasPods && (
          <>
            <Button
              styleType={BtnType.Text}
              className={style.resetBtn}
              circleLoaderClassName={style.resetBtn__loader}
              onClick={this.updatePods}
              isLoading={podsStore.isLoading}
              disabled={podsStore.isLoading}
            >
              <Icon type="reset" className={style.btnIcon} />
              <FormattedMessage id="button.refresh" />
            </Button>

            <table className={style.table}>
              <PodsTableHeader />
              {!podsStore.isLoading && (
                <tbody>
                  {podsStore.sortedPods.map(pod => {
                    const podIsOpen = this.podsList[pod.podName];
                    const podRuntimeStatus = pod.runtimeStatus;
                    const errorMessage =
                      podRuntimeStatus.reason && podRuntimeStatus.message
                        ? `${podRuntimeStatus.reason}: ${podRuntimeStatus.message}`
                        : podRuntimeStatus.reason || podRuntimeStatus.message;

                    return (
                      <React.Fragment key={pod.podName}>
                        <tr
                          className={classNames(style.rowContainer, {
                            [style.podsListIsOpen]: podIsOpen,
                          })}
                        >
                          <td>
                            <div className={style.cellContainer}>
                              <span
                                className={classNames(
                                  style.firstTitle,
                                  style.podTitle,
                                )}
                              >
                                <PodStatusCube
                                  podStatusInfo={{
                                    podName: pod.podName,
                                    runtimeStatus:
                                      podRuntimeStatus.runtimeStatus,
                                    outdated: pod.outdated,
                                  }}
                                  className={style.podStatusCube}
                                />
                                <OverflowedText
                                  title={pod.podName}
                                  styles={{ popper: style.popper }}
                                />
                              </span>
                              <span className={style.firstTitle}>
                                {pod.createDate && (
                                  <BorderedText
                                    className={style.borderedTextWrapper}
                                  >
                                    <FormattedMessage
                                      id="pod.started"
                                      values={{
                                        dateTime: `${stringToDate(
                                          pod.createDate,
                                        )} ${stringToTime(
                                          pod.createDate,
                                          true,
                                        )}`,
                                      }}
                                    />
                                  </BorderedText>
                                )}
                                {errorMessage && (
                                  <BorderedText
                                    type={
                                      runtimeStatusToBorderedTextType[
                                        podRuntimeStatus.runtimeStatus
                                      ]
                                    }
                                    className={style.borderedText}
                                  >
                                    <OverflowedText title={errorMessage} />
                                  </BorderedText>
                                )}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className={style.cellContainer}>
                              <span className={style.firstTitle}>
                                <Status
                                  statusType={
                                    podSyncStatusesToStatusType[pod.syncStatus]
                                  }
                                  className={style.status}
                                />
                                <span className={style.statusText}>
                                  {pod.syncStatus}
                                </span>
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className={style.cellContainer}>
                              <span className={style.firstTitle}>
                                <Status
                                  statusType={podPhasesToStatusType[pod.phase]}
                                  className={style.status}
                                />
                                <span className={style.statusText}>
                                  {pod.phase}
                                </span>
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className={style.cellContainer}>
                              <span className={style.firstTitle}>
                                {pod.restartCount}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className={style.cellContainer}>
                              <span className={style.firstTitle}>
                                {pod.runtimeStatus.goodContainers}/
                                {pod.runtimeStatus.totalContainers}
                              </span>
                            </div>
                          </td>
                          <td className={style.cellArrow}>
                            <Icon
                              className={style.iconArrowPod}
                              type={podIsOpen ? 'arrowTop' : 'arrowBottom'}
                              onClick={this.togglePod(pod.podName)}
                            />
                          </td>
                        </tr>
                        {podIsOpen && (
                          <ContainersSection
                            podParams={{
                              ...podsStore.serviceParams,
                              podName: pod.podName,
                            }}
                            containersList={this.containersList}
                            toggleContainer={this.toggleContainer}
                            isUnschedulableError={
                              pod.runtimeStatus.reason === UNSCHEDULABLE_ERROR
                            }
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              )}
            </table>
          </>
        )}
        {!hasPods && podsStore.podsRequested && (
          <div className={style.emptyPods}>
            <EmptyContent
              icon="emptyProviderList"
              text="pods.cannot.receive"
              className={style.emptyContent}
              messageClassName={style.emptyContentPods__message}
            />
            <Button
              className={style.button__refresh}
              onClick={this.onRefresh}
              isLoading={podsStore.isLoading}
            >
              <Icon type="reset" className={style.btnIcon} />
              <FormattedMessage id="button.refresh" />
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default PodsTable;
