import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import style from './Project.module.scss';

import { ProjectStore } from 'stores/ProjectStore';
import RootStore from 'stores/RootStore';
import { Cluster } from 'models/Cluster';

import ClusterContainer from './ClusterContainer/ClusterContainer';
import FlipMove from 'components/FlipMove/FlipMove';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { SystemVersion } from 'components/SystemVersion/SystemVersion';

interface Props extends RouteComponentProps<{ id?: string }> {
  alertsStore?;
}

interface State {
  [key: string]: any;
}

@inject('alertsStore')
@observer
class Project extends Component<Props, State> {
  public state: State = {};
  public myRefs = {};
  constructor(props: Props) {
    super(props);
    this.state = { projectStore: new ProjectStore(RootStore.currentProject) };
  }

  public componentDidMount = (): void => {
    this.setState({
      projectStore: this.state.projectStore,
      clusters: this.state.projectStore.clusterList,
    });
  };

  public componentDidUpdate = (prevProps): void => {
    const prevProject = prevProps.match.params.id;
    const newProject = this.props.match.params.id;

    if (
      prevProps.match.params &&
      this.props.match.params &&
      prevProject !== newProject
    ) {
      RootStore.currentProject = newProject || ''; // to support auth on direct links
      this.setState({ projectStore: new ProjectStore(newProject || '') });
    }
  };

  public changeClusterState = (clusterName: string, tabName: string): void => {
    const key = `${clusterName}_${tabName}_collapsed`;
    const value = !this.state[key];

    this.setState({ [key]: value });
  };

  public render(): JSX.Element | string {
    if (this.state.projectStore.pending) {
      return '';
    }

    const { clusterList, projectName } = this.state.projectStore;

    return (
      <FlipMove
        className={classNames(style.cardListWrapper, {
          [style.cardListWrapper__openedAlert]: this.props.alertsStore.isOpen,
        })}
        myRefs={this.myRefs}
      >
        <div className={style.sectionTitle} data-project-header>
          <h1>
            <FormattedMessage id="page.project.dashboard" />
          </h1>
          <SystemVersion />
        </div>
        <div className={style.cardList}>
          {clusterList.map((cluster: Cluster) => (
            <ClusterContainer
              ref={ref => {
                this.myRefs[cluster.name] = ref;
                return true;
              }}
              key={cluster.name}
              name={cluster.name}
              projectName={projectName}
              changeRootState={tabName =>
                this.changeClusterState(cluster.name, tabName)
              }
              history={this.props.history}
            />
          ))}
        </div>
      </FlipMove>
    );
  }
}

export default Project;
