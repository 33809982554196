import React from 'react';
import { FormattedMessage } from 'react-intl';

import ActionMenu from 'components/ActionMenu/ActionMenu';
import { IActionItem } from 'components/ActionMenu/types';
import Button, { BtnSize, BtnType } from 'components/Button/Button';

import style from './ExternalLinksMenu.module.scss';

interface Props {
  links: any;
}

const ExternalLinksMenu = ({ links }: Props) => {
  const onClick = link => () => {
    window.open(link);
  };

  const items: IActionItem[] = links.map(link => {
    const isDisabled = link.name === 'link.monitoring';

    return {
      action: onClick(link.path),
      label: <FormattedMessage id={link.name} />,
      icon: link.icon,
      disabled: isDisabled,
      popper: isDisabled ? 'under.development' : '',
    };
  });

  return (
    <ActionMenu
      items={items}
      openMenuComponent={toggleList => (
        <div className={style.openMenuBtnWrapper}>
          <FormattedMessage id="cluster.header.external.links" />
          <Button
            className={style.btn}
            size={BtnSize.ExtraTiny}
            onClick={toggleList}
            styleType={BtnType.Secondary}
          >
            <FormattedMessage id="cluster.header.view" />
          </Button>
        </div>
      )}
      styles={{
        wrapper: style.menuWrapper,
        menu: style.menu,
        menuItem: style.menuItem,
      }}
    />
  );
};

export default ExternalLinksMenu;
