import { DialogActionType, ModalProps } from 'stores/ModalStore';
import { observable, computed } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import style from './CopyFromEnvModal.module.scss';
import CommonModal from 'components/Modals/Modal/CommonModal';
import CopyFromEnvForm from './CopyFromEnvForm';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import { FormattedMessage } from 'react-intl';
import SelectWithSearch from 'components/Form/Fields/SelectWithSearch/SelectWithSearch';

interface Props {
  projectName: string;
  clusterName: string;
  environmentName: string;
  microserviceName: string;
  providerInstanceName: string;
}

@observer
class CopyFromEnvModal extends Component<Props & ModalProps> {
  @observable form = new CopyFromEnvForm();
  FormControl = bindFormControl(this.form);

  @computed
  get isSubmitBtnDisabled() {
    return !this.form.isFormValid;
  }

  @observable
  serverErrors: string[] = [];

  @observable
  serverWarnings: string[] = [];

  @observable
  isUIblocked: boolean = false;

  onClose = async () => {
    this.props.onClose(DialogActionType.cancel);
  };

  componentDidMount() {
    return this.form.fetchClusterProviderInstancesUsage(
      this.props.projectName,
      this.props.clusterName,
      this.props.providerInstanceName,
    );
  }

  onEnvChange = value => {
    this.form.setValue('environmetName', value);
    this.form.setValue('microserviceName', undefined);
  };

  onConfirm = async () => {
    const isFormValid = this.form.validate();
    if (isFormValid) {
      this.form.validated = false;
      try {
        this.isUIblocked = true;
        await this.form.submit(this.props);
        this.serverErrors = [];
        this.serverWarnings = [];
        this.props.onClose(DialogActionType.submit);
      } catch (error) {
        this.serverErrors = error.response.data.errorMessages || [];
        this.serverWarnings = error.response.data.warningMessages || [];
      } finally {
        this.isUIblocked = false;
      }
    }
  };

  render() {
    const FormControl = this.FormControl;
    return (
      <CommonModal
        bodyClassName={style.body}
        bodyContentClassName={style.bodyContent}
        title="copy.database"
        confirm="button.copy"
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        isSubmitBtnDisabled={!this.form.isFormValid}
        isUIblocked={this.isUIblocked}
      >
        <div className={style.text}>
          <FormattedMessage id="ms.support.copy.from.env.text" />
        </div>
        <div className={style.formControlWrapper}>
          <FormControl
            withoutLabel
            className={style.input}
            name="environmetName"
            render={props => (
              <SelectWithSearch
                {...props}
                label={'ms.support.copy.from.env.label.environment'}
                options={this.form.envOptions}
                onChange={this.onEnvChange}
                menuMaxHeight={'160px'}
              />
            )}
          />
        </div>
        <div className={style.formControlWrapper}>
          <FormControl
            withoutLabel
            className={style.input}
            name="microserviceName"
            render={props => (
              <SelectWithSearch
                {...props}
                label={'ms.support.copy.from.env.label.microservice'}
                options={this.form.serviceOptions}
                disabled={!this.form.environmetName}
                menuMaxHeight={'160px'}
              />
            )}
          />
        </div>
      </CommonModal>
    );
  }
}

export default CopyFromEnvModal;
