import React from 'react';
import style from 'components/ModalLayout/ModalLayout.module.scss';

interface IProps {
  children: React.ReactNode;
}

export const ModalLayoutActions = ({ children }: IProps) => {
  return <div className={style.actions}>{children}</div>;
};
