export function groupBy<T extends {}, K extends keyof T>(
  arr: T[],
  propsName: K,
): Record<string, T[]> {
  return arr.reduce(
    (a, v) => ({
      ...a,
      [v[propsName as string]]:
        v[propsName as string] in a ? [...a[v[propsName as string]], v] : [v],
    }),
    {},
  );
}

export function toPairs<T>(data: Record<string, T>): Array<[string, T]> {
  return Object.keys(data).map(key => [key, data[key]]);
}

export function hasDuplicate(data: any[]): boolean {
  return data.some((v, i) => data.slice(i + 1).includes(v));
}

export const sortByLongName = arr => {
  return arr.sort((a, b) => {
    if (a.longName < b.longName) {
      return -1;
    }
    if (a.longName > b.longName) {
      return 1;
    }
    return 0;
  });
};

export const sortByName = (arr, caseSensitive = true) => {
  return arr.sort((a, b) => {
    let valueA = a.name;
    let valueB = b.name;

    if (!caseSensitive) {
      valueA = valueA.toLowerCase();
      valueB = valueB.toLowerCase();
    }

    return valueA > valueB ? 1 : -1;
  });
};

export const sortByField = <T>(arr: T[], field, reverse?) => {
  return arr.sort((a, b) => {
    if (a[field] < b[field]) {
      return reverse ? 1 : -1;
    }
    if (a[field] > b[field]) {
      return reverse ? -1 : 1;
    }
    return 0;
  });
};

export const getSortByObjectPropsFunc = obj => {
  return (a, b) => {
    if (!obj[a] && !obj[b]) {
      return 0;
    }
    if (obj[a] < obj[b] || !obj[b]) {
      return -1;
    }
    if (obj[a] > obj[b] || !obj[a]) {
      return 1;
    }
    return 0;
  };
};

export const sortByIssueId = (a, b) => {
  const first = Number(a.id.match(/\d+/)[0] || 0);
  const second = Number(b.id.match(/\d+/)[0] || 0);
  return first - second;
};

export const splitArrayToChunks = <T>(array: T[], chunkSize: number): T[][] => {
  const chunks: T[][] = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunks.push(chunk);
  }

  return chunks;
};
