import { action, observable } from 'mobx';

import { localizationApi as localization } from './Api/localization';
import { rootApi as root } from './Api/root';
import { userApi as user } from './Api/User/user';
import { projectsApi as projects } from './Api/projects';
import { clusterApi as cluster } from './Api/cluster';
import { environmentApi as environment } from './Api/environment';
import { serviceApi as service } from './Api/service';
import { alertsApi as alerts } from './Api/alerts';
import { templatesApi as templates } from './Api/templates';
import { podsApi as pods } from './Api/pods';

export const FETCH_DATA_INTERVAL = 1 * 1000;

export class IntervalFetch {
  timerId: ReturnType<typeof setTimeout>;
  @observable active: boolean = true;
  @observable request: () => Promise<any> = async () => undefined;

  @action
  setRequest = request => {
    this.request = request;
  };

  @action
  fetch = async () => {
    if (this.active) {
      try {
        await this.request();
      } catch (e) {
        throw e;
      } finally {
        this.timerId = setTimeout(this.fetch, FETCH_DATA_INTERVAL);
      }
    }
  };

  @action
  disable = () => {
    this.active = false;
    clearTimeout(this.timerId);
  };

  @action
  enable = async () => {
    this.active = true;
    await this.fetch();
  };
}

const httpFacade = {
  localization,
  root,
  user,
  projects,
  cluster,
  environment,
  service,
  alerts,
  templates,
  pods,
};

export default httpFacade;
