import { action, observable } from 'mobx';

import UserStore from './UserStore';

import httpFacade from 'http/httpFacade';
import Log from 'helpers/log';

import ThemeService from 'services/ThemeService';
import UserService from 'services/UserService';
import { Cluster } from 'models/Cluster';
import ModalStore from './ModalStore';

interface Config {
  appURL: string;
}

class RootStore {
  @observable user = new UserStore();
  @observable config: Config;
  @observable isLoading = true;
  @observable accessDeniedError = false;
  @observable currentProject = '';
  @observable projects: string[] = [];
  @observable clusterList: Cluster[] = [];
  @observable resizeEvent = false;

  @action
  onResize = () => {
    this.resizeEvent = !this.resizeEvent;
  };

  @action.bound
  async init() {
    ThemeService.init();
    UserService.init();

    this.isLoading = true;

    try {
      await this.fetchConfig();
      await this.fetchProjects();
    } catch (error) {
      Log.error('initialization error');
    }

    this.isLoading = false;
  }

  @action
  async fetchConfig() {
    try {
      const response = await httpFacade.root.fetchConfig();

      this.config = {
        appURL: response.data.appURL,
      };
    } catch (error) {
      Log.error('fetchConfig config error');
    }
  }

  @action.bound
  async fetchProjects() {
    try {
      const response = await httpFacade.projects.fetchProjects();
      this.projects = response.data;
    } catch (error) {
      this.projects = [];
    }
  }

  @action.bound
  setClusterList(list: Cluster[]) {
    this.clusterList = list;
  }

  @action.bound
  reset() {
    this.user.reset();
    this.currentProject = '';
    ModalStore.closeAllModal();
  }
}

export default new RootStore();
