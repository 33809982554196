import { action, observable } from 'mobx';
import { BaseFormModel, displayName, isSameAs } from 'stores/BaseForm';
import httpFacade from 'http/httpFacade';
import NotificationService, {
  fullscreenNotificationOptions,
} from '../Notification/NotificationService';

class DeleteEnvironmentForm extends BaseFormModel {
  @observable
  providerInstanceNameExample = '';

  @observable
  @displayName('instance.provider.modal.provider.name')
  @isSameAs('providerInstanceNameExample', 'wrong.provider.instance.msg')
  providerInstanceName: string = '';

  @action
  setProviderInstanceNameExample = name => {
    this.providerInstanceNameExample = name;
  };

  @action.bound
  async deleteProviderInstance(params) {
    const isFormValid = this.validate();
    if (isFormValid) {
      const { data } = await httpFacade.service.deleteServiceProviderInstance({
        ...params,
      });
      NotificationService.successMessage(
        {
          id: 'instance.provider.support.delete.success',
          values: { microserviceName: data.serviceName },
        },
        fullscreenNotificationOptions,
      );
    }
  }
}

export default DeleteEnvironmentForm;
