import React from 'react';
import classNames from 'classnames';
import style from './CircleLoader.module.scss';

export enum SpinnerType {
  Default = 'default',
  Problem = 'problem',
  BtnLoading = 'btnLoading',
  Big = 'big',
}

interface Props {
  className?: string;
  styleType?: SpinnerType;
}

const CircleLoader: React.FC<Props> = ({
  className,
  styleType = SpinnerType.Default,
}) => {
  return (
    <div
      className={classNames(
        style.spinner,
        {
          [style.default]: styleType === SpinnerType.Default,
          [style.isProblem]: styleType === SpinnerType.Problem,
          [style.btnSpinner]: styleType === SpinnerType.BtnLoading,
          [style.big]: styleType === SpinnerType.Big,
        },
        className,
      )}
    >
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default CircleLoader;
