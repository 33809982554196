import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { FormattedMessage } from 'react-intl';
import TemplateVariableForm from '../../../stores/Forms/TemplateVariableForm';
import Icon from 'components/Icon/Icon';
import Button, { BtnType } from 'components/Button/Button';
import TemplateVarTableItem from './TemplateVarTableItem';
import EmptyContent from '../../../components/EmptyContent/EmptyContent';

import style from './TemplateVariablesList.module.scss';

interface Props {
  templateVariableForms: TemplateVariableForm[];
  addTemplateVariable: () => void;
  deleteTemplateVariable: (index) => void;
  previewMode: boolean;
  namesInUse: string[];
  isDisabled: boolean;
}

@observer
class TemplateVariablesTable extends Component<Props> {
  @computed
  get isEmptyTable() {
    const { previewMode, templateVariableForms } = this.props;
    return previewMode && !templateVariableForms.length;
  }

  checkTemplateNameUniq = () => {
    const { templateVariableForms, namesInUse } = this.props;
    const allVarNames = templateVariableForms.map(form => form.variableName);
    templateVariableForms.forEach((form, index) => {
      const templateName = form.variableName;
      if (!form.validated) {
        return;
      }
      const isNameInUse = namesInUse.some(name => name === templateName);
      if (
        allVarNames
          .filter((item, itemIndex) => index !== itemIndex)
          .some(name => name.length && name === templateName) ||
        isNameInUse
      ) {
        form.setValidName(false);
        form.setErrorsMap('variableName');
      } else {
        form.setValidName(true);
        form.setErrorsMap('variableName');
      }
    });
  };

  deleteTemplateVariable = (index: number) => {
    this.props.deleteTemplateVariable(index);
  };

  componentDidUpdate() {
    this.checkTemplateNameUniq();
  }

  render() {
    const {
      templateVariableForms,
      previewMode,
      isDisabled,
      addTemplateVariable,
    } = this.props;
    return (
      <div className={style.templateVariablesTable}>
        {this.isEmptyTable ? (
          <EmptyContent
            text={'templates.variables.userDefined.empty'}
            icon={'emptyBox'}
          />
        ) : (
          <table className={style.table}>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="templates.name" />
                </th>
                <th>
                  <FormattedMessage id="templates.description" />
                </th>
                <th>
                  <FormattedMessage id="templates.value" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {templateVariableForms.map((item, index) => (
                <TemplateVarTableItem
                  key={index}
                  index={index}
                  deleteVariable={this.deleteTemplateVariable}
                  checkNameUniq={this.checkTemplateNameUniq}
                  templateVariableItem={item}
                  previewMode={previewMode}
                  isDisabled={isDisabled}
                />
              ))}
            </tbody>
          </table>
        )}
        <Button
          styleType={BtnType.Add}
          className={style.addButton}
          onClick={addTemplateVariable}
        >
          <Icon type="plus2" />
        </Button>
      </div>
    );
  }
}

export default TemplateVariablesTable;
