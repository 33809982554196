import React, { Component } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import ModalStore, {
  DialogActionType,
  ModalProps,
} from '../../../stores/ModalStore';
import {
  getSortByObjectPropsFunc,
  groupBy,
  sortByIssueId,
} from 'helpers/array';
import CommonModal from '../../Modals/Modal/CommonModal';
import { IssueItem, Artifact } from 'models/Artifact';
import { stringToDate } from 'helpers/datetime';
import WithPopper from 'components/WithPopper/WithPopper';
import Icon from 'components/Icon/Icon';
import Switcher from 'components/Form/Fields/Switcher/Switcher';
import Button, { BtnType } from 'components/Button/Button';
import { Icons } from 'components/Icon';
import { lineBreakFunc } from 'helpers/string';
import InstallArtifactModal from '../InstallArtifactModal/InstallArtifactModal';
import IssueItemLink from './IssueItemLink';

import style from './AllIssuesModal.module.scss';

interface Props extends ModalProps {
  artefact: Artifact;
  issue: IssueItem[];
  collectedIssue?: IssueItem[];
  updateArtefact?: () => void;
}

interface IssueStatusMap {
  [key: string]: keyof typeof Icons;
}

const issueStatusMap: IssueStatusMap = {
  InProgress: 'issueProgress',
  ReadyForQA: 'issueEye',
  Resolved: 'issueResolve',
  NotWorkedYet: 'issueClock',
  Unknown: 'issueClock',
};

const sortNamesObj = {
  Unknown: 1,
  NotWorkedYet: 2,
  InProgress: 3,
  ReadyForQA: 4,
  Resolved: 5,
};

const customizer = (objValue, srcValue) => {
  if (_.isArray(objValue)) {
    return _.unionBy(objValue, srcValue, el => el.id);
  }
};

@observer
class AllIssuesModal extends Component<Props & ModalProps> {
  @observable isCollectedShow: boolean = false;
  @observable isIssueGroupClose: string[] = [];
  @observable isUIblocked: boolean = false;

  onChangeCollectedShow = () => {
    this.isCollectedShow = !this.isCollectedShow;
  };

  onClose = () => {
    this.props.onClose(DialogActionType.submit);
  };

  onConfirm = () => {
    this.props.onClose(DialogActionType.submit);
  };

  sortIssuesByStatus(issues: IssueItem[]) {
    return groupBy<IssueItem, keyof IssueItem>(issues, 'status');
  }

  toggleIssueGroup = name => () => {
    if (this.isIssueGroupClose.includes(name)) {
      this.isIssueGroupClose = this.isIssueGroupClose.filter(el => el !== name);
    } else {
      this.isIssueGroupClose.push(name);
    }
  };

  render() {
    const issuesGroups = this.sortIssuesByStatus(this.props.issue);
    const collectedIssuesGroups = this.props.collectedIssue
      ? this.sortIssuesByStatus(
          this.props.collectedIssue.filter(
            collectedIssue =>
              !this.props.issue
                .map(issue => issue.id)
                .includes(collectedIssue.id),
          ),
        )
      : null;

    const allIssue = _.mergeWith(
      {},
      issuesGroups,
      collectedIssuesGroups,
      customizer,
    );

    const issuesNames = Object.keys(
      this.isCollectedShow ? allIssue : issuesGroups,
    ).sort(getSortByObjectPropsFunc(sortNamesObj));

    return (
      <CommonModal
        bodyContentClassName={style.modalBodyContent}
        title={
          collectedIssuesGroups
            ? 'artifact.issues.status.header'
            : 'artifact.issues.upcoming.issues'
        }
        confirm="button.ok"
        cancel="button.cancel"
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        className={style.modalBody}
        isCancellable={false}
        isUIblocked={this.isUIblocked}
        advancedControls={
          <>
            {this.props.updateArtefact && (
              <Button
                className={style.updateArtefactBTN}
                styleType={BtnType.Secondary}
                onClick={async () => {
                  this.onClose();
                  ModalStore.showModal(InstallArtifactModal, {
                    image: this.props.artefact.fullImage,
                    updateMicroservice: async () => {
                      if (this.props.updateArtefact) {
                        await this.props.updateArtefact();
                      }
                    },
                  });
                }}
              >
                <FormattedMessage id={'update.to.last.version'} />
              </Button>
            )}
          </>
        }
      >
        <>
          <div
            className={classNames(
              style.statusWrapper,
              !!collectedIssuesGroups && style.withCollectedIssuesGroups,
            )}
          >
            <span className={style.textLabel}>
              <FormattedMessage id={'all.issues.modal.name'} />:
            </span>
            <span
              className={style.imageText}
              dangerouslySetInnerHTML={{
                __html: lineBreakFunc(this.props.artefact.fullImage || ''),
              }}
            />
            <div className={style.delimiter} />
            <span className={style.textLabel}>
              <FormattedMessage id={'all.issues.modal.created'} />:
            </span>
            <span className={style.dateText}>
              {this.props.artefact.buildInfo
                ? stringToDate(String(this.props.artefact.buildInfo.date))
                : 'none'}
            </span>
            <div className={style.delimiter} />
            <WithPopper title={<FormattedMessage id="view.git.commit" />}>
              <a
                href={
                  this.props.artefact.links && this.props.artefact.links.git
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon type="git" />
              </a>
            </WithPopper>
            <div className={style.delimiter} />
            <WithPopper
              title={<FormattedMessage id="view.on.docker.registry" />}
            >
              <a
                href={
                  this.props.artefact.links &&
                  this.props.artefact.links.registry
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon type="docker" />
              </a>
            </WithPopper>
            {collectedIssuesGroups && (
              <>
                <div className={style.delimiter} />
                <span
                  className={classNames(style.textLabel, style.toggleLabel)}
                >
                  <FormattedMessage
                    id={'all.issues.modal.show.collected.issues'}
                  />
                  :
                </span>
                <Switcher
                  className={style.checkbox}
                  checked={this.isCollectedShow}
                  onChange={this.onChangeCollectedShow}
                />
              </>
            )}
          </div>
          <div className={style.issuesWrapper}>
            {issuesNames.map(issuesGroupName => (
              <div key={issuesGroupName} className={style.issueGroup}>
                <div className={style.issueGroupHeader}>
                  <div className={style.issueGroupHeaderTitle}>
                    <Icon
                      className={style.issueIcon}
                      type={
                        issuesGroupName
                          ? issueStatusMap[issuesGroupName]
                          : 'issueClock'
                      }
                    />
                    <span
                      className={classNames(style.issueGroupLabel, {
                        [style.errorLabel]:
                          issuesGroupName === 'Unknown' ||
                          issuesGroupName === 'NotWorkedYet',
                      })}
                    >
                      <FormattedMessage id={issuesGroupName} /> (
                      {
                        (this.isCollectedShow ? allIssue : issuesGroups)[
                          issuesGroupName
                        ].length
                      }
                      )
                    </span>
                  </div>
                  <div className={style.issuesList}>
                    {issuesGroups[issuesGroupName] &&
                      issuesGroups[issuesGroupName]
                        .filter(el => !el.obsolete)
                        .sort(sortByIssueId)
                        .map((item, index) => {
                          return (
                            <IssueItemLink
                              issueItem={item}
                              key={index + item.id}
                            />
                          );
                        })}
                    {!!(
                      issuesGroups[issuesGroupName] &&
                      issuesGroups[issuesGroupName].filter(el => el.obsolete)
                        .length
                    ) && (
                      <>
                        <span className={style.obsoleteLabel}>
                          <FormattedMessage id={'all.issues.modal.obsolete'} />:
                        </span>
                        {issuesGroups[issuesGroupName]
                          .filter(el => el.obsolete)
                          .sort(sortByIssueId)
                          .map((item, index) => {
                            return (
                              <IssueItemLink
                                issueItem={item}
                                key={index + item.id}
                              />
                            );
                          })}
                      </>
                    )}
                  </div>
                  <div className={style.toggleIconContainer}>
                    <Icon
                      className={style.toggleIcon}
                      type={
                        !this.isIssueGroupClose.includes(issuesGroupName)
                          ? 'arrowTop'
                          : 'arrowBottom'
                      }
                      onClick={this.toggleIssueGroup(issuesGroupName)}
                    />
                  </div>
                </div>
                {!this.isIssueGroupClose.includes(issuesGroupName) && (
                  <>
                    <ul className={style.issueGroupBody}>
                      {issuesGroups[issuesGroupName] &&
                        issuesGroups[issuesGroupName]
                          .sort(sortByIssueId)
                          .map((item, index) => {
                            return (
                              <li key={index} className={style.issueContainer}>
                                {item.link ? (
                                  <a
                                    href={item.link}
                                    className={classNames(style.issueItem, {
                                      [style.issueObsolete]: item.obsolete,
                                    })}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {item.id}
                                  </a>
                                ) : (
                                  <span
                                    key={index}
                                    className={classNames(style.issueItem, {
                                      [style.issueObsolete]: item.obsolete,
                                    })}
                                  >
                                    {item.id}
                                  </span>
                                )}
                                <div className={style.issueDescription}>
                                  {item.description}
                                </div>
                              </li>
                            );
                          })}
                    </ul>
                    {this.isCollectedShow && collectedIssuesGroups && (
                      <div className={style.collectedContainer}>
                        <span className={style.collectedTitle}>
                          <FormattedMessage
                            id={'all.issues.modal.collected.issues'}
                          />
                        </span>
                        <ul className={style.issueGroupBody}>
                          {collectedIssuesGroups[issuesGroupName] &&
                            collectedIssuesGroups[issuesGroupName]
                              .sort(sortByIssueId)
                              .map((item, index) => {
                                return (
                                  <li
                                    key={index}
                                    className={style.issueContainer}
                                  >
                                    {item.link ? (
                                      <a
                                        href={item.link}
                                        className={classNames(style.issueItem)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {item.id}
                                      </a>
                                    ) : (
                                      <span
                                        key={index}
                                        className={classNames(style.issueItem)}
                                      >
                                        {item.id}
                                      </span>
                                    )}
                                    <div className={style.issueDescription}>
                                      {item.description}
                                    </div>
                                  </li>
                                );
                              })}
                        </ul>
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
        </>
      </CommonModal>
    );
  }
}

export default AllIssuesModal;
