import React from 'react';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { useClickOutside } from '../../hooks/useClickOutside';

import Icon from 'components/Icon/Icon';
import AlertsList from 'components/AlertsList/AlertsList';

import style from './AlertDrawer.module.scss';

const AlertDrawer: React.FC<{ alertsStore? }> = inject('alertsStore')(
  observer(({ alertsStore }) => {
    const clickRef = React.useRef<HTMLDivElement | null>(null);

    useClickOutside(clickRef, alertsStore.clickOutside, alertsStore.isOpen);

    return (
      <>
        <div
          className={classNames(style.fakeContainer, {
            [style.open]: alertsStore.isOpen,
          })}
        />
        <div
          ref={clickRef}
          className={classNames(style.mainContainer, {
            [style.open]: alertsStore.isOpen,
          })}
        >
          <div className={style.header}>
            <FormattedMessage id="alert.drawer.alerts" />
            <Icon
              className={style.closeBtn}
              onClick={alertsStore.closeAlertDrawer}
              type="close"
            />
          </div>
          <AlertsList alerts={alertsStore.normalizedAlerts} />
        </div>
      </>
    );
  }),
);

export default AlertDrawer;
