import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { replaceUrlParams } from 'http/helpers';
import { ROUTES } from 'routes/routes';
import RootStore from 'stores/RootStore';
import MenuItem from '../MenuItem/MenuItem';
import { Icons } from 'components/Icon';
import style from './MenuList.module.scss';

interface Props extends RouteComponentProps {
  title: string;
  active?: boolean;
  icon?: keyof typeof Icons;
  hoveredIcon?: keyof typeof Icons;
  list?: any;
  toggleMenu?: () => void;
}

@observer
class MenuList extends React.Component<Props> {
  @observable isOpen = false;

  toggleList = () => {
    this.isOpen = !this.isOpen;
  };

  gotoCluster = clusterName => {
    const { toggleMenu, history } = this.props;
    if (toggleMenu) {
      toggleMenu();
    }

    history.push(
      replaceUrlParams(ROUTES.cluster, {
        id: RootStore.currentProject,
        clusterName,
      }),
    );
  };

  gotoTemplates = clusterName => {
    const { toggleMenu, history } = this.props;
    if (toggleMenu) {
      toggleMenu();
    }

    history.push(
      replaceUrlParams(ROUTES.templates, {
        id: RootStore.currentProject,
        clusterName,
      }),
    );
  };

  render() {
    const { title, active, icon, hoveredIcon, list } = this.props;

    return (
      <>
        <MenuItem
          onClick={this.toggleList}
          title={title}
          active={active}
          icon={icon}
          hoveredIcon={hoveredIcon}
          isList
          isListOpen={this.isOpen}
        />
        {this.isOpen &&
          list.map(listItem => (
            <Fragment key={listItem.name}>
              <MenuItem
                title={listItem.name}
                onClick={() => this.gotoCluster(listItem.name)}
              />
              <div className={style.menuItem__nested}>
                <MenuItem
                  title="templates.title"
                  onClick={() => this.gotoTemplates(listItem.name)}
                />
              </div>
            </Fragment>
          ))}
      </>
    );
  }
}

export default withRouter(MenuList);
