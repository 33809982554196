import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import _ from 'lodash';

import { getSortByObjectPropsFunc, sortByIssueId } from 'helpers/array';
import { Artifact } from 'models/Artifact';

import IssueItemLink from 'components/MicroservicePageArtifact/AllIssuesModal/IssueItemLink';

import style from 'components/SearchImage/SearchImage.module.scss';

const sortStatusObj = {
  Unknown: 1,
  NotWorkedYet: 2,
  InProgress: 3,
  ReadyForQA: 4,
  Resolved: 5,
};

interface Props {
  image: Artifact;
  isShowCollectedIssues: boolean;
}

@observer
class ImageTopics extends Component<Props> {
  render() {
    const { image, isShowCollectedIssues } = this.props;
    const artifactIssuesByStatus = _.groupBy(
      image.artifactIssues,
      el => el.status,
    );
    const collectedIssuesByStatus = _.groupBy(
      image.collectedIssues,
      el => el.status,
    );
    const statuses = Array.from(
      new Set(
        Object.keys(artifactIssuesByStatus).concat(
          Object.keys(collectedIssuesByStatus),
        ),
      ),
    ).sort(getSortByObjectPropsFunc(sortStatusObj));

    if (
      (!image.artifactIssues.length && !image.collectedIssues.length) ||
      (!image.artifactIssues.length && !isShowCollectedIssues)
    ) {
      return (
        <div className={style.secondColumn}>
          <div className={style.issuesMessage}>
            <FormattedMessage id="image.search.no.artefact.issues" />
          </div>
        </div>
      );
    }

    return (
      <div className={style.secondColumn}>
        {statuses.map(status => (
          <Fragment key={status}>
            {(artifactIssuesByStatus[status] ||
              (isShowCollectedIssues && collectedIssuesByStatus[status])) && (
              <div className={style.issueGroupContainer}>
                <div className={style.issuesGroup}>
                  <div
                    className={classNames(style.issueStatus, {
                      [style.warningStatus]:
                        status === 'Unknown' || status === 'NotWorkedYet',
                    })}
                  >
                    <FormattedMessage id={status} />
                  </div>
                  {artifactIssuesByStatus[status] && (
                    <div
                      className={classNames(
                        style.issueColumns,
                        artifactIssuesByStatus[status].length === 1 &&
                          style.oneColumn,
                      )}
                    >
                      {artifactIssuesByStatus[status]
                        .slice()
                        .sort(sortByIssueId)
                        .map(issue => (
                          <div key={issue.id} className={style.issueContainer}>
                            <IssueItemLink issueItem={issue} />
                            <div className={style.issueDescription}>
                              {issue.description}
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
                {isShowCollectedIssues && collectedIssuesByStatus[status] && (
                  <div
                    className={classNames(
                      style.issuesGroup,
                      style.collectedIssues,
                    )}
                  >
                    <div className={style.issueStatus}>
                      <FormattedMessage id="image.search.collected.issues" />
                    </div>
                    <div
                      className={classNames(
                        style.issueColumns,
                        collectedIssuesByStatus[status].length === 1 &&
                          style.oneColumn,
                      )}
                    >
                      {collectedIssuesByStatus[status]
                        .slice()
                        .sort(sortByIssueId)
                        .map(issue => (
                          <div key={issue.id} className={style.issueContainer}>
                            <IssueItemLink issueItem={issue} />
                            <div className={style.issueDescription}>
                              {issue.description}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </Fragment>
        ))}
      </div>
    );
  }
}

export default ImageTopics;
