import React, { Component } from 'react';
import { observer } from 'mobx-react';
import DatePicker, { registerLocale } from 'react-datepicker';
// tslint:disable-next-line:no-implicit-dependencies
import enGB from 'date-fns/locale/en-GB';

import { DATE_FORMAT } from 'helpers/datetime';
import TextFieldWithoutForm from 'components/Form/Fields/TextFieldWithoutForm/TextField';

import 'react-datepicker/dist/react-datepicker.css';
import style from './DatePicker.module.scss';

registerLocale('en-gb', enGB);

interface Props {
  onChange: (value: any) => void;
  selectedDate: string;
}

@observer
class DatePickerInput extends Component<Props> {
  datePickerInputRef = React.createRef<HTMLInputElement>();

  openDatePicker = () => {
    if (this.datePickerInputRef.current) {
      this.datePickerInputRef.current.focus();
    }
  };

  render() {
    const { onChange, selectedDate } = this.props;

    return (
      <DatePicker
        wrapperClassName={style.dateInput}
        popperClassName={style.calendarContainer}
        customInput={
          <TextFieldWithoutForm
            label={'since.date'}
            iconType="calendar"
            containerClassName={style.datePicker__inputContainer}
            forwardRef={this.datePickerInputRef}
            onIconClick={this.openDatePicker}
          />
        }
        enableTabLoop={false}
        dateFormat={DATE_FORMAT}
        locale="en-gb"
        selected={selectedDate}
        onChange={onChange}
      />
    );
  }
}

export default DatePickerInput;
