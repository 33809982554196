import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import classNames from 'classnames';

import RootStore from 'stores/RootStore';
import ModalStore from 'stores/ModalStore';
import MenuStore from 'stores/MenuStore';
import UserService from 'services/UserService';

import Icon from 'components/Icon/Icon';
import ProjectSelect from 'components/ProjectSelect/ProjectSelect';
import HeaderButton from './HeaderButton/HeaderButton';
import MenuIcon from './MenuIcon/MenuIcon';
import MenuDrawer from './MenuDrawer/MenuDrawer';
import EditProjectModal from './EditProjectModal/EditProjectModal';
import CreateClusterModal from './CreateClusterModal/CreateClusterModal';
import ActionMenu from 'components/ActionMenu/ActionMenu';
import EncryptMessageModal from './EncryptMessageModal/EncryptMessageModal';

import style from './AppHeader.module.scss';

interface Props
  extends RouteComponentProps<{ id?: string; clusterName?: string }> {
  alertsStore?;
}

@inject('alertsStore')
@observer
class AppHeader extends React.Component<Props> {
  private menuStore: MenuStore;

  constructor(props) {
    super(props);
    this.menuStore = new MenuStore(RootStore.currentProject);
  }

  public componentDidUpdate = (prevProps): void => {
    const prevProject = prevProps.match.params.id;
    const newProject = this.props.match.params.id;

    if (
      prevProps.match.params &&
      this.props.match.params &&
      prevProject !== newProject
    ) {
      RootStore.currentProject = newProject || ''; // to support auth on direct links

      this.menuStore = new MenuStore(newProject || '');
    }
  };

  showAlerts = () => {
    const { alertsStore } = this.props;
    alertsStore.showAlertDrawer([], 'AppHeader');
  };

  showEditProjectModal = () => {
    ModalStore.showModal(EditProjectModal);
  };

  showCreateClusterModal = () => {
    ModalStore.showModal(CreateClusterModal);
  };

  showEncryptMessageModal = (projectName, clusterName) => {
    ModalStore.showModal(EncryptMessageModal, { projectName, clusterName });
  };

  render() {
    const { currentProject } = RootStore;
    const isMenuOpen = this.menuStore.isOpen;
    const { clusterList, toggleMenu } = this.menuStore;
    const isAlertsExist = this.props.alertsStore.alerts.length > 0;
    const settingsMenuItems = [
      {
        action: this.showEditProjectModal,
        label: <FormattedMessage id="header.settings.edit.roject" />,
        icon: 'editProject',
      },
      {
        action: this.showCreateClusterModal,
        label: <FormattedMessage id="header.settings.create.cluster" />,
        icon: 'createCluster',
        popper: 'under.development',
      },
      {
        action: () =>
          this.showEncryptMessageModal(
            this.props.match.params.id,
            this.props.match.params.clusterName,
          ),
        disabled: !this.props.match.params.clusterName,
        label: <FormattedMessage id="encrypt.message" />,
        icon: 'message',
        popper: !this.props.match.params.clusterName ? 'encrypt.disabled' : '',
      },
    ];
    const profileMenuItems = [
      {
        action: () => undefined,
        label: <FormattedMessage id="header.account.settings" />,
        icon: 'accountSettings',
        popper: 'under.development',
      },
      {
        action: UserService.logout,
        label: <FormattedMessage id="header.logout" />,
        icon: 'logout2',
      },
    ];
    const actionMenuStyles = {
      menu: style.menu,
      menuItem: style.menuItem,
    };

    return (
      <>
        <MenuDrawer isOpen={isMenuOpen} {...{ clusterList, toggleMenu }} />
        <div className={style.headerContainer}>
          <header
            className={classNames(style.header, {
              [style.header__openedAlert]: this.props.alertsStore.isOpen,
            })}
          >
            <HeaderButton
              className={classNames(style.menuButton, {
                [style.menuButtonOpen]: isMenuOpen,
                [style.menuButtonClosed]: !isMenuOpen,
              })}
              onClick={this.menuStore.toggleMenu}
            >
              <MenuIcon isOpen={isMenuOpen} />
            </HeaderButton>
            <Link
              to={`/project/${currentProject}/info`}
              className={classNames(style.logo, {
                [style.logoOpen]: isMenuOpen,
              })}
            >
              <Icon type="logo2WhiteSmall" />
            </Link>
            <div className={style.projectSelectWrapper}>
              <ProjectSelect
                currentProject={currentProject}
                projects={this.projectOptions}
              />
            </div>
            <div className={style.line} />
            <HeaderButton
              isDotVisible={isAlertsExist}
              onClick={this.showAlerts}
            >
              <Icon type="notifications" className={style.notifIcon} />
            </HeaderButton>
            <div className={style.line} />
            <ActionMenu
              openMenuComponent={toggleList => (
                <HeaderButton onClick={toggleList}>
                  <Icon type="settings" className={style.settingsIcon} />
                </HeaderButton>
              )}
              items={settingsMenuItems}
              styles={actionMenuStyles}
            />
            <div className={style.line} />
            <ActionMenu
              openMenuComponent={toggleList => (
                <HeaderButton onClick={toggleList}>
                  <Icon type="profile" className={style.profileIcon} />
                </HeaderButton>
              )}
              items={profileMenuItems}
              styles={actionMenuStyles}
            />
          </header>
        </div>
      </>
    );
  }

  @computed
  get projectOptions() {
    return RootStore.projects.map(project => {
      return {
        value: project,
        hasAuth: localStorage.getItem(project) !== null,
      };
    });
  }
}

export default withRouter(AppHeader);
