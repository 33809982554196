import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { FormattedMessage } from 'react-intl';

import TemplateVariableForm from 'stores/Forms/TemplateVariableForm';

import Icon from 'components/Icon/Icon';
import MSTemplateVariablesTableItem from 'components/MSTemplateVariablesTable/MSTemplateVariablesTableItem';

import style from './MSTemplateVariablesTable.module.scss';

interface Props {
  templateVariablesForms: TemplateVariableForm[];
  isDisabled?: boolean;
}

@observer
class MSTemplateVariablesTable extends Component<Props> {
  @computed
  get isEmptyTable() {
    const { templateVariablesForms } = this.props;
    return !templateVariablesForms.length;
  }

  render() {
    const { templateVariablesForms, isDisabled } = this.props;
    return (
      <div className={style.tableWrapper}>
        {this.isEmptyTable ? (
          <div className={style.emptyStateContainer}>
            <Icon type={'emptyBox'} />
            <div className={style.emptyStateNotification}>
              <FormattedMessage id="templates.variables.userDefined.empty" />
            </div>
          </div>
        ) : (
          <table className={style.table}>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="template.variable.name" />
                </th>
                <th />
                <th>
                  <FormattedMessage id="template.variable.value" />
                </th>
              </tr>
            </thead>
            <tbody>
              {templateVariablesForms.map((item, index) => (
                <MSTemplateVariablesTableItem
                  key={`${item.variableName}${index}`}
                  index={index}
                  templateVariableItem={item}
                  isDisabled={isDisabled}
                />
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default MSTemplateVariablesTable;
