import React from 'react';
import { observer } from 'mobx-react';

import { IPodContainerParams } from 'models/Pod';
import { ContainerLogsStore } from 'stores/PodsPage/ContainerLogsStore';

import CircleLoader from 'components/CircleLoader/CircleLoader';
import LogsPanel from 'components/LodsPanel/LodsPanel';
import EmptyContent from 'components/EmptyContent/EmptyContent';

import style from './ContainerLods.module.scss';

interface IProps {
  containerParams: IPodContainerParams;
  isLastContainerLogs: boolean;
}

@observer
class ContainerLogs extends React.Component<IProps> {
  logsStore = new ContainerLogsStore(this.props.containerParams);

  async componentDidMount() {
    if (this.props.isLastContainerLogs) {
      await this.logsStore.fetchPodContainerLogs(
        this.props.isLastContainerLogs,
      );
      return;
    }
    this.logsStore.startLogsReceiving();
  }

  componentWillUnmount() {
    this.logsStore.stopLogsReceiving();
  }

  render() {
    const hasLogs = !!this.logsStore.logs.length;
    const isError =
      this.logsStore.isLogsReceivingInterrupted || this.logsStore.error;
    const showLoading = this.logsStore.isLoading;
    const showLogs = !this.logsStore.isLoading && (hasLogs || isError);
    const showEmptyMessage = !this.logsStore.isLoading && !hasLogs && !isError;

    return (
      <div className={style.logsWrapper}>
        {showLoading && <CircleLoader className={style.loader} />}
        {showLogs && (
          <LogsPanel
            logs={this.logsStore.logs}
            error={this.logsStore.error}
            isLogsReceivingInterrupted={
              this.logsStore.isLogsReceivingInterrupted
            }
          />
        )}
        {showEmptyMessage && (
          <EmptyContent
            icon="emptyBox"
            text="container.logs.empty.message"
            className={style.emptyContent}
            messageClassName={style.emptyContentMessage}
          />
        )}
      </div>
    );
  }
}

export default ContainerLogs;
