import classNames from 'classnames';
import Button, { BtnSize, BtnType } from 'components/Button/Button';
import { Icons } from 'components/Icon';
import Icon from 'components/Icon/Icon';
import ManyPodsSection from 'components/MicroservicePageArtifact/ManyPodsSection/ManyPodsSection';
import { PodStatusesGrid } from 'components/MicroservicePageArtifact/PodStatusesGrid/PodStatusesGrid';
import Status, { StatusType } from 'components/Status/Status';
import Counter from 'components/Tabs/DefaultTabs/Counter/Counter';
import TextBadge, { TextBadgeTypes } from 'components/TextBadge/TextBadge';
import WithPopper from 'components/WithPopper/WithPopper';
import { sortByIssueId } from 'helpers/array';
import { stringToDateTime } from 'helpers/datetime';
import { replaceUrlParams } from 'http/helpers';
import { action, computed } from 'mobx';
import { IssueItemStatusTranslationKeys } from 'models/Artifact';
import { IServiceParams, ServiceDetails, ServiceUpdate } from 'models/Service';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ROUTES } from 'routes/routes';
import ModalStore from '../../stores/ModalStore';
import InfoLine from '../EnvironmentPageHeader/InfoLine/InfoLine';
import AllIssuesModal from './AllIssuesModal/AllIssuesModal';
import ArtifactUpdate from './ArtifactUpdate/ArtifactUpdate';
import style from './MicroservicePageArtifact.module.scss';

export const artifactStatusMap = {
  uptodate: StatusType.Success,
  outofdate: StatusType.Warning,
  obsolete: StatusType.Error,
  unknown: StatusType.Warning,
};

interface IssueStatusMap {
  [key: string]: keyof typeof Icons;
}

const issueStatusMap: IssueStatusMap = {
  InProgress: 'issueProgress',
  ReadyForQA: 'issueEye',
  Resolved: 'issueResolve',
  NotWorkedYet: 'issueClock',
  Unknown: 'issueClock',
};

const PODS_COUNT = 36;

interface Props extends RouteComponentProps {
  serviceParams: IServiceParams;
  service: ServiceDetails;
  updateMicroservice: (data: ServiceUpdate) => Promise<any>;
}

class MicroservicePageArtifact extends React.Component<Props> {
  openIssuesModal = async () => {
    const { service } = this.props;
    ModalStore.showModal(AllIssuesModal, {
      artefact: service.artifact,
      issue: service.artifact.artifactIssues,
      collectedIssue: service.artifact.collectedIssues,
    });
  };

  @computed
  get issues() {
    const { artifactIssues, collectedIssues } = this.props.service.artifact;
    return artifactIssues
      .slice()
      .sort(sortByIssueId)
      .concat(
        collectedIssues
          .slice()
          .sort(sortByIssueId)
          .filter(
            collectedIssue =>
              !artifactIssues.find(issue => issue.id === collectedIssue.id),
          ),
      );
  }

  @action
  gotoPodsPage = () => {
    const { serviceParams, history } = this.props;
    const { projectName, clusterName, environmentName, serviceName } =
      serviceParams;
    history.push(
      replaceUrlParams(ROUTES.podsLogs, {
        id: projectName,
        clusterName,
        environmentName,
        microserviceName: serviceName,
      }),
    );
  };

  render() {
    const { service, updateMicroservice } = this.props;
    const { artifactStatus, runtimeState, podRuntimeStatuses } = service.info;
    const { artifact } = service;
    const hasPods = !!podRuntimeStatuses.length;
    const showPodsInTable = podRuntimeStatuses.length <= PODS_COUNT;

    return (
      <div className={style.mainContainer}>
        <table>
          <thead>
            <tr className={style.tableHeader}>
              <th>
                <FormattedMessage id="artefact.status" />
              </th>
              <th>
                <FormattedMessage id="available.update" />
              </th>
              <th>
                <FormattedMessage id="image" />
              </th>
              {showPodsInTable && (
                <th>
                  <FormattedMessage id="runtime.status" />
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className={style.cellContainer}>
                  <div className={style.columnContainer}>
                    <div className={style.statusContainer}>
                      <Status
                        statusType={artifactStatusMap[artifactStatus]}
                        className={style.status}
                      />
                      <span className={style.statusText}>
                        <FormattedMessage id={artifactStatus} />
                      </span>
                      <TextBadge
                        className={style.artifactImage}
                        type={TextBadgeTypes.ImageInfo}
                      >
                        {service.artifact.fullImage.split('/').pop()}
                      </TextBadge>
                    </div>
                    {this.issues.length > 0 && (
                      <div className={style.issueContainer}>
                        <div className={style.issueList}>
                          {this.issues.slice(0, 8).map(issue => (
                            <div key={issue.id} className={style.issueItem}>
                              <Icon
                                className={style.issueIcon}
                                type={
                                  issue.status
                                    ? issueStatusMap[issue.status]
                                    : 'issueClock'
                                }
                              />
                              {issue.link ? (
                                <a
                                  href={issue.link}
                                  className={style.issueLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <WithPopper
                                    className={style.issueText}
                                    title={
                                      <>
                                        <FormattedMessage
                                          id={`artifact.issues.status.${
                                            IssueItemStatusTranslationKeys[
                                              issue.status
                                            ]
                                          }`}
                                        />
                                        {issue.description && (
                                          <>: {issue.description}</>
                                        )}
                                      </>
                                    }
                                  >
                                    {issue.id}
                                  </WithPopper>
                                </a>
                              ) : (
                                <WithPopper
                                  className={style.issueText}
                                  title={
                                    <>
                                      <FormattedMessage
                                        id={`artifact.issues.status.${
                                          IssueItemStatusTranslationKeys[
                                            issue.status
                                          ]
                                        }`}
                                      />
                                      {issue.description && (
                                        <>: {issue.description}</>
                                      )}
                                    </>
                                  }
                                >
                                  {issue.id}
                                </WithPopper>
                              )}
                            </div>
                          ))}
                          {this.issues.length > 8 && (
                            <div className={style.elipsis}>...</div>
                          )}
                        </div>
                        <Button
                          styleType={BtnType.Text}
                          className={style.moreBtn}
                          onClick={this.openIssuesModal}
                        >
                          <FormattedMessage id="view.more" />
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className={style.delimiter} />
                </div>
              </td>
              <td>
                <div className={style.cellContainer}>
                  <div className={style.columnContainer}>
                    <ArtifactUpdate
                      update={service.update}
                      updateMicroservice={updateMicroservice}
                    />
                  </div>
                  <div className={style.delimiter} />
                </div>
              </td>
              <td>
                <div
                  className={classNames(
                    style.cellContainer,
                    style.imageContainer,
                  )}
                >
                  <div className={style.columnContainer}>
                    <InfoLine
                      label="label.branch"
                      value={
                        artifact.buildInfo && artifact.buildInfo.git.branch
                      }
                      className={style.infoLine}
                      valueClassName={style.infoValue}
                    />

                    <InfoLine
                      label="label.build.date"
                      value={
                        artifact.buildInfo &&
                        stringToDateTime(artifact.buildInfo.date.toString())
                      }
                      className={style.infoLine}
                      valueClassName={style.infoValue}
                    />

                    <ul className={style.imageLinks}>
                      <li>
                        <a
                          className={style.imageLink}
                          href={artifact.links && artifact.links.git}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FormattedMessage
                            id="artifact.link.source"
                            tagName={React.Fragment}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          className={style.imageLink}
                          href={artifact.links && artifact.links.build}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FormattedMessage
                            id="artifact.link.pipeline"
                            tagName={React.Fragment}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          className={style.imageLink}
                          href={artifact.links && artifact.links.registry}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FormattedMessage
                            id="artifact.link.registry"
                            tagName={React.Fragment}
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                  {showPodsInTable && <div className={style.delimiter} />}
                </div>
              </td>
              {showPodsInTable && (
                <td>
                  <div
                    className={classNames(
                      style.cellContainer,
                      style.podsColumnContainer,
                    )}
                  >
                    <div
                      className={classNames(
                        style.rowContainer,
                        style.podsRowContainer,
                      )}
                    >
                      <div className={style.counterWrapper}>
                        <Counter
                          counter={{
                            success: Number(runtimeState.existing),
                            all: Number(runtimeState.desired),
                          }}
                          active
                        />
                      </div>
                      <PodStatusesGrid
                        podRuntimeStatuses={podRuntimeStatuses}
                        className={style.podStatuses}
                      />
                      <Button
                        className={style.viewPodsBtn}
                        styleType={BtnType.Secondary}
                        size={BtnSize.ExtraTiny}
                        onClick={this.gotoPodsPage}
                        disabled={!hasPods}
                      >
                        <FormattedMessage
                          id="view.pods"
                          tagName={React.Fragment}
                        />
                      </Button>
                    </div>
                  </div>
                </td>
              )}
            </tr>
          </tbody>
        </table>
        {!showPodsInTable && (
          <ManyPodsSection
            service={service.info}
            gotoPodsPage={this.gotoPodsPage}
          />
        )}
      </div>
    );
  }
}

export default withRouter(MicroservicePageArtifact);
