import classNames from 'classnames';
import React, { ReactNode } from 'react';
import style from 'components/Table/Table.module.scss';

interface IProps {
  children: ReactNode;
  className?: string;
}

export const TableHeaderRow = ({ children, className }: IProps) => {
  return (
    <tr className={classNames(className, style.tableHeaderRow)}>{children}</tr>
  );
};
