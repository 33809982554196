import React from 'react';
import classNames from 'classnames';

import style from './InstallCheckbox.module.scss';
import Icon from 'components/Icon/Icon';
import { FormattedMessage } from 'react-intl';

interface Props {
  onClick?: (event) => void;
  className?: string;
  installed?: boolean;
  selected?: boolean;
}

class InstallCheckbox extends React.Component<Props> {
  render() {
    const { onClick, selected, className, installed } = this.props;

    const text = installed
      ? 'installed'
      : selected
      ? 'selected'
      : 'select.to.install';

    return (
      <div
        className={classNames(style.container, className, {
          [style.installed]: installed,
          [style.selected]: selected,
        })}
        onClick={!installed ? onClick : () => undefined}
      >
        <FormattedMessage id={text} />
        <div className={classNames(style.circle)}>
          {(selected || installed) && (
            <Icon className={style.check} type="check" />
          )}
        </div>
      </div>
    );
  }
}

export default InstallCheckbox;
