import http from '../http';

import { appUrl } from 'http/helpers';
import URLS from 'http/urls';
import {
  Cluster,
  ClusterAction,
  ClusterDetails,
  ClusterSchedule,
  ExternalStorageStatus,
} from 'models/Cluster';
import {
  StorageProvider,
  StorageProviderInstance,
  StorageInstanceParams,
  StorageInstanceForm,
  StorageInstanceResponse,
} from 'models/ExternalStorage';

export const clusterApi = {
  fetchClusters(projectName: string) {
    return http.get<Cluster[]>(appUrl(URLS.clusters), {
      replaceParams: {
        projectName,
      },
    });
  },

  switchCluster({
    projectName,
    clusterName,
    action,
  }: {
    projectName: string;
    clusterName: string;
    action: ClusterAction;
  }) {
    return http.post(
      appUrl(URLS.clusterActions),
      {},
      {
        replaceParams: {
          projectName,
          clusterName,
          switch: action,
        },
      },
    );
  },

  fetchCluster(projectName: string, clusterName: string) {
    return http.get<Cluster>(appUrl(URLS.cluster), {
      replaceParams: {
        projectName,
        clusterName,
      },
    });
  },

  fetchClusterDetails(projectName: string, clusterName: string) {
    return http.get<ClusterDetails>(appUrl(URLS.clusterDetails), {
      replaceParams: {
        projectName,
        clusterName,
      },
    });
  },

  fetchClusterSchedule(projectName: string, clusterName: string) {
    return http.get<ClusterSchedule>(appUrl(URLS.clusterSchedule), {
      replaceParams: {
        projectName,
        clusterName,
      },
    });
  },

  fetchClusterInfo(params: any) {
    return http.get(
      appUrl('/projects/:projectName/clusters/:clusterName/all/'),
      {
        replaceParams: params,
      },
    );
  },

  fetchClusterProviders(projectName: string, clusterName: string) {
    return http.get<StorageProvider[]>(appUrl(URLS.clusterProviders), {
      replaceParams: {
        projectName,
        clusterName,
      },
    });
  },

  fetchClusterProviderInstances(projectName: string, clusterName: string) {
    return http.get<StorageProviderInstance[]>(
      appUrl(URLS.clusterProviderInstances),
      {
        replaceParams: {
          projectName,
          clusterName,
        },
      },
    );
  },

  deleteProviderInstance(
    projectName: string,
    clusterName: string,
    providerInstanceName: string,
  ) {
    return http.delete(appUrl(URLS.deleteProviderInstance), {
      replaceParams: {
        projectName,
        clusterName,
        providerInstanceName,
      },
    });
  },

  fetchAvailablePIForMsSupport(
    projectName: string,
    clusterName: string,
    environmentName: string,
    microserviceName: string,
  ) {
    return http.get<StorageProviderInstance[]>(
      appUrl(URLS.availablePIForMsSupport),
      {
        replaceParams: {
          projectName,
          clusterName,
          environmentName,
          microserviceName,
        },
      },
    );
  },

  fetchClusterProviderInstancesUsage(
    projectName: string,
    clusterName: string,
    instanceName: string,
  ) {
    return http.get<Map<string, string[]>>(
      appUrl(URLS.clusterProviderInstancesUsage),
      {
        replaceParams: {
          projectName,
          clusterName,
          instanceName,
        },
      },
    );
  },

  checkAvailability(projectName: string, clusterName: string) {
    return http.get<ExternalStorageStatus>(appUrl(URLS.externalStorageStatus), {
      replaceParams: {
        projectName,
        clusterName,
      },
    });
  },

  createInstanceProvider(
    formData: StorageInstanceForm,
    params: StorageInstanceParams,
  ) {
    return http.post<StorageInstanceForm, StorageInstanceResponse>(
      appUrl(URLS.externalStorage),
      formData,
      {
        replaceParams: params,
      },
    );
  },

  encryptMessage(projectName: string, clusterName: string, text, base64) {
    return http.post<any, { text: string }>(
      appUrl(URLS.encryptMessage),
      { text, base64 },
      {
        replaceParams: {
          projectName,
          clusterName,
        },
      },
    );
  },
};
