import React from 'react';
import classNames from 'classnames';

import style from './RadioCard.module.scss';
import { FormattedMessage } from 'react-intl';
import InstallCheckbox from './InstallCheckbox/InstallCheckbox';

interface Props {
  text1: string;
  text2: string;
  selected: boolean;
  onClick: () => void;
}

const RadioCard: React.FC<Props> = ({
  text1,
  text2,
  selected,
  onClick = () => undefined,
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(style.container, { [style.selected]: selected })}
    >
      <InstallCheckbox label={text1} selected={selected} />
      <div className={style.textContainer}>
        <FormattedMessage id={text2} />
      </div>
    </div>
  );
};

export default RadioCard;
