import React, { FC } from 'react';

import {
  Background,
  BlueLabel,
  GrayCube,
  GreenCube,
  RedCube,
  RedLabel,
} from './svg-parts';

import style from './ClusterImage.module.scss';
import classNames from 'classnames';
import { ClusterDetails, ClusterState } from 'models/Cluster';
import { inject, observer } from 'mobx-react';
import AlertsStore, { DisplayedAlerts } from 'stores/AlertsStore';

interface Props {
  className?: string;
  errors?: number;
  onClick?: () => void;
  clusterName: string;
  cluster: ClusterDetails;
  alertsStore?: AlertsStore;
  toggleAlertDrawer: () => void;
}

const ClusterImage: FC<Props> = inject('alertsStore')(
  observer(
    ({
      className = '',
      errors = 0,
      onClick = () => undefined,
      clusterName,
      cluster,
      alertsStore,
      toggleAlertDrawer,
    }) => {
      const alertsCount = alertsStore
        ? alertsStore.getAlertsByFilters(
            [{ clusterReference: clusterName }],
            alertsStore.alerts,
            DisplayedAlerts.Visible,
            alertsStore.hiddenAlertsCounters,
          ).length
        : 0;

      const getClusterData = event => {
        event.stopPropagation();
        if (event.ctrlKey) {
          onClick();
        }
      };

      return (
        <div
          onClick={getClusterData}
          className={classNames(style.image, className)}
        >
          <Background />
          {getCubeByState(cluster.state)}
          {alertsCount ? (
            <>
              <RedLabel className={style.label} />
              <span onClick={toggleAlertDrawer} className={style.errorNumber}>
                {alertsCount}
              </span>
            </>
          ) : (
            <BlueLabel className={style.label} />
          )}
        </div>
      );
    },
  ),
);

function getCubeByState(state: ClusterState) {
  switch (state) {
    case ClusterState.Running:
      return <GreenCube className={style.cube} />;

    case ClusterState.Stopped:
      return <GrayCube className={style.cube} />;

    case ClusterState.Unknown:
      return <RedCube className={style.cube} />;
  }
}

export default ClusterImage;
