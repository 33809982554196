import RootStore from '../stores/RootStore';
import httpFacade from '../http/httpFacade';
import { convertToTokens } from 'http/Api/User/helpers';

class UserService {
  init = (): void => {
    const project = this._getProjectNameFromUrl();
    if (project) {
      RootStore.currentProject = project;
      const tokens = RootStore.user.getAuthTokens(project);
      if (tokens) {
        RootStore.user.init(tokens, project);
      }
    }
  };

  async login(username, password, project) {
    try {
      const response = await httpFacade.user.login({
        username,
        password,
        project,
      });

      RootStore.user.init(convertToTokens(response.data), project);
      RootStore.currentProject = project;

      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  logout = async () => {
    try {
      const projectName = this._getProjectNameFromUrl();
      if (projectName) {
        const { refreshToken } = RootStore.user.getAuthTokens(projectName);
        if (refreshToken) {
          await httpFacade.user.logout(refreshToken, projectName);
          RootStore.reset();
        }
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  _getProjectNameFromUrl = (): string | null => {
    return (window.location.href.match(`/*/project/([\\w-]+)/`) || [])[1];
  };
}

export default new UserService();
