import { observable } from 'mobx';

import { BaseFormModel, displayName } from 'stores/BaseForm';

class CreateClusterForm extends BaseFormModel {
  @observable
  @displayName('cluster.create.clusterName')
  clusterName: string = '';

  @observable
  @displayName('cluster.create.clustersBaseDomain')
  clustersBaseDomain: string = '';

  @observable
  @displayName('cluster.create.gitURL')
  gitURL: string = '';

  @observable
  @displayName('cluster.create.gitStateSubpath')
  gitStateSubpath: string = '';

  @observable
  @displayName('cluster.create.usernameToAccessGIT')
  usernameToAccessGIT: string = '';

  @observable
  @displayName('cluster.create.userPasswordToAccessGIT')
  userPasswordToAccessGIT: string = '';
}

export default CreateClusterForm;
