import React, { Component } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { IssueItem } from 'models/Artifact';

import style from './AllIssuesModal.module.scss';

interface Props {
  issueItem: IssueItem;
}

@observer
class IssueItemLink extends Component<Props> {
  render() {
    const item = this.props.issueItem;
    return item.link ? (
      <a
        href={item.link}
        className={classNames(style.issueItem, {
          [style.issueObsolete]: item.obsolete,
        })}
        target="_blank"
        rel="noopener noreferrer"
      >
        {item.id}
      </a>
    ) : (
      <span
        className={classNames(style.issueItem, {
          [style.issueObsolete]: item.obsolete,
        })}
      >
        {item.id}
      </span>
    );
  }
}

export default IssueItemLink;
