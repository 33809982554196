import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Icon from 'components/Icon/Icon';
import { Icons } from 'components/Icon';

import style from './EmptyContent.module.scss';

interface Props {
  text: string | JSX.Element;
  icon?: keyof typeof Icons;
  className?: string;
  messageClassName?: string;
}

const EmptyContent: React.FC<Props> = ({
  text,
  icon,
  className,
  messageClassName,
}) => {
  const message =
    typeof text === 'string' ? <FormattedMessage id={text} /> : text;

  return (
    <div className={classNames(style.wrapper, className)}>
      {icon && <Icon type={icon} />}
      <div className={classNames(style.message, messageClassName)}>
        {message}
      </div>
    </div>
  );
};

export default EmptyContent;
