import React from 'react';
import TabsContainer from 'components/Tabs/TabsContainer';
import ClusterEnvironmentList from '../ClusterEnvironmentList/ClusterEnvironmentList';
import ClusterExternalStoragePage from 'components/ClusterExternalStoragePage/ClusterExternalStoragePage';
import { ClusterDetails } from 'models/Cluster';

import style from './ClusterBody.module.scss';

export enum ClusterPageTabs {
  environments = 'cluster.environments',
  storage = 'cluster.external.storage',
}

interface Props {
  cluster: ClusterDetails;
  fetchCluster;
  addEnvToFavorite;
}

class ClusterPageBody extends React.Component<Props> {
  render() {
    return (
      <div className={style.clusterBody}>
        <TabsContainer
          tabsComponents={[
            {
              component: (
                <ClusterEnvironmentList
                  environments={this.props.cluster.environments}
                  clusterName={this.props.cluster.info.name}
                  fetchCluster={this.props.fetchCluster}
                  addEnvToFavorite={this.props.addEnvToFavorite}
                />
              ),
              label: ClusterPageTabs.environments,
            },
            {
              component: (
                <ClusterExternalStoragePage cluster={this.props.cluster} />
              ),
              label: ClusterPageTabs.storage,
            },
          ]}
        />
      </div>
    );
  }
}

export default ClusterPageBody;
