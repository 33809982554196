import { PodRuntimeStatus } from 'models/StatusTypes';

export const UNSCHEDULABLE_ERROR = 'Unschedulable';
export const STATE_TERMINATED_SUCCESS_REASON = 'Completed';

export const LOG_ID_PREFIX = 'logId_';

export enum PodPhase {
  Pending = 'Pending',
  Running = 'Running',
  Succeeded = 'Succeeded',
  Failed = 'Failed',
  Unknown = 'Unknown',
}

export enum PodSyncStatus {
  Synced = 'Synced',
  Diff = 'Diff',
}

export enum PodContainerLogType {
  log = 'log',
  event = 'event',
}

export type WithId<T> = { id: string } & T;

export interface IPodParams {
  projectName: string;
  clusterName: string;
  environmentName: string;
  serviceName: string;
  podName: string;
}

export interface IPodContainerParams extends IPodParams {
  containerName: string;
}

export interface IServicePod {
  envName: string;
  svcName: string;
  podName: string;
  createDate: string;
  phase: PodPhase;
  restartCount: number;
  lastStart?: string;
  outdated: boolean;
  syncStatus: PodSyncStatus;
  runtimeStatus: IPodRuntimeStatus;
}

interface IPodRuntimeStatus {
  runtimeStatus: PodRuntimeStatus;
  reason?: string;
  message?: string;
  totalContainers: number;
  goodContainers: number;
}

export interface ILog {
  message: string;
  level: string;
  date: string;
  nanoSec: number;
  order: number;
  type: PodContainerLogType;
  sourceKind: string;
  sourceName: string;
}

export interface IPodContainer {
  containerName: string;
  image: string;
  restartCount: number;
  started: boolean;
  ready: boolean;
  stateWaiting?: {
    reason?: string;
    message?: string;
  };
  stateRunning?: {
    startedAt: string;
  };
  stateTerminated?: {
    reason?: string;
    message?: string;
    exitCode: number;
    signal: number;
    startedAt: string;
    finishedAt: string;
  };
  lastStateWaiting?: {
    reason?: string;
    message?: string;
  };
  lastStateRunning?: {
    startedAt: string;
  };
  lastStateTerminated?: {
    reason?: string;
    message?: string;
    exitCode: number;
    signal: number;
    startedAt: string;
    finishedAt: string;
  };
}
