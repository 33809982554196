import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { ComparingState } from 'models/Environment';
import { ComparingEnvVarsForm } from 'stores/ApplyMsTo/ComparingEnvVarsForm';

import Icon from 'components/Icon/Icon';
import Button, { BtnType } from 'components/Button/Button';
import Checkbox from 'components/Form/Fields/Checkbox/Checkbox';
import TextField from 'components/Form/Fields/TextField/TextField';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import { TableBodyRow } from 'components/Table/TableBobyRow';
import { ENV_VAR_REGEXP, testRegExp } from '../../../../helpers/testRegexp';
import WithPopper from '../../../WithPopper/WithPopper';
import { uniqueId } from '../../../../helpers/uniqueId';

import style from 'components/EnvironmentPageServicesList/Compare/CompareMsItem/CompareMsItem.module.scss';

interface IProps {
  envVarForm: ComparingEnvVarsForm;
  checkEnvNameUniq: () => void;
  onDelete: () => void;
}

export const CompareEnvVarFormItem = observer(
  ({ envVarForm, onDelete, checkEnvNameUniq }: IProps) => {
    const { envVar } = envVarForm;
    const [valAppliedVisible, toggleAppliedVisible] = useState(false);
    const [valSourceVisible, toggleSourceVisible] = useState(false);
    const FormControl = bindFormControl(envVarForm);

    const onVariableNameChange = onChange => (value: string, e) => {
      const targetElement = e.target;
      const ss = targetElement.selectionStart;
      const se = targetElement.selectionEnd;
      const length = value.length;
      const isValidRegexp = testRegExp(value, ENV_VAR_REGEXP);
      if (!isValidRegexp && length > 0) {
        setTimeout(() => {
          targetElement.selectionStart = ss - 1;
          targetElement.selectionEnd = se - 1;
        }, 0);
        return;
      }
      onChange(value, e);
      envVarForm.checkRequiredValid(true);
      checkEnvNameUniq();
      envVarForm.validate();
    };

    const onVarValueChange = onChange => (value: string, e) => {
      onChange(value, e);
      envVarForm.checkRequiredValid();
      envVarForm.validate();
    };

    return (
      <TableBodyRow
        className={classNames(style.tableRow, {
          [style.tableRow__new]: envVar.envVarState === ComparingState.NEW,
        })}
      >
        {!envVar.isAddedByUser ? (
          <>
            <td>
              <div className={style.nameContainer}>
                <WithPopper
                  className={classNames(style.textTooltip)}
                  title={envVar.name}
                >
                  <div className={classNames(style.varName, style.textWrapper)}>
                    {envVar.name}
                  </div>
                </WithPopper>
              </div>
            </td>
            <td>
              <div className={style.cellContainer}>
                <div className={style.varValue}>
                  <WithPopper
                    className={classNames(style.textTooltip)}
                    title={
                      !envVar.secureSource || valSourceVisible
                        ? envVar.valueSource
                        : ''
                    }
                  >
                    <div className={style.textWrapper}>
                      {!envVar.secureSource || valSourceVisible
                        ? envVar.valueSource
                        : envVar.valueSource?.replace(/./g, '·')}
                    </div>
                  </WithPopper>
                  {envVar.secureSource && (
                    <Icon
                      className={style.visibleIcon}
                      type={valSourceVisible ? 'visible' : 'hidden'}
                      onClick={() => toggleSourceVisible(!valSourceVisible)}
                    />
                  )}
                </div>
                {!envVarForm.isValueEqualToSource && (
                  <Button
                    styleType={BtnType.Text}
                    className={classNames(style.btnIcon, style.btnUse)}
                    onClick={envVarForm.useSourceValue}
                  >
                    <FormattedMessage id="button.use.this" />
                  </Button>
                )}
              </div>
            </td>
          </>
        ) : (
          <td colSpan={2}>
            <FormControl
              withoutLabel
              className={classNames(style.input, style.newVar)}
              name="name"
              render={props => (
                <TextField
                  id={`name_${uniqueId()}`}
                  {...props}
                  onChange={onVariableNameChange(props.onChange)}
                />
              )}
              immediateValidation
            />
          </td>
        )}
        <td>
          <FormControl
            withoutLabel
            className={style.input}
            name="value"
            render={props => (
              <TextField
                {...props}
                id={`value_${uniqueId()}`}
                onChange={onVarValueChange(props.onChange)}
                iconType={
                  envVarForm.secure
                    ? valAppliedVisible
                      ? 'visible'
                      : 'hidden'
                    : undefined
                }
                type={
                  envVarForm.secure && !valAppliedVisible
                    ? 'password'
                    : undefined
                }
                onIconClick={() => toggleAppliedVisible(!valAppliedVisible)}
              />
            )}
            immediateValidation
          />
        </td>
        <td className={style.cellCenter}>
          <label className={style.label}>
            <Checkbox
              checked={envVarForm.secure}
              onChange={envVarForm.toggleSecure}
            />
          </label>
        </td>
        <td className={style.cellCenter}>
          <label className={style.label}>
            <Checkbox
              checked={envVarForm.envSpecific}
              onChange={envVarForm.toggleEnvSpecific}
            />
          </label>
        </td>
        <td className={style.cellCenter}>
          {envVar.isAddedByUser ? (
            <Button
              styleType={BtnType.Text}
              onClick={onDelete}
              className={style.deleteButton}
            >
              <Icon type="deleteEmpty" className={style.iconArrow} />
            </Button>
          ) : (
            <label className={style.label}>
              <Checkbox
                checked={envVarForm.deleted}
                onChange={envVarForm.toggleDeleted}
              />
            </label>
          )}
        </td>
      </TableBodyRow>
    );
  },
);
