import { observable, action } from 'mobx';

import { BaseFormModel, displayName } from 'stores/BaseForm';
import httpFacade from 'http/httpFacade';
import { copyToClipboard } from 'helpers/copyToClipboard';

class EncryptMessageForm extends BaseFormModel {
  @observable
  @displayName('text.to.encrypt')
  message: string = '';

  @observable
  @displayName('encrypted.text')
  encryptMessage: string = '';

  @action
  encryptData = async (projectName, clusterName, base64) => {
    try {
      const response = await httpFacade.cluster.encryptMessage(
        projectName,
        clusterName,
        this.message,
        base64,
      );
      this.encryptMessage = response.data.text;
    } catch (e) {
      throw e;
    }
  };

  @action
  clear = () => {
    this.message = '';
    this.encryptMessage = '';
  };

  copyToClipboard = () => {
    copyToClipboard(this.encryptMessage);
  };
}

export default EncryptMessageForm;
