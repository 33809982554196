import React, { ReactNode } from 'react';

import style from './BulkActionLayout.module.scss';

interface IProps {
  children: ReactNode;
}

export const BulkActionLayout = ({ children }: IProps) => {
  return (
    <div className={style.selectModePanel}>
      <div className={style.selectModePanelWrap}>{children}</div>
    </div>
  );
};
