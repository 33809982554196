import { action, observable } from 'mobx';
import _ from 'lodash';

import Log from 'helpers/log';
import { replaceUrlParams, urlWithQueryParams } from 'http/helpers';
import URLS from 'http/urls';
import httpFacade from 'http/httpFacade';
import { ILog, IPodContainerParams, LOG_ID_PREFIX, WithId } from 'models/Pod';
import Socket from 'stores/Socket/Socket';

const LOGS_TAIL = 1000;

export class ContainerLogsStore {
  @observable.shallow logs: Array<WithId<ILog>> = [];
  @observable error = '';
  @observable isLogsReceivingInterrupted = false;
  @observable isLoading = false;
  containerParams: IPodContainerParams;

  private socket = new Socket();

  constructor(podContainerParams: IPodContainerParams) {
    this.containerParams = podContainerParams;
  }

  @action
  logsSubscribe = event => {
    const data: { payload?: ILog[]; error?: string } = JSON.parse(event.data);

    if (data.error) {
      this.error = data.error;
    }

    if (data.payload) {
      this.isLoading = false;

      this.logs = [
        ...this.logs,
        ...data.payload.map(log => ({
          id: _.uniqueId(LOG_ID_PREFIX),
          ...log,
        })),
      ];
    }
  };

  @action
  startLogsReceiving = () => {
    this.isLoading = true;

    this.socket.connect({
      url: urlWithQueryParams(
        replaceUrlParams(URLS.podContainerLogsAndEvents, this.containerParams),
        {
          tail: LOGS_TAIL,
        },
      ),
      onClose: this.onLogsConnectionClose,
    });

    this.socket.subscribe(this.logsSubscribe);
  };

  @action
  onLogsConnectionClose = () => {
    this.isLoading = false;
    this.isLogsReceivingInterrupted = true;
  };

  stopLogsReceiving = () => {
    this.socket.unsubscribe(this.logsSubscribe);
    this.socket.disconnect();
  };

  @action
  fetchPodContainerLogs = async (isLastContainerLogs = false) => {
    try {
      this.isLoading = true;

      const { data } = await httpFacade.pods.fetchPodContainerLogsAndEvents(
        this.containerParams,
        {
          tail: LOGS_TAIL,
          old: isLastContainerLogs,
        },
      );

      this.logs = data.map(log => ({
        id: _.uniqueId(LOG_ID_PREFIX),
        ...log,
      }));
    } catch (error) {
      this.logs = [];
      Log.warn(error);
    } finally {
      this.isLoading = false;
    }
  };
}
