import React, { Component } from 'react';
import style from 'components/Tabs/DefaultTabs/Counter/Counter.module.scss';
import classNames from 'classnames';
import { TabCounter } from 'components/Tabs/DefaultTabs/DefaultTabsItem';

interface Props {
  active: boolean;
  counter: TabCounter;
  onClick?: () => void;
}

class Counter extends Component<Props> {
  render() {
    const { counter, active, onClick } = this.props;

    return (
      <div
        onClick={onClick}
        className={classNames(style.counter, {
          [style.counter__active]: active,
        })}
      >
        {counter.success}/{counter.all}
      </div>
    );
  }
}

export default Counter;
