export class Alert {
  references: AlertReference[];
  message: string;
  type: AlertType;
  key: string;
  args?: object;
}

export enum AlertReferenceKind {
  Cluster = 'Cluster',
  Environment = 'Environment',
  Microservice = 'Microservice',
  Config = 'Config',
  Disk = 'Disk',
  Memory = 'Memory',
  CPU = 'CPU',
  Git = 'Git',
  IssueTracker = 'IssueTracker',
  DockerRegistry = 'DockerRegistry',
  K8s = 'K8s',
  Esm = 'Esm',
  General = 'General',
}

export enum AlertType {
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
  Unset = 'unset',
}

export class AlertReference {
  kind: AlertReferenceKind;
  id: string;
}

export class AlertsFilter {
  clusterReference?: string;
  environmentReference?: string;
  microserviceReference?: string;
  availabilityReference?: string;
  withOutMsRef?: boolean;
  anyMsRef?: boolean;
}
