import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import classNames from 'classnames';

import httpFacade from 'http/httpFacade';
import RootStore from 'stores/RootStore';
import { Artifact } from 'models/Artifact';

import SearchImageForm from './SearchImageForm';
import { bindFormControl } from '../Form/FormControl/FormControl';
import { FormattedMessage } from 'react-intl';
import TextField from 'components/Form/Fields/TextField/TextField';
import Button, { BtnType } from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import ImageCard from 'components/SearchImage/ImageCard';
import Switcher from 'components/Form/Fields/Switcher/Switcher';
import TagsField from 'components/TagsField/TagsField';
import EmptyContent from 'components/EmptyContent/EmptyContent';
import DatePickerInput from 'components/DatePickerInput/DatePickerInput';

import style from './SearchImage.module.scss';

interface Props {
  selectedImage: string;
  setImage: (image: string) => void;
  installedImage?: string;
  installedArtefactName?: string;
  installedBranchName?: string;
  afterSearchHook?: (error?) => void;
  withWidth?: boolean;
}

@observer
class SearchImage extends Component<Props> {
  imagesContainerRef = React.createRef<HTMLDivElement>();
  @observable searchImageForm = new SearchImageForm();
  FormControl = bindFormControl(this.searchImageForm);
  @observable images: Artifact[] = [];
  @observable isShowCollectedIssues = false;
  @observable isImagesFetched = false;
  @observable isUIblocked = false;
  @observable date;

  componentDidMount() {
    if (this.props.installedArtefactName) {
      this.searchImageForm.setValue(
        'artifactName',
        this.props.installedArtefactName,
      );
    }
    if (this.props.installedBranchName) {
      this.searchImageForm.setValue(
        'branchName',
        this.props.installedBranchName,
      );
    }
  }

  @action
  toggleCollectedIssues = () => {
    this.isShowCollectedIssues = !this.isShowCollectedIssues;
  };

  onChangeDate = date => {
    this.date = date;
  };

  searchImages = async () => {
    try {
      this.isUIblocked = true;
      const response = await httpFacade.projects.fetchArtifacts(
        RootStore.currentProject,
        {
          artifactName: this.searchImageForm.artifactName || undefined,
          branch: this.searchImageForm.branchName || undefined,
          since: this.date
            ? new Date(
                this.date - this.date.getTimezoneOffset() * 60 * 1000,
              ).toISOString()
            : undefined,
          topics: this.searchImageForm.topics.join(',') || undefined,
        },
      );
      this.reset();
      this.images = response.data;
      this.isImagesFetched = true;
      if (this.props.afterSearchHook) {
        this.props.afterSearchHook();
      }
    } catch (e) {
      if (this.props.afterSearchHook) {
        this.props.afterSearchHook(e);
      }
    } finally {
      this.isUIblocked = false;
    }
  };

  @action
  reset = () => {
    this.images = [];
    this.props.setImage('');
    this.imagesContainerRef.current?.scrollTo(0, 0);
  };

  addTag = (tag: string) => {
    this.searchImageForm.topics.push(tag);
  };

  deleteTag = (index: number) => {
    this.searchImageForm.topics.splice(index, 1);
  };

  clearForm = () => {
    this.date = undefined;
    this.searchImageForm.clearForm();
  };

  render() {
    const FormControl = this.FormControl;
    const { selectedImage, setImage, installedImage } = this.props;

    return (
      <div
        className={classNames(style.mainContainer, {
          [style.withWidth]: this.props.withWidth,
        })}
      >
        <div className={style.formContainer}>
          <div className={style.formFields}>
            {this.isUIblocked && <div className={style.overlay} />}
            <div className={style.title}>
              <FormattedMessage id="microservice.modal.image.search" />
            </div>
            <FormControl
              className={style.formField}
              name="artifactName"
              render={props => <TextField {...props} iconType="search" />}
            />
            <FormControl
              className={style.formField}
              name="branchName"
              render={props => <TextField {...props} />}
            />
            <DatePickerInput
              onChange={this.onChangeDate}
              selectedDate={this.date}
            />
            <TagsField
              tags={this.searchImageForm.topics}
              handleDelete={this.deleteTag}
              handleAddition={this.addTag}
              label="included.topics"
            />
          </div>
          <div className={style.formActionContainer}>
            <Button
              styleType={BtnType.Ghost}
              className={style.clearBtn}
              onClick={this.clearForm}
              disabled={this.isUIblocked}
            >
              <FormattedMessage id="button.clear" />
            </Button>
            <Button
              onClick={this.searchImages}
              isLoading={this.isUIblocked}
              className={style.searchBtn}
            >
              <FormattedMessage id="button.search" />
            </Button>
          </div>
        </div>

        <div className={style.imagesContainer} ref={this.imagesContainerRef}>
          {!!this.images.length && (
            <div className={style.btnWrapper}>
              <div className={style.switcherLabel}>
                <FormattedMessage id="image.search.show.collected.issues" />
              </div>
              <Switcher
                className={style.switcher}
                checked={this.isShowCollectedIssues}
                onChange={this.toggleCollectedIssues}
              />
            </div>
          )}
          {this.images.map((image: Artifact) => (
            <ImageCard
              key={image.fullImage}
              image={image}
              selectedImage={selectedImage}
              setImage={setImage}
              installedImage={installedImage}
              isShowCollectedIssues={this.isShowCollectedIssues}
            />
          ))}
          {!this.images.length &&
            (this.isImagesFetched ? (
              <EmptyContent
                icon="emptyBox"
                text="image.search.artefact.do.not.exist"
                className={style.noResults}
              />
            ) : (
              <div className={style.noResults}>
                <Icon type="noResults" />
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default SearchImage;
