import React from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Log from 'helpers/log';
import { AlertType } from 'models/Alert';
import {
  ClusterAction,
  ClusterDetails,
  ClusterState,
  ClusterStateAlertTypes,
} from 'models/Cluster';
import Metric from 'models/Metric';
import ModalStore from 'stores/ModalStore';
import ClusterStore from 'stores/ClusterStore';

import Alert from 'components/Alert/Alert';
import Button, { BtnSize, BtnType } from 'components/Button/Button';
import ClusterShutdownModal from 'components/ClusterHeader/ClusterShutdownModal/ClusterShutdownModal';
import Icon from 'components/Icon/Icon';
import NotificationService, {
  fullscreenNotificationOptions,
} from 'components/Notification/NotificationService';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import ScheduleStatus from 'components/ScheduleStatus/ScheduleStatus';
import WithPopper from 'components/WithPopper/WithPopper';
import ExternalLinksMenu from 'components/ExternalLinksMenu/ExternalLinksMenu';
import ClusterImage from 'pages/ProjectPage/ClusterContainer/ClusterImage/ClusterImage';

import style from './ClusterHeader.module.scss';

interface Props {
  cluster: ClusterDetails;
  clusterStore: ClusterStore;
  alertsStore?;
}

@inject('alertsStore')
@observer
class ClusterHeader extends React.Component<Props> {
  switchOnCluster = async () => {
    try {
      await this.props.clusterStore.switchCluster(ClusterAction.ON);
      NotificationService.successMessage(
        { id: 'cluster.switched.on' },
        fullscreenNotificationOptions,
      );
    } catch (error) {
      Log.warn(error);
    }
  };

  openShutdownModal = async () => {
    const { cluster } = this.props;

    await ModalStore.showModal(ClusterShutdownModal, {
      clusterName: cluster.info.name,
    });

    try {
      await this.props.clusterStore.switchCluster(ClusterAction.OFF);
      NotificationService.successMessage(
        { id: 'cluster.switched.off' },
        fullscreenNotificationOptions,
      );
    } catch (error) {
      Log.warn(error);
    }
  };

  toGib(bytes) {
    return parseFloat((bytes / 1000000000).toFixed(2));
  }

  async componentDidMount() {
    await this.props.clusterStore.fetchProject();
  }

  toggleAlertDrawer = () => {
    const { alertsStore } = this.props;
    if (!alertsStore) {
      return;
    }
    alertsStore.showAlertDrawer([], 'ClusterHeader');
  };

  render() {
    const { cluster, clusterStore } = this.props;
    const { state, nodeCount, links, cpu, memory } = cluster;
    const { clusterSchedule } = clusterStore;

    const linksData = [
      {
        path: clusterStore.project && clusterStore.project.links.jira,
        name: 'link.jira',
        icon: 'jira1',
      },
      {
        path: links.stateGit,
        name: 'link.GIT',
        icon: 'git',
      },
      {
        path: links.cloudProvider,
        name: 'link.kubernetes',
        icon: 'kubernetes',
      },
      {
        path: links.registry,
        name: 'link.registry',
        icon: 'docker',
      },
      {
        path: links.logging,
        name: 'link.logging',
        icon: 'kibana',
      },
      {
        path: links.monitoring,
        name: 'link.monitoring',
        icon: 'grafana',
      },
    ];

    if (links.storageManagerLogging) {
      linksData.push({
        path: links.storageManagerLogging,
        name: 'link.storageManagerLogging',
        icon: 'kibana',
      });
    }

    const calculatePercent = (item: Metric | null | undefined) =>
      !item ? item : (item.used / item.available) * 100;

    return (
      <div className={style.mainContainer}>
        <div className={style.container1}>
          <div className={style.clusterTitleContainer}>
            <div className={style.title}>
              <FormattedMessage id="page.project.cluster" />
              <span>{cluster.info.name}</span>
            </div>
            {state ? (
              <Alert type={AlertType[ClusterStateAlertTypes[state]]}>
                <FormattedMessage id={`page.project.${state}`} />
              </Alert>
            ) : (
              <Alert type={AlertType.Unset}>
                <FormattedMessage id={`page.project.unknown`} />
              </Alert>
            )}
          </div>
          <div className={style.imageContainer}>
            <ClusterImage
              clusterName={cluster.info.name}
              className={style.image}
              cluster={cluster}
              errors={cluster.errorCountPerHour}
              toggleAlertDrawer={this.toggleAlertDrawer}
            />
          </div>
        </div>
        <div className={style.container2}>
          <div className={style.container2line1}>
            <div className={style.delimiter} />
            <div className={style.onlineNodesContainer}>
              <div className={style.nodesLine1}>
                <FormattedMessage id="cluster.header.online.nodes" />
                <span className={style.nodeCount}>{nodeCount || 0}</span>
              </div>
              <div className={style.nodesLine2}>
                <WithPopper
                  title={<FormattedMessage id={'under.development'} />}
                >
                  <Button
                    className={style.scaleButton}
                    size={BtnSize.ExtraTiny}
                    styleType={BtnType.Secondary}
                  >
                    <FormattedMessage id="cluster.header.scale" />
                  </Button>
                </WithPopper>
              </div>
            </div>
            <div className={style.delimiter} />
            <ProgressBar
              isUnknown={cluster.state === ClusterState.Unknown}
              unknownDescription={'unknown.cores.used'}
              name={<FormattedMessage id="cluster.header.cpu" />}
              value={
                calculatePercent(
                  cluster.state === ClusterState.Stopped ||
                    cluster.state === ClusterState.Unknown
                    ? undefined
                    : cpu,
                ) as number
              }
              styles={{
                mainContainer: style.progressBar,
                progress: style.progress,
                valueLabel: style.progressBarValueLabel,
              }}
              description={
                <FormattedMessage
                  id="page.project.cpu.used"
                  values={{
                    part: cpu ? parseFloat(cpu.used.toFixed(2)) : undefined,
                    total: cpu
                      ? parseFloat(cpu.available.toFixed(2))
                      : undefined,
                  }}
                />
              }
            />
            <div className={style.delimiter} />
            <ProgressBar
              isUnknown={cluster.state === ClusterState.Unknown}
              unknownDescription={'unknown.gib.used'}
              name={<FormattedMessage id="cluster.header.memory" />}
              value={
                calculatePercent(
                  cluster.state === ClusterState.Stopped ||
                    cluster.state === ClusterState.Unknown
                    ? undefined
                    : memory,
                ) as number
              }
              styles={{
                mainContainer: style.progressBar,
                progress: style.progress,
                valueLabel: style.progressBarValueLabel,
              }}
              description={
                <FormattedMessage
                  id="page.project.gib.used"
                  values={{
                    part: memory ? this.toGib(memory.used) : undefined,
                    total: memory ? this.toGib(memory.available) : undefined,
                  }}
                />
              }
            />
          </div>
          <div className={style.container2line2}>
            <WithPopper
              title={
                !cluster?.actions.switchEnabled ? (
                  <FormattedMessage id="need.to.configure.cluster" />
                ) : (
                  ''
                )
              }
            >
              <Button
                className={style.shutdownButton}
                size={BtnSize.Small}
                onClick={
                  cluster.state === ClusterState.Stopped
                    ? this.switchOnCluster
                    : this.openShutdownModal
                }
                disabled={!cluster?.actions.switchEnabled}
              >
                <Icon type={'power'} className={style.powerIcon} />
                <FormattedMessage
                  id={
                    cluster.state === ClusterState.Stopped
                      ? 'cluster.header.switch.on'
                      : 'cluster.header.shutdown'
                  }
                />
              </Button>
            </WithPopper>
            <div className={style.scheduleAndLinksContainer}>
              <ScheduleStatus
                schedule={clusterSchedule}
                className={style.scheduleStatus}
                compact={this.props.alertsStore.isOpen}
              />
              <div className={style.scheduleAndLinksContainer__buttons}>
                <WithPopper
                  title={<FormattedMessage id={'under.development'} />}
                >
                  <Button
                    size={BtnSize.ExtraTiny}
                    styleType={BtnType.Secondary}
                  >
                    <FormattedMessage id="cluster.header.edit" />
                  </Button>
                </WithPopper>
                <div
                  className={classNames(
                    style.delimiter,
                    style.delimiter__links,
                  )}
                />
                <ExternalLinksMenu links={linksData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClusterHeader;
