import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { computed } from 'mobx';

import { Service } from 'models/Service';
import Status, { StatusType } from 'components/Status/Status';
import { stringToDate, timeSince } from 'helpers/datetime';
import TextBadge, { TextBadgeTypes } from 'components/TextBadge/TextBadge';
import { IEnvironmentParams } from 'models/Environment';
import style from './ServiceItem.module.scss';
import WithPopper from 'components/WithPopper/WithPopper';
import { RuntimeStatus } from 'components/RuntimeStatus/RuntimeStatus';
import ServiceItemStore from 'stores/ServiceItemStore';
import CircleLoader from 'components/CircleLoader/CircleLoader';
import Menu from './Menu/Menu';
import { lineBreakFunc } from 'helpers/string';
import FavoriteStar from 'components/FavoriteStar/FavoriteStar';
import AllIssuesModal from 'components/MicroservicePageArtifact/AllIssuesModal/AllIssuesModal';
import ModalStore from 'stores/ModalStore';
import httpFacade from 'http/httpFacade';
import Checkbox from 'components/Form/Fields/Checkbox/Checkbox';
import { ActionType } from '../EnvironmentPageServicesList';
import ResourceLinks from 'components/ResourseLinks/ResourceLinks';
import Icon from 'components/Icon/Icon';
import { sortByIssueId } from 'helpers/array';

const syncStateMap = {
  synced: StatusType.Success,
  not_in_git: StatusType.Error,
  not_in_k8s: StatusType.Error,
  diff: StatusType.Error,
  no_hash: StatusType.Error,
  unknown: StatusType.Warning,
};

const artifactStatusMap = {
  uptodate: StatusType.Success,
  outofdate: StatusType.Warning,
  obsolete: StatusType.Error,
  unknown: StatusType.Warning,
};

export enum artefactStatusList {
  obsolete = 'obsolete',
  unknown = 'unknown',
  outofdate = 'outofdate',
  uptodate = 'uptodate',
}

interface Props {
  service: Service;
  params: IEnvironmentParams;
  gotoMicroservice: (name: string) => void;
  addMSToFavorite: (name: string) => void;
  isSelectMode: boolean;
  onSelectMicroservice: (name) => void;
  selectedMicroservices: string[];
  actionType: ActionType;
  tableBody: HTMLElement | null;
}

const ISSUES_COUNT_TO_DISPLAY = 2;

@observer
class ServiceItem extends React.Component<Props> {
  store: ServiceItemStore;

  @computed
  get resourceLinks() {
    if (!this.store.serviceInfo) {
      return [];
    }
    return this.store.serviceInfo.serviceIngresses.reduce((acc, el) => {
      return acc.concat(
        el.paths
          ? el.paths.map(
              path => `${el.tls ? 'https://' : 'http://'}${el.host}${path}`,
            )
          : [],
      );
    }, [] as any);
  }

  constructor(props: Props) {
    super(props);
    this.store = new ServiceItemStore(
      this.props.params,
      this.props.service.name,
    );
  }

  async componentWillUnmount() {
    this.store.clearTimer();
  }

  openUpdateMicroserviceModal = e => {
    e.stopPropagation();
    if (this.store.serviceInfo && this.store.serviceInfo.update) {
      const fullImage = this.store.serviceInfo.update.latest.fullImage;
      ModalStore.showModal(AllIssuesModal, {
        artefact: this.store.serviceInfo.update.latest,
        issue: this.store.serviceInfo.update.incoming,
        updateArtefact: async () =>
          httpFacade.service.updateService(
            { ...this.props.params, serviceName: this.props.service.name },
            { image: fullImage },
          ),
      });
    }
  };

  renderLatestImage = () => {
    const { update } = this.store.serviceInfo || {};
    const slicedIssues =
      update &&
      update.incoming.length > 0 &&
      update.incoming
        .slice()
        .sort(sortByIssueId)
        .slice(0, ISSUES_COUNT_TO_DISPLAY);

    return (
      <div
        className={style.rowContainer}
        onClick={this.openUpdateMicroserviceModal}
      >
        {update ? (
          <>
            <TextBadge
              className={style.artefactImage}
              type={TextBadgeTypes.ImageInfo}
            >
              {update.latest.buildInfo
                ? update.latest.buildInfo.date &&
                  stringToDate(update.latest.buildInfo.date.toString())
                : 'null'}
              {slicedIssues && (
                <>
                  {': '}
                  {slicedIssues.map(({ id }: { id: string }, i: number) => (
                    <span key={`${id}${i}`}>
                      {id}
                      {i < slicedIssues.length - 1 && '; '}
                    </span>
                  ))}
                </>
              )}
            </TextBadge>{' '}
            {update && update.incoming.length > ISSUES_COUNT_TO_DISPLAY && (
              <span className={style.moreIssuesLabel}>{`+${
                update.incoming.length - ISSUES_COUNT_TO_DISPLAY
              }`}</span>
            )}
          </>
        ) : (
          <CircleLoader />
        )}
      </div>
    );
  };

  toFavorite = (e: React.MouseEvent) => {
    const { addMSToFavorite, service } = this.props;
    e.stopPropagation();
    addMSToFavorite(service.name);
  };

  handleMicroserviceClick = () => {
    if (this.props.isSelectMode) {
      if (
        !(this.store.serviceInfo && this.store.serviceInfo.update) &&
        this.props.actionType === ActionType.UpdateToLastVersion
      ) {
        return;
      }
      this.props.onSelectMicroservice(this.props.service.name);
    } else {
      this.props.gotoMicroservice(this.props.service.name);
    }
  };

  render() {
    const { service, isSelectMode, selectedMicroservices, tableBody } =
      this.props;
    const { updateDate, runtimeState, image } = service;

    const envUpdateTime = timeSince(updateDate);

    const fullImageName = image || '';

    const shortImageName = fullImageName.split('/').pop();

    return (
      <tr
        className={classNames(style.mainContainer, {
          [style.scaleOff]: !runtimeState.desired,
          [style.isSelected]:
            isSelectMode && selectedMicroservices.includes(service.name),
          [style.selectDisabled]:
            isSelectMode &&
            this.props.actionType === ActionType.UpdateToLastVersion &&
            this.store.serviceInfo &&
            !this.store.serviceInfo.update,
        })}
        onClick={this.handleMicroserviceClick}
      >
        <td>
          {!isSelectMode && (
            <FavoriteStar active={service.favorite} onClick={this.toFavorite} />
          )}
          {isSelectMode && (
            <label
              className={classNames(style.checkboxContainer, {
                [style.active]: service.favorite,
              })}
            >
              <Checkbox
                onChange={this.handleMicroserviceClick}
                disabled={
                  this.store.serviceInfo &&
                  !this.store.serviceInfo.update &&
                  this.props.actionType === ActionType.UpdateToLastVersion
                }
                checked={selectedMicroservices.includes(service.name)}
              />
            </label>
          )}
        </td>
        <td>
          <div className={style.cellContainer}>
            <span className={style.firstTitle}>{service.longName}</span>
            <span className={style.secondTitle}>
              {service.updateDate && (
                <FormattedMessage
                  id="updated.ago"
                  values={{
                    interval: envUpdateTime.interval,
                    type: <FormattedMessage id={envUpdateTime.type} />,
                  }}
                />
              )}
            </span>
          </div>
        </td>
        <td>
          <div className={style.cellContainer}>
            <span className={style.firstTitle}>
              <Status
                statusType={syncStateMap[service.syncState]}
                className={style.icon}
              />
              <span className={style.statusText}>
                <FormattedMessage id={service.syncState} />
              </span>
            </span>
            <span className={classNames(style.secondTitle)} />
          </div>
        </td>
        <td>
          <div className={style.cellContainer}>
            <span className={style.firstTitle}>
              <Status
                statusType={artifactStatusMap[service.artifactStatus]}
                className={style.icon}
              />
              <span className={style.statusText}>
                <FormattedMessage id={service.artifactStatus} />
              </span>
            </span>
            {(service.artifactStatus === artefactStatusList.obsolete ||
              service.artifactStatus === artefactStatusList.outofdate) &&
              this.renderLatestImage()}
          </div>
        </td>
        <td>
          <div className={style.cellContainer}>
            <span className={style.firstTitle}>
              <RuntimeStatus
                runtimeState={runtimeState}
                podsStyles={{ pods: style.pods }}
              />
            </span>
            <span className={style.secondTitle} />
          </div>
        </td>
        <td className={classNames(style.withWidth, style.imageNameCell)}>
          <div className={style.cellContainer}>
            <div className={style.rowContainer}>
              <WithPopper
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: lineBreakFunc(fullImageName || ''),
                    }}
                  />
                }
                className={style.imageName}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: lineBreakFunc(shortImageName || ''),
                  }}
                  className={style.imageName__text}
                />
              </WithPopper>
            </div>
          </div>
        </td>
        <td>
          <div className={style.menuContainer}>
            <div className={style.menuItemWrapper}>
              {this.store.serviceInfo && this.resourceLinks.length > 1 && (
                <ResourceLinks
                  links={this.resourceLinks}
                  boundaryElement={tableBody}
                />
              )}
              {this.store.serviceInfo && this.resourceLinks.length === 1 && (
                <a
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  target="_blank"
                  href={this.resourceLinks[0]}
                  rel="noopener noreferrer"
                >
                  <Icon className={style.storageMenuIcon} type="externalLink" />
                </a>
              )}
              {!this.resourceLinks.length && (
                <WithPopper title={<FormattedMessage id="ms.no.links" />}>
                  <Icon
                    onClick={e => {
                      if (e) {
                        e.stopPropagation();
                      }
                    }}
                    className={classNames(
                      style.storageMenuIcon,
                      style.storageMenuIcon__disabled,
                    )}
                    type="externalLink"
                  />
                </WithPopper>
              )}
            </div>
            <div className={style.menuItemWrapper}>
              {this.store.serviceInfo && (
                <>
                  <Menu
                    params={this.props.params}
                    service={this.store.serviceInfo}
                    boundaryElement={tableBody}
                  />
                </>
              )}
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

export default ServiceItem;
