import {
  BaseFormModel,
  displayName,
  isRange,
  isRequired,
} from 'stores/BaseForm';
import { observable } from 'mobx';

class ScaleMSForm extends BaseFormModel {
  @observable
  @displayName('scale')
  @isRequired('form.field.is.required')
  @isRange(0, 100, 'incorrect.value')
  scale: number = 0;
}

export default ScaleMSForm;
