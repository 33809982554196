import React from 'react';
import classNames from 'classnames';

import style from './MenuIcon.module.scss';

interface Props {
  isOpen: boolean;
}

const MenuIcon: React.FC<Props> = ({ isOpen }) => {
  return (
    <div className={style.menuIconWrap}>
      <div className={style.menuIconContainer}>
        <div className={classNames(style.menuIcon, { [style.open]: isOpen })}>
          <span />
          <span />
          <span />
        </div>
      </div>
    </div>
  );
};

export default MenuIcon;
