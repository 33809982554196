import { action, computed, observable } from 'mobx';
import { ITemplateVariable } from 'models/Template';
import TemplateVariableForm from './Forms/TemplateVariableForm';

export enum TemplateVarsTableModes {
  EDIT_VARS = 'EDIT_VARS',
  PREVIEW = 'PREVIEW',
  SET_VAR_VALUE = 'SET_VAR_VALUE',
}

class TemplateVarsTableStore {
  @observable
  templateVarsForms: TemplateVariableForm[] = [];

  @observable
  isPreviewMode = false;

  constructor(
    templateVariables?: ITemplateVariable[],
    mode: TemplateVarsTableModes = TemplateVarsTableModes.EDIT_VARS,
  ) {
    if (templateVariables && templateVariables.length) {
      templateVariables.forEach(variable => {
        const templateVariableForm = new TemplateVariableForm();
        templateVariableForm.setValue('variableName', variable.name);
        templateVariableForm.setValue('variableValue', variable.value);
        templateVariableForm.setValue(
          'variableDescription',
          variable.description,
        );
        templateVariableForm.validate();
        this.templateVarsForms.push(templateVariableForm);
      });
    } else if (mode === TemplateVarsTableModes.EDIT_VARS) {
      this.templateVarsForms.push(new TemplateVariableForm());
    }
    if (mode === TemplateVarsTableModes.PREVIEW) {
      this.setPreviewMode(true);
    }
  }

  @computed
  get isTemplateVarsValid() {
    const invalidForms = this.templateVarsForms.filter(
      form => form.validated && !form.isFormValid,
    );
    const hasEmptyFields = this.templateVarsForms.filter(
      form =>
        (!(form.variableValue && form.variableName) &&
          form.variableDescription) ||
        (form.variableName && !form.variableValue) ||
        (!form.variableName && form.variableValue),
    ).length;
    return !invalidForms.length && !hasEmptyFields;
  }

  @computed
  get templateVarsData(): ITemplateVariable[] {
    return this.templateVarsForms
      .filter(form => form.variableName && form.variableValue)
      .map(form => {
        return {
          name: form.variableName,
          value: form.variableValue,
          description: form.variableDescription,
          system: false,
        };
      });
  }

  @action.bound
  setPreviewMode = (previewMode: boolean) => {
    this.isPreviewMode = previewMode;
  };

  @action.bound
  addTemplateVariable = () => {
    this.templateVarsForms.push(new TemplateVariableForm());
    this.templateVarsForms.forEach(form => {
      if (
        (form.variableName || form.variableDescription) &&
        !form.variableValue
      ) {
        form.setFieldRequiredValid('variableValue', false);
      }
      if (
        (form.variableValue || form.variableDescription) &&
        !form.variableName
      ) {
        form.setFieldRequiredValid('variableName', false);
      }
    });
  };

  @action.bound
  deleteTemplateVariable = index => {
    this.templateVarsForms =
      this.templateVarsForms.length === 1
        ? [new TemplateVariableForm()]
        : this.templateVarsForms.filter(
            (item, itemIndex) => index !== itemIndex,
          );
  };
}

export default TemplateVarsTableStore;
