import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/Icon/Icon';
import EnvVarTableItem from './EnvVarTableItem';
import Button, { BtnType } from '../Button/Button';
import EnvironmentVariableForm from '../EnvironmentPageServicesList/CreateMicroservice/EnvironmentVariableForm';
import WithPopper from '../WithPopper/WithPopper';

import style from './EnvironmentVariablesTable.module.scss';

interface Props {
  environmentVariablesForms: EnvironmentVariableForm[];
  addEnvVariable: () => void;
  deleteEnvVariable: (index) => void;
  previewMode?: boolean;
  className?: string;
  namesInUse?: string[];
  isDisabled?: boolean;
}

@observer
class EnvironmentVariablesTable extends Component<Props> {
  @computed
  get isEmptyTable() {
    const { previewMode, environmentVariablesForms } = this.props;
    return previewMode && !environmentVariablesForms.length;
  }

  addEnvVariable = () => {
    this.props.addEnvVariable();
  };

  checkEnvNameUniq = () => {
    const { environmentVariablesForms, namesInUse } = this.props;
    const allVarNames = environmentVariablesForms.map(
      form => form.variableName,
    );
    environmentVariablesForms.forEach((form, index) => {
      const envName = form.variableName;
      if (!form.validated) {
        return;
      }
      const isNameInUse =
        namesInUse &&
        namesInUse.length &&
        namesInUse.some(name => name === envName);
      if (
        allVarNames
          .filter((item, itemIndex) => index !== itemIndex)
          .some(name => name.length && name === envName) ||
        isNameInUse
      ) {
        form.setValidName(false);
        form.setErrorsMap('variableName');
      } else {
        form.setValidName(true);
        form.setErrorsMap('variableName');
      }
    });
  };

  deleteEnvVariable = (index: number) => {
    this.props.deleteEnvVariable(index);
  };

  componentDidUpdate() {
    this.checkEnvNameUniq();
  }

  render() {
    const { environmentVariablesForms, className, previewMode, isDisabled } =
      this.props;
    return (
      <div className={style[className || 'defaultTable']}>
        {this.isEmptyTable ? (
          <div className={style.emptyStateContainer}>
            <Icon type={'emptyBox'} />
            <div className={style.emptyStateNotification}>
              <FormattedMessage id="env.var.empty.notification" />
            </div>
          </div>
        ) : (
          <table className={style.table}>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="environment.variables.name" />
                </th>
                <th>
                  <FormattedMessage id="environment.variables.value" />
                </th>
                <th className={style.secureInfo}>
                  <FormattedMessage id="environment.variables.secure" />
                  <WithPopper
                    className={style.secureTooltip}
                    title={<FormattedMessage id={'env.var.secure.tooltip'} />}
                  >
                    <Icon type={'info'} className={style.infoBtn} />
                  </WithPopper>
                </th>
              </tr>
            </thead>
            <tbody>
              {environmentVariablesForms.map((item, index) => (
                <EnvVarTableItem
                  key={index}
                  index={index}
                  deleteEnvVariable={this.deleteEnvVariable}
                  checkEnvNameUniq={this.checkEnvNameUniq}
                  environmentVariableItem={item}
                  previewMode={previewMode}
                  isDisabled={isDisabled}
                />
              ))}
            </tbody>
          </table>
        )}
        <Button
          styleType={BtnType.Add}
          className={style.addButton}
          onClick={this.addEnvVariable}
        >
          <Icon type="plus2" />
        </Button>
      </div>
    );
  }
}

export default EnvironmentVariablesTable;
