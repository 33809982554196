import React from 'react';
import { FormattedMessage } from 'react-intl';

import ModalStore from 'stores/ModalStore';
import { MsStorageProviderDetails } from 'models/Service';

import DeleteStorageSupportModal from 'components/DeleteStorageSupportModal/DeleteStorageSupportModal';
import EnvVariablesModal from 'components/EnvVariablesModal/EnvVariablesModal';
import ActionMenu from 'components/ActionMenu/ActionMenu';
import { IActionItem } from 'components/ActionMenu/types';
import CopyFromEnvModal from './CopyFromEnvModal/CopyFromEnvModal';
import CleanDBModal from './CleanDBModal/CleanDBModal';

import style from './Menu.module.scss';

interface IProps {
  serviceProvider: MsStorageProviderDetails;
  params: {
    id: string;
    clusterName: string;
    environmentName: string;
    microserviceName: string;
  };
}

const Menu = ({ serviceProvider, params }: IProps) => {
  const openEnvVariablesModal = () => {
    const { host, port, dbname, user, password } = serviceProvider;
    ModalStore.showModal(EnvVariablesModal, {
      host,
      port,
      dbname,
      user,
      password,
    });
  };

  const openCopyFromEnvModal = () => {
    const { providerInstanceName } = serviceProvider;
    const {
      id: projectName,
      clusterName,
      environmentName,
      microserviceName,
    } = params;
    ModalStore.showModal(CopyFromEnvModal, {
      projectName,
      clusterName,
      environmentName,
      microserviceName,
      providerInstanceName,
    });
  };

  const openDeleteStorageSupportModal = () => {
    const { providerInstanceName } = serviceProvider;
    const {
      id: projectName,
      clusterName,
      environmentName,
      microserviceName,
    } = params;
    ModalStore.showModal(DeleteStorageSupportModal, {
      projectName,
      clusterName,
      environmentName,
      microserviceName,
      providerInstanceName,
    });
  };

  const openCleanDBModal = () => {
    const { providerInstanceName } = serviceProvider;
    const {
      id: projectName,
      clusterName,
      environmentName,
      microserviceName,
    } = params;
    ModalStore.showModal(CleanDBModal, {
      projectName,
      clusterName,
      environmentName,
      microserviceName,
      providerInstanceName,
    });
  };

  const menuItems: IActionItem[] = [
    {
      label: <FormattedMessage id="ms.sl.menu.view.env.variables" />,
      icon: 'visible',
      action: openEnvVariablesModal,
    },
    {
      label: <FormattedMessage id="ms.sl.menu.copy.from.env" />,
      icon: 'esmCopy',
      action: openCopyFromEnvModal,
    },
    {
      label: <FormattedMessage id="ms.sl.menu.clean.up.database" />,
      icon: 'db',
      action: openCleanDBModal,
    },
    {
      label: <FormattedMessage id="ms.sl.menu.delete.support" />,
      icon: 'esmDelete',
      action: openDeleteStorageSupportModal,
    },
  ];

  return (
    <ActionMenu
      items={menuItems}
      styles={{
        wrapper: style.wrapper,
        menu: style.menu,
        menuItem: style.menuItem,
      }}
    />
  );
};

export default Menu;
