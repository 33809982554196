import { action, observable, reaction } from 'mobx';
import {
  createIntl,
  createIntlCache,
  defineMessages,
  IntlShape,
} from 'react-intl';

import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';

export enum Locale {
  en = 'en',
  de = 'de',
}

const locale = 'LOCALE';
const defaultLocale = Locale.de;

class Localization {
  @observable.shallow messages: Record<string, string> = {};
  @observable locale = localStorage.getItem(locale) || defaultLocale;

  @observable intl: IntlShape;

  constructor() {
    reaction(
      () => this.locale,
      async value => {
        if (this.locale !== value) {
          this.locale = value;

          localStorage.setItem(locale, value);
          await this.fetchTranslation();
        }
      },
    );
  }

  @action
  async init() {
    await this.fetchTranslation();

    this.initIntl();

    reaction(
      () => this.locale,
      () => this.initIntl(),
    );
    reaction(
      () => this.messages,
      () => this.initIntl(),
    );
  }

  @action
  async fetchTranslation() {
    try {
      const response = await httpFacade.localization.fetchLocalization(
        this.locale,
      );

      this.messages = response.data;
    } catch (error) {
      Log.error(error);
    }
  }

  formatMessage(key: string, values?: Record<string, any>) {
    if (!this.intl) {
      Log.warn("Localization didn't init");
      return key;
    }

    const messages = defineMessages({
      [key]: {
        id: key,
      },
    });

    return this.intl.formatMessage(messages[key], values);
  }

  private initIntl() {
    // This is optional but highly recommended
    // since it prevents memory leak
    const cache = createIntlCache();

    this.intl = createIntl(
      {
        locale: this.locale,
        messages: this.messages,
        textComponent: 'span',
      },
      cache,
    );
  }
}

export default Localization;
