import React from 'react';
import { FormattedMessage } from 'react-intl';

import httpFacade from 'http/httpFacade';
import ModalStore from 'stores/ModalStore';
import { ServiceDetails } from 'models/Service';
import { IEnvironmentParams } from 'models/Environment';

import ActionMenuWithPopover from 'components/ActionMenu/ActionMenuWithPopover';
import { BoundaryElementType } from 'components/FloatingComponents/types';
import { IActionItem } from 'components/ActionMenu/types';
import ScaleMSModal from 'components/MicroservicePageHeader/ScaleMSModal/ScaleMSModal';
import InstallArtifactModal from 'components/MicroservicePageArtifact/InstallArtifactModal/InstallArtifactModal';

import style from './Menu.module.scss';

interface Props {
  service: ServiceDetails;
  params: IEnvironmentParams;
  boundaryElement?: BoundaryElementType;
}

const Menu = ({ service, params, boundaryElement }: Props) => {
  const openUpdateMicroserviceModal = () => {
    ModalStore.showModal(InstallArtifactModal, {
      image: service.update ? service.update.latest.fullImage : '',
      updateMicroservice: () =>
        httpFacade.service.updateService(
          { ...params, serviceName: service.info.name },
          { image: service.update ? service.update.latest.fullImage : '' },
        ),
    });
  };

  const scaleMS = () => {
    ModalStore.showModal(ScaleMSModal, {
      params: { ...params, serviceName: service.info.name },
      msLongName: service.info.longName,
      value: Number(service.info.runtimeState.desired),
    });
  };

  const menuItems: IActionItem[] = [
    {
      label: <FormattedMessage id="update.microservice" />,
      action: openUpdateMicroserviceModal,
      disabled: !service.update,
      popper: !service.update ? 'ms.uptodate' : '',
    },
    {
      label: <FormattedMessage id="scale.ms" />,
      action: scaleMS,
    },
  ];

  return (
    <ActionMenuWithPopover
      items={menuItems}
      styles={{
        menu: style.menu,
        menuItem: style.menuItem,
      }}
      boundaryElement={boundaryElement}
    />
  );
};

export default Menu;
