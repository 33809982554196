import React, { FC, useState } from 'react';
import classNames from 'classnames';

import Icon from 'components/Icon/Icon';

import style from './SelectField.module.scss';
import { Icons } from 'components/Icon';
import { useClickOutside } from '../../../../hooks/useClickOutside';

type OptionValue = string | number;

interface Option<T extends OptionValue> {
  value: T;
  label: string | JSX.Element;
}

interface Props<T extends OptionValue> {
  className?: string;
  iconType?: string;
  value: T;
  onChange: (v: T) => void;
  options: Array<Option<T>>;
  hasErrors?: boolean;
  hasWarnings?: boolean;
  isSucceed?: boolean;
  hasInfo?: boolean;
  placeholder?: string;
  dropUp?: boolean;
}

const SelectField: FC<Props<OptionValue>> = ({
  className,
  iconType,
  value,
  onChange,
  options,
  hasErrors,
  hasWarnings,
  isSucceed,
  hasInfo,
  placeholder,
  dropUp,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const wrapperRef = React.createRef<HTMLDivElement>();
  useClickOutside(wrapperRef, () => setIsOpened(false));

  const toggleList = () => {
    setIsOpened(!isOpened);
  };

  const onChangeFunc = e => {
    onChange(e.target.value);
  };

  const valueFunc = () => {
    const option = options.find(item => item.value === value);
    return option ? option.value : '';
  };

  const colors = {
    [style.wrapper__info]: isSucceed,
    [style.wrapper__warning]: hasWarnings,
    [style.wrapper__error]: hasErrors,
    [style.wrapper__success]: hasInfo,
  };

  return (
    <div
      className={classNames(style.wrapper, colors, className)}
      onClick={toggleList}
      ref={wrapperRef}
      tabIndex={0}
    >
      <div
        className={classNames(style.field, {
          [style.fieldActive]: isOpened,
        })}
      >
        {valueFunc() || placeholder}
      </div>
      {iconType && (
        <Icon className={style.icon} type={iconType as keyof typeof Icons} />
      )}
      <Icon
        className={classNames(style.selectIcon, {
          [style.selectIconActive]: isOpened,
        })}
        type="arrowBottom"
      />
      {isOpened && (
        <div
          className={classNames(style.menu, {
            [style.dropUp]: dropUp,
            [style.dropDown]: !dropUp,
          })}
        >
          <ul>
            {options.map(option => (
              <li
                key={option.value}
                className={classNames({
                  [style.active]: valueFunc() === option.value,
                })}
                onClick={() => {
                  onChangeFunc({ target: { value: option.value } });
                }}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SelectField;
