import React, { useState } from 'react';
import style from './InfoButton.module.scss';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Icon from 'components/Icon/Icon';
import { usePopper } from 'react-popper';
import { observer } from 'mobx-react';

export enum RulePrefix {
  ms = 'ms',
  env = 'env',
}

interface Props {
  rules: Rule[];
  rulePrefix?: RulePrefix;
  className?: string;
}

interface Rule {
  msgKey: string;
  id: number;
  isValid: boolean;
}

export const InfoButton: React.FC<Props> = observer((props: Props) => {
  const { rules, rulePrefix, className } = props;
  const formattedMessagesValues = {};

  if (rulePrefix) {
    for (let i = 1; i <= 11; i++) {
      formattedMessagesValues[i] = (
        <span className={style.keyword}>
          <FormattedMessage
            id={`validation.${rulePrefix}.rule.${i}`}
            defaultMessage={`validation.${rulePrefix}.rule.${i}`}
          />
        </span>
      );
    }
  }

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null,
  );
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const [isVisible, toggleVisibility] = useState<boolean>(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right',
    strategy: 'fixed',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', options: { offset: [10, 8] } },
    ],
  });

  const togglePopup = () => {
    toggleVisibility(!isVisible);
  };

  const generateListItem = (msg: Rule) => {
    return (
      <li className={style.error} key={msg.id}>
        <FormattedMessage id={msg.msgKey} values={formattedMessagesValues} />
      </li>
    );
  };

  const hasErrors = !!rules.find(msg => msg.isValid === false);
  return (
    <div className={classNames(className)} ref={setReferenceElement}>
      <Icon
        type={'infoBtn'}
        onClick={togglePopup}
        className={classNames(style.infoBtn, hasErrors && style.infoBtn__error)}
      />
      {isVisible ? (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className={classNames(style.popup, isVisible && style.popup__visible)}
        >
          <div className={style.header}>
            {hasErrors ? (
              <>
                <Icon type={'crossWrong'} className={style.headerIcon} />
                <FormattedMessage id={'validation.env.header.invalid'} />
              </>
            ) : (
              <>
                <Icon type={'unknown'} className={style.headerIcon} />
                <FormattedMessage id={'validation.env.header.valid'} />
              </>
            )}
          </div>
          {rules.find(msg => msg.isValid === false) && (
            <ul className={style.errorList}>
              {rules
                .filter((msg: Rule) => msg.isValid === false)
                .map((msg: Rule) => {
                  return generateListItem(msg);
                })}
            </ul>
          )}
          <ul className={style.rulesList}>
            {rules
              .filter((msg: Rule) => msg.isValid === true)
              .map((msg: Rule) => {
                return generateListItem(msg);
              })}
          </ul>
          <div
            ref={setArrowElement}
            style={styles.arrow}
            className={classNames(style.arrow)}
          />
        </div>
      ) : null}
    </div>
  );
});
