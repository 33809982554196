import http from '../http';
import { appUrl } from 'http/helpers';
import URLS from 'http/urls';
import {
  EnvironmentDetails,
  Environment,
  IEnvironmentParams,
  EnvironmentOther,
  NewEnvForm,
  CloneEnvForm,
  NewEnvResponse,
  IClusterParams,
  ITargetEnvParams,
  IComparingEnvironment,
  IApplyToData,
} from 'models/Environment';

export const environmentApi = {
  fetchEnvironmentBranches(replaceParams: IEnvironmentParams, params) {
    return http.get<any>(appUrl(URLS.environmentBranches), {
      replaceParams,
      params,
    });
  },

  switchToBranch(
    branchName: string,
    microservices: string[],
    params: IEnvironmentParams,
    force: boolean = false,
  ) {
    return http.put<any>(
      appUrl(URLS.switchToBranch),
      { branchName, microservices },
      {
        replaceParams: params,
        params: {
          force,
        },
      },
    );
  },

  fetchEnvironments(projectName: string, clusterName: string) {
    return http.get<Environment[]>(appUrl(URLS.environments), {
      replaceParams: {
        projectName,
        clusterName,
      },
    });
  },

  fetchEnvironment(params: IEnvironmentParams) {
    return http.get<Environment>(appUrl(URLS.environment), {
      replaceParams: params,
    });
  },

  fetchEnvironmentDetails(params: IEnvironmentParams) {
    return http.get<EnvironmentDetails>(appUrl(URLS.environmentDetails), {
      replaceParams: params,
    });
  },

  fetchEnvironmentOthers(params: IEnvironmentParams) {
    return http.get<EnvironmentOther[]>(appUrl(URLS.environmentOthers), {
      replaceParams: params,
    });
  },

  createNewEnvironment(formData: NewEnvForm, params: IClusterParams) {
    return http.post<NewEnvForm, NewEnvResponse>(
      appUrl(URLS.environments),
      formData,
      {
        replaceParams: params,
      },
    );
  },

  updateMSsToLastVersion(microservices: string[], params: IEnvironmentParams) {
    return http.put(appUrl(URLS.updateMSsToLastVersion), microservices, {
      replaceParams: params,
    });
  },

  cloneEnvironment(formData: CloneEnvForm, params: IClusterParams) {
    return http.post<CloneEnvForm, NewEnvResponse>(
      appUrl(URLS.environments),
      formData,
      {
        replaceParams: params,
      },
    );
  },

  deleteEnvironment(params: IEnvironmentParams) {
    return http.delete(appUrl(URLS.deleteEnvironment), {
      replaceParams: params,
    });
  },

  setAutoUpdateEnvironment(updatable: boolean, params: IEnvironmentParams) {
    return http.put(
      `${appUrl(URLS.setAutoUpdateEnvironment)}?updatable=${updatable}`,
      null,
      {
        replaceParams: params,
      },
    );
  },

  compareMSs(params: IEnvironmentParams, targetParams: ITargetEnvParams) {
    return http.get<IComparingEnvironment>(appUrl(URLS.compare), {
      replaceParams: params,
      params: targetParams,
    });
  },

  applyTo(params: IEnvironmentParams, applyToData: IApplyToData) {
    return http.put(appUrl(URLS.applyTo), applyToData, {
      replaceParams: params,
    });
  },
};
