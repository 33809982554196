class PopoverStore {
  container: Element | null = null;

  getContainer(): Element | null {
    if (this.container) {
      return this.container;
    } else {
      this.container = document.querySelector('#popovers');
      return this.container;
    }
  }
}

export default new PopoverStore();
