import { action, observable } from 'mobx';

import { BaseFormModel, displayName, isSameAs } from 'stores/BaseForm';

class DeleteEnvironmentForm extends BaseFormModel {
  @displayName('environment.modal.form.label.environment.name.example')
  @observable
  environmentNameExample = '';

  @observable
  @displayName('environment.modal.form.label.environment.name')
  @isSameAs('environmentNameExample', 'wrong.env.msg')
  environmentName: string = '';

  @action
  setEnvironmentNameExample = environmentName => {
    this.environmentNameExample = environmentName;
  };
}

export default DeleteEnvironmentForm;
