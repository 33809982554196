import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { observable } from 'mobx';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import RootStore from 'stores/RootStore';
import { DialogActionType, ModalProps } from 'stores/ModalStore';
import { bindFormControl } from '../../Form/FormControl/FormControl';
import TextField from '../../Form/Fields/TextField/TextField';
import EditProjectForm from './EditProjectForm';
import CommonModal from '../../Modals/Modal/CommonModal';
import PasswordField from 'components/Form/Fields/PasswordField/PasswordField';
import NotificationService, {
  fullscreenNotificationOptions,
} from 'components/Notification/NotificationService';

import style from './EditProjectModal.module.scss';

@observer
class EditProjectModal extends Component<ModalProps> {
  @observable editProjectModal = new EditProjectForm();
  FormControl = bindFormControl(this.editProjectModal);

  onClose = () => {
    this.props.onClose(DialogActionType.cancel);
  };

  onConfirm = async () => {
    try {
      this.props.onClose(DialogActionType.submit);
      await this.editProjectModal.updateProjectSettings();
      NotificationService.successMessage(
        { id: 'project.edit.success' },
        fullscreenNotificationOptions,
      );
    } catch (error) {
      NotificationService.errorMessage(
        { id: 'project.edit.error' },
        fullscreenNotificationOptions,
      );
    }
  };

  componentDidMount() {
    this.editProjectModal.fetchProjectData(RootStore.currentProject);
  }

  render() {
    const FormControl = this.FormControl;

    return (
      <CommonModal
        className={style.EditProjectBody}
        title="header.settings.edit.roject"
        cancel="button.cancel"
        confirm="button.save"
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        fullScreen
      >
        <div className={style.sectionWrapper}>
          <div className={style.text}>
            <FormattedMessage id="project.edit.heading1" />
          </div>
          <div
            className={classNames(
              style.formControlWrapper,
              style.formControlWrapper__half,
              style.formControlWrapper__half__oneChild,
            )}
          >
            <FormControl
              className={style.input}
              name="projectName"
              render={props => <TextField {...props} disabled />}
            />
          </div>
        </div>
        <div className={style.divider} />
        <div className={style.sectionWrapper}>
          <div className={style.text}>
            <FormattedMessage id="project.edit.heading2" />
          </div>
          <div className={style.formControlWrapper}>
            <FormControl
              className={style.input}
              name="issueTrackerURL"
              render={props => <TextField {...props} />}
            />
          </div>
          <div className={style.inputSection}>
            <div
              className={classNames(
                style.formControlWrapper,
                style.formControlWrapper__half,
              )}
            >
              <FormControl
                className={style.input}
                name="issueTrackerUserName"
                render={props => <TextField {...props} />}
              />
            </div>
            <div
              className={classNames(
                style.formControlWrapper,
                style.formControlWrapper__half,
              )}
            >
              <FormControl
                className={style.input}
                name="issueTrackerPassword"
                render={props => <PasswordField {...props} />}
              />
            </div>
          </div>
        </div>
        <div className={style.divider} />
        <div className={style.sectionWrapper}>
          <div className={style.text}>
            <FormattedMessage id="project.edit.heading3" />
          </div>
          <div className={style.formControlWrapper}>
            <FormControl
              className={style.input}
              name="registryURL"
              render={props => <TextField {...props} />}
            />
          </div>
          <div className={style.inputSection}>
            <div
              className={classNames(
                style.formControlWrapper,
                style.formControlWrapper__half,
              )}
            >
              <FormControl
                className={style.input}
                name="registryUserName"
                render={props => <TextField {...props} />}
              />
            </div>
            <div
              className={classNames(
                style.formControlWrapper,
                style.formControlWrapper__half,
              )}
            >
              <FormControl
                className={style.input}
                name="registryPassword"
                render={props => <PasswordField {...props} />}
              />
            </div>
          </div>
        </div>
      </CommonModal>
    );
  }
}

export default EditProjectModal;
