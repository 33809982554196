import { DialogActionType, ModalProps } from '../../../stores/ModalStore';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { observable } from 'mobx';
import { bindFormControl } from '../../Form/FormControl/FormControl';
import { FormattedMessage } from 'react-intl';
import EncryptMessageForm from './EncryptMessageForm';
import NotificationService, {
  fullscreenNotificationOptions,
} from '../../Notification/NotificationService';
import CommonModal from '../../Modals/Modal/CommonModal';
import Button, { BtnType } from 'components/Button/Button';
import TextArea from 'components/Form/Fields/TextArea/TextArea';
import Switcher from 'components/Form/Fields/Switcher/Switcher';

import style from './EncryptMessageModal.module.scss';

interface Props extends ModalProps {
  projectName: string;
  clusterName: string;
}

@observer
class EncryptMessageModal extends Component<Props> {
  @observable encryptMessageForm = new EncryptMessageForm();
  FormControl = bindFormControl(this.encryptMessageForm);

  @observable base64: boolean = false;

  @observable
  isUIblocked: boolean = false;

  onChangeBase64 = () => {
    this.base64 = !this.base64;
  };

  onClose = () => {
    if (!this.isUIblocked) {
      this.props.onClose(DialogActionType.cancel);
    }
  };

  onConfirm = async () => {
    try {
      this.isUIblocked = true;
      await this.encryptMessageForm.encryptData(
        this.props.projectName,
        this.props.clusterName,
        this.base64,
      );
      this.isUIblocked = false;
    } catch (e) {
      const { data } = e.response;
      const errorString = data.id || 'response.error';
      NotificationService.errorMessage(
        { id: errorString },
        fullscreenNotificationOptions,
      );
      this.isUIblocked = false;
    }
  };

  render() {
    const FormControl = this.FormControl;

    return (
      <CommonModal
        isCancellable={false}
        isLeftCancel
        bodyContentClassName={style.bodyContent}
        title="encrypt.message"
        cancel="button.cancel"
        confirm="encrypt"
        onClose={this.onClose}
        onLeftCancel={this.onClose}
        onConfirm={this.onConfirm}
        isUIblocked={this.isUIblocked}
        isSubmitBtnDisabled={!this.encryptMessageForm.message}
        advancedControls={
          <>
            <Button
              className={style.btnContainer}
              styleType={BtnType.Secondary}
              onClick={this.encryptMessageForm.clear}
              disabled={
                !this.encryptMessageForm.message &&
                !this.encryptMessageForm.encryptMessage
              }
            >
              <FormattedMessage id={'clear.all'} />
            </Button>
            <Button
              className={style.btnContainer}
              styleType={BtnType.Secondary}
              onClick={this.encryptMessageForm.copyToClipboard}
              disabled={!this.encryptMessageForm.encryptMessage}
            >
              <FormattedMessage id={'copy.encripted'} />
            </Button>
          </>
        }
      >
        <FormControl
          className={style.input}
          name="message"
          render={props => <TextArea rows={9} {...props} />}
        />
        <FormControl
          className={style.input}
          name="encryptMessage"
          render={props => (
            <TextArea
              rows={9}
              {...props}
              disabled={!this.encryptMessageForm.encryptMessage}
            />
          )}
        />
        <div className={style.checkboxContainer}>
          <Switcher
            label="base64"
            className={style.checkbox}
            checked={this.base64}
            onChange={this.onChangeBase64}
          />
        </div>
      </CommonModal>
    );
  }
}

export default EncryptMessageModal;
