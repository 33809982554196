import { ActionId } from 'models/Template';

export const getUniqNameErrorMessage = (name: string) => {
  return `Action Template name '${name}' is already in use.`;
};

export const EditTemplateFormTitleToActionId: Record<ActionId, string> = {
  [ActionId.create_environment]: 'templates.edit.env.template',
  [ActionId.create_microservice]: 'templates.edit.ms.template',
};

export const CreateTemplateFormTitleToActionId: Record<ActionId, string> = {
  [ActionId.create_environment]: 'templates.create.env.template',
  [ActionId.create_microservice]: 'templates.create.ms.template',
};

export const ViewTemplateFormTitleToActionId: Record<ActionId, string> = {
  [ActionId.create_environment]: 'templates.env.template',
  [ActionId.create_microservice]: 'templates.ms.template',
};
