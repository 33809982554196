import React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { IPodParams } from 'models/Pod';
import { PodLogsStore } from 'stores/PodsPage/PodLogsStore';

import EmptyContent from 'components/EmptyContent/EmptyContent';
import CircleLoader from 'components/CircleLoader/CircleLoader';
import LogsPanel from 'components/LodsPanel/LodsPanel';

import style from './PodLogs.module.scss';

interface IProps {
  podParams: IPodParams;
}

@observer
class PodLogs extends React.Component<IProps> {
  logsStore = new PodLogsStore(this.props.podParams);

  async componentDidMount() {
    await this.logsStore.fetchPodLogs();
  }

  render() {
    const hasLogs = !!this.logsStore.logs.length;
    const showLoading = this.logsStore.isLoading;
    const showLogs = !this.logsStore.isLoading && hasLogs;
    const showEmptyMessage = !this.logsStore.isLoading && !hasLogs;

    return (
      <div className={style.logsWrapper}>
        {showLoading && <CircleLoader className={style.loader} />}
        {showLogs && (
          <>
            <div className={style.title}>
              <FormattedMessage id="pods.events" />
            </div>
            <LogsPanel logs={this.logsStore.logs} />
          </>
        )}
        {showEmptyMessage && (
          <EmptyContent
            icon="emptyBox"
            text="pod.logs.empty.message"
            className={style.emptyContent}
            messageClassName={style.emptyContentMessage}
          />
        )}
      </div>
    );
  }
}

export default PodLogs;
