import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { FormattedMessage } from 'react-intl';

import { ModalProps, DialogActionType } from 'stores/ModalStore';
import TextField from 'components/Form/Fields/TextField/TextField';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import CleanDBForm from './CleanDBForm';

import style from './CleanDBModal.module.scss';
import CommonModal, { ModalType } from 'components/Modals/Modal/CommonModal';

interface Props extends ModalProps {
  clusterName: string;
  projectName: string;
  environmentName: string;
  microserviceName: string;
  providerInstanceName: string;
}

@observer
class CleanDBModal extends Component<Props> {
  @observable cleanDBForm = new CleanDBForm();
  FormControl = bindFormControl(this.cleanDBForm);
  @observable
  serverErrors: string[] = [];

  @observable
  serverWarnings: string[] = [];

  @observable
  isUIblocked: boolean = false;

  onClose = () => {
    if (!this.isUIblocked) {
      this.props.onClose(DialogActionType.cancel);
    }
  };

  onConfirm = async () => {
    try {
      const isValid = this.cleanDBForm.validate();
      if (isValid) {
        this.isUIblocked = true;
        await this.cleanDBForm.cleanDB(this.props);
        this.props.onClose(DialogActionType.submit);
      }
    } catch (error) {
      this.serverErrors = error.response.data.errorMessages || [];
      this.serverWarnings = error.response.data.warningMessages || [];
    } finally {
      this.isUIblocked = false;
    }
  };

  componentDidMount() {
    const { providerInstanceName } = this.props;
    this.cleanDBForm.setProviderInstanceNameExample(providerInstanceName);
  }

  onProviderInstanceNameChange = (value: string) => {
    this.cleanDBForm.validate();
    if (value.length > 0) {
      this.cleanDBForm.setValue('providerInstanceName', value);
    } else {
      this.cleanDBForm.setValue('providerInstanceName', '');
    }
  };

  render() {
    const { providerInstanceName } = this.props;
    const FormControl = this.FormControl;

    return (
      <CommonModal
        className={style.body}
        title="clean.db.title"
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        confirm={'clean.up'}
        headerIcon="modalError"
        serverErrors={this.serverErrors}
        serverWarnings={this.serverWarnings}
        isSubmitBtnDisabled={!this.cleanDBForm.isFormValid}
        isUIblocked={this.isUIblocked}
        modalType={ModalType.Delete}
      >
        <div className={style.text1}>
          <FormattedMessage
            id="clean.db.text1"
            values={{
              providerInstanceName,
            }}
          />
        </div>
        <div className={style.text2}>
          <FormattedMessage id="clean.db.text2" />
        </div>
        <div className={style.text2}>
          <FormattedMessage
            id="clean.db.text3"
            values={{
              providerInstanceName: (
                <span className={style.example}>{providerInstanceName}</span>
              ),
            }}
          />
        </div>
        <FormControl
          className={style.input}
          name="providerInstanceName"
          render={props => (
            <TextField
              {...props}
              onChange={this.onProviderInstanceNameChange}
            />
          )}
        />
      </CommonModal>
    );
  }
}

export default CleanDBModal;
