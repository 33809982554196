import React, { FC, InputHTMLAttributes } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import style from './Switcher.module.scss';

interface SwitcherProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  withCheck?: boolean;
}

const Switcher: FC<SwitcherProps> = ({
  checked,
  className,
  label,
  type,
  withCheck = true,
  ...restProps
}) => (
  <div className={classNames(style.container, className)}>
    <label className={style.switch}>
      <input checked={checked} type="checkbox" {...restProps} />
      <span
        className={classNames(style.slider, withCheck && style.sliderWithCheck)}
      />
    </label>
    <span className={classNames(style.text, { [style.checkedText]: checked })}>
      {label && <FormattedMessage id={label} />}
    </span>
  </div>
);

export default Switcher;
