import { action, observable } from 'mobx';
import httpFacade from 'http/httpFacade';
import Log from 'helpers/log';
import { ITemplate, ITemplateVariable, TemplatesParams } from 'models/Template';
import RootStore from 'stores/RootStore';

class TemplateStore {
  @observable templatesVariables: ITemplateVariable[] = [];
  @observable fetchingVariables = false;
  @observable isUIblocked = false;

  readonly templateParams: TemplatesParams;

  constructor(params: TemplatesParams) {
    this.templateParams = params;
  }

  init = async () => {
    await this.fetchTemplatesVariables();
  };

  @action
  fetchTemplatesVariables = async () => {
    try {
      this.fetchingVariables = true;
      const { data } = await httpFacade.templates.fetchTemplatesVariables(
        this.templateParams,
      );
      this.templatesVariables = data;
    } catch (error) {
      Log.warn(error);
    } finally {
      this.fetchingVariables = false;
    }
  };

  createUpdateTemplate = async (data: ITemplate, templateId?: string) => {
    try {
      this.isUIblocked = true;
      if (templateId) {
        await httpFacade.templates.updateTemplate(data, {
          projectName: RootStore.currentProject,
          clusterName: this.templateParams.clusterName,
          templateName: templateId,
          actionId: this.templateParams.actionId,
        });
      } else {
        await httpFacade.templates.createTemplate(data, {
          projectName: RootStore.currentProject,
          clusterName: this.templateParams.clusterName,
          actionId: this.templateParams.actionId,
        });
      }
    } catch (error) {
      throw error;
    } finally {
      this.isUIblocked = false;
    }
  };
}

export default TemplateStore;
