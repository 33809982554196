import { observable, action } from 'mobx';

import {
  BaseFormModel,
  displayName,
  hasMinMaxLength,
  hasNoSpecialWords,
  isMatchRegExp,
  isRequired,
} from 'stores/BaseForm';
import { ENV_KEY_START_END_REGEXP, KEY_REGEXP } from 'helpers/testRegexp';

import httpFacade from 'http/httpFacade';
import NotificationService, {
  fullscreenNotificationOptions,
} from '../Notification/NotificationService';

class CreateEnvironmentForm extends BaseFormModel {
  @observable
  @displayName('environment.modal.form.label.environment.name')
  @isRequired()
  envName: string = '';

  @observable
  @displayName('environment.modal.form.label.key')
  @hasMinMaxLength(1, 63, 'validation.env.key.length')
  @isMatchRegExp(KEY_REGEXP, 'validation.env.key.characters')
  @isMatchRegExp(ENV_KEY_START_END_REGEXP, 'validation.env.key.startend')
  @hasNoSpecialWords(
    ['default', 'kube-system', 'kube-public', 'kube-node-lease'],
    'validation.env.key.reserved',
  )
  key: string = '';

  @action.bound
  async submit(projectName: string, clusterName: string) {
    try {
      await httpFacade.environment.createNewEnvironment(
        { name: this.key, longName: this.envName },
        { projectName, clusterName },
      );
      NotificationService.successMessage(
        { id: 'env.created' },
        fullscreenNotificationOptions,
      );
    } catch (error) {
      if (
        error.response.data.errorMessages ||
        error.response.data.warningMessages
      ) {
        throw error;
      } else {
        NotificationService.errorMessage({ id: 'env.fail' });
      }
    }
  }
}

export default CreateEnvironmentForm;
