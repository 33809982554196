import React, { useState } from 'react';

import { Omit, GetComponentProps } from 'helpers/types';
import TextField from '../TextField/TextField';

import styles from './PasswordField.module.scss';

const PasswordField = (props: Omit<GetComponentProps<TextField>, 'type'>) => {
  const [type, toggle] = useState('password');
  const toogleType = () => {
    type === 'password' ? toggle('text') : toggle('password');
  };
  const iconType = type === 'password' ? 'hidden' : 'visible';
  const iconTooltip = type === 'password' ? 'show.password' : 'hide.password';

  return (
    <>
      <TextField
        className={styles.input}
        type={type}
        iconType={iconType}
        iconTooltip={iconTooltip}
        onIconClick={toogleType}
        {...props}
      />
    </>
  );
};

export default PasswordField;
