import { afterWrite } from '@popperjs/core/lib/enums';

export interface IClosePopperOptions {
  boundaryElementRect: DOMRect;
  onClose: () => void;
}

const closePopper = ({ boundaryElementRect, onClose }: IClosePopperOptions) => {
  return {
    name: 'closePopper',
    enabled: true,
    phase: afterWrite,
    fn({ state }) {
      const referenceRect = state.rects.reference;

      if (
        referenceRect.y + referenceRect.height <= boundaryElementRect.top ||
        referenceRect.y >= boundaryElementRect.bottom
      ) {
        onClose();
      }
    },
  };
};

export { closePopper };
