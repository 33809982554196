import { getDisplayName } from '../helpers';
import { addValidation } from '../Validation';

export function isRequired(msg?: string) {
  return (target: any, fieldName: string) => {
    const validation = {
      fieldName,
      validateFunction: (obj: any) => {
        const name = getDisplayName(obj, fieldName);
        const value = obj[fieldName];

        return value || value === 0
          ? undefined
          : {
              message: msg || 'form.field.is.required',
              data: {
                name,
                value,
              },
            };
      },
    };

    addValidation(target, fieldName, validation);
  };
}
