import { action, observable } from 'mobx';

import { BaseFormModel, displayName, isSameAs } from 'stores/BaseForm';

class DeleteMicroserviceForm extends BaseFormModel {
  @displayName('service.modal.form.label.service.name.example')
  @observable
  serviceNameExample = '';

  @observable
  @displayName('service.modal.form.label.service.name')
  @isSameAs('serviceNameExample', 'wrong.ms.msg')
  serviceName: string = '';

  @action
  setServiceNameExample = serviceName => {
    this.serviceNameExample = serviceName;
  };
}

export default DeleteMicroserviceForm;
