import React, { Component } from 'react';
import classNames from 'classnames';

import style from './TagsField.module.scss';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import Icon from '../Icon/Icon';
import { FormattedMessage } from 'react-intl';

interface Props {
  tags: string[];
  handleDelete: (index: number) => void;
  handleAddition: (tag: string) => void;
  label: string;
  className?: string;
}

@observer
class TagsField extends Component<Props> {
  inputRef = React.createRef<HTMLInputElement>();
  @observable tag = '';

  onChange = event => (this.tag = event.target.value);

  onKeyUp = event => {
    if ((event.keyCode === 13 || event.keyCode === 32) && this.tag.trim()) {
      this.props.handleAddition(this.tag.trim());
      this.tag = '';
    }
  };

  onBlur = () => {
    if (this.tag.trim()) {
      this.props.handleAddition(this.tag.trim());
      this.tag = '';
    }
  };

  deleteTag = index => {
    this.props.handleDelete(index);
  };

  focusInput = () => {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  public render(): JSX.Element {
    const { tags, className, label } = this.props;

    return (
      <div
        className={classNames(style.field, className)}
        onClick={this.focusInput}
      >
        <label
          htmlFor="tagInput"
          className={classNames(style.fieldLabel, {
            [style.fieldLabel__active]: tags.length || this.tag,
          })}
        >
          <FormattedMessage id={label} tagName={React.Fragment} />
        </label>

        {Boolean(tags.length) && (
          <ul className={style.tags}>
            {tags.map((tag, index) => (
              <li key={index} className={style.tag}>
                <span className={style.tagTitle}>{tag}</span>
                <Icon
                  type={'close'}
                  className={style.deleteTagIcon}
                  onClick={() => this.deleteTag(index)}
                />
              </li>
            ))}
          </ul>
        )}

        <input
          id="tagInput"
          value={this.tag}
          className={style.tagInput}
          onKeyUp={this.onKeyUp}
          onChange={this.onChange}
          onBlur={this.onBlur}
          ref={this.inputRef}
        />
        <label className={style.tagInputLabel} htmlFor="tagInput">
          <Icon type={'search'} />
        </label>
      </div>
    );
  }
}

export default TagsField;
