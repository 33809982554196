import React, { FC } from 'react';
import classNames from 'classnames';

import style from './ClusterCard.module.scss';
import { FormattedMessage } from 'react-intl';
import ClusterCardWrapper from '../ClusterCardWrapper/ClusterCardWrapper';
import Alert from 'components/Alert/Alert';
import { AlertReferenceKind, AlertType } from 'models/Alert';
import {
  ClusterDetails,
  ClusterStateAlertTypes,
  ClusterState,
} from 'models/Cluster';
import Status, { StatusType } from 'components/Status/Status';
import ClusterImage from '../ClusterImage/ClusterImage';
import { observer, inject } from 'mobx-react';
import { DisplayedAlerts } from 'stores/AlertsStore';

interface Props {
  cluster: ClusterDetails;
  className?: string;
  isExternalStorageAvailable?: boolean;
  getClusterDataFromLink?: () => void;
  gotoClusterPage?: () => void;
  alertsStore?;
}

const ClusterCard: FC<Props> = inject('alertsStore')(
  observer(
    ({
      cluster,
      className = '',
      isExternalStorageAvailable = false,
      getClusterDataFromLink,
      gotoClusterPage,
      alertsStore,
    }) => {
      const { state, nodeCount, errorCountPerHour } = cluster;

      const toggleAlertDrawerCluster = () => {
        if (!alertsStore) {
          return;
        }
        alertsStore.showAlertDrawer(
          [{ clusterReference: cluster.info.name }],
          `ClusterImage${cluster.info.name}`,
        );
      };

      const getAvailabilityData = () => {
        const data = [
          {
            name: 'cluster.availability.git',
            alerts: alertsStore.getAlertsByFilters(
              [
                {
                  availabilityReference: AlertReferenceKind.Git,
                  clusterReference: cluster.info.name,
                },
              ],
              alertsStore.alerts,
              DisplayedAlerts.Visible,
              alertsStore.hiddenAlertsCounters,
            ),
          },
          {
            name: 'cluster.availability.issueTracking',
            alerts: alertsStore.getAlertsByFilters(
              [
                {
                  availabilityReference: AlertReferenceKind.IssueTracker,
                  clusterReference: cluster.info.name,
                },
              ],
              alertsStore.alerts,
              DisplayedAlerts.Visible,
              alertsStore.hiddenAlertsCounters,
            ),
          },
          {
            name: 'cluster.availability.k8s',
            alerts: alertsStore.getAlertsByFilters(
              [
                {
                  availabilityReference: AlertReferenceKind.K8s,
                  clusterReference: cluster.info.name,
                },
              ],
              alertsStore.alerts,
              DisplayedAlerts.Visible,
              alertsStore.hiddenAlertsCounters,
            ),
          },
          {
            name: 'cluster.availability.registry',
            alerts: alertsStore.getAlertsByFilters(
              [
                {
                  availabilityReference: AlertReferenceKind.DockerRegistry,
                  clusterReference: cluster.info.name,
                },
              ],
              alertsStore.alerts,
              DisplayedAlerts.Visible,
              alertsStore.hiddenAlertsCounters,
            ),
          },
        ];
        if (isExternalStorageAvailable) {
          data.push({
            name: 'cluster.availability.storages',
            alerts: alertsStore.getAlertsByFilters(
              [
                {
                  availabilityReference: AlertReferenceKind.Esm,
                  clusterReference: cluster.info.name,
                },
              ],
              alertsStore.alerts,
              DisplayedAlerts.Visible,
              alertsStore.hiddenAlertsCounters,
            ),
          });
        }
        return data;
      };

      let availabilityData = getAvailabilityData();

      React.useEffect(() => {
        availabilityData = getAvailabilityData();
      }, [alertsStore.availabilityAlertsSlice]);

      return (
        <ClusterCardWrapper
          gotoClusterPage={gotoClusterPage}
          className={classNames(className)}
          title="page.project.cluster"
          type={cluster.info.type}
          name={cluster.info.name}
          top
        >
          <div className={style.wrapper}>
            <span className={style.wrapper__subtitle}>
              <FormattedMessage id="page.project.status" />
            </span>
            {state ? (
              <Alert type={AlertType[ClusterStateAlertTypes[state]]}>
                <FormattedMessage id={`page.project.${state}`} />
              </Alert>
            ) : (
              <Alert type={AlertType.Unset}>
                <FormattedMessage id={`page.project.unknown`} />
              </Alert>
            )}
          </div>

          <div className={style.wrapper}>
            <span className={style.wrapper__subtitle}>
              <FormattedMessage id="page.project.nodes" />
            </span>
            <div className={style.wrapper__nodes}>
              <Status
                statusType={
                  state === ClusterState.Stopped
                    ? StatusType.Unset
                    : StatusType.Normal
                }
              />
              <span className={style.wrapper__value}>{nodeCount || 0}</span>
            </div>
          </div>

          <div className={style.wrapper}>
            <span className={style.wrapper__subtitle}>
              <FormattedMessage id="page.project.availability" />
            </span>
            <ul className={style.wrapper__list}>
              {availabilityData.map(item => (
                <li key={item.name}>
                  <Status
                    statusType={
                      !!item.alerts.length
                        ? StatusType.Error
                        : StatusType.Normal
                    }
                  />
                  <span
                    className={classNames(style.wrapper__value, {
                      [style.wrapper__value_error]: !!item.alerts.length,
                    })}
                  >
                    <FormattedMessage id={item.name} />
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <ClusterImage
            clusterName={cluster.info.name}
            onClick={getClusterDataFromLink}
            className={classNames(style.image, {
              [style.image__openedAlert]: alertsStore.isOpen,
            })}
            errors={errorCountPerHour}
            cluster={cluster}
            toggleAlertDrawer={toggleAlertDrawerCluster}
          />
        </ClusterCardWrapper>
      );
    },
  ),
);

export default ClusterCard;
