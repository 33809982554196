import qs from 'qs';

import Log from 'helpers/log';

export function appUrl(url: string): string {
  return `/api${url}`;
}

export function appWSSUrl(url: string): string {
  return `/api/ws${url}`;
}

export function mockUrl(url: string): string {
  return `/mock${url}.json`;
}

export function replaceUrlParams(url: string, params: object = {}): string {
  return url.replace(/:(\w+)/gi, (str, bracket) => {
    if (bracket in params) {
      return params[bracket];
    } else {
      Log.error("url param doesn't exist");
      return '';
    }
  });
}

export function arrayParamsSerializer(requestParams: any): string {
  return qs.stringify(requestParams, { indices: false });
}

export function urlWithQueryParams(
  url: string,
  queryParams: object = {},
): string {
  return `${url}?${arrayParamsSerializer(queryParams)}`;
}

export const isAuthTokenExpired = (headers: Headers): boolean =>
  headers.hasOwnProperty('www-authenticate');

export const isAuthRequest = (url: string = ''): boolean =>
  Boolean((url.match(`/auth/([\\w-]+)/token`) || [])[0]);
