import { useEffect, useState } from 'react';

export const useDomElement = (selector: string) => {
  const [domElement, setDomElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setDomElement(document.querySelector(selector) as HTMLElement);
  }, []);

  return domElement;
};
