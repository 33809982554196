import React from 'react';
import classNames from 'classnames';
import { Icons } from 'components/Icon/index';

import style from './Icon.module.scss';

const iconsClassName = {
  arrowRight: style.rotate180,
  arrowTop: style.rotate90,
  arrowBottom: style.rotate270,
};

interface Props {
  type: keyof typeof Icons;
  className?: string;
  disabled?: boolean;
  onClick?: (event?: React.MouseEvent | React.TouchEvent) => void;
  [key: string]: any;
}

const Icon: React.FC<Props> = ({
  type,
  className,
  disabled = false,
  onClick = () => undefined,
  ...attrs
}) => {
  const SVGIcon = Icons[type] || null;
  const iconClassName = iconsClassName[type] || null;

  return (
    SVGIcon && (
      <i
        className={classNames(style.icon, className, { disabled })}
        onClick={disabled ? undefined : onClick}
        {...attrs}
      >
        <SVGIcon className={classNames(iconClassName, style.svg)} />
      </i>
    )
  );
};

export default Icon;
