import { Theme } from 'types/theme';

class ThemeService {
  _setTheme = (theme: string): void => {
    document.documentElement.setAttribute(Theme.ATTR, theme);
    localStorage.setItem(Theme.LOCAL_PARAM, theme);
  };

  init = (): void => {
    const currentTheme = localStorage.getItem(Theme.LOCAL_PARAM);

    if (!currentTheme) {
      this._setTheme(Theme.DEFAULT);
    } else {
      this._setTheme(currentTheme);
    }
  };

  changeTheme = (): void => {
    const theme = document.documentElement.getAttribute(Theme.ATTR);

    if (theme === Theme.CUSTOM) {
      this._setTheme(Theme.DEFAULT);
    } else {
      this._setTheme(Theme.CUSTOM);
    }
  };
}

export default new ThemeService();
