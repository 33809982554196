import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { groupBy, splitArrayToChunks } from 'helpers/array';
import { IServiceInfoPodRuntimeStatus } from 'models/Service';
import { PodStatusCube } from 'components/PodStatusCube/PodStatusCube';
import style from './PodStatusesGrid.module.scss';

const POD_SIZE = 20;
const POD_GAP_SIZE = 4;
const ROWS_COUNT = 3;
const COLUMNS_COUNT = 4;

const sortPodRuntimeStatuses = (
  podRuntimeStatuses: IServiceInfoPodRuntimeStatus[],
) => {
  const groups = groupBy(podRuntimeStatuses, 'runtimeStatus');
  const array = [
    ...(groups.Error ? groups.Error : []),
    ...(groups.Warning ? groups.Warning : []),
    ...(groups.Initialization ? groups.Initialization : []),
    ...(groups.Alive ? groups.Alive : []),
  ];

  return array.sort((statusA, statusB) => {
    return statusB.outdated && !statusA.outdated ? -1 : 1;
  });
};

interface IProps {
  podRuntimeStatuses: IServiceInfoPodRuntimeStatus[];
  rows?: number;
  columns?: number;
  className?: string;
}

export const PodStatusesGrid = ({
  podRuntimeStatuses,
  rows = ROWS_COUNT,
  columns = COLUMNS_COUNT,
  className,
}: IProps) => {
  const hasPods = !!podRuntimeStatuses.length;
  const groupedStatuses = useMemo(
    () =>
      splitArrayToChunks(
        sortPodRuntimeStatuses(podRuntimeStatuses),
        rows * columns,
      ),
    [podRuntimeStatuses, rows, columns],
  );

  return (
    <div className={classNames(style.podsStatuses, className)}>
      {hasPods ? (
        groupedStatuses.map(block => (
          <div
            key={`${block[0].podName}${podRuntimeStatuses.length}`}
            className={style.podsStatusesBlock}
            style={{
              maxWidth:
                columns * POD_SIZE + (columns - 1) * POD_GAP_SIZE + 'px',
              maxHeight: rows * POD_SIZE + (rows - 1) * POD_GAP_SIZE + 'px',
            }}
          >
            {block.map(podStatus => (
              <PodStatusCube
                key={podStatus.podName}
                podStatusInfo={{
                  podName: podStatus.podName,
                  runtimeStatus: podStatus.runtimeStatus,
                  outdated: podStatus.outdated,
                }}
                withTooltip
              />
            ))}
          </div>
        ))
      ) : (
        <FormattedMessage id="artifact.no.active.pod" />
      )}
    </div>
  );
};
