import { observable } from 'mobx';
import { BaseFormModel, displayName, isRequired } from 'stores/BaseForm';

class ImageForm extends BaseFormModel {
  @observable
  @displayName('microservice.modal.form.label.image')
  @isRequired('form.field.is.required')
  image: string = '';
}

export default ImageForm;
