import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import style from './AccessDenied.module.scss';

import RootStore from 'stores/RootStore';
import { ROUTES } from 'routes/routes';

@observer
class AccessDenied extends Component {
  onClick = () => {
    RootStore.accessDeniedError = false;
  };

  render() {
    return (
      <div className={style.content}>
        <div className={style.contentRow}>
          <div className={style.description}>
            <h2 className={style.title}>403</h2>

            <p className={style.explanation}>
              <FormattedMessage id="error.403.sorry" />,<br />
              <FormattedMessage id="error.403.explanation" />
            </p>

            <p className={style.solution}>
              <FormattedMessage
                id="error.403.solution"
                values={{
                  home: (
                    <Link
                      to={ROUTES.root}
                      className={style.link}
                      onClick={this.onClick}
                    >
                      <FormattedMessage id="error.403.home" />
                    </Link>
                  ),
                }}
              />
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default AccessDenied;
