import { action, observable } from 'mobx';
import { BaseFormModel, displayName, isSameAs } from 'stores/BaseForm';
import httpFacade from 'http/httpFacade';
import NotificationService, {
  fullscreenNotificationOptions,
} from '../Notification/NotificationService';

class DeleteProviderInstanceForm extends BaseFormModel {
  @observable
  providerInstanceNameExample = '';

  @observable
  @displayName('instance.provider.modal.provider.name')
  @isSameAs('providerInstanceNameExample', 'wrong.provider.instance.msg')
  providerInstanceName: string = '';

  @action
  setProviderInstanceNameExample = name => {
    this.providerInstanceNameExample = name;
  };

  @action.bound
  async deleteProviderInstance(params) {
    const isFormValid = this.validate();
    if (isFormValid) {
      try {
        await httpFacade.cluster.deleteProviderInstance(
          params.projectName,
          params.clusterName,
          params.providerInstanceName,
        );
        NotificationService.successMessage(
          { id: 'provider.instance.delete.success' },
          fullscreenNotificationOptions,
        );
      } catch (error) {
        if (
          error.response.data.errorMessages ||
          error.response.data.warningMessages
        ) {
          throw error;
        } else {
          NotificationService.errorMessage({
            id: 'provider.instance.delete.fail',
          });
        }
      }
    }
  }
}

export default DeleteProviderInstanceForm;
