export const isElementOverflowedById = (
  elemId: string,
): boolean | undefined => {
  const elem = document.getElementById(elemId);
  if (!elem) {
    return;
  }
  return elem.scrollWidth > elem.clientWidth;
};

export const isElementOverflowed = (elem: Element): boolean => {
  return elem.scrollWidth > elem.clientWidth;
};
