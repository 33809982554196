import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { observable } from 'mobx';
import Checkbox from '../Form/Fields/Checkbox/Checkbox';
import { FormattedMessage } from 'react-intl';
import EnvironmentStore from '../../stores/EnvironmentStore';

import style from './EnvironmentPageHeader.module.scss';

interface Props {
  autoUpdate: boolean;
  environmentStore: EnvironmentStore;
}

@observer
class AutoUpdateCheckbox extends Component<Props> {
  @observable
  isAutoUpdateChecked = false;

  @observable
  disabled = false;

  constructor(props) {
    super(props);
    this.isAutoUpdateChecked = this.props.autoUpdate;
  }

  onAutoUpdateChange = async () => {
    const { setAutoUpdateEnvironment } = this.props.environmentStore;
    this.isAutoUpdateChecked = !this.isAutoUpdateChecked;
    this.disabled = true;
    try {
      await setAutoUpdateEnvironment(this.isAutoUpdateChecked);
      this.disabled = false;
    } catch (e) {
      this.isAutoUpdateChecked = !this.isAutoUpdateChecked;
      this.disabled = false;
    }
  };

  render() {
    return (
      <label className={style.checkboxContainer}>
        <Checkbox
          className={style.withLabel}
          checked={this.isAutoUpdateChecked}
          onChange={this.onAutoUpdateChange}
          disabled={this.disabled}
        />
        <FormattedMessage id="service.autoupdate" />
      </label>
    );
  }
}

export default AutoUpdateCheckbox;
