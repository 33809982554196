import React from 'react';
import { observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { ITemplateVariable } from 'models/Template';
import { isElementOverflowedById } from 'helpers/domElement';
import RootStore from 'stores/RootStore';

import style from 'pages/TemplatesPage/TemplateVariablesList/TemplateVariablesList.module.scss';
import WithPopper from 'components/WithPopper/WithPopper';

interface Props {
  index: number;
  variable: ITemplateVariable;
}

@observer
class TemplateSystemVarTableItem extends React.Component<Props> {
  @observable
  isNameTooltipVisible = true;
  @observable
  isDescriptionTooltipVisible = true;
  disposer;
  resizeTimeout;

  constructor(props) {
    super(props);
    this.disposer = reaction(() => RootStore.resizeEvent, this.onResize);
  }

  onResize = () => {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = undefined;
    }
    this.resizeTimeout = setTimeout(this.setTooltips, 700);
  };

  getElementId = fieldName => {
    const { index } = this.props;
    return `${fieldName}${index}`;
  };

  setTooltips = () => {
    const isNameOverflowed = isElementOverflowedById(
      this.getElementId('systemVariableName'),
    );
    this.isNameTooltipVisible = !(
      isNameOverflowed !== undefined && !isNameOverflowed
    );
    const isDescriptionOverflowed = isElementOverflowedById(
      this.getElementId('systemVariableDescription'),
    );
    this.isDescriptionTooltipVisible = !(
      isDescriptionOverflowed !== undefined && !isDescriptionOverflowed
    );
  };

  componentDidMount() {
    this.setTooltips();
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const { variable } = this.props;

    return (
      <tr>
        <td>
          <div className={style.cellContainer}>
            <WithPopper
              className={classNames(style.varPopper, style.varTooltip)}
              title={variable.name}
              hidden={!this.isNameTooltipVisible}
            >
              <div
                className={style.inputText}
                id={this.getElementId('systemVariableName')}
              >
                <span>{variable.name}</span>
              </div>
            </WithPopper>
          </div>
        </td>
        <td>
          <div className={style.cellContainer}>
            <WithPopper
              className={classNames(
                style.varPopper,
                style.varTooltip,
                style.description,
              )}
              title={variable.description}
              hidden={!this.isDescriptionTooltipVisible}
            >
              <div
                className={classNames(style.inputText, style.varDescription)}
                id={this.getElementId('systemVariableDescription')}
              >
                <span>{variable.description}</span>
              </div>
            </WithPopper>
          </div>
        </td>
      </tr>
    );
  }
}

export default TemplateSystemVarTableItem;
