import React from 'react';
import classNames from 'classnames';

import { PodRuntimeStatus } from 'models/StatusTypes';

import WithPopper from 'components/WithPopper/WithPopper';

import style from './PodStatusCube.module.scss';

interface IPodStatusInfo {
  podName: string;
  outdated: boolean;
  runtimeStatus: PodRuntimeStatus;
}

interface IProps {
  podStatusInfo: IPodStatusInfo;
  withTooltip?: boolean;
  className?: string;
}

export function PodStatusCube({
  podStatusInfo,
  withTooltip,
  className,
}: IProps) {
  const title = `${podStatusInfo.podName} - ${podStatusInfo.runtimeStatus}`;

  return (
    <WithPopper title={withTooltip ? title : ''}>
      <div
        className={classNames(style.podStatus, className, {
          [style.podStatus__transparent]: podStatusInfo.outdated,
          [style.podStatus__error]:
            podStatusInfo.runtimeStatus === PodRuntimeStatus.Error,
          [style.podStatus__warning]:
            podStatusInfo.runtimeStatus === PodRuntimeStatus.Warning,
          [style.podStatus__info]:
            podStatusInfo.runtimeStatus === PodRuntimeStatus.Initialization,
        })}
      />
    </WithPopper>
  );
}
