import React from 'react';
import classNames from 'classnames';

import {
  stringToTime,
  getDayOfWeek,
  setParsedHoursToDate,
} from '../../helpers/datetime';

import style from './ScheduleStatus.module.scss';
import DayCheckbox from './DayCheckbox/DayCheckbox';
import Icon from 'components/Icon/Icon';
import { ClusterSchedule, Week } from 'models/Cluster';

interface Props {
  className?: string;
  schedule: ClusterSchedule;
  compact?: boolean;
}

class ScheduleStatus extends React.Component<Props> {
  render() {
    const { className, schedule, compact } = this.props;
    const { startTime = '', endTime = '' } = schedule;
    const daysData = Object.keys(Week).map((day: string, index: number) => {
      return {
        id: index,
        active: schedule[day],
      };
    });

    return (
      <div
        className={classNames(style.mainContainer, className, {
          [style.compact]: compact,
        })}
      >
        <span className={style.title}>Schedule</span>
        <div className={style.timeContainer}>
          <Icon className={style.clockIcon} type="scheduleClock" />
          <div className={style.time}>
            {startTime ? stringToTime(setParsedHoursToDate(startTime)) : '-'} -{' '}
            {endTime ? stringToTime(setParsedHoursToDate(endTime)) : '-'}
          </div>
        </div>

        <div className={style.daysContainer}>
          {daysData.map(day => (
            <DayCheckbox
              key={day.id}
              active={day.active}
              isToday={day.id === getDayOfWeek()}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default ScheduleStatus;
