import React, { useMemo } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Button, { BtnSize, BtnType } from 'components/Button/Button';
import ActionMenu from 'components/ActionMenu/ActionMenu';

import style from './ApplicationLinks.module.scss';

interface Props {
  className?: string;
  links: string[];
}

const ApplicationLinks = ({ className, links }: Props) => {
  const sortedLinks = useMemo(
    () =>
      links.slice().sort((a, b) => {
        const lenDiff = a.length - b.length;
        if (lenDiff) {
          return lenDiff;
        } else {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        }
      }),
    [links],
  );
  const [firstLink, ...restLinks] = sortedLinks;

  const onClick = link => () => {
    window.open(link);
  };

  const items = restLinks.map(link => ({
    action: onClick(link),
    label: <span>{link}</span>,
  }));

  return (
    <div className={classNames(style.mainContainer, className)}>
      <FormattedMessage id="application.links" />:
      {
        <a
          className={style.applinkValue}
          target="_blank"
          href={firstLink}
          rel="noopener noreferrer"
        >
          {firstLink}
        </a>
      }
      {links.length > 1 && (
        <ActionMenu
          openMenuComponent={toggleList => (
            <Button
              className={style.btn}
              size={BtnSize.ExtraTiny}
              onClick={toggleList}
              styleType={BtnType.Secondary}
            >
              +{links.length - 1}
            </Button>
          )}
          items={items}
          styles={{
            wrapper: style.menuWrapper,
            menu: style.menu,
            menuItem: style.menuItem,
          }}
        />
      )}
    </div>
  );
};

export default ApplicationLinks;
