import { observer } from 'mobx-react';
import classNames from 'classnames';
import {
  ArtifactUpdate as ArtifactUpdateModel,
  IssueItemStatusTranslationKeys,
} from 'models/Artifact';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { sortByIssueId } from 'helpers/array';
import { ServiceUpdate } from 'models/Service';
import ModalStore from 'stores/ModalStore';

import InstallArtifactModal from '../InstallArtifactModal/InstallArtifactModal';
import Button, { BtnSize, BtnType } from 'components/Button/Button';
import { Icons } from 'components/Icon';
import Icon from 'components/Icon/Icon';
import AllIssuesModal from 'components/MicroservicePageArtifact/AllIssuesModal/AllIssuesModal';
import TextBadge, { TextBadgeTypes } from 'components/TextBadge/TextBadge';
import WithPopper from 'components/WithPopper/WithPopper';

import style from './ArtifactUpdate.module.scss';

interface IssueStatusMap {
  [key: string]: keyof typeof Icons;
}

export const issueStatusMap: IssueStatusMap = {
  InProgress: 'issueProgress',
  ReadyForQA: 'issueEye',
  Resolved: 'issueResolve',
  NotWorkedYet: 'issueClock',
  Unknown: 'issueClock',
};

interface Props {
  update?: ArtifactUpdateModel;
  updateMicroservice: (data: ServiceUpdate) => Promise<any>;
}

@observer
class ArtifactUpdate extends React.Component<Props> {
  openInstallArtifactModal = async () => {
    const { update, updateMicroservice } = this.props;
    ModalStore.showModal(InstallArtifactModal, {
      image: update ? update.latest.fullImage : '',
      updateMicroservice,
    });
  };

  openIssuesModal = async () => {
    const { update, updateMicroservice } = this.props;
    if (update) {
      ModalStore.showModal(AllIssuesModal, {
        artefact: update.latest,
        issue: update.incoming,
        updateArtefact: async () =>
          updateMicroservice({ image: update.latest.fullImage }),
      });
    }
  };

  render() {
    const { update } = this.props;

    return (
      <>
        {update ? (
          <>
            <div>
              <TextBadge type={TextBadgeTypes.ImageInfo}>
                {update.latest.fullImage.split('/').pop()}
              </TextBadge>
            </div>
            <div className={style.issueContainer}>
              <div className={style.issueList}>
                {update.incoming
                  .slice()
                  .sort(sortByIssueId)
                  .slice(0, 8)
                  .map((issue, index) => {
                    const isAddEllipsis =
                      index === 7 && update.incoming.length > 8;

                    return (
                      <div key={issue.id} className={style.issueItem}>
                        <Icon
                          className={style.issueIcon}
                          type={
                            issue.status
                              ? issueStatusMap[issue.status]
                              : 'issueClock'
                          }
                        />
                        {issue.link ? (
                          <a
                            href={issue.link}
                            className={style.issueLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <WithPopper
                              className={classNames(
                                style.issueText,
                                isAddEllipsis && style.withEllipsis,
                              )}
                              title={
                                <>
                                  <FormattedMessage
                                    id={`artifact.issues.status.${
                                      IssueItemStatusTranslationKeys[
                                        issue.status
                                      ]
                                    }`}
                                  />
                                  {issue.description && (
                                    <>: {issue.description}</>
                                  )}
                                </>
                              }
                            >
                              {issue.id}
                            </WithPopper>
                          </a>
                        ) : (
                          <WithPopper
                            className={classNames(
                              style.issueText,
                              isAddEllipsis && style.withEllipsis,
                            )}
                            title={
                              <>
                                <FormattedMessage
                                  id={`artifact.issues.status.${
                                    IssueItemStatusTranslationKeys[issue.status]
                                  }`}
                                />
                                {issue.description && (
                                  <>: {issue.description}</>
                                )}
                              </>
                            }
                          >
                            {issue.id}
                          </WithPopper>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </>
        ) : (
          <div className={style.noUpdatesContainer}>
            <div className={style.iconWrapper}>
              <Icon type="updates" />
            </div>
            <div className={style.textContainer}>
              <div className={style.firstTitle}>
                <FormattedMessage id="artifact.no.update.first" />
              </div>
              <div className={style.secondTitle}>
                <FormattedMessage id="artifact.no.update.second" />
              </div>
            </div>
          </div>
        )}
        <div className={style.btnWrapper}>
          {update && (
            <>
              <Button
                className={style.installBtn}
                size={BtnSize.Tiny}
                styleType={BtnType.Success}
                onClick={this.openInstallArtifactModal}
              >
                <FormattedMessage id="install" />
              </Button>

              <Button
                styleType={BtnType.Text}
                className={style.moreBtn}
                onClick={this.openIssuesModal}
              >
                <FormattedMessage id="view.more" />
              </Button>
            </>
          )}
        </div>
      </>
    );
  }
}

export default ArtifactUpdate;
