import { action, computed, observable } from 'mobx';

import {
  BaseFormModel,
  displayName,
  isMatchRegExp,
  isRequired,
  isValueValid,
} from 'stores/BaseForm';

import httpFacade from 'http/httpFacade';

import NotificationService, {
  fullscreenNotificationOptions,
} from 'components/Notification/NotificationService';
import {
  StorageProviderInstance,
  StorageProviderModes,
} from 'models/ExternalStorage';
import { ENV_VAR_REGEXP, ENV_VAR_START_REGEXP } from 'helpers/testRegexp';

const fieldsMap = {
  dbName: 'isDbNameValid',
  dbHost: 'isDbHostValid',
  dbPort: 'isDbPortValid',
  dbUser: 'isDbUserValid',
  dbPassword: 'isDbPasswordValid',
};

class CreateExternalStorageProviderForm extends BaseFormModel {
  @observable
  isDbNameValid = true;
  @observable
  isDbHostValid = true;
  @observable
  isDbPortValid = true;
  @observable
  isDbUserValid = true;
  @observable
  isDbPasswordValid = true;

  @observable
  @displayName('provider.instance')
  @isRequired()
  providerInstance: string = '';

  @observable
  @displayName('environment.name')
  copyEnv: string = '';

  @observable
  @displayName('microservice.name')
  copyMicroservice: string = '';

  @observable
  @displayName('database.name')
  @isMatchRegExp(ENV_VAR_REGEXP, 'validation.env.var')
  @isMatchRegExp(ENV_VAR_START_REGEXP, 'validation.env.var.start')
  @isValueValid('isDbNameValid', 'validation.env.var.name.uniq')
  @isRequired()
  dbName: string = 'DB_NAME';

  @observable
  @displayName('database.host')
  @isMatchRegExp(ENV_VAR_REGEXP, 'validation.env.var')
  @isMatchRegExp(ENV_VAR_START_REGEXP, 'validation.env.var.start')
  @isValueValid('isDbHostValid', 'validation.env.var.name.uniq')
  @isRequired()
  dbHost: string = 'DB_HOST';

  @observable
  @displayName('database.port')
  @isMatchRegExp(ENV_VAR_REGEXP, 'validation.env.var')
  @isMatchRegExp(ENV_VAR_START_REGEXP, 'validation.env.var.start')
  @isValueValid('isDbPortValid', 'validation.env.var.name.uniq')
  @isRequired()
  dbPort: string = 'DB_PORT';

  @observable
  @displayName('user')
  @isMatchRegExp(ENV_VAR_REGEXP, 'validation.env.var')
  @isMatchRegExp(ENV_VAR_START_REGEXP, 'validation.env.var.start')
  @isValueValid('isDbUserValid', 'validation.env.var.name.uniq')
  @isRequired()
  dbUser: string = 'DB_USER';

  @observable
  @displayName('users.password')
  @isMatchRegExp(ENV_VAR_REGEXP, 'validation.env.var')
  @isMatchRegExp(ENV_VAR_START_REGEXP, 'validation.env.var.start')
  @isValueValid('isDbPasswordValid', 'validation.env.var.name.uniq')
  @isRequired()
  dbPassword: string = 'DB_PASSWORD';

  @observable
  mode: StorageProviderModes = StorageProviderModes.EMPTY;

  @observable
  providersList: StorageProviderInstance[] = [];

  @observable
  usageList: any = {};

  @computed
  get isFormValidByMode() {
    return this.mode === StorageProviderModes.COPY_BASED_ON_ENV
      ? this.isFormValid && this.copyMicroservice && this.copyEnv
      : this.isFormValid;
  }

  @action
  setFieldValid = (field: string, isValid: boolean) => {
    this[fieldsMap[field]] = isValid;
  };

  @action
  fetchAvailablePIForMsSupport = async (
    projectName,
    clusterName,
    environmentName,
    microserviceName,
  ) => {
    try {
      const response = await httpFacade.cluster.fetchAvailablePIForMsSupport(
        projectName,
        clusterName,
        environmentName,
        microserviceName,
      );
      this.providersList = response.data;
    } catch (e) {
      this.providersList = [];
    }
  };

  @action
  fetchClusterProviderInstancesUsage = async (
    projectName,
    clusterName,
    instanceName,
  ) => {
    try {
      const response =
        await httpFacade.cluster.fetchClusterProviderInstancesUsage(
          projectName,
          clusterName,
          instanceName,
        );
      this.usageList = response.data;
    } catch (e) {
      this.usageList = new Map();
    }
  };

  @action.bound
  async submit(
    projectName: string,
    clusterName: string,
    environmentName: string,
    microserviceName: string,
    msLongName: string,
  ) {
    await httpFacade.service.addSupportToMS(
      {
        projectName,
        clusterName,
        environmentName,
        serviceName: microserviceName,
      },
      {
        providerInstanceName: this.providerInstance,
        mode: this.mode,
        copyFromEnv: this.copyEnv,
        copyFromService: this.copyMicroservice,
        environmentVariablesMapping: {
          host: this.dbHost.toUpperCase(),
          port: this.dbPort.toUpperCase(),
          user: this.dbUser.toUpperCase(),
          password: this.dbPassword.toUpperCase(),
          dbname: this.dbName.toUpperCase(),
        },
      },
    );
    NotificationService.successMessage(
      { id: 'external.storage.support.create.success', values: { msLongName } },
      fullscreenNotificationOptions,
    );
  }
}

export default CreateExternalStorageProviderForm;
