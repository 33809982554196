import { observable } from 'mobx';
import { BaseFormModel, displayName } from 'stores/BaseForm';

class EnvVariablesModalForm extends BaseFormModel {
  @observable
  @displayName('db.env.variable.host')
  host: string = '';

  @observable
  @displayName('db.env.variable.port')
  port: string = '';

  @observable
  @displayName('db.env.variable.name')
  name: string = '';

  @observable
  @displayName('db.env.variable.userName')
  userName: string = '';

  @observable
  @displayName('db.env.variable.password')
  password: string = '';
}

export default EnvVariablesModalForm;
