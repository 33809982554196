import { ArtifactStatus, SyncState, RuntimeState } from './StatusTypes';
import { Service } from './Service';

export enum EnvCopyMode {
  CLONE_BASED_ON_ENV = 'CLONE_BASED_ON_ENV',
  EMPTY = 'EMPTY',
}

export class Environment {
  isHaveProblem: boolean;
  isShutdown: boolean;
  favorite: boolean;
  name: string;
  longName: string;
  syncState: SyncState;
  artifactStatus: ArtifactStatus;
  runtimeState: RuntimeState;
  createDate?: Date;
  updateDate?: Date;
  autoUpdate: boolean;
  branches: string[];
  links: {
    pipeline?: string;
    entrypoints?: string[];
    logging?: string;
    monitoring?: string;
    registry?: string;
  };
}

export class EnvironmentOther {
  isHaveProblem: boolean;
  kind: string;
  name: string;
  syncState: SyncState;
  createDate?: Date;
  updateDate?: Date;
}

export class EnvironmentDetails {
  info: Environment;
  microservices: Service[];
  others: EnvironmentOther[];
}

export interface IEnvironmentParams {
  clusterName: string;
  projectName: string;
  environmentName: string;
}

export interface EnvironmentVersion {
  major: string;
  minor: string;
  patch: string;
}

export interface NewEnvForm {
  name: string;
  longName: string;
}

export interface NewEnvResponse {
  environmentName: string;
}

export interface IClusterParams {
  clusterName: string;
  projectName: string;
}

export interface CloneEnvForm {
  name: string;
  longName: string;
  basedOn: string;
  mode?: EnvCopyMode;
}

export interface ITargetEnvParams {
  projectTarget: string;
  clusterTarget: string;
  envTarget: string;
  microservices: string;
}

export enum ComparingState {
  NEW = 'New',
  DELETED = 'Deleted',
  MODIFIED = 'Modified',
  NOTHING_NEW = 'Nothing new',
}

interface ITargetEnvironment {
  clusterTarget: string;
  envTarget: string;
  envTargetCommitId: string;
}

export interface IComparingEnvironment extends ITargetEnvironment {
  projectSource: string;
  clusterSource: string;
  envSource: string;
  projectTarget: string;
  envSourceCommitId: string;
  microservices: IComparingMicroservice[];
}

export interface IComparingMicroservice {
  description?: string;
  name: string;
  longName?: string;
  imageSource?: string;
  imageTarget?: string;
  hostsSource?: string[];
  msState: ComparingState;
  envVars: IEnvVarComparing[];
}

export interface IEnvVarComparing {
  description?: string;
  name: string;
  valueSource?: string;
  secureSource?: boolean;
  valueTarget?: string;
  secureTarget?: boolean;
  envSpecific: boolean;
  envVarState: ComparingState;
}

export interface IApplyToData extends ITargetEnvironment {
  envTargetLongName?: string;
  envSourceCommitId: string;
  merge: boolean;
  microservices: IApplyToMicroservice[];
}

interface IApplyToMicroservice {
  name: string;
  hosts: string[];
  envVariables: IApplyToEnvVariable[];
}

interface IApplyToEnvVariable {
  name: string;
  value: string;
  secure: boolean;
  envSpecific: boolean;
  deleted: boolean;
}
