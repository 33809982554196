export interface ITemplate {
  name: string;
  description?: string;
  content: string;
  defaultTemplate?: boolean;
}

export interface ITemplateVariable {
  name: string;
  description?: string;
  value?: string;
  system: boolean;
}

export enum ActionId {
  create_environment = 'create_environment',
  create_microservice = 'create_microservice',
}

export interface TemplatesParams {
  projectName: string;
  clusterName: string;
  actionId: ActionId;
}

export interface TemplateParams extends TemplatesParams {
  templateName: string;
}
