import { DialogActionType, ModalProps } from '../../stores/ModalStore';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { action, computed, observable } from 'mobx';
import { bindFormControl } from '../Form/FormControl/FormControl';
import { ValidationResult } from 'stores/BaseForm/types';
import { FormattedMessage } from 'react-intl';
import TextField from '../Form/Fields/TextField/TextField';
import CreateEnvironmentForm from './CreateEnvironmentForm';
import style from './CreateEnvironmentModal.module.scss';
import CommonModal from '../Modals/Modal/CommonModal';
import { InfoButton, RulePrefix } from 'components/InfoButton/InfoButton';
import {
  KEY_WITH_SPEC_CHARS_REGEXP,
  NAME_REGEXP,
  testRegExp,
} from 'helpers/testRegexp';
import { generateKey } from 'helpers/generateKey';

interface Props {
  clusterName: string;
  projectName: string;
  fetchCluster: (projectName: string, clusterName: string) => void;
}

@observer
class CreateEnvironmentModal extends Component<Props & ModalProps> {
  @observable createEnvironmentForm = new CreateEnvironmentForm();
  FormControl = bindFormControl(this.createEnvironmentForm);
  @computed
  get isSubmitBtnDisabled() {
    return !this.createEnvironmentForm.isFormValid;
  }

  @observable
  isKeyChangedManually: boolean = false;

  @observable
  keyFieldErrors: ValidationResult[] = [];

  @observable
  serverErrors: string[] = [];

  @observable
  serverWarnings: string[] = [];

  @observable
  isUIblocked: boolean = false;

  @observable
  validationMessages = [
    {
      msgKey: 'validation.env.key.characters.rule',
      id: 0,
      isValid: true,
    },
    {
      msgKey: 'validation.env.key.length.rule',
      id: 1,
      isValid: true,
    },
    {
      msgKey: 'validation.env.key.startend.rule',
      id: 2,
      isValid: true,
    },
    {
      msgKey: 'validation.env.key.reserved.rule',
      id: 3,
      isValid: true,
    },
    {
      msgKey: 'validation.env.key.visibility.rule',
      id: 4,
      isValid: true,
    },
  ];

  onClose = () => {
    if (!this.isUIblocked) {
      this.props.onClose(DialogActionType.cancel);
    }
  };

  onConfirm = async () => {
    const { clusterName, projectName } = this.props;
    const isFormValid = this.createEnvironmentForm.validate();
    if (isFormValid) {
      this.validationMessages.forEach(msg => (msg.isValid = true));
      this.createEnvironmentForm.validated = false;
      try {
        this.isUIblocked = true;
        await this.createEnvironmentForm.submit(projectName, clusterName);
        this.serverErrors = [];
        this.serverWarnings = [];
        this.props.onClose(DialogActionType.submit);
      } catch (error) {
        this.serverErrors = error.response.data.errorMessages || [];
        this.serverWarnings = error.response.data.warningMessages || [];
      } finally {
        this.isUIblocked = false;
      }
    } else {
      const keyErrors = this.createEnvironmentForm.errorFor('key');
      keyErrors.forEach(err => {
        const rule = this.validationMessages.find(
          msg => msg.msgKey === `${err.message}.rule`,
        );
        if (rule) {
          rule.isValid = false;
        }
      });
    }
  };

  onEnvNameChange = (value: string) => {
    const length = value.length;
    const isValidRegexp = testRegExp(value, NAME_REGEXP);

    if (!isValidRegexp && length > 0) {
      return;
    }

    if (length > 0) {
      this.createEnvironmentForm.setValue('envName', value);
      if (!this.isKeyChangedManually) {
        this.createEnvironmentForm.setValue('key', generateKey(value));
      }
    } else {
      this.createEnvironmentForm.setValue('envName', '');
      if (!this.isKeyChangedManually) {
        this.createEnvironmentForm.setValue('key', '');
      }
    }

    this.validateEnvKey();
  };

  onEnvNameBlur = () => {
    if (!this.isKeyChangedManually) {
      this.createEnvironmentForm.setValue(
        'key',
        this.createEnvironmentForm.key.replace(/^-|-$/g, ''),
      );
    }
  };

  onEnvKeyChange = (value: string, e) => {
    e.persist();

    const length = value.length;
    const isValidRegexp = testRegExp(
      value.toLowerCase(),
      KEY_WITH_SPEC_CHARS_REGEXP,
    );

    if (!isValidRegexp && length > 0) {
      return;
    }

    const ss = e.target.selectionStart;
    const se = e.target.selectionEnd;

    if (!this.isKeyChangedManually) {
      this.isKeyChangedManually = true;
    }

    if (length > 0) {
      this.createEnvironmentForm.setValue('key', value.toLowerCase());
    } else {
      this.createEnvironmentForm.setValue('key', '');
    }

    setTimeout(() => {
      e.target.selectionStart = ss;
      e.target.selectionEnd = se;
    }, 0);

    this.validateEnvKey();
  };

  @action
  validateEnvKey = () => {
    setTimeout(() => {
      // we use setTimeout 0 to delay this work and not block input rerender
      this.validationMessages.forEach(msg => (msg.isValid = true));
      this.keyFieldErrors = this.createEnvironmentForm.errorFor('key');
      if (this.keyFieldErrors.length) {
        this.keyFieldErrors.forEach(err => {
          const rule = this.validationMessages.find(
            msg => msg.msgKey === `${err.message}.rule`,
          );
          if (rule) {
            rule.isValid = false;
          }
        });
      }
    }, 0);
  };

  render() {
    const FormControl = this.FormControl;

    return (
      <CommonModal
        className={style.body}
        title="environment.modal.create.environment.title"
        cancel="environment.modal.create.environment.cancel"
        confirm="environment.modal.create.environment.confirm"
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        isSubmitBtnDisabled={this.isSubmitBtnDisabled}
        serverErrors={this.serverErrors}
        serverWarnings={this.serverWarnings}
        isUIblocked={this.isUIblocked}
      >
        <div className={style.text}>
          <FormattedMessage id="environment.modal.create.environment.text" />
        </div>
        <div className={style.formControlWrapper}>
          <FormControl
            className={style.input}
            name="envName"
            render={props => (
              <TextField
                {...props}
                onBlur={this.onEnvNameBlur}
                onChange={this.onEnvNameChange}
              />
            )}
          />
        </div>
        <div className={style.formControlWrapper}>
          <FormControl
            className={style.input}
            name="key"
            errors={this.keyFieldErrors}
            immediateValidation={true}
            render={props => (
              <TextField {...props} onChange={this.onEnvKeyChange} />
            )}
          />{' '}
          <InfoButton
            className={style.infoBtnWrapper}
            rules={this.validationMessages}
            rulePrefix={RulePrefix.env}
          />
        </div>
      </CommonModal>
    );
  }
}

export default CreateEnvironmentModal;
