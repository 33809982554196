import * as constants from './constants';
import { TokensResponse, UserAuthFormData } from './types';
import { Tokens } from 'models/User';

export const createLoginParams = ({
  username,
  password,
}: UserAuthFormData): URLSearchParams => {
  const params = new URLSearchParams();
  params.append(constants.FORM_FIELD_USERNAME, username);
  params.append(constants.FORM_FIELD_PASSWORD, password);
  params.append(constants.FORM_FIELD_CLIENT_ID, constants.CLIENT_ID_SAMCLOUD);
  params.append(constants.FORM_FIELD_GRANT_TYPE, constants.GRANT_TYPE_PASSWORD);

  return params;
};

export const createRefreshTokenFormData = (
  refreshToken: string,
): URLSearchParams => {
  const params = new URLSearchParams();

  params.append(constants.FORM_FIELD_REFRESH_TOKEN, refreshToken);
  params.append(constants.FORM_FIELD_CLIENT_ID, constants.CLIENT_ID_SAMCLOUD);
  params.append(
    constants.FORM_FIELD_GRANT_TYPE,
    constants.GRANT_TYPE_REFRESH_TOKEN,
  );

  return params;
};

export const convertToTokens = (tokensResponse: TokensResponse): Tokens => {
  return {
    accessToken: tokensResponse.access_token,
    refreshToken: tokensResponse.refresh_token,
  };
};

export const createLogoutParams = (refreshToken: string): URLSearchParams => {
  const params = new URLSearchParams();
  params.append(constants.FORM_FIELD_REFRESH_TOKEN, refreshToken);
  params.append(constants.FORM_FIELD_CLIENT_ID, constants.CLIENT_ID_SAMCLOUD);

  return params;
};
