import React from 'react';
import { action, computed } from 'mobx';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { replaceUrlParams } from 'http/helpers';
import { ROUTES } from 'routes/routes';
import { Cluster } from 'models/Cluster';
import RootStore from 'stores/RootStore';

import MenuItem from './MenuItem/MenuItem';
import MenuList from './MenuList/MenuList';
import WithPopper from 'components/WithPopper/WithPopper';

import style from './MenuDrawer.module.scss';

interface Props extends RouteComponentProps {
  isOpen: boolean;
  clusterList?: Cluster[];
  toggleMenu?: () => void;
}

@observer
class MenuDrawer extends React.Component<Props> {
  @computed
  get isDashboardOpened() {
    return (
      this.props.location.pathname ===
      replaceUrlParams(ROUTES.project, {
        id: RootStore.currentProject,
      })
    );
  }

  gotoDashboard = () => {
    const { toggleMenu, history } = this.props;
    if (toggleMenu) {
      toggleMenu();
    }

    history.push(
      replaceUrlParams(ROUTES.project, { id: RootStore.currentProject }),
    );
  };

  @action
  onOverlayClick = () => {
    const { toggleMenu } = this.props;
    if (toggleMenu) {
      toggleMenu();
    }
  };

  render() {
    const { isOpen, clusterList, toggleMenu } = this.props;

    return (
      <>
        <div
          className={classNames(style.menuContainer, {
            [style.menuContainerOpen]: isOpen,
          })}
        >
          <MenuItem
            title={'menu.dashboard'}
            active={this.isDashboardOpened}
            icon="dashboard"
            hoveredIcon="dashboardActive"
            onClick={this.gotoDashboard}
          />
          <MenuList
            title={'menu.clusters'}
            active={false}
            icon="clusters"
            hoveredIcon="clustersActive"
            list={clusterList}
            toggleMenu={toggleMenu}
          />
          <WithPopper title={<FormattedMessage id={'under.development'} />}>
            <MenuItem
              title={'menu.applications'}
              active={false}
              icon="apps"
              hoveredIcon="appsActive"
            />
          </WithPopper>
          <WithPopper title={<FormattedMessage id={'under.development'} />}>
            <MenuItem
              title={'menu.help'}
              active={false}
              icon="help"
              hoveredIcon="helpActive"
            />
          </WithPopper>
        </div>
        <div
          className={classNames(style.menuOverlay, {
            [style.menuOverlayOpen]: isOpen,
          })}
          onClick={this.onOverlayClick}
        />
      </>
    );
  }
}

export default withRouter(MenuDrawer);
