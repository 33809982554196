import http from '../http';

import { appUrl } from 'http/helpers';
import URLS from 'http/urls';
import { Alert } from 'models/Alert';

export const alertsApi = {
  fetchAlerts(projectName: string) {
    return http.get<Alert[]>(appUrl(URLS.alerts), {
      replaceParams: {
        projectName,
      },
    });
  },
};
