import http from '../http';
import { appUrl } from 'http/helpers';
import URLS from 'http/urls';
import ProjectDetails, { Project, ProjectSettings } from 'models/Project';
import { Artifact, ArtifactSearchParams } from 'models/Artifact';

export const projectsApi = {
  fetchProjects() {
    return http.get<string[]>(appUrl(URLS.projects));
  },

  fetchProject(projectName: string) {
    return http.get<Project>(appUrl(URLS.project), {
      replaceParams: {
        projectName,
      },
    });
  },

  fetchArtifacts(projectName: string, params: ArtifactSearchParams) {
    return http.get<Artifact[]>(appUrl(URLS.artifacts), {
      params,
      replaceParams: {
        projectName,
      },
    });
  },

  fetchProjectDetails(projectName: string) {
    return http.get<ProjectDetails>(appUrl(URLS.projectDetails), {
      replaceParams: {
        projectName,
      },
    });
  },

  fetchProjectSettings(projectName: string) {
    return http.get<ProjectSettings>(appUrl(URLS.projectSettings), {
      replaceParams: {
        projectName,
      },
    });
  },

  updateProjectSettings(projectName: string, projectSettings: ProjectSettings) {
    return http.put(appUrl(URLS.updateProjectSettings), projectSettings, {
      replaceParams: {
        projectName,
      },
    });
  },
};
