import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Service } from 'models/Service';

import Button, { BtnSize, BtnType } from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import { PodStatusesGrid } from 'components/MicroservicePageArtifact/PodStatusesGrid/PodStatusesGrid';
import Counter from 'components/Tabs/DefaultTabs/Counter/Counter';

import style from './ManyPodsSection.module.scss';

interface IProps {
  service: Service;
  gotoPodsPage: () => void;
}

const ManyPodsSection = ({ service, gotoPodsPage }: IProps) => {
  const [isOpened, setIsOpened] = React.useState(false);
  const { podRuntimeStatuses, runtimeState } = service;

  const togglePodsGrid = () => {
    setIsOpened(!isOpened);
  };

  return (
    <div className={style.podsSection}>
      <div className={style.podsSectionHeader}>
        <div className={style.podsSectionHeaderLeft}>
          <span className={style.title}>
            <FormattedMessage id="manyPodsSection.title" />
          </span>
          <Counter
            counter={{
              success: Number(runtimeState.existing),
              all: Number(runtimeState.desired),
            }}
            active
          />
        </div>
        <Button
          styleType={BtnType.Secondary}
          size={BtnSize.ExtraTiny}
          onClick={gotoPodsPage}
          className={style.viewPodsBtn}
        >
          <FormattedMessage id="view.pods" tagName={React.Fragment} />
        </Button>
        <Button
          styleType={BtnType.Text}
          className={style.arrowBtn}
          onClick={togglePodsGrid}
        >
          <Icon
            className={style.arrowIcon}
            type={isOpened ? 'arrowTop' : 'arrowBottom'}
          />
        </Button>
      </div>
      {isOpened && (
        <div className={style.gridWrapper}>
          <PodStatusesGrid
            podRuntimeStatuses={podRuntimeStatuses}
            rows={4}
            className={style.podsStatuses}
          />
        </div>
      )}
    </div>
  );
};

export default ManyPodsSection;
