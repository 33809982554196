import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { DialogActionType, ModalProps } from 'stores/ModalStore';

import CommonModal from 'components/Modals/Modal/CommonModal';

import style from './ContainerLastStateModal.module.scss';

export interface IContainerLastState {
  title: string;
  state: object;
}

interface IProps extends ModalProps {
  containerLastState: IContainerLastState;
}

@observer
class ContainerLastStateModal extends Component<IProps> {
  render() {
    const { containerLastState, onClose } = this.props;

    return (
      <CommonModal
        title="title.container.last.state"
        onClose={() => onClose(DialogActionType.cancel)}
        cancel="button.close"
        bodyContentClassName={style.bodyContent}
      >
        <div className={style.stateTitle}>{containerLastState.title}</div>
        <div className={style.stateInfo}>
          {Object.entries(containerLastState.state).map(([key, value]) => (
            <p key={key}>
              &emsp; {key}: {value}
            </p>
          ))}
        </div>
      </CommonModal>
    );
  }
}

export default ContainerLastStateModal;
