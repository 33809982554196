import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { action, computed, observable, reaction } from 'mobx';
import classNames from 'classnames';
import { bindFormControl } from '../Form/FormControl/FormControl';
import Checkbox from '../Form/Fields/Checkbox/Checkbox';
import TextField from '../Form/Fields/TextField/TextField';
import Icon from 'components/Icon/Icon';
import EnvironmentVariableForm from '../EnvironmentPageServicesList/CreateMicroservice/EnvironmentVariableForm';
import { ENV_VAR_REGEXP, testRegExp } from '../../helpers/testRegexp';
import { localization } from '../../index';
import WithPopper from '../WithPopper/WithPopper';
import RootStore from '../../stores/RootStore';
import { isElementOverflowedById } from 'helpers/domElement';

import style from './EnvironmentVariablesTable.module.scss';

interface Props {
  environmentVariableItem: EnvironmentVariableForm;
  deleteEnvVariable: (index) => void;
  checkEnvNameUniq: () => void;
  index: number;
  previewMode?: boolean;
  isDisabled?: boolean;
}

@observer
class EnvVarTableItem extends Component<Props> {
  @observable
  isValueVisible = false;

  @observable
  isNameTooltipVisible = true;
  @observable
  isValueTooltipVisible = true;
  disposer;
  resizeTimeout;

  constructor(props) {
    super(props);
    this.disposer = reaction(() => RootStore.resizeEvent, this.onResize);
  }

  onResize = () => {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = undefined;
    }
    this.resizeTimeout = setTimeout(this.setTooltips, 700);
  };

  @action
  toggleValueVisible = () => {
    this.isValueVisible = !this.isValueVisible;
    setTimeout(this.setTooltips, 0);
  };

  @computed
  get envVariableValue() {
    const { environmentVariableItem } = this.props;
    return this.isValueVisible || !environmentVariableItem.variableSecure
      ? environmentVariableItem.variableValue
      : environmentVariableItem.variableValue.replace(/./g, '·');
  }

  @computed
  get iconType() {
    return this.isValueVisible ? 'visible' : 'hidden';
  }

  @computed
  get iconTooltip() {
    return this.isValueVisible
      ? 'env.var.value.icon.tooltip.hide'
      : 'env.var.value.icon.tooltip.show';
  }

  @computed
  get variableNameTooltipTitle() {
    const { environmentVariableItem } = this.props;
    return this.isNameTooltipVisible
      ? environmentVariableItem.variableName
      : '';
  }

  @computed
  get variableValueTooltipTitle() {
    const { environmentVariableItem } = this.props;
    return this.isValueTooltipVisible &&
      (this.isValueVisible || !environmentVariableItem.variableSecure)
      ? environmentVariableItem.variableValue
      : '';
  }

  getElementId = fieldName => {
    const { index } = this.props;
    return `${fieldName}${index}`;
  };

  onChangeSecure = () => {
    const { environmentVariableItem, previewMode } = this.props;
    if (previewMode) {
      return;
    }
    environmentVariableItem.setValue(
      'variableSecure',
      !environmentVariableItem.variableSecure,
    );
  };

  onVariableNameChange = onChange => (value: string, e) => {
    const { checkEnvNameUniq, environmentVariableItem } = this.props;
    const targetElement = e.target;
    const ss = targetElement.selectionStart;
    const se = targetElement.selectionEnd;
    const length = value.length;
    const isValidRegexp = testRegExp(value, ENV_VAR_REGEXP);
    if (!isValidRegexp && length > 0) {
      setTimeout(() => {
        targetElement.selectionStart = ss - 1;
        targetElement.selectionEnd = se - 1;
      }, 0);
      return;
    }

    onChange(value, e);
    environmentVariableItem.checkRequiredValid(true);
    environmentVariableItem.validate();
    checkEnvNameUniq();
  };

  onVarValueChange = onChange => (value: string, e) => {
    const { environmentVariableItem } = this.props;
    onChange(value, e);
    environmentVariableItem.checkRequiredValid();
    environmentVariableItem.validate();
  };

  setTooltips = () => {
    const isNameOverflowed = isElementOverflowedById(
      this.getElementId('variableName'),
    );
    this.isNameTooltipVisible = !(
      isNameOverflowed !== undefined && !isNameOverflowed
    );
    const isValueOverflowed = isElementOverflowedById(
      this.getElementId('variableValue'),
    );
    this.isValueTooltipVisible = !(
      isValueOverflowed !== undefined && !isValueOverflowed
    );
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { previewMode } = this.props;
    if (previewMode && !prevProps.previewMode) {
      this.setTooltips();
    }
  }

  componentDidMount() {
    this.setTooltips();
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const { environmentVariableItem, index, previewMode, isDisabled } =
      this.props;
    const FormControl = bindFormControl(environmentVariableItem);
    return (
      <tr>
        <td>
          <div className={style.cellContainer}>
            {previewMode ? (
              <WithPopper
                className={classNames(style.varPopper, style.envVarTooltip)}
                title={this.variableNameTooltipTitle}
              >
                <div
                  className={style.inputText}
                  id={this.getElementId('variableName')}
                >
                  <span>{environmentVariableItem.variableName}</span>
                </div>
              </WithPopper>
            ) : (
              <FormControl
                withoutLabel
                className={style.variableNameInput}
                name={'variableName'}
                immediateValidation={true}
                render={props => (
                  <TextField
                    {...props}
                    id={'variableName' + index}
                    placeholder={localization.formatMessage(
                      'env.var.name.placeholder',
                    )}
                    onChange={this.onVariableNameChange(props.onChange)}
                    disabled={isDisabled}
                  />
                )}
              />
            )}
          </div>
        </td>
        <td>
          <div className={style.cellContainer}>
            {previewMode ? (
              <>
                <div className={style.valueContainer}>
                  <WithPopper
                    className={classNames(style.varPopper, style.envVarTooltip)}
                    title={this.variableValueTooltipTitle}
                  >
                    <div
                      id={this.getElementId('variableValue')}
                      className={classNames(
                        style.inputText,
                        !(
                          this.isValueVisible ||
                          !environmentVariableItem.variableSecure
                        ) && style.dottedText,
                      )}
                    >
                      <span>{this.envVariableValue}</span>
                    </div>
                  </WithPopper>
                </div>
                {environmentVariableItem.variableSecure && (
                  <div className={style.displayIconContainer}>
                    <WithPopper
                      title={<FormattedMessage id={this.iconTooltip} />}
                    >
                      <Icon
                        className={classNames(
                          style.displayIcon,
                          !this.isValueVisible && style.closeEye,
                        )}
                        type={this.iconType}
                        onClick={this.toggleValueVisible}
                      />
                    </WithPopper>
                  </div>
                )}
              </>
            ) : (
              <FormControl
                withoutLabel
                className={style.variableValueInput}
                name="variableValue"
                immediateValidation={true}
                render={props => (
                  <TextField
                    {...props}
                    id={'variableValue' + index}
                    placeholder={localization.formatMessage(
                      'env.var.value.placeholder',
                    )}
                    onChange={this.onVarValueChange(props.onChange)}
                    disabled={isDisabled}
                  />
                )}
              />
            )}
          </div>
        </td>
        <td>
          <div
            className={classNames(style.cellContainer, style.buttonsContainer)}
          >
            <div className={style.checkboxContainer}>
              <label>
                <Checkbox
                  className={classNames(style.envVarCheckbox)}
                  onChange={this.onChangeSecure}
                  checked={this.props.environmentVariableItem.variableSecure}
                  disabled={this.props.previewMode || isDisabled}
                />
              </label>
            </div>
            {!previewMode && (
              <Icon
                type={'deleteEmpty'}
                className={classNames(
                  style.deleteBtn,
                  isDisabled && style.disabled,
                )}
                onClick={() => this.props.deleteEnvVariable(this.props.index)}
                disabled={isDisabled}
              />
            )}
          </div>
        </td>
      </tr>
    );
  }
}

export default EnvVarTableItem;
