import { Icons } from 'components/Icon';
import { ToastOptions } from 'react-toastify';

export enum ContentType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
  HELP = 'help',
}

export enum AnimationType {
  FADE = 'fadeToast',
  FADE_AND_MOVE = 'fadeAndMoveToast',
}

export enum ToastPosition {
  TOP_RIGHT = 'top-right',
  TOP_CENTER = 'top-center',
  TOP_LEFT = 'top-left',
}

export enum ToastType {
  STATE,
  CONTENT,
  SCREEN_WIDTH,
}

export interface ICustomToastOptions extends ToastOptions {
  toastType?: ToastType;
  content?: string;
  icon?: keyof typeof Icons;
}

export interface IMessage {
  id: string;
  values?: { [key: string]: string };
}

export interface IRenderContentOptions {
  toastType?: ToastType;
  content?: string;
  icon?: keyof typeof Icons;
}
