import React, { ReactNode } from 'react';
import classNames from 'classnames';

import style from './ClusterCardWrapper.module.scss';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/Icon/Icon';
import { observer, inject } from 'mobx-react';
import { action, observable } from 'mobx';

interface Props {
  className?: string;
  title?: string;
  type?: string;
  children?: ReactNode;
  top?: boolean;
  label?: string;
  name?: string;
  onCollapse?: () => void;
  gotoClusterPage?: () => void;
  alertsStore?;
}

@inject('alertsStore')
@observer
class ClusterCardWrapper extends React.Component<Props> {
  @observable isClosed = false;

  @action
  toggleTab = () => {
    this.isClosed = !this.isClosed;

    if (this.props.onCollapse) {
      this.props.onCollapse();
    }
  };

  render() {
    const {
      title = '',
      gotoClusterPage,
      className,
      label,
      children,
      name,
      top,
    } = this.props;

    const tabClasses: string = classNames(style.tab, className, {
      [style.tab__top]: top,
      [style.tab__closed]: this.isClosed,
      [style.tab__openedAlert]: this.props.alertsStore.isOpen,
    });

    const contentClasses = classNames(style.content, {
      [style.content__closed]: this.isClosed,
    });

    return (
      <div className={tabClasses}>
        <h5>
          <span className={style.clusterTitle} onClick={gotoClusterPage}>
            <FormattedMessage id={title} /> {name}
          </span>
          {label && <span className={style.tab__label}>{label}</span>}
          {!top && (
            <Icon
              type={this.isClosed ? 'collapseBtnClosed' : 'collapseBtnOpened'}
              onClick={this.toggleTab}
            />
          )}
        </h5>
        <div className={contentClasses}>{children}</div>
      </div>
    );
  }
}

export default ClusterCardWrapper;
