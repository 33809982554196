import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { replaceUrlParams } from 'http/helpers';
import { ROUTES } from 'routes/routes';

import { ModalProps, DialogActionType } from 'stores/ModalStore';
import TextField from 'components/Form/Fields/TextField/TextField';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import DeleteEnvironmentForm from './DeleteEnvironmentForm';

import style from './DeleteEnvironmentModal.module.scss';
import CommonModal, { ModalType } from '../../Modals/Modal/CommonModal';

interface Props extends ModalProps {
  clusterName: string;
  projectName: string;
  environmentName: string;
  deleteEnvironment: (onDelete: () => void) => Promise<any>;
}

@observer
class DeleteEnvironmentModal extends Component<Props & RouteComponentProps> {
  @observable deleteEnvironmentForm = new DeleteEnvironmentForm();
  FormControl = bindFormControl(this.deleteEnvironmentForm);
  @observable
  serverErrors: string[] = [];

  @observable
  serverWarnings: string[] = [];

  @observable
  isUIblocked: boolean = false;

  onClose = () => {
    if (!this.isUIblocked) {
      this.props.onClose(DialogActionType.cancel);
    }
  };

  gotoClusterPage = () => {
    const { projectName, clusterName, history } = this.props;

    history.push(
      replaceUrlParams(ROUTES.cluster, {
        id: projectName,
        clusterName,
      }),
    );
  };

  onConfirm = async () => {
    try {
      const isValid = this.deleteEnvironmentForm.validate();
      if (isValid) {
        this.isUIblocked = true;
        await this.props.deleteEnvironment(this.gotoClusterPage);
        this.props.onClose(DialogActionType.submit);
      }
    } catch (error) {
      this.serverErrors = error.response.data.errorMessages || [];
      this.serverWarnings = error.response.data.warningMessages || [];
    } finally {
      this.isUIblocked = false;
    }
  };

  componentDidMount() {
    const { environmentName } = this.props;

    this.deleteEnvironmentForm.setEnvironmentNameExample(environmentName);
  }
  onEnvNameChange = (value: string) => {
    this.deleteEnvironmentForm.validate();
    if (value.length > 0) {
      this.deleteEnvironmentForm.setValue('environmentName', value);
    } else {
      this.deleteEnvironmentForm.setValue('environmentName', '');
    }
  };

  render() {
    const { environmentName } = this.props;
    const FormControl = this.FormControl;

    return (
      <CommonModal
        bodyClassName={style.body}
        bodyContentClassName={style.bodyContent}
        title="environment.delete.title"
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        serverErrors={this.serverErrors}
        serverWarnings={this.serverWarnings}
        isSubmitBtnDisabled={!this.deleteEnvironmentForm.isFormValid}
        isUIblocked={this.isUIblocked}
        modalType={ModalType.Delete}
        headerIcon="modalError"
        confirm={'button.delete'}
      >
        <div className={style.text1}>
          <FormattedMessage
            id="env.modal.delete.text1"
            values={{
              environmentName,
            }}
          />
        </div>
        <div className={style.text2}>
          <FormattedMessage id="env.modal.delete.text2" />
        </div>
        <div className={style.text3}>
          <FormattedMessage
            id="cluster.modal.shutdown.text3"
            values={{
              clusterName: (
                <span className={style.example}>{environmentName}</span>
              ),
            }}
          />
        </div>
        <FormControl
          className={style.input}
          name="environmentName"
          render={props => (
            <TextField {...props} onChange={this.onEnvNameChange} />
          )}
        />
      </CommonModal>
    );
  }
}

export default withRouter(DeleteEnvironmentModal);
