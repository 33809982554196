import React, { Component, ReactNode } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Omit } from 'helpers/types';
import style from './FormControl.module.scss';

import { BaseFormModel } from 'stores/BaseForm/BaseFormModel';
import { ValidationResult } from 'stores/BaseForm/types';
import FormControlErrors from './FormControlError';
import Icon from 'components/Icon/Icon';
import { Icons } from 'components/Icon';
import { observable } from 'mobx';

interface Props<T> {
  description?: string | ReactNode;
  withoutLabel?: boolean;
  name: string;
  form: BaseFormModel;
  className?: string;
  messageClassName?: string;
  immediateValidation?: boolean;
  errors?: ValidationResult[];
  render: (props: {
    className?: string;
    name: string;
    value: T;
    onChange: (value: T) => void;
  }) => React.ReactElement<any>;
}

interface Styles {
  isSucceed: boolean;
  hasWarnings: boolean;
  hasInfo: boolean;
  hasErrors: boolean;
}

@observer
class FormControl<T = any> extends Component<Props<T>> {
  @observable validateField = false;
  @observable isAutofilled = false;

  onChange = (value: T) => {
    this.props.form.setValue(this.props.name, value);
    if (this.props.immediateValidation) {
      this.validateField = true;
    }
  };

  getErrors() {
    const { name, form, immediateValidation } = this.props;
    if (immediateValidation) {
      return this.validateField ? form.errorFor(name) : [];
    } else {
      return form.validated ? form.errorFor(name) : [];
    }
  }

  onAutoFillStart = () => (this.isAutofilled = true);
  onAutoFillCancel = () => (this.isAutofilled = false);

  onAnimationStart = ({ animationName }) => {
    switch (animationName) {
      case 'onAutoFillStart':
        return this.onAutoFillStart();
      case 'onAutoFillCancel':
        return this.onAutoFillCancel();
    }
  };

  public render(): JSX.Element {
    const { className, name, form, render, description, messageClassName } =
      this.props;
    const value = form[name];
    const fieldErrors = form.errorsMap.get(name);
    const errors =
      this.props.errors && this.props.immediateValidation
        ? this.props.errors
        : fieldErrors && fieldErrors.length
        ? fieldErrors
        : this.getErrors();
    const styles = this.getStyles({
      hasErrors: Boolean(errors.length),
    });
    const iconType = this.getIconType(styles);

    const renderProps = {
      value,
      onChange: this.onChange,
      name,
      onAnimationStart: this.onAnimationStart,
      ...styles,
    };

    if (iconType) {
      (renderProps as any).iconType = iconType;
    }

    return (
      <div className={classNames(style.formField, className)}>
        {!this.props.withoutLabel && (
          <label
            className={classNames(style.fieldLabel, {
              [style.fieldLabel__active]: Boolean(value) || this.isAutofilled,
              [style.fieldLabel__info]: styles.hasInfo,
              [style.fieldLabel__warning]: styles.hasWarnings,
              [style.fieldLabel__error]: styles.hasErrors,
              [style.fieldLabel__success]: styles.isSucceed,
            })}
            htmlFor={name}
          >
            <FormattedMessage
              id={form.displayName(name)}
              defaultMessage={name}
            />
          </label>
        )}

        {render(renderProps)}

        {errors && !!errors.length && (
          <div
            className={classNames(messageClassName, style.message, {
              [style.message__info]: styles.hasInfo,
              [style.message__warning]: styles.hasWarnings,
              [style.message__error]: styles.hasErrors,
              [style.message__success]: styles.isSucceed,
            })}
          >
            <Icon type={iconType as keyof typeof Icons} />
            <div className={style.errorsWrapper}>
              <FormControlErrors errors={errors} />
            </div>
          </div>
        )}
        {(!errors || errors.length === 0) && description && (
          <div className={style.description}>{description}</div>
        )}
      </div>
    );
  }

  private getStyles = ({ hasErrors }): Styles => {
    return {
      isSucceed: false,
      hasWarnings: false,
      hasInfo: false,
      hasErrors,
    };
  };

  private getIconType = (styles: Styles): string | void => {
    switch (true) {
      case styles.hasWarnings:
        return 'warning';
      case styles.isSucceed:
        return 'accept';
      case styles.hasErrors || styles.hasInfo:
        return 'info';
    }
  };
}

const bindFormControl =
  (form: BaseFormModel) =>
  <T extends unknown = any>(props: Omit<Props<T>, 'form'>) =>
    <FormControl form={form} {...props} />;

export { FormControl, bindFormControl };
