import http from '../http';
import { appUrl } from 'http/helpers';
import URLS from 'http/urls';
import {
  ITemplate,
  TemplateParams,
  TemplatesParams,
  ITemplateVariable,
} from 'models/Template';

export const templatesApi = {
  fetchTemplates(replaceParams: TemplatesParams) {
    return http.get<ITemplate[]>(appUrl(URLS.templates), {
      replaceParams,
    });
  },

  fetchTemplatesVariables(replaceParams: TemplatesParams) {
    return http.get<ITemplateVariable[]>(appUrl(URLS.templatesVariables), {
      replaceParams,
    });
  },

  createTemplate(data: ITemplate, replaceParams: TemplatesParams) {
    return http.post<ITemplate, ITemplate>(appUrl(URLS.templates), data, {
      replaceParams,
    });
  },

  updateTemplate(data: ITemplate, replaceParams: TemplateParams) {
    return http.put<ITemplate>(appUrl(URLS.template), data, {
      replaceParams,
    });
  },

  deleteTemplate(replaceParams: TemplateParams) {
    return http.delete(appUrl(URLS.template), {
      replaceParams,
    });
  },

  resetTemplates(replaceParams: TemplatesParams) {
    return http.delete(appUrl(URLS.templates), {
      replaceParams,
    });
  },

  updateTemplateVariables(
    replaceParams: TemplatesParams,
    templateVarsData: ITemplateVariable[],
  ) {
    return http.put(appUrl(URLS.templatesVariables), templateVarsData, {
      replaceParams,
    });
  },
};
