import { observable, action } from 'mobx';

import {
  BaseFormModel,
  displayName,
  hasMinMaxLength,
  isMatchRegExp,
  isRequired,
} from 'stores/BaseForm';

import { KEY_REGEXP, ENV_KEY_START_END_REGEXP } from 'helpers/testRegexp';

import httpFacade from 'http/httpFacade';

import NotificationService, {
  fullscreenNotificationOptions,
} from '../../Notification/NotificationService';

class CreateExternalStorageProviderForm extends BaseFormModel {
  @observable
  @displayName('external.storage.provider.form.provide.name')
  @isRequired()
  providerName: string = '';

  @observable
  @displayName('external.storage.provider.form.provide.instance.name')
  @isRequired()
  instanceName: string = '';

  @observable
  @displayName('external.storage.provider.form.provider.instance.key')
  @hasMinMaxLength(1, 63, 'validation.provider.key.length')
  @isMatchRegExp(KEY_REGEXP, 'validation.provider.key.characters')
  @isMatchRegExp(ENV_KEY_START_END_REGEXP, 'validation.provider.key.startend')
  instanceKey: string = '';

  @observable
  @displayName('external.storage.provider.form.host')
  @isRequired()
  host: string = '';

  @observable
  @displayName('external.storage.provider.form.port')
  @isRequired()
  port: string = '';

  @observable
  @displayName('external.storage.provider.form.username')
  @isRequired()
  userName: string = '';

  @observable
  @displayName('external.storage.provider.form.password')
  @isRequired()
  password: string = '';

  @action.bound
  async submit(projectName: string, clusterName: string) {
    await httpFacade.cluster.createInstanceProvider(
      {
        name: this.instanceKey,
        longName: this.instanceName,
        host: this.host,
        port: +this.port,
        userName: this.userName,
        password: this.password,
      },
      { projectName, clusterName, providerId: this.providerName },
    );
    NotificationService.successMessage(
      { id: 'external.storage.provider.create.success' },
      fullscreenNotificationOptions,
    );
  }
}

export default CreateExternalStorageProviderForm;
