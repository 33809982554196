import React, { ReactChild } from 'react';
import classNames from 'classnames';

import style from './BorderedText.module.scss';

export enum BorderedTextType {
  Info = 'info',
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
}

interface Props {
  className?: string;
  type?: BorderedTextType;
  children?: ReactChild;
}

const BorderedText: React.FC<Props> = ({
  className = '',
  type = BorderedTextType.Info,
  children,
}) => {
  const classes: string = classNames(style.text, className, {
    [style.text__info]: type === BorderedTextType.Info,
    [style.text__error]: type === BorderedTextType.Error,
    [style.text__warning]: type === BorderedTextType.Warning,
    [style.text__success]: type === BorderedTextType.Success,
  });

  return <div className={classes}>{children}</div>;
};

export default BorderedText;
