import React, { ComponentType, ReactElement } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import DefaultTabsList from 'components/Tabs/DefaultTabs/DefaultTabsList';
import { TabCounter } from 'components/Tabs/DefaultTabs/DefaultTabsItem';

export interface TabsProps {
  onTabClick: (index: number) => () => void;
  activeTabIndex: number;
}

export interface ITabsComponent {
  component: ReactElement;
  label: string;
  labelContent?: (isActive?: boolean) => JSX.Element;
  counter?: TabCounter;
  onCounterClick?: () => void;
}

interface Props {
  CustomTabs?: ComponentType<TabsProps>;
  tabsComponents: ITabsComponent[];
  activeTabIndex?: number;
}

@observer
class TabsContainer extends React.Component<Props> {
  @observable activeIndex = 0;

  onTabClick = (index: number) => () => {
    this.activeIndex = index;
  };

  componentDidMount() {
    if (this.props.activeTabIndex) {
      this.activeIndex = this.props.activeTabIndex;
    }
  }

  render() {
    const { tabsComponents, CustomTabs } = this.props;

    return (
      <>
        {CustomTabs ? (
          <CustomTabs
            onTabClick={this.onTabClick}
            activeTabIndex={this.activeIndex}
          />
        ) : (
          <DefaultTabsList
            tabs={tabsComponents}
            activeTabIndex={this.activeIndex}
            onTabClick={this.onTabClick}
          />
        )}

        {tabsComponents[this.activeIndex]
          ? tabsComponents[this.activeIndex].component
          : tabsComponents[0].component}
      </>
    );
  }
}

export default TabsContainer;
