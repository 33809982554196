import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { localization } from '../../../../index';

import { ComparingState } from 'models/Environment';
import { ComparingEnvVarsForm } from 'stores/ApplyMsTo/ComparingEnvVarsForm';

import Button, { BtnType } from 'components/Button/Button';
import style from 'components/EnvironmentPageServicesList/Compare/CompareMsItem/CompareMsItem.module.scss';
import Checkbox from 'components/Form/Fields/Checkbox/Checkbox';
import TextField from 'components/Form/Fields/TextField/TextField';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import { TableBodyRow } from 'components/Table/TableBobyRow';
import Icon from '../../../Icon/Icon';
import WithPopper from '../../../WithPopper/WithPopper';
import { uniqueId } from '../../../../helpers/uniqueId';

interface IProps {
  envVarForm: ComparingEnvVarsForm;
  isShowOnlySourceValue?: boolean;
}

export const CompareEnvVar = observer(
  ({ envVarForm, isShowOnlySourceValue }: IProps) => {
    const [valAppliedVisible, toggleAppliedVisible] = useState(false);
    const [valSourceVisible, toggleSourceVisible] = useState(false);
    const [valTargetVisible, toggleTargetVisible] = useState(false);
    const { envVar } = envVarForm;
    const FormControl = bindFormControl(envVarForm);

    const onVarValueChange = onChange => (value: string, e) => {
      onChange(value, e);
      envVarForm.checkRequiredValid();
      envVarForm.validate();
    };

    return (
      <TableBodyRow
        className={classNames(style.tableRow, {
          [style.tableRow__new]: envVar.envVarState === ComparingState.NEW,
          [style.tableRow__modified]:
            envVar.envVarState === ComparingState.MODIFIED,
          [style.tableRow__deleted]:
            envVar.envVarState === ComparingState.DELETED,
        })}
      >
        <td>
          <div className={style.nameContainer}>
            <WithPopper
              className={classNames(style.textTooltip)}
              title={envVar.name}
            >
              <div className={classNames(style.varName, style.textWrapper)}>
                {envVar.name}
              </div>
            </WithPopper>
          </div>
        </td>
        <td colSpan={isShowOnlySourceValue ? 2 : 0}>
          {envVar.valueSource && (
            <div className={style.cellContainer}>
              <div className={style.varValue}>
                <WithPopper
                  className={classNames(style.textTooltip)}
                  title={
                    !envVar.secureSource || valSourceVisible
                      ? envVar.valueSource
                      : ''
                  }
                >
                  <div className={style.textWrapper}>
                    {!envVar.secureSource || valSourceVisible
                      ? envVar.valueSource
                      : envVar.valueSource?.replace(/./g, '·')}
                  </div>
                </WithPopper>
                {envVar.secureSource && (
                  <Icon
                    className={style.visibleIcon}
                    type={valSourceVisible ? 'visible' : 'hidden'}
                    onClick={() => toggleSourceVisible(!valSourceVisible)}
                  />
                )}
              </div>
              {!envVarForm.isValueEqualToSource && (
                <Button
                  styleType={BtnType.Text}
                  className={classNames(style.btnIcon, style.btnUse)}
                  onClick={envVarForm.useSourceValue}
                >
                  <FormattedMessage id="button.use.this" />
                </Button>
              )}
            </div>
          )}
        </td>
        {!isShowOnlySourceValue && (
          <td>
            {envVar.valueTarget && (
              <div className={style.cellContainer}>
                <div className={style.varValue}>
                  <WithPopper
                    className={classNames(style.textTooltip)}
                    title={
                      !envVar.secureTarget || valTargetVisible
                        ? envVar.valueTarget
                        : ''
                    }
                  >
                    <div className={style.textWrapper}>
                      {!envVar.secureTarget || valTargetVisible
                        ? envVar.valueTarget
                        : envVar.valueTarget.replace(/./g, '·')}
                    </div>
                  </WithPopper>
                  {envVar.secureTarget && (
                    <Icon
                      className={style.visibleIcon}
                      type={valTargetVisible ? 'visible' : 'hidden'}
                      onClick={() => toggleTargetVisible(!valTargetVisible)}
                    />
                  )}
                </div>
                {!envVarForm.isValueEqualToTarget && (
                  <Button
                    styleType={BtnType.Text}
                    className={classNames(style.btnIcon, style.btnUse)}
                    onClick={envVarForm.useTargetValue}
                  >
                    <FormattedMessage id="button.use.this" />
                  </Button>
                )}
              </div>
            )}
          </td>
        )}
        <td>
          {!envVarForm.deleted && (
            <FormControl
              withoutLabel
              className={style.input}
              name="value"
              render={props => (
                <TextField
                  {...props}
                  id={`value_${uniqueId()}`}
                  placeholder={
                    envVarForm.envVar.envVarState === ComparingState.MODIFIED ||
                    envVarForm.envVar.envVarState === ComparingState.DELETED
                      ? localization.formatMessage(
                          'comparing.value.placeholder',
                        )
                      : ''
                  }
                  onChange={onVarValueChange(props.onChange)}
                  iconType={
                    envVarForm.secure
                      ? valAppliedVisible
                        ? 'visible'
                        : 'hidden'
                      : undefined
                  }
                  type={
                    envVarForm.secure && !valAppliedVisible
                      ? 'password'
                      : undefined
                  }
                  onIconClick={() => toggleAppliedVisible(!valAppliedVisible)}
                />
              )}
              immediateValidation
            />
          )}
        </td>
        <td className={style.cellCenter}>
          <label className={style.label}>
            <Checkbox
              checked={envVarForm.deleted}
              onChange={envVarForm.toggleDeleted}
            />
          </label>
        </td>
        <td className={style.cellCenter}>
          <label className={style.label}>
            <Checkbox
              checked={envVarForm.envSpecific}
              onChange={envVarForm.toggleEnvSpecific}
            />
          </label>
        </td>
      </TableBodyRow>
    );
  },
);
