import { observable, action, computed } from 'mobx';
import httpFacade, { IntervalFetch } from 'http/httpFacade';
import { Alert, AlertReferenceKind, AlertsFilter } from 'models/Alert';
import RootStore from 'stores/RootStore';
import { sortByName } from 'helpers/array';

const HIDDEN_ALERT_CLEAR_COUNT = 5;

export enum AlertsSelectionState {
  All = 'All',
  Page = 'Page',
  FilteredFromPage = 'FilteredFromPage',
}

export enum DisplayedAlerts {
  Visible,
  Hidden,
}

export default class AlertsStore {
  @observable displayedAlerts: DisplayedAlerts = DisplayedAlerts.Visible;
  @observable alerts: Alert[] = [];
  @observable hiddenAlertsCounters: any = [];
  @observable pending = true;
  @observable isOpen = false;
  @observable lastClickedElement = '';
  @observable isClickOutsideBlocked = false;
  @observable filters: AlertsFilter[] = [];
  @observable intervalFetch: IntervalFetch = new IntervalFetch();
  @observable pageFilter: AlertsFilter = {};
  @observable selectionState: AlertsSelectionState = AlertsSelectionState.Page;

  constructor() {
    this.hiddenAlertsCounters =
      JSON.parse(localStorage.getItem('hiddenAlertsCounters') || 'null') || [];
    this.intervalFetch.setRequest(() =>
      this.fetchAlerts(RootStore.currentProject),
    );
    this.intervalFetch.enable();
  }

  @action
  setSelectionState(selectionState: AlertsSelectionState) {
    this.selectionState = selectionState;
  }

  @action
  showAllAlerts = () => {
    this.setSelectionState(AlertsSelectionState.All);
  };

  @action
  showPageAlerts = () => {
    this.setSelectionState(AlertsSelectionState.Page);
  };

  @action
  setPageFilter = pageParams => {
    const result: AlertsFilter = {};
    if (pageParams.clusterName) {
      result.clusterReference = pageParams.clusterName;
    }
    if (pageParams.environmentName) {
      result.environmentReference = pageParams.environmentName;
    }
    if (pageParams.microserviceName) {
      result.microserviceReference = pageParams.microserviceName;
    }
    this.pageFilter = result;
  };

  @action
  setFilters = (filters: AlertsFilter[]) => {
    this.filters = filters;

    if (filters.length) {
      this.setSelectionState(AlertsSelectionState.FilteredFromPage);
    } else {
      this.setSelectionState(AlertsSelectionState.Page);
    }
  };

  @action
  clearTimer() {
    this.intervalFetch.disable();
  }

  @action
  toggleDisplayedAlerts = () => {
    this.displayedAlerts =
      this.displayedAlerts === DisplayedAlerts.Visible
        ? DisplayedAlerts.Hidden
        : DisplayedAlerts.Visible;
  };

  @action.bound
  async fetchAlerts(projectName: string) {
    try {
      const response = await httpFacade.alerts.fetchAlerts(projectName);
      this.alerts = response.data;
      this.updateHiddenAlertsCount();
    } catch (error) {
      this.alerts = [];
    }
  }

  @action
  updateHiddenAlertsCount = () => {
    this.hiddenAlertsCounters.forEach(hiddenAlertsCounter => {
      hiddenAlertsCounter.count = !this.alerts.some(
        alert => JSON.stringify(alert) === hiddenAlertsCounter.alert,
      )
        ? hiddenAlertsCounter.count + 1
        : 0;
    });
    this.hiddenAlertsCounters = this.hiddenAlertsCounters.filter(
      el =>
        el.count <
        (Number(localStorage.getItem('HIDDEN_ALERT_CLEAR_COUNT')) ||
          HIDDEN_ALERT_CLEAR_COUNT),
    );
    localStorage.setItem(
      'hiddenAlertsCounters',
      JSON.stringify(this.hiddenAlertsCounters),
    );
  };

  @action
  toggleAlertVisibility = alert => {
    if (
      this.hiddenAlertsCounters.some(
        hiddenAlertsCounter =>
          JSON.stringify(alert) === hiddenAlertsCounter.alert,
      )
    ) {
      const deleteIdx = this.hiddenAlertsCounters.findIndex(
        el => el.alert === JSON.stringify(alert),
      );
      this.hiddenAlertsCounters.splice(deleteIdx, 1);
    } else {
      this.hiddenAlertsCounters.push({
        alert: JSON.stringify(alert),
        count: 0,
      });
    }
    localStorage.setItem(
      'hiddenAlertsCounters',
      JSON.stringify(this.hiddenAlertsCounters),
    );
  };

  getFilterBySelectionState(): AlertsFilter[] {
    switch (this.selectionState) {
      case AlertsSelectionState.All:
        return [];
      case AlertsSelectionState.Page:
        return [this.pageFilter];
      case AlertsSelectionState.FilteredFromPage:
        return [...this.filters, this.pageFilter];
    }
  }

  @computed
  get totalAlertsCount() {
    return this.getAlertsByFilters(
      [],
      this.alerts,
      DisplayedAlerts.Visible,
      this.hiddenAlertsCounters,
    ).length;
  }

  @computed
  get pageAlertsCount() {
    return this.getAlertsByFilters(
      [this.pageFilter],
      this.alerts,
      DisplayedAlerts.Visible,
      this.hiddenAlertsCounters,
    ).length;
  }

  @action
  getAlertsByFilters = (
    filters: AlertsFilter[],
    alerts,
    displayedAlertsType,
    hiddenAlertsCounters,
  ) => {
    const filteringFunc = (filter: AlertsFilter) => (alert: Alert) => {
      const clusterReference = alert.references.find(
        reference => reference.kind === AlertReferenceKind.Cluster,
      );
      const environmentReference = alert.references.find(
        reference => reference.kind === AlertReferenceKind.Environment,
      );
      const microserviceReference = alert.references.find(
        reference => reference.kind === AlertReferenceKind.Microservice,
      );
      const availabilityReference = alert.references.find(
        reference =>
          reference.kind === AlertReferenceKind.Git ||
          reference.kind === AlertReferenceKind.K8s ||
          reference.kind === AlertReferenceKind.IssueTracker ||
          reference.kind === AlertReferenceKind.DockerRegistry ||
          reference.kind === AlertReferenceKind.Esm,
      );

      const alertReference = {
        clusterReference,
        environmentReference,
        microserviceReference,
        availabilityReference,
      };

      if (filter.withOutMsRef) {
        return !microserviceReference;
      }

      if (filter.anyMsRef) {
        return !!microserviceReference;
      }

      return Object.keys(filter).every(filterReferenceKind => {
        return (
          alertReference[filterReferenceKind] &&
          filter[filterReferenceKind] === alertReference[filterReferenceKind].id
        );
      });
    };

    return filters.reduce(
      (acc, filter) => {
        return acc.filter(filteringFunc(filter));
      },
      alerts.filter(alert =>
        displayedAlertsType === DisplayedAlerts.Hidden
          ? hiddenAlertsCounters.some(
              hiddenAlertsCounter =>
                JSON.stringify(alert) === hiddenAlertsCounter.alert,
            )
          : hiddenAlertsCounters.every(
              hiddenAlertsCounter =>
                JSON.stringify(alert) !== hiddenAlertsCounter.alert,
            ),
      ),
    );
  };

  @action
  showAlertDrawer = (filters: AlertsFilter[], clickedElement: string) => {
    if (this.isOpen && clickedElement === this.lastClickedElement) {
      this.closeAlertDrawer();
      return;
    }
    if (this.lastClickedElement) {
      this.isClickOutsideBlocked = true;
      setTimeout(() => {
        this.isClickOutsideBlocked = false;
      }, 20);
    }
    this.displayedAlerts = DisplayedAlerts.Visible;
    this.lastClickedElement = clickedElement;
    this.setFilters(filters);
    this.isOpen = true;
  };

  @action
  closeAlertDrawer = () => {
    this.isOpen = false;
    this.lastClickedElement = '';
  };

  clickOutside = () => {
    setTimeout(() => {
      if (!this.isClickOutsideBlocked) {
        this.closeAlertDrawer();
      }
    }, 10);
  };

  @computed
  get availabilityAlertsSlice() {
    return this.alerts.filter((alert: Alert) => {
      return alert.references.find(
        reference =>
          reference.kind === AlertReferenceKind.Git ||
          reference.kind === AlertReferenceKind.K8s ||
          reference.kind === AlertReferenceKind.IssueTracker ||
          reference.kind === AlertReferenceKind.DockerRegistry ||
          reference.kind === AlertReferenceKind.Esm,
      );
    });
  }

  @computed
  get normalizedAlerts() {
    const alerts = this.getAlertsByFilters(
      this.getFilterBySelectionState(),
      this.alerts,
      this.displayedAlerts,
      this.hiddenAlertsCounters,
    );

    const result: any = { clusters: [], systemAlerts: [] };

    alerts.reduce((acc, alert) => {
      const clusterReference = alert.references.find(
        reference => reference.kind === AlertReferenceKind.Cluster,
      );

      if (!clusterReference) {
        acc.systemAlerts.push(alert);
        return acc;
      }

      let cluster = acc.clusters.find(el => el.name === clusterReference.id);
      if (!cluster) {
        cluster = { name: clusterReference.id, scopes: {}, alerts: [] };
        acc.clusters.push(cluster);
      }

      const clusterReferenceOnly =
        alert.references.length === 1 &&
        !!alert.references.find(
          reference => reference.kind === AlertReferenceKind.Cluster,
        );

      if (clusterReferenceOnly) {
        if (!cluster.scopes.general) {
          cluster.scopes.general = [];
        }
        cluster.scopes.general.push(alert);
        return acc;
      }

      const configReference = alert.references.find(
        reference => reference.kind === AlertReferenceKind.Config,
      );
      if (configReference) {
        if (!cluster.scopes.config) {
          cluster.scopes.config = [];
        }
        cluster.scopes.config.push(alert);
        return acc;
      }

      const diskReference = alert.references.find(
        reference => reference.kind === AlertReferenceKind.Disk,
      );
      if (diskReference) {
        if (!cluster.scopes.monitoring) {
          cluster.scopes.monitoring = [];
        }
        let scope = cluster.scopes.availability.find(
          el => el.name === diskReference.id,
        );
        if (!scope) {
          scope = {
            name: diskReference.id,
            microservices: [],
            alerts: [],
          };
          scope.alerts.push(alert);
          cluster.scopes.availability.push(scope);
        }
        return acc;
      }

      const cpuReference = alert.references.find(
        reference => reference.kind === AlertReferenceKind.CPU,
      );
      if (cpuReference) {
        if (!cluster.scopes.monitoring) {
          cluster.scopes.monitoring = [];
        }
        let scope = cluster.scopes.availability.find(
          el => el.name === cpuReference.id,
        );
        if (!scope) {
          scope = {
            name: cpuReference.id,
            microservices: [],
            alerts: [],
          };
          scope.alerts.push(alert);
          cluster.scopes.availability.push(scope);
        }
        return acc;
      }

      const memoryReference = alert.references.find(
        reference => reference.kind === AlertReferenceKind.Memory,
      );
      if (memoryReference) {
        if (!cluster.scopes.monitoring) {
          cluster.scopes.monitoring = [];
        }
        let scope = cluster.scopes.availability.find(
          el => el.name === memoryReference.id,
        );
        if (!scope) {
          scope = {
            name: memoryReference.id,
            microservices: [],
            alerts: [],
          };
          scope.alerts.push(alert);
          cluster.scopes.availability.push(scope);
        }
        return acc;
      }

      const gitReference = alert.references.find(
        reference => reference.kind === AlertReferenceKind.Git,
      );
      if (gitReference) {
        if (!cluster.scopes.availability) {
          cluster.scopes.availability = [];
        }
        let scope = cluster.scopes.availability.find(
          el => el.name === gitReference.id,
        );
        if (!scope) {
          scope = {
            name: gitReference.id,
            microservices: [],
            alerts: [],
          };
          scope.alerts.push(alert);
          cluster.scopes.availability.push(scope);
        }
        return acc;
      }

      const issueTrackerReference = alert.references.find(
        reference => reference.kind === AlertReferenceKind.IssueTracker,
      );
      if (issueTrackerReference) {
        if (!cluster.scopes.availability) {
          cluster.scopes.availability = [];
        }

        let scope = cluster.scopes.availability.find(
          el => el.name === issueTrackerReference.id,
        );
        if (!scope) {
          scope = {
            name: issueTrackerReference.id,
            microservices: [],
            alerts: [],
          };
          scope.alerts.push(alert);
          cluster.scopes.availability.push(scope);
        }
        return acc;
      }

      const dockerRegistryReference = alert.references.find(
        reference => reference.kind === AlertReferenceKind.DockerRegistry,
      );
      if (dockerRegistryReference) {
        if (!cluster.scopes.availability) {
          cluster.scopes.availability = [];
        }
        let scope = cluster.scopes.availability.find(
          el => el.name === dockerRegistryReference.id,
        );
        if (!scope) {
          scope = {
            name: dockerRegistryReference.id,
            microservices: [],
            alerts: [],
          };
          scope.alerts.push(alert);
          cluster.scopes.availability.push(scope);
        }
        return acc;
      }

      const k8sReference = alert.references.find(
        reference => reference.kind === AlertReferenceKind.K8s,
      );
      if (k8sReference) {
        if (!cluster.scopes.availability) {
          cluster.scopes.availability = [];
        }
        let scope = cluster.scopes.availability.find(
          el => el.name === k8sReference.id,
        );
        if (!scope) {
          scope = {
            name: k8sReference.id,
            microservices: [],
            alerts: [],
          };
          scope.alerts.push(alert);
          cluster.scopes.availability.push(scope);
        }
        return acc;
      }

      const externalStoragesReference = alert.references.find(
        reference => reference.kind === AlertReferenceKind.Esm,
      );
      if (externalStoragesReference) {
        if (!cluster.scopes.availability) {
          cluster.scopes.availability = [];
        }
        let scope = cluster.scopes.availability.find(
          el => el.name === externalStoragesReference.id,
        );
        if (!scope) {
          scope = {
            name: externalStoragesReference.id,
            microservices: [],
            alerts: [],
          };
          scope.alerts.push(alert);
          cluster.scopes.availability.push(scope);
        }
        return acc;
      }

      const environmentReference = alert.references.find(
        reference => reference.kind === AlertReferenceKind.Environment,
      );
      if (!environmentReference) {
        cluster.alerts.push(alert);
        return acc;
      }
      if (!cluster.scopes.environment) {
        cluster.scopes.environment = [];
      }
      let environment = cluster.scopes.environment.find(
        el => el.name === environmentReference.id,
      );
      if (!environment) {
        environment = {
          name: environmentReference.id,
          microservices: [],
          alerts: [],
        };
        cluster.scopes.environment.push(environment);
      }
      const microserviceReference = alert.references.find(
        reference => reference.kind === AlertReferenceKind.Microservice,
      );
      if (!microserviceReference) {
        environment.alerts.push(alert);
        return acc;
      }
      let microservice = environment.microservices.find(
        el => el.name === microserviceReference.id,
      );
      if (!microservice) {
        microservice = { name: microserviceReference.id, alerts: [] };
        environment.microservices.push(microservice);
      }
      microservice.alerts.push(alert);

      return acc;
    }, result);
    result.clusters.forEach(cluster => {
      if (cluster.scopes.environment) {
        sortByName(cluster.scopes.environment);
        const favoriteEnv =
          JSON.parse(
            localStorage.getItem(
              `favoriteEnv${RootStore.currentProject}${cluster.name}`,
            ) || 'null',
          ) || [];
        cluster.scopes.environment.sort(
          (a, b) =>
            Number(favoriteEnv.includes(b.name)) -
            Number(favoriteEnv.includes(a.name)),
        );
      }
    });
    return result;
  }
}
