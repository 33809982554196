import React, { RefObject, useState } from 'react';
import { usePopper } from 'react-popper';

import {
  BoundaryElementType,
  IModifier,
} from 'components/FloatingComponents/types';
import { Portal } from 'components/Portal';
import { closePopper } from 'components/FloatingComponents/Popover/popperModifiers';

interface IProps {
  referenceElement: RefObject<HTMLElement>;
  onClose: () => void;
  boundaryElement?: BoundaryElementType;
}

export const Popover = ({
  children,
  referenceElement,
  onClose,
  boundaryElement,
}: React.PropsWithChildren<IProps>) => {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [boundaryElementRect] = useState<DOMRect | null>(() => {
    return boundaryElement ? boundaryElement.getBoundingClientRect() : null;
  });
  const modifiers: IModifier[] = [
    {
      name: 'offset',
      options: {
        offset: [0, 5],
      },
    },
  ];

  const closePopperModifier = boundaryElementRect
    ? React.useMemo(
        () =>
          closePopper({
            boundaryElementRect,
            onClose,
          }),
        [],
      )
    : null;

  if (closePopperModifier) {
    modifiers.push(closePopperModifier);
  }

  const { styles, attributes } = usePopper(
    referenceElement.current,
    popperElement,
    {
      placement: 'bottom-end',
      strategy: 'fixed',
      modifiers,
    },
  );

  return (
    <Portal>
      <div style={styles.popper} ref={setPopperElement} {...attributes.popper}>
        {children}
      </div>
    </Portal>
  );
};
