import { ITemplateVariable } from 'models/Template';
import { Artifact, ArtifactUpdate } from './Artifact';
import { StorageProviderModes } from './ExternalStorage';
import {
  ArtifactStatus,
  RuntimeState,
  PodRuntimeStatus,
  SyncState,
} from './StatusTypes';

export class Service {
  isHaveProblem: boolean;
  isShutdown: boolean;
  favorite: boolean;
  name: string;
  longName: string;
  syncState: SyncState;
  artifactStatus: ArtifactStatus;
  runtimeState: RuntimeState;
  createDate?: Date;
  updateDate?: Date;
  image: string;
  newImage?: string;
  issues?: string[];
  links: {
    pipeline: string;
    logging: string;
    monitoring: string;
    storageManagerLogging?: string;
  };
  podRuntimeStatuses: IServiceInfoPodRuntimeStatus[];
}

export interface IServiceInfoPodRuntimeStatus {
  podName: string;
  outdated: boolean;
  runtimeStatus: PodRuntimeStatus;
}

export class ServiceIngressObject {
  clusterUrl: string;
  host?: string;
  tls?: boolean;
  paths?: string[];
  syncState: SyncState;
}

export class ServiceOther {
  isHaveProblem: boolean;
  kind: string;
  name: string;
  syncState: SyncState;
  createDate?: Date;
  updateDate?: Date;
}

export class ServiceDetails {
  info: Service;
  artifact: Artifact;
  serviceIngresses: ServiceIngressObject[];
  others: ServiceOther[];
  update?: ArtifactUpdate;
  deployment?: ServiceOther;
}

export interface IServiceParams {
  clusterName: string;
  projectName: string;
  environmentName: string;
  serviceName: string;
}

export interface Ingress {
  host: string;
  path: string;
  tls: boolean;
  certSecret?: string;
  clusterIssuer?: string;
}

export interface ApplicationRoute {
  containerPort: number;
  defaultIngress: boolean;
  ingress?: Ingress;
}

export interface IEnvVariable {
  name: string;
  value: string;
  secure: boolean;
}

export interface ServiceCreate {
  name: string;
  longName?: string;
  image: string;
  applicationRoutes: ApplicationRoute[];
  environmentVariables: IEnvVariable[];
  templateVariables: ITemplateVariable[];
}

export interface ServiceUpdate {
  image: string;
}

export interface IInstanceProviderParams {
  projectName: string;
  clusterName: string;
  environmentName: string;
  microserviceName: string;
  providerInstanceName: string;
}

export enum MsStorageStatusEnum {
  CREATION_PENDING = 'CREATION_PENDING',
  SUCCESSFUL = 'SUCCESSFUL',
  FAILURE = 'FAILURE',
  DELETION_PENDING = 'DELETION_PENDING ',
}

export interface EnvironmentVariableMapping {
  host: string;
  port: string;
  user: string;
  password: string;
  dbname: string;
}

export interface MsStorageProviderDetails {
  isHaveProblem: boolean;
  providerInstanceName: string;
  mode: StorageProviderModes;
  status: MsStorageStatusEnum;
  statusDescription?: string;
  host: string;
  port: string;
  user: string;
  password: string;
  dbname: string;
  createDate?: Date;
  environmentVariablesMapping?: EnvironmentVariableMapping;
  providerInstanceObsolete: boolean;
}
