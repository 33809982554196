import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { AlertsSelectionState, DisplayedAlerts } from 'stores/AlertsStore';
import { Alert, AlertReferenceKind } from '../../models/Alert';

import { ScopeGroup } from './ScopeGroup/ScopeGroup';
import ListItem from './ListItem/ListItem';
import Icon from 'components/Icon/Icon';

import style from './AlertsList.module.scss';

interface Props {
  alerts: {
    clusters: any;
    systemAlerts: Alert[];
  };
  alertsStore?;
}

@inject('alertsStore')
@observer
class AlertsList extends Component<Props> {
  scopesListRender(scopes) {
    const sortScopesMap = {
      general: 0,
      config: 1,
      monitoring: 2,
      availability: 3,
      environment: 4,
    };
    return (
      <>
        {Object.keys(scopes)
          .sort((a, b) => sortScopesMap[a] - sortScopesMap[b])
          .map(scopeKey => {
            let alertCount: number;
            if (
              scopeKey === AlertReferenceKind.Config.toLocaleLowerCase() ||
              scopeKey === AlertReferenceKind.General.toLocaleLowerCase()
            ) {
              alertCount = scopes[scopeKey].length;
            } else {
              alertCount = scopes[scopeKey].reduce((acc, scope) => {
                if (scope.alerts) {
                  acc += scope.alerts.length;
                }
                if (scope.microservices && scope.microservices.length > 0) {
                  acc += scope.microservices.reduce((_acc, microservice) => {
                    _acc += microservice.alerts.length;
                    return _acc;
                  }, 0);
                }
                return acc;
              }, 0);
            }

            return (
              <div key={scopeKey} className={style.scopeContainer}>
                <ScopeGroup
                  scope={scopes[scopeKey]}
                  scopeKey={scopeKey}
                  alertCount={alertCount}
                />
              </div>
            );
          })}
      </>
    );
  }

  systemAlertsRender(systemAlerts: Alert[]) {
    return (
      <>
        {systemAlerts.map((alert, index) => (
          <div key={index}>
            <ListItem alert={alert} />
          </div>
        ))}
      </>
    );
  }

  render() {
    const { alerts, alertsStore } = this.props;
    return (
      <>
        <div className={style.toggleAlerts}>
          <div
            className={classNames(style.toggleAlertsItem, {
              [style.toggleAlertsItem__active]:
                alertsStore.selectionState === AlertsSelectionState.All,
            })}
            onClick={alertsStore.showAllAlerts}
          >
            <FormattedMessage id="show.all.alerts" />
            <div className={style.count}>{alertsStore.totalAlertsCount}</div>
          </div>
          <div
            className={classNames(style.toggleAlertsItem, {
              [style.toggleAlertsItem__active]:
                alertsStore.selectionState === AlertsSelectionState.Page,
            })}
            onClick={alertsStore.showPageAlerts}
          >
            <FormattedMessage id="show.page.alerts" />
            <div className={style.count}>{alertsStore.pageAlertsCount}</div>
          </div>
        </div>
        <div
          className={classNames(style.toggleDisplay, {
            [style.toggleDisplay__active]:
              alertsStore.displayedAlerts === DisplayedAlerts.Hidden,
          })}
          onClick={alertsStore.toggleDisplayedAlerts}
        >
          <FormattedMessage id="show.hidden.alerts" />
          <Icon className={style.toggleDisplayIcon} type="hidden" />
        </div>
        <div className={style.clustersWrapper}>
          {alerts.systemAlerts.length > 0 && (
            <div className={style.systemAlertsWrapper}>
              <span className={style.clusterHeader}>
                <FormattedMessage id="alert.drawer.system" />
              </span>
              {this.systemAlertsRender(alerts.systemAlerts)}
            </div>
          )}
          {alerts.clusters.map(cluster => (
            <div key={cluster.name} className={style.clusterContainer}>
              <span className={style.clusterHeader}>
                <FormattedMessage
                  id="alert.drawer.cluster.name"
                  values={{ name: cluster.name }}
                />
              </span>
              {this.scopesListRender(cluster.scopes)}
            </div>
          ))}
        </div>
      </>
    );
  }
}

export default AlertsList;
