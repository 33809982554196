import { action, computed, observable } from 'mobx';
import Log from 'helpers/log';
import { sortByName } from 'helpers/array';
import httpFacade from 'http/httpFacade';
import { TemplatesParams, ITemplate } from 'models/Template';
import NotificationService, {
  fullscreenNotificationOptions,
} from 'components/Notification/NotificationService';

class TemplatesStore {
  @observable templates: ITemplate[] = [];
  @observable pending = false;

  private readonly params: TemplatesParams;

  constructor(params: TemplatesParams) {
    this.params = params;
  }

  @computed
  get isTemplatesDefault(): boolean {
    return this.templates.every(template => template.defaultTemplate);
  }

  @computed
  get sortedTemplates() {
    return sortByName([...this.templates], false);
  }

  @action
  fetchTemplates = async () => {
    try {
      this.pending = true;
      const { data } = await httpFacade.templates.fetchTemplates(this.params);
      this.templates = data;
    } catch (error) {
      Log.warn(error);
    } finally {
      this.pending = false;
    }
  };

  @action
  deleteTemplate = async (templateName: string) => {
    try {
      await httpFacade.templates.deleteTemplate({
        ...this.params,
        templateName,
      });
      NotificationService.successMessage(
        { id: 'template.delete.success' },
        fullscreenNotificationOptions,
      );
    } catch (error) {
      Log.warn(error);
    }
  };

  @action
  resetTemplates = async () => {
    try {
      await httpFacade.templates.resetTemplates({
        ...this.params,
      });
      NotificationService.successMessage(
        { id: 'templates.reset.success' },
        fullscreenNotificationOptions,
      );
    } catch (error) {
      Log.warn(error);
    }
  };
}

export default TemplatesStore;
