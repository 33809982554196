import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { FormattedMessage } from 'react-intl';

import { ModalProps, DialogActionType } from 'stores/ModalStore';
import TextField from 'components/Form/Fields/TextField/TextField';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import ClusterShutdownForm from './ClusterShutdownForm';

import style from './ClusterShutdownModal.module.scss';
import CommonModal from '../../Modals/Modal/CommonModal';

interface Props extends ModalProps {
  clusterName: string;
}

@observer
class ClusterShutdownModal extends Component<Props> {
  @observable clusterShutdownForm = new ClusterShutdownForm();
  FormControl = bindFormControl(this.clusterShutdownForm);

  onClose = () => {
    this.props.onClose(DialogActionType.cancel);
  };

  onConfirm = () => {
    const isValid = this.clusterShutdownForm.validate();
    if (isValid) {
      this.props.onClose(DialogActionType.submit);
    }
  };

  componentDidMount() {
    const { clusterName } = this.props;

    this.clusterShutdownForm.setClusterNameExample(clusterName);
  }

  render() {
    const { clusterName } = this.props;
    const FormControl = this.FormControl;

    return (
      <CommonModal
        className={style.body}
        title="cluster.modal.shutdown.title"
        headerIcon="modalWarning"
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        isSubmitBtnDisabled={!this.clusterShutdownForm.isFormValid}
      >
        <div className={style.text1}>
          <FormattedMessage
            id="cluster.modal.shutdown.text1"
            values={{
              clusterName,
            }}
          />
        </div>
        <div className={style.text2}>
          <FormattedMessage id="cluster.modal.shutdown.text2" />
        </div>
        <br />
        <div className={style.text3}>
          <FormattedMessage
            id="cluster.modal.shutdown.text3"
            values={{
              clusterName: <span className={style.example}>{clusterName}</span>,
            }}
          />
        </div>
        <FormControl
          className={style.input}
          name="clusterName"
          render={props => <TextField {...props} />}
          immediateValidation
        />
      </CommonModal>
    );
  }
}

export default ClusterShutdownModal;
