import _ from 'lodash';
import { sortByLongName } from 'helpers/array';

interface ISortableEntity {
  longName: string;
  isShutdown: boolean;
  isHaveProblem: boolean;
}

export function sortClusterEntities(data: ISortableEntity[]) {
  const dataCopy = [...data];
  sortByLongName(dataCopy);
  const stage1 = _.groupBy(dataCopy, el => el.isShutdown);
  const stage2 = _.groupBy(stage1[false], el => el.isHaveProblem);
  return (stage2[true] || []).concat(
    (stage2[false] || []).concat(stage1[true] || []),
  );
}

export const shortImage = (image: string) => {
  return image.split('/').pop();
};
