import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { action, computed, observable } from 'mobx';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import LoginForm from 'stores/Forms/LoginForm';
import RootStore from 'stores/RootStore';

import Button from 'components/Button/Button';
import TextField from 'components/Form/Fields/TextField/TextField';
import PasswordField from 'components/Form/Fields/PasswordField/PasswordField';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import { Spinner } from 'components/Spinner/Spinner';
import SelectField from 'components/Form/Fields/SelectField/SelectField';
import Icon from 'components/Icon/Icon';

import style from './Login.module.scss';

@observer
class Login extends Component<RouteComponentProps> {
  @observable loginStore = new LoginForm();
  FormControl = bindFormControl(this.loginStore);

  constructor(props) {
    super(props);
    RootStore.reset();
    if (!RootStore.projects.length) {
      RootStore.fetchProjects().then();
    }
  }

  @action
  onSubmit = async event => {
    event.preventDefault();
    const { history } = this.props;
    await this.loginStore.submit(history);
  };

  render() {
    const FormControl = this.FormControl;

    return (
      <section className={style.layout}>
        {this.loginStore.pending && (
          <Spinner contain message="loader.in.progress" />
        )}
        <div className={style.wrapper__side}>
          <Icon className={style.logo} type="logo2White" />
          <div className={style.wrapper__form}>
            <form name="login-form" onSubmit={this.onSubmit}>
              <h3 className={style.title}>
                <FormattedMessage id="page.login.title" />
              </h3>

              {this.loginStore.message && (
                <div className={style.message}>
                  <FormattedMessage id={this.loginStore.message} />
                </div>
              )}

              <div className={style.controls__form}>
                <FormControl
                  name="username"
                  className={style.control}
                  render={props => <TextField {...props} />}
                />

                <FormControl
                  name="password"
                  className={style.control}
                  render={props => <PasswordField {...props} />}
                />

                <FormControl
                  name="project"
                  className={style.control}
                  render={props => (
                    <SelectField {...props} options={this.projectOptions} />
                  )}
                />
              </div>

              <div className={style.wrapper__btn}>
                <Button
                  className={classNames(style.btn, style.btn__submit)}
                  type="submit"
                >
                  <FormattedMessage id="button.login" />
                </Button>
              </div>
            </form>
          </div>
          <div className={style.copyrightContainer}>
            <span className={style.copyright}>
              © Copyright 2021 Sam Solutions
            </span>
          </div>
        </div>
      </section>
    );
  }

  @computed
  get projectOptions() {
    return RootStore.projects.map(project => {
      return { label: project, value: project };
    });
  }
}

export default withRouter(Login);
