import { action, computed, observable } from 'mobx';
import jwtDecode from 'jwt-decode';

import httpFacade from 'http/httpFacade';

import { Tokens, UserName, JWT } from '../models/User';
import RootStore from './RootStore';
import { convertToTokens } from '../http/Api/User/helpers';

class UserStore {
  @observable private _id: string;
  @observable private _username: UserName;

  @action.bound
  init(tokens: Tokens, project: string = RootStore.currentProject) {
    if (tokens.accessToken) {
      const authData = jwtDecode<JWT>(tokens.accessToken);
      this._id = authData.preferred_username;
      this._username = authData.name;
    }
    this.setAuthTokens(tokens, project);
  }

  @computed get id() {
    return this._id;
  }

  @computed
  get authenticated() {
    const { accessToken } = this.getAuthTokens(RootStore.currentProject);
    if (!accessToken) {
      return false;
    }
    const { exp } = jwtDecode<JWT>(accessToken);
    if (this.isExpired(exp)) {
      // TODO: return false;
    }
    return true;
  }

  @computed
  get username() {
    return this._username;
  }

  @action.bound
  reset() {
    this.removeAuthToken(RootStore.currentProject);
    this._id = '';
    this._username = null;
  }

  setAuthTokens = (tokens: Tokens, project: string): void => {
    this.storeAuthToken(project, tokens);
  };

  getAuthTokens = (projectName = RootStore.currentProject): Tokens => {
    const tokens = localStorage.getItem(projectName);
    return tokens
      ? JSON.parse(tokens)
      : { accessToken: undefined, refreshToken: undefined };
  };

  refreshTokens = async (refreshToken: string): Promise<void> =>
    this.setAuthTokens(
      convertToTokens(
        (
          await httpFacade.user.refreshToken(
            refreshToken,
            RootStore.currentProject,
          )
        ).data,
      ),
      RootStore.currentProject,
    );

  private isExpired = (exp: number): boolean => Date.now() >= exp * 1000;

  private removeAuthToken = (project: string) => {
    localStorage.removeItem(project);
  };

  private storeAuthToken = (project: string, token: Tokens) => {
    localStorage.setItem(project, JSON.stringify(token));
  };
}

export default UserStore;
