import React, { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Icon from 'components/Icon/Icon';

import style from './Breadcrumbs.module.scss';

interface Props {
  links: Array<{ to?: string; name: string | JSX.Element }>;
  className?: string;
}

const Breadcrumbs: FC<Props> = ({ links, className }) => (
  <div className={classNames(style.breadcrumbs, className)}>
    {links.map((link, index) =>
      link.to ? (
        <Fragment key={index}>
          <Link className={style.link} to={link.to}>
            {link.name}
          </Link>
          <Icon className={style.arrowIcon} type="arrowRight" />
        </Fragment>
      ) : (
        <span key={index} className={style.breadcrumbsItem__active}>
          {link.name}
        </span>
      ),
    )}
  </div>
);

export default Breadcrumbs;
