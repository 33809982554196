import classNames from 'classnames';
import { Icons } from 'components/Icon';

import Icon from 'components/Icon/Icon';
import {
  AnimationType,
  ContentType,
  ICustomToastOptions,
  IMessage,
  IRenderContentOptions,
  ToastPosition,
  ToastType,
} from 'components/Notification/types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { toast, ToastOptions, ToastTransitionProps } from 'react-toastify';
import { Transition } from 'react-transition-group';

import style from './Notification.module.scss';

export const ToastTransition =
  (animationName: AnimationType) => (Props: ToastTransitionProps) => {
    const { children, position, nodeRef, ...props } = Props;
    // @ts-ignore
    delete props.preventExitTransition;
    // @ts-ignore
    delete props.done;

    const onEnter = () => {
      if (nodeRef.current) {
        nodeRef.current.classList.add(style[animationName + 'In']);
      }
    };

    const onExit = () => {
      if (nodeRef.current) {
        nodeRef.current.classList.remove(style[animationName + 'In']);
        nodeRef.current.classList.add(style[animationName + 'Out']);
      }
    };

    return (
      <Transition
        {...props}
        timeout={500}
        unmountOnExit
        onEnter={onEnter}
        onExit={onExit}
      >
        {children}
      </Transition>
    );
  };

const mapContentTypeToIconType: Record<ContentType, keyof typeof Icons> = {
  [ContentType.SUCCESS]: 'stateSuccess',
  [ContentType.ERROR]: 'stateError',
  [ContentType.INFO]: 'stateInfo',
  [ContentType.WARNING]: 'stateWarning',
  [ContentType.HELP]: 'stateHelp',
};

const defaultMessagesOptions: ICustomToastOptions = {
  transition: ToastTransition(AnimationType.FADE_AND_MOVE),
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  toastType: ToastType.STATE,
};

export const fullscreenNotificationOptions = {
  transition: ToastTransition(AnimationType.FADE),
  position: ToastPosition.TOP_LEFT,
  draggable: false,
  toastType: ToastType.SCREEN_WIDTH,
};

class NotificationService {
  getToastOptions(
    contentType: ContentType,
    options?: ICustomToastOptions,
  ): ToastOptions {
    return {
      ...defaultMessagesOptions,
      ...options,
      className: this.getToastClasses(contentType, options?.toastType),
    };
  }

  getToastClasses(contentType: ContentType, toastType?: ToastType): string {
    return classNames(style.toast, {
      [style.toast__success]: contentType === ContentType.SUCCESS,
      [style.toast__error]: contentType === ContentType.ERROR,
      [style.toast__warning]: contentType === ContentType.WARNING,
      [style.toast__info]: contentType === ContentType.INFO,
      [style.toast__help]: contentType === ContentType.HELP,
      [style.toastScreenWidth]: toastType === ToastType.SCREEN_WIDTH,
      [style.toastContent]: toastType === ToastType.CONTENT,
    });
  }

  getRenderContentOptions(
    options?: ICustomToastOptions,
  ): IRenderContentOptions {
    return {
      toastType: options?.toastType,
      content: options?.content,
      icon: options?.icon,
    };
  }

  successMessage(message: IMessage, options?: ICustomToastOptions) {
    toast.success(
      this.renderContent(
        ContentType.SUCCESS,
        message,
        this.getRenderContentOptions(options),
      ),
      this.getToastOptions(ContentType.SUCCESS, options),
    );
  }

  errorMessage(message: IMessage, options?: ICustomToastOptions) {
    toast.error(
      this.renderContent(
        ContentType.ERROR,
        message,
        this.getRenderContentOptions(options),
      ),
      this.getToastOptions(ContentType.ERROR, options),
    );
  }

  infoMessage(message: IMessage, options?: ICustomToastOptions) {
    toast.info(
      this.renderContent(
        ContentType.INFO,
        message,
        this.getRenderContentOptions(options),
      ),
      this.getToastOptions(ContentType.INFO, options),
    );
  }

  warningMessage(message: IMessage, options?: ICustomToastOptions) {
    toast.warn(
      this.renderContent(
        ContentType.WARNING,
        message,
        this.getRenderContentOptions(options),
      ),
      this.getToastOptions(ContentType.WARNING, options),
    );
  }

  helpMessage(message: IMessage, options?: ICustomToastOptions) {
    toast.warn(
      this.renderContent(
        ContentType.HELP,
        message,
        this.getRenderContentOptions(options),
      ),
      this.getToastOptions(ContentType.HELP, options),
    );
  }

  renderContent(
    contentType: ContentType,
    message: IMessage,
    options: IRenderContentOptions,
  ) {
    let iconNode;
    const { content, toastType, icon } = options;
    const iconStyle = { [style.iconWhite]: toastType !== ToastType.CONTENT };

    if (!icon) {
      iconNode = (
        <Icon
          type={mapContentTypeToIconType[contentType]}
          className={classNames(style.notification__icon, iconStyle)}
        />
      );
    } else {
      iconNode = (
        <Icon
          type={icon}
          className={classNames(style.notification__icon, iconStyle)}
        />
      );
    }

    return (
      <div className={style.notification}>
        <div className={style.notification__header}>
          {iconNode}
          <div
            className={classNames(style.notification__message, {
              [style.light]: toastType !== ToastType.CONTENT,
            })}
          >
            <FormattedMessage id={message.id} values={message.values} />
          </div>
        </div>
        {toastType === ToastType.CONTENT && content && (
          <div className={style.notification__text}>
            <FormattedMessage id={content} />
          </div>
        )}
      </div>
    );
  }
}

export default new NotificationService();
