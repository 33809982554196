import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Counter from 'components/Tabs/DefaultTabs/Counter/Counter';

import style from './DefaultTabs.module.scss';

export interface TabCounter {
  success: number;
  all: number;
}

export interface DefaultTab {
  label: string;
  labelContent?: (isActive?: boolean) => JSX.Element;
  counter?: TabCounter;
  onCounterClick?: () => void;
}

interface DefaultTabsItemProps extends DefaultTab {
  onClick: () => void;
  isActive: boolean;
}

class DefaultTabsItem extends Component<DefaultTabsItemProps> {
  render() {
    const { onClick, isActive, label, labelContent, counter, onCounterClick } =
      this.props;

    return (
      <li
        onClick={onClick}
        className={classNames(style.tabItem, {
          [style.tabItem__active]: isActive,
        })}
      >
        <div className={style.tabItem__text}>
          <FormattedMessage id={label} />
        </div>
        {labelContent && labelContent(isActive)}
        {counter && (
          <Counter
            onClick={onCounterClick}
            counter={counter}
            active={isActive}
          />
        )}
      </li>
    );
  }
}

export default DefaultTabsItem;
