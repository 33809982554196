import React from 'react';
import classNames from 'classnames';
import Icon from 'components/Icon/Icon';

import style from './Alert.module.scss';
import { AlertType } from 'models/Alert';
import { Icons } from 'components/Icon';

const AlertIconTypes = {
  info: 'play',
  unset: 'stop',
  error: 'alert',
};

interface Props {
  className?: string;
  type?: AlertType;
  iconType?: keyof typeof Icons;
  children?: React.ReactNode;
  noIcon?: boolean;
  onClick?: (event: any) => void;
}

const Alert: React.FC<Props> = ({
  className = '',
  type = AlertType.Info,
  iconType,
  children = 'Running',
  noIcon = false,
  onClick = () => undefined,
}) => {
  const classes: string = classNames(style.alert, className, {
    [style.alert__info]: type === AlertType.Info,
    [style.alert__warning]: type === AlertType.Warning,
    [style.alert__error]: type === AlertType.Error,
    [style.alert__unset]: type === AlertType.Unset,
  });

  return (
    <span onClick={onClick} className={classes}>
      {!noIcon && <Icon type={iconType ? iconType : AlertIconTypes[type]} />}
      <span>{children}</span>
    </span>
  );
};

export default Alert;
