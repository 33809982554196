import React from 'react';

import TabsContainer from 'components/Tabs/TabsContainer';
import {
  ServiceDetails,
  MsStorageProviderDetails,
  MsStorageStatusEnum,
  IEnvVariable,
} from 'models/Service';
import MicroservicePageStorageList from 'components/MicroservicePageStorageList/MicroserviceStorageList';
import MicroservicePageResourceList from 'components/MicroservicePageResourceList/MicroservicePageResourceList';
import { StorageProviderInstance } from 'models/ExternalStorage';
import MicroservicePageEnvVariablesList from '../MicroservicePageEnvVariablesList/MicroservicePageEnvVariablesList';
import ServiceStore from 'stores/ServiceStore';

export enum MicroservicePageTabs {
  resources = 'microservice.resources',
  storage = 'microservice.storage',
  env_variables = 'microservice.env.variables',
}

interface Props {
  service: ServiceDetails;
  serviceProviders: MsStorageProviderDetails[];
  externalStorageStatus: boolean;
  providerInstances: StorageProviderInstance[];
  envVariables: IEnvVariable[];
  serviceStore: ServiceStore;
}

class MicroservicePageBody extends React.Component<Props> {
  render() {
    const resourcesSuccess = this.props.service.serviceIngresses.filter(
      ingress => ingress.syncState === 'synced',
    );
    const externalStorageSupportSuccess = this.props.serviceProviders.filter(
      support => support.status === MsStorageStatusEnum.SUCCESSFUL,
    );
    return (
      <div>
        <TabsContainer
          tabsComponents={[
            {
              component: (
                <MicroservicePageResourceList service={this.props.service} />
              ),
              label: MicroservicePageTabs.resources,
              counter: {
                success: resourcesSuccess.length,
                all: this.props.service.serviceIngresses.length,
              },
            },
            {
              component: (
                <MicroservicePageEnvVariablesList
                  serviceStore={this.props.serviceStore}
                />
              ),
              label: MicroservicePageTabs.env_variables,
            },
            {
              component: (
                <MicroservicePageStorageList
                  serviceProviders={this.props.serviceProviders}
                  externalStorageStatus={this.props.externalStorageStatus}
                  providerInstances={this.props.providerInstances}
                  service={this.props.service}
                />
              ),
              label: MicroservicePageTabs.storage,
              counter: {
                success: externalStorageSupportSuccess.length,
                all: this.props.serviceProviders.length,
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default MicroservicePageBody;
