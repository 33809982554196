import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { OthersItem } from '../OthersResourcesItem/OthersItem';
import { ServiceOther } from 'models/Service';
import style from './MicroserviceOthers.module.scss';
import Counter from 'components/Tabs/DefaultTabs/Counter/Counter';

interface Props {
  others: ServiceOther[];
  deployment?: ServiceOther;
}

@observer
class MicroserviceOthers extends Component<Props> {
  render() {
    const { others, deployment } = this.props;
    const items = others.slice();
    if (deployment) {
      items.unshift(deployment);
    }

    return (
      <>
        <div className={classNames(style.title, style.othersTitle)}>
          <span className={style.titleText}>
            <FormattedMessage id="service.others" />
          </span>
          <Counter
            counter={{
              success: items.filter(item => item.syncState === 'synced').length,
              all: items.length,
            }}
            active
          />
        </div>
        <table className={style.othersTable}>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="service.others.name" />
              </th>
              <th>
                <FormattedMessage id="service.others.type" />
              </th>
              <th>
                <FormattedMessage id="service.others.sync.state" />
              </th>
            </tr>
          </thead>
          {items && (
            <tbody>
              {items.map((other, index) => (
                <OthersItem key={index} other={other} />
              ))}
            </tbody>
          )}
        </table>
      </>
    );
  }
}

export { MicroserviceOthers };
