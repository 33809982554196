import React from 'react';
import classNames from 'classnames';

import style from './RadioField.module.scss';
import { FormattedMessage } from 'react-intl';

interface Props {
  text1: string;
  text2: string;
  selected: boolean;
  onClick: () => void;
}

const RadioField: React.FC<Props> = ({
  text1,
  text2,
  selected,
  onClick = () => undefined,
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(style.container, { [style.selected]: selected })}
    >
      <div className={style.textContainer}>
        <FormattedMessage id={text1} />
        <FormattedMessage id={text2} />
      </div>
    </div>
  );
};

export default RadioField;
