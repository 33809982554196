import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import { ActionId } from 'models/Template';
import RootStore from 'stores/RootStore';
import TemplatesVariablesStore from 'stores/TemplaleVariablesStore';

import TemplateSystemVariablesTable from 'pages/TemplatesPage/TemplateVariablesList/TemplateSystemVariablesTable';
import TemplateVariablesList from './TemplateVariablesList/TemplateVariablesList';
import CircleLoader from 'components/CircleLoader/CircleLoader';

import style from './TemplatesPage.module.scss';

interface Props {
  clusterName: string;
  actionId: ActionId;
  updateStatistics: () => void;
}

@observer
class TemplatesVariables extends Component<Props> {
  store: TemplatesVariablesStore;

  constructor(props: Props) {
    super(props);

    const { actionId, clusterName } = this.props;
    this.store = new TemplatesVariablesStore({
      clusterName,
      projectName: RootStore.currentProject,
      actionId,
    });
  }

  async componentDidMount() {
    await this.store.fetchTemplatesVariables();
  }

  render() {
    const { pending, systemTemplatesVariables } = this.store;

    return (
      <div className={style.content}>
        {pending ? (
          <CircleLoader className={style.loader} />
        ) : (
          <>
            <h3 className={style.tableTitle}>
              <FormattedMessage id="templates.variables.userDefined" />
            </h3>
            <div className={style.tableWrapper}>
              <TemplateVariablesList
                templatesVariablesStore={this.store}
                updateStatistics={this.props.updateStatistics}
              />
            </div>

            <h3 className={style.tableTitle}>
              <FormattedMessage id="templates.variables.system" />
            </h3>
            {!!systemTemplatesVariables.length && (
              <TemplateSystemVariablesTable
                systemTemplatesVariables={systemTemplatesVariables}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

export default TemplatesVariables;
