import { action, observable } from 'mobx';

import { BaseFormModel, displayName, isSameAs } from 'stores/BaseForm';

class ClusterShutdownForm extends BaseFormModel {
  @observable clusterNameExample = '';

  @observable
  @displayName('CLUSTER_NAME')
  @isSameAs('clusterNameExample', 'wrong.cluster.msg')
  clusterName: string = '';

  @action
  setClusterNameExample = clusterName => {
    this.clusterNameExample = clusterName;
  };
}

export default ClusterShutdownForm;
