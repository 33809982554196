import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { lineBreakFunc } from 'helpers/string';
import { stringToDateTime } from 'helpers/datetime';
import { Artifact } from 'models/Artifact';

import WithPopper from 'components/WithPopper/WithPopper';
import Icon from 'components/Icon/Icon';
import InstallCheckbox from 'components/SearchImage/InstallCheckbox/InstallCheckbox';
import ImageTopics from 'components/SearchImage/ImageTopics';

import style from 'components/SearchImage/SearchImage.module.scss';

interface Props {
  image: Artifact;
  selectedImage: string;
  setImage: (image: string) => void;
  installedImage?: string;
  isShowCollectedIssues: boolean;
}

@observer
class ImageCard extends Component<Props> {
  @observable isTopicsListOpened = false;

  @action
  toggleTopicsList = () => {
    this.isTopicsListOpened = !this.isTopicsListOpened;
  };

  onLinkClick = event => {
    event.stopPropagation();
  };

  render() {
    const {
      image,
      selectedImage,
      setImage,
      installedImage,
      isShowCollectedIssues,
    } = this.props;

    return (
      <div key={image.fullImage} className={style.listItem}>
        <div
          className={classNames(style.artifactContainer, {
            [style.selectedContainer]: selectedImage === image.fullImage,
          })}
        >
          <div className={style.artifactData}>
            <div className={style.firstColumn} onClick={this.toggleTopicsList}>
              <Icon
                className={style.arrowIcon}
                type={this.isTopicsListOpened ? 'arrowTop' : 'arrowBottom'}
              />
              <div className={style.titleContainer}>
                <span
                  className={style.imageTitle}
                  dangerouslySetInnerHTML={{
                    __html: lineBreakFunc(
                      image.fullImage.split('/').pop() || '',
                    ),
                  }}
                />
                <span className={style.imageDate}>
                  {image.buildInfo ? (
                    stringToDateTime(image.buildInfo.date.toString())
                  ) : (
                    <br />
                  )}
                </span>
              </div>
              <div className={style.delemiter} />
              <div onClick={this.onLinkClick}>
                <WithPopper title={<FormattedMessage id="view.git.commit" />}>
                  <a
                    href={image.links && image.links.git}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon type="git" />
                  </a>
                </WithPopper>
              </div>
              <div className={style.delemiter} />
              <div onClick={this.onLinkClick}>
                <WithPopper
                  title={<FormattedMessage id="view.on.docker.registry" />}
                >
                  <a
                    href={image.links && image.links.registry}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon type="docker" />
                  </a>
                </WithPopper>
              </div>
              <div className={style.delemiter} />
              <div className={style.checkboxWrap}>
                <InstallCheckbox
                  onClick={event => {
                    event.stopPropagation();
                    setImage(image.fullImage);
                  }}
                  selected={selectedImage === image.fullImage}
                  installed={installedImage === image.fullImage}
                />
              </div>
            </div>
            {this.isTopicsListOpened && (
              <ImageTopics
                image={image}
                isShowCollectedIssues={isShowCollectedIssues}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ImageCard;
