import { observable, action, computed } from 'mobx';
import { BaseFormModel, displayName, isRequired } from 'stores/BaseForm';
import httpFacade from 'http/httpFacade';
import NotificationService, {
  fullscreenNotificationOptions,
} from 'components/Notification/NotificationService';
import { StorageProviderModes } from 'models/ExternalStorage';

class CopyFromEnvForm extends BaseFormModel {
  @observable
  @isRequired()
  @displayName('environment')
  environmetName: string = '';

  @observable
  @isRequired()
  @displayName('microservice')
  microserviceName: string = '';

  @observable
  usageList: any = {};

  @computed
  get envOptions() {
    return Object.keys(this.usageList);
  }

  @computed
  get serviceOptions() {
    return this.usageList[this.environmetName] || [];
  }

  @action
  fetchClusterProviderInstancesUsage = async (
    projectName,
    clusterName,
    instanceName,
  ) => {
    try {
      const response =
        await httpFacade.cluster.fetchClusterProviderInstancesUsage(
          projectName,
          clusterName,
          instanceName,
        );
      this.usageList = response.data;
    } catch (e) {
      this.usageList = new Map();
    }
  };

  submit = async params => {
    try {
      await httpFacade.service.updateMSSupport(params, {
        mode: StorageProviderModes.COPY_BASED_ON_ENV,
        copyFromEnv: this.environmetName,
        copyFromService: this.microserviceName,
      });
      NotificationService.successMessage(
        { id: 'copy.from.env.success' },
        fullscreenNotificationOptions,
      );
    } catch (error) {
      if (
        error.response.data.errorMessages ||
        error.response.data.warningMessages
      ) {
        throw error;
      }
    }
  };
}

export default CopyFromEnvForm;
