import React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { uniqueId } from 'helpers/uniqueId';
import { ComparingMsItemStore } from 'stores/ApplyMsTo/ComparingMsItemStore';

import Button, { BtnType } from 'components/Button/Button';
import { CompareEnvVarFormItem } from 'components/EnvironmentPageServicesList/Compare/CompareMsItem/CompareEnvVarFormItem';
import Icon from 'components/Icon/Icon';
import { Table } from 'components/Table/Table';
import { TableHeaderRow } from 'components/Table/TableHeaderRow';
import { TableExtendedContent } from 'components/TableExtendedContent/TableExtendedContent';
import { HostItem } from './HostItem';

import style from 'components/EnvironmentPageServicesList/Compare/CompareMsItem/CompareMsItem.module.scss';

interface IProps {
  microserviceStore: ComparingMsItemStore;
  isOpened: boolean;
}

export const MsNewDetails = observer(
  ({ microserviceStore, isOpened }: IProps) => {
    return (
      <TableExtendedContent key={uniqueId()} isOpened={isOpened}>
        <div className={style.tableActions}>
          <h2 className={style.tableTitle}>
            <FormattedMessage id="comparing.env.vars" />
          </h2>
          <Button
            styleType={BtnType.Text}
            onClick={microserviceStore.restoreInitialForms}
            className={style.btnIcon}
          >
            <Icon type="reset" className={style.icon} />
            <FormattedMessage id="comparing.env.vars.restore.initial" />
          </Button>
          <Button
            styleType={BtnType.Text}
            onClick={microserviceStore.addNewEnvVar}
            className={style.btnIcon}
          >
            <Icon
              type="plus2"
              className={classNames(style.iconPlus, style.icon)}
            />
            <FormattedMessage id="comparing.env.vars.add.new" />
          </Button>
        </div>
        <Table fixedLayout>
          <thead>
            <TableHeaderRow className={style.tableHeaderRowNew}>
              <th className={style.nameHeader}>
                <FormattedMessage id="comparing.env.var.name" />
              </th>
              <th className={style.valueHeader}>
                <FormattedMessage id="comparing.env.var.value" />
              </th>
              <th className={style.inputValueHeader}>
                <FormattedMessage id="comparing.env.var.value.be.applied" />
              </th>
              <th className={style.checkboxHeader}>
                <FormattedMessage id="comparing.env.var.secure" />
              </th>
              <th className={style.checkboxHeader}>
                <FormattedMessage id="comparing.env.var.env.specific" />
              </th>
              <th className={style.checkboxHeader}>
                <FormattedMessage id="comparing.env.var.delete" />
              </th>
            </TableHeaderRow>
          </thead>
          <tbody>
            {!!microserviceStore.envVarsForms.length &&
              microserviceStore.envVarsForms.map((envVarForm, index) => {
                return (
                  <CompareEnvVarFormItem
                    key={uniqueId()}
                    envVarForm={envVarForm}
                    onDelete={() => microserviceStore.deleteEnvVar(index)}
                    checkEnvNameUniq={microserviceStore.checkEnvNameUniq}
                  />
                );
              })}
          </tbody>
        </Table>
        {microserviceStore.hostsForms && (
          <div className={style.hostsWrapper}>
            <h2 className={style.tableTitle}>
              <FormattedMessage id="comparing.hosts" />
            </h2>
            <div className={style.hosts}>
              {microserviceStore.hostsForms.map((hostForm, index) => (
                <HostItem hostForm={hostForm} key={index} />
              ))}
            </div>
          </div>
        )}
      </TableExtendedContent>
    );
  },
);
