import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ITemplateVariable } from 'models/Template';

import TemplateSystemVarTableItem from 'pages/TemplatesPage/TemplateVariablesList/TemplateSystemVarTableItem';

import style from './TemplateVariablesList.module.scss';
import { observer } from 'mobx-react';

interface Props {
  systemTemplatesVariables: ITemplateVariable[];
}

@observer
class TemplateSystemVariablesTable extends Component<Props> {
  render() {
    const { systemTemplatesVariables } = this.props;

    return (
      <table className={style.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="templates.name" />
            </th>
            <th>
              <FormattedMessage id="templates.description" />
            </th>
          </tr>
        </thead>

        <tbody>
          {systemTemplatesVariables.map((variable, index) => (
            <TemplateSystemVarTableItem
              key={index}
              variable={variable}
              index={index}
            />
          ))}
        </tbody>
      </table>
    );
  }
}

export default TemplateSystemVariablesTable;
