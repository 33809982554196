import React from 'react';
import classNames from 'classnames';

import style from './DayCheckbox.module.scss';
import Icon from 'components/Icon/Icon';

interface Props {
  isToday: boolean;
  active: boolean;
}

class DayCheckbox extends React.Component<Props> {
  render() {
    const { active, isToday } = this.props;

    return (
      <div className={classNames(style.day, { [style.active]: active })}>
        {isToday && <Icon className={style.check} type="check" />}
      </div>
    );
  }
}

export default DayCheckbox;
