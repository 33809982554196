import React from 'react';
import { inject, observer } from 'mobx-react';

import EnvironmentStore from 'stores/EnvironmentStore';

import TabsContainer from 'components/Tabs/TabsContainer';
import EnvironmentOthers from 'components/EnvironmentOthers/EnvironmentOthers';
import EnvironmentPageServicesList from 'components/EnvironmentPageServicesList/EnvironmentPageServicesList';

export enum EnvironmentPageTabs {
  services = 'environment.microservices',
  others = 'environment.others',
}

interface Props {
  services: string[];
  environmentStore: EnvironmentStore;
  alertsStore?;
}

@inject('alertsStore')
@observer
class EnvironmentPageBody extends React.Component<Props> {
  toggleAlertDrawerMS = () => {
    const { alertsStore, environmentStore } = this.props;
    alertsStore.showAlertDrawer(
      [
        {
          clusterReference: environmentStore.params.clusterName,
          environmentReference: environmentStore.params.environmentName,
        },
        { anyMsRef: true },
      ],
      'AlertDrawerMS',
    );
  };

  toggleAlertDrawerOthers = () => {
    const { alertsStore, environmentStore } = this.props;
    alertsStore.showAlertDrawer(
      [
        {
          clusterReference: environmentStore.params.clusterName,
          environmentReference: environmentStore.params.environmentName,
        },
        { withOutMsRef: true },
      ],
      'AlertDrawerOthers',
    );
  };

  render() {
    const othersSucces = this.props.environmentStore.environmentOthers.filter(
      other => other.syncState === 'synced',
    );
    const microserviceSuccess = this.props.environmentStore.environment
      ? this.props.environmentStore.environment.microservices.filter(
          service =>
            service.syncState === 'synced' &&
            service.artifactStatus === 'uptodate' &&
            service.runtimeState.desired === service.runtimeState.existing,
        )
      : [];
    return (
      <>
        <TabsContainer
          tabsComponents={[
            {
              component: <EnvironmentPageServicesList {...this.props} />,
              label: EnvironmentPageTabs.services,
              onCounterClick: this.toggleAlertDrawerMS,
              counter: {
                success: microserviceSuccess.length,
                all: this.props.environmentStore.environment
                  ? this.props.environmentStore.environment.microservices.length
                  : 0,
              },
            },
            {
              component: (
                <EnvironmentOthers
                  others={this.props.environmentStore.environmentOthers}
                />
              ),
              label: EnvironmentPageTabs.others,
              counter: {
                success: othersSucces.length,
                all: this.props.environmentStore.environmentOthers.length,
              },
              onCounterClick: this.toggleAlertDrawerOthers,
            },
          ]}
        />
      </>
    );
  }
}

export { EnvironmentPageBody };
