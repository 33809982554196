import { sortByField } from 'helpers/array';
import {
  IPodContainer,
  IServicePod,
  PodPhase,
  PodSyncStatus,
} from 'models/Pod';

export const sortPods = (pods: IServicePod[]) => {
  return sortByField([...pods], 'createDate').sort(comparePods);
};

export const sortContainers = (containers: IPodContainer[]) => {
  return sortByField([...containers], 'containerName');
};

const isPodProblem = (pod: IServicePod) => {
  return (
    pod.phase === PodPhase.Failed ||
    pod.phase === PodPhase.Unknown ||
    pod.syncStatus === PodSyncStatus.Diff ||
    pod.restartCount
  );
};

const comparePods = (podA: IServicePod, podB: IServicePod) => {
  const isPodAProblem = isPodProblem(podA);
  const isPodBProblem = isPodProblem(podB);

  if (isPodAProblem && !isPodBProblem) {
    return -1;
  } else if (!isPodAProblem && isPodBProblem) {
    return 1;
  } else {
    return 0;
  }
};
