import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import style from './Button.module.scss';
import CircleLoader, {
  SpinnerType,
} from 'components/CircleLoader/CircleLoader';

export enum BtnType {
  Primary = 'primary',
  Secondary = 'secondary',
  Ghost = 'ghost',
  Add = 'add',
  Success = 'success',
  Delete = 'delete',
  DeleteGhost = 'deleteGhost',
  Text = 'text',
}

export enum BtnSize {
  Giant = 'giant',
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  Tiny = 'tiny',
  ExtraTiny = 'extra_tiny',
}

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  circleLoaderClassName?: string;
  children?: React.ReactNode;
  styleType?: BtnType;
  size?: BtnSize;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent | React.TouchEvent) => void;
  isLoading?: boolean;
}

const Button: React.FC<Props> = ({
  className = '',
  circleLoaderClassName = '',
  children = 'Default button',
  styleType = BtnType.Primary,
  size = BtnSize.Medium,
  isLoading = false,
  ...props
}) => {
  const classes: string = classNames(style.btn, className, {
    [style.btn__primary]:
      styleType === BtnType.Primary || styleType === BtnType.Add,
    [style.btn__secondary]: styleType === BtnType.Secondary,
    [style.btn__ghost]: styleType === BtnType.Ghost,
    [style.btn__deleteGhost]: styleType === BtnType.DeleteGhost,
    [style.btn__add]: styleType === BtnType.Add,
    [style.btn__success]: styleType === BtnType.Success,
    [style.btn__delete]: styleType === BtnType.Delete,
    [style.btn__text]: styleType === BtnType.Text,
    [style.btn__giant]: size === BtnSize.Giant,
    [style.btn__large]: size === BtnSize.Large,
    [style.btn__medium]: size === BtnSize.Medium,
    [style.btn__small]: size === BtnSize.Small,
    [style.btn__tiny]: size === BtnSize.Tiny,
    [style.btn__extra__tiny]: size === BtnSize.ExtraTiny,
    [style.btn__loading]: isLoading,
  });

  return (
    <button className={classes} {...props}>
      {isLoading && (
        <div className={style.loaderWrapper}>
          <CircleLoader
            styleType={SpinnerType.BtnLoading}
            className={circleLoaderClassName}
          />
        </div>
      )}
      <>{children}</>
    </button>
  );
};

export default Button;
