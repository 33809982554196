import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { action, computed } from 'mobx';
import { Link, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';

import RootStore from 'stores/RootStore';
import EnvironmentStore from 'stores/EnvironmentStore';

import EnvironmentPageHeader from 'components/EnvironmentPageHeader/EnvironmentPageHeader';
import Icon from 'components/Icon/Icon';
import CompareModal from 'components/EnvironmentPageServicesList/Compare/CompareModal';
import { EnvironmentPageBody } from 'components/EnvironmetPageBody/EnvironmentPageBody';
import CreateMicroserviceModal from 'components/EnvironmentPageServicesList/CreateMicroservice/CreateMicroserviceModal';

import style from './Environment.module.scss';

export enum EnvPageState {
  CREATE_MS = 'CREATE_MS',
  COMPARE_MSS = 'COMPARE_MSS',
  MSS_LIST = 'MSS_LIST',
}

interface Props
  extends RouteComponentProps<{
    id?: string;
    clusterName?: string;
    environmentName?: string;
  }> {
  alertsStore?;
}

@inject('alertsStore')
@observer
class Environment extends Component<Props> {
  store: EnvironmentStore;

  constructor(props: Props) {
    super(props);

    const { clusterName, environmentName } = this.props.match.params;
    this.store = new EnvironmentStore(
      clusterName || '',
      RootStore.currentProject,
      environmentName || '',
    );
  }

  @action
  closeCompareModal = () => {
    this.store.comparingEnvironmentData = null;
    this.store.changePageState(EnvPageState.MSS_LIST);
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    const oldParams = prevProps.match.params;
    const { clusterName, environmentName } = this.props.match.params;
    if (
      oldParams.clusterName !== clusterName ||
      oldParams.environmentName !== environmentName
    ) {
      this.store.updateEnvironment(
        clusterName || '',
        RootStore.currentProject,
        environmentName || '',
      );
    }
  }

  componentWillUnmount() {
    this.store.clearTimer();
  }

  render() {
    const { clusterName, environmentName } = this.props.match.params;

    return (
      <div
        className={classNames(style.wrapper, {
          [style.wrapper__openedAlert]: this.props.alertsStore.isOpen,
        })}
      >
        <div className={style.breadcrumbs}>
          <Link
            className={style.link}
            to={`/project/${RootStore.currentProject}/info`}
          >
            <FormattedMessage id="page.project.dashboard" />
          </Link>
          <Icon className={style.arrowIcon} type="arrowRight" />

          <Link
            className={style.link}
            to={`/project/${RootStore.currentProject}/cluster/${clusterName}/info`}
          >
            <FormattedMessage id="page.project.cluster" /> {clusterName}
          </Link>
          <Icon className={style.arrowIcon} type="arrowRight" />

          <span className={style.envName}>
            {environmentName} <FormattedMessage id="environment.title" />
          </span>
        </div>
        {!this.store.pending && !this.store.environment && (
          <div className={style.notFoundContainer}>
            <FormattedMessage id="environment.not.found" />
          </div>
        )}
        {this.store.pending ? '' : this.environmentRender}
      </div>
    );
  }

  @computed
  get environmentRender() {
    const {
      environment,
      deleteEnvironment,
      pageState,
      comparingEnvironmentData,
      targetEnvironmentName,
      params,
    } = this.store;
    const { clusterName } = this.props.match.params;
    if (!environment) {
      return null;
    }

    return (
      <>
        {pageState === EnvPageState.CREATE_MS && (
          <CreateMicroserviceModal
            onClose={() => this.store.changePageState(EnvPageState.MSS_LIST)}
            params={this.store.params}
          />
        )}
        {pageState === EnvPageState.COMPARE_MSS && comparingEnvironmentData && (
          <CompareModal
            comparingEnvironment={comparingEnvironmentData}
            targetEnvironmentName={targetEnvironmentName}
            envParams={params}
            onCancel={this.closeCompareModal}
          />
        )}
        {pageState === EnvPageState.MSS_LIST && (
          <>
            <EnvironmentPageHeader
              environmentStore={this.store}
              environment={environment}
              clusterName={clusterName}
              deleteEnvironment={deleteEnvironment}
            />
            <EnvironmentPageBody
              services={environment.microservices.map(
                microservice => microservice.name,
              )}
              environmentStore={this.store}
            />
          </>
        )}
      </>
    );
  }
}

export default Environment;
