import { observable, action } from 'mobx';
import { Cluster } from '../models/Cluster';
import httpFacade from '../http/httpFacade';

export class MenuStore {
  @observable isOpen = false;
  @observable clusterList: Cluster[] = [];

  constructor(projectName: string) {
    this.init(projectName);
  }

  init = async (projectName: string) => {
    try {
      await this.fetchProjectDetails(projectName);
    } catch (error) {
      // TODO: message
    }
  };

  @action
  toggleMenu = () => {
    this.isOpen = !this.isOpen;
  };

  @action.bound
  async fetchProjectDetails(projectName: string) {
    try {
      const response = await httpFacade.cluster.fetchClusters(projectName);
      this.clusterList = response.data;
    } catch (error) {
      this.clusterList = [];
    }
  }
}

export default MenuStore;
