import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import Log from 'helpers/log';
import { replaceUrlParams } from 'http/helpers';
import httpFacade from 'http/httpFacade';
import { Cluster } from 'models/Cluster';
import { Environment, ITargetEnvParams } from 'models/Environment';
import { ROUTES } from 'routes/routes';
import EnvironmentStore from 'stores/EnvironmentStore';
import ModalStore from 'stores/ModalStore';
import RootStore from 'stores/RootStore';

import WithPopper from 'components/WithPopper/WithPopper';
import ActionMenu from 'components/ActionMenu/ActionMenu';
import { IActionItem } from 'components/ActionMenu/types';
import Button, { BtnSize, BtnType } from 'components/Button/Button';
import { BulkActionLayout } from 'components/EnvironmentPageServicesList/BulkActionLayout/BulkActionLayout';
import Checkbox from 'components/Form/Fields/Checkbox/Checkbox';
import {
  ISelectOption,
  SelectWithInput,
} from 'components/Form/Fields/SelectWithInput/SelectWithInput';
import Icon from 'components/Icon/Icon';
import NotificationService, {
  fullscreenNotificationOptions,
} from 'components/Notification/NotificationService';
import CheckboxDropdown from './CheckboxListDropdown/CheckboxDropdown';
import FilterChip from './FilterChip/FilterChip';
import ServiceItem, { artefactStatusList } from './ServiceItem/ServiceItem';
import SwitchToBranchModal from './SwitchToBranchModal/SwitchToBranchModal';
import UpdateToLastModal from './UpdateToLastModal/UpdateToLastModal';
import { EnvPageState } from 'pages/EnvironmentPage/Environment';

import style from './EnvironmentPageServicesList.module.scss';

export enum ActionType {
  UpdateToLastVersion = 'update.to.last.version',
  SwitchToBranch = 'switch.to.branch',
  ApplyMsTo = 'apply.ms.to',
}

interface SearchParams {
  name?: string;
  syncState?: string[];
  artefactStatus?: string[];
  status?: string[];
}

interface Props extends RouteComponentProps {
  services: string[];
  environmentStore: EnvironmentStore;
}

const DEFAULT_BLUR_TIMEOUT = 100;
const FETCH_TIMEOUT = 300;
const initialSelectValue: ISelectOption = { id: '', title: '' };

@observer
class EnvironmentPageServicesList extends React.Component<Props> {
  tableBodyRef = React.createRef<HTMLTableSectionElement>();
  @observable selectedMicroservices: string[] = [];
  @observable isSelectMode: boolean = false;
  @observable actionType: ActionType = ActionType.UpdateToLastVersion;
  @observable isUIblocked: boolean = false;
  @observable branches: any[] = [];
  @observable clusters: Cluster[] = [];
  @observable environments: Environment[] = [];
  @observable selectedBranch: ISelectOption = initialSelectValue;
  @observable selectedCluster: ISelectOption = initialSelectValue;
  @observable selectedEnv: ISelectOption = initialSelectValue;
  @observable isNameSearchActive: boolean = false;
  @observable searchName: string = '';
  @observable searchParams: SearchParams = {};
  @observable syncStateOptions = [
    'synced',
    'not_in_git',
    'not_in_k8s',
    'diff',
    'no_hash',
    'unknown',
  ];
  @observable artefactStatusOptions = [
    'unknown',
    'uptodate',
    'outofdate',
    'obsolete',
  ];
  servicesRefs = {};
  searchNameInputRef;
  fetchBranchesTimer;

  @computed
  get filteredMicroservices() {
    const environment = this.props.environmentStore.environment;

    return environment
      ? environment.microservices
          .slice()
          .sort((a, b) => Number(!!b.favorite) - Number(!!a.favorite))
          .filter(service => {
            if (!this.searchParams.name) {
              return true;
            }
            return (
              service.name
                .toLowerCase()
                .includes(this.searchParams.name.toLowerCase()) ||
              service.longName
                .toLowerCase()
                .includes(this.searchParams.name.toLowerCase())
            );
          })
          .filter(service => {
            if (!this.searchParams.syncState) {
              return true;
            }
            return !this.searchParams.syncState.includes(service.syncState);
          })
          .filter(service => {
            if (!this.searchParams.artefactStatus) {
              return true;
            }
            return !this.searchParams.artefactStatus.includes(
              service.artifactStatus,
            );
          })
      : [];
  }

  @computed
  get microservicesWithAvailableUpdate() {
    return this.props.environmentStore.environment
      ? this.props.environmentStore.environment.microservices.filter(
          service =>
            service.artifactStatus === artefactStatusList.obsolete ||
            service.artifactStatus === artefactStatusList.outofdate,
        )
      : [];
  }

  @computed
  get isAllChecked() {
    if (!this.props.environmentStore.environment) {
      return false;
    }

    const array =
      this.actionType === ActionType.UpdateToLastVersion
        ? this.microservicesWithAvailableUpdate
        : this.filteredMicroservices;

    return (
      !!this.selectedMicroservices.length &&
      array.every(el => this.selectedMicroservices.includes(el.name))
    );
  }

  @computed
  get targetCluster(): Cluster | undefined {
    return this.clusters.find(
      clusterItem =>
        clusterItem.name.toLowerCase() ===
        this.selectedCluster.title.toLowerCase(),
    );
  }

  @computed
  get targetEnvironment(): Environment | undefined {
    return this.environments.find(
      environmentItem =>
        environmentItem.longName.toLowerCase() ===
        this.selectedEnv.title.toLowerCase(),
    );
  }

  componentDidMount() {
    const { environmentStore } = this.props;
    this.searchParams =
      JSON.parse(
        localStorage.getItem(
          `msFilters${environmentStore.params.projectName}${environmentStore.params.clusterName}${environmentStore.params.environmentName}`,
        ) || 'null',
      ) || {};
  }

  gotoMicroservice = microserviceName => {
    const { environmentStore, history } = this.props;
    history.push(
      replaceUrlParams(ROUTES.microservice, {
        id: RootStore.currentProject,
        clusterName: environmentStore.params.clusterName,
        environmentName: environmentStore.params.environmentName,
        microserviceName,
      }),
    );
  };

  updateToLastVersionAction = () => {
    this.isSelectMode = true;
    this.actionType = ActionType.UpdateToLastVersion;
    this.selectedMicroservices = [];
  };

  switchToBranchAction = async () => {
    this.selectedBranch = initialSelectValue;
    this.isSelectMode = true;
    this.actionType = ActionType.SwitchToBranch;
    this.selectedMicroservices = [];
    await this.fetchBranchesList();
  };

  applyMsToAction = async () => {
    if (this.isSelectMode && this.actionType === ActionType.ApplyMsTo) {
      return;
    }
    this.isSelectMode = true;
    this.actionType = ActionType.ApplyMsTo;
    this.selectedMicroservices = [];
    this.checkAll();
    this.selectedEnv = initialSelectValue;
    this.selectedCluster = initialSelectValue;
    await this.fetchClustersList();
  };

  fetchBranchesList = async () => {
    this.branches = [];
    this.selectedBranch = initialSelectValue;
    const response = await httpFacade.environment.fetchEnvironmentBranches(
      this.props.environmentStore.params,
      { microservices: this.selectedMicroservices.join(',') },
    );
    this.branches = response.data;
  };

  fetchClustersList = async () => {
    const { projectName } = this.props.environmentStore.params;
    this.clusters = [];
    const { data } = await httpFacade.cluster.fetchClusters(projectName);
    this.clusters = data;
  };

  fetchEnvironmentsList = async () => {
    const { projectName } = this.props.environmentStore.params;
    const clusterName = this.targetCluster?.name || '';
    const { data } = await httpFacade.environment.fetchEnvironments(
      projectName,
      clusterName,
    );
    this.environments = data;
  };

  onChangeSelectedMicroservices = () => {
    if (this.actionType !== ActionType.SwitchToBranch) {
      return;
    }
    if (this.fetchBranchesTimer) {
      clearTimeout(this.fetchBranchesTimer);
      this.fetchBranchesTimer = null;
    }
    this.fetchBranchesTimer = setTimeout(this.fetchBranchesList, FETCH_TIMEOUT);
  };

  onSelectMicroservice = name => {
    if (this.selectedMicroservices.includes(name)) {
      this.selectedMicroservices = this.selectedMicroservices.filter(
        el => el !== name,
      );
    } else {
      this.selectedMicroservices.push(name);
    }
    this.onChangeSelectedMicroservices();
  };

  updateMSStoLatest = async () => {
    const ms = this.selectedMicroservices.slice();
    ModalStore.showModal(UpdateToLastModal, {
      microservices: this.selectedMicroservices.map(name => ({
        longName: this.servicesRefs[name].store.serviceInfo.info.longName,
        image:
          this.servicesRefs[name].store.serviceInfo.update.latest.fullImage,
      })),
      request: () =>
        httpFacade.environment.updateMSsToLastVersion(
          ms,
          this.props.environmentStore.params,
        ),
    });
    this.isSelectMode = false;
    this.selectedMicroservices = [];
  };

  switchToBranch = async () => {
    try {
      this.isUIblocked = true;
      await httpFacade.environment.switchToBranch(
        this.selectedBranch.id,
        this.selectedMicroservices,
        this.props.environmentStore.params,
      );
      NotificationService.successMessage(
        {
          id: 'switch.to.branch.success',
          values: {
            count: String(this.selectedMicroservices.length),
            branch: this.selectedBranch.id,
            env: this.props.environmentStore.environment
              ? this.props.environmentStore.environment.info.longName
              : '',
          },
        },
        fullscreenNotificationOptions,
      );
    } catch (error) {
      if (error.response.data.additionalInfo) {
        ModalStore.showModal(SwitchToBranchModal, {
          additionalInfo: error.response.data.additionalInfo,
          branch: this.selectedBranch.id,
          environmentLongName: this.props.environmentStore.environment
            ? this.props.environmentStore.environment.info.longName
            : '',
          microservices: this.selectedMicroservices.slice(),
          request: microservices =>
            httpFacade.environment.switchToBranch(
              this.selectedBranch.id,
              microservices,
              this.props.environmentStore.params,
              true,
            ),
        });
      } else {
        NotificationService.errorMessage({ id: 'switch.to.branch.error' });
      }
    } finally {
      this.isUIblocked = false;
      this.isSelectMode = false;
      this.selectedMicroservices = [];
    }
  };

  compareMSs = async () => {
    const { environmentStore } = this.props;
    const targetEnvParams: ITargetEnvParams = {
      projectTarget: environmentStore.params.projectName,
      clusterTarget: this.targetCluster?.name || '',
      envTarget: this.targetEnvironment?.name || '',
      microservices: this.selectedMicroservices.join(','),
    };
    try {
      this.isUIblocked = true;
      const { data } = await httpFacade.environment.compareMSs(
        environmentStore.params,
        targetEnvParams,
      );
      environmentStore.changePageState(EnvPageState.COMPARE_MSS, data);
    } catch (error) {
      Log.warn(error);
      const { data } = error.response;
      const errorString = data.id || 'response.error';
      NotificationService.errorMessage(
        { id: errorString },
        fullscreenNotificationOptions,
      );
    } finally {
      this.isUIblocked = false;
      this.isSelectMode = false;
      this.selectedMicroservices = [];
    }
  };

  checkAll = () => {
    if (this.props.environmentStore.environment) {
      const array =
        this.actionType === ActionType.UpdateToLastVersion
          ? this.microservicesWithAvailableUpdate
          : this.filteredMicroservices;

      this.selectedMicroservices = array.every(el =>
        this.selectedMicroservices.includes(el.name),
      )
        ? []
        : (this.selectedMicroservices = array.map(el => el.name));
    }
    this.onChangeSelectedMicroservices();
  };

  onNameSearchActivate = () => {
    this.isNameSearchActive = true;
    setTimeout(() => this.searchNameInputRef.focus());
  };

  handleInputEnter = e => {
    if (e.key === 'Enter') {
      this.handleSearch();
    }
  };

  handleSearch = () => {
    this.isNameSearchActive = false;
    this.searchParams.name = this.searchName;
    const { environmentStore } = this.props;
    localStorage.setItem(
      `msFilters${environmentStore.params.projectName}${environmentStore.params.clusterName}${environmentStore.params.environmentName}`,
      JSON.stringify(this.searchParams),
    );
  };

  onBlurSearchInput = () => {
    const blurTimeout = this.searchName
      ? DEFAULT_BLUR_TIMEOUT * 2.5
      : DEFAULT_BLUR_TIMEOUT;
    setTimeout(() => {
      this.isNameSearchActive = false;
      this.searchName = '';
    }, blurTimeout);
  };

  clearFilters = () => {
    this.searchParams = {};
    const { environmentStore } = this.props;
    localStorage.setItem(
      `msFilters${environmentStore.params.projectName}${environmentStore.params.clusterName}${environmentStore.params.environmentName}`,
      JSON.stringify(this.searchParams),
    );
  };

  onClickFilterCheckbox = fieldName => name => {
    if (
      this.searchParams[fieldName] &&
      this.searchParams[fieldName].includes(name)
    ) {
      this.searchParams[fieldName] = this.searchParams[fieldName].filter(
        el => el !== name,
      );
      if (!this.searchParams[fieldName].length) {
        this.searchParams[fieldName] = undefined;
      }
    } else {
      if (this.searchParams[fieldName]) {
        this.searchParams[fieldName].push(name);
      } else {
        this.searchParams[fieldName] = [name];
      }
    }
    const { environmentStore } = this.props;
    localStorage.setItem(
      `msFilters${environmentStore.params.projectName}${environmentStore.params.clusterName}${environmentStore.params.environmentName}`,
      JSON.stringify(this.searchParams),
    );
  };

  deleteFilter = name => () => {
    this.searchParams[name] = undefined;
    const { environmentStore } = this.props;
    localStorage.setItem(
      `msFilters${environmentStore.params.projectName}${environmentStore.params.clusterName}${environmentStore.params.environmentName}`,
      JSON.stringify(this.searchParams),
    );
  };

  onClusterChange = async el => {
    this.selectedCluster = el;
    this.selectedEnv = initialSelectValue;
    this.environments = [];
    if (this.targetCluster) {
      await this.fetchEnvironmentsList();
    }
  };

  onEnvChange = el => {
    this.selectedEnv = el;
    this.props.environmentStore.setTargetEnvironment(el.title);
  };

  render() {
    const { environmentStore } = this.props;
    const { environment } = environmentStore;
    if (!environment) {
      return null;
    }

    const menuItems: IActionItem[] = [
      {
        action: this.updateToLastVersionAction,
        label: <FormattedMessage id={ActionType.UpdateToLastVersion} />,
        icon: 'ok',
        disabled: !this.microservicesWithAvailableUpdate.length,
        popper: !this.microservicesWithAvailableUpdate.length
          ? 'all.ms.up.to.date'
          : '',
      },
      {
        action: this.switchToBranchAction,
        label: <FormattedMessage id={ActionType.SwitchToBranch} />,
        icon: 'switchBranch',
      },
      {
        action: this.applyMsToAction,
        label: <FormattedMessage id={ActionType.ApplyMsTo} />,
        icon: 'rocket',
      },
    ];

    return (
      <div className={style.wrapper}>
        <div className={style.mainContainer}>
          <div className={style.controls}>
            <Button
              size={BtnSize.Small}
              styleType={BtnType.Primary}
              className={style.addButton}
              onClick={() =>
                environmentStore.changePageState(EnvPageState.CREATE_MS)
              }
            >
              <Icon type="plus2" className={style.iconPlus} />
              <FormattedMessage id="button.add.new" />
            </Button>
            <ActionMenu
              items={menuItems}
              openMenuComponent={(toggleList, isOpened) => (
                <Button
                  size={BtnSize.Small}
                  styleType={BtnType.Secondary}
                  className={style.actionButton}
                  onClick={toggleList}
                >
                  <FormattedMessage id="bulk.actions" />
                  <Icon
                    className={style.arrowIcon}
                    type={isOpened ? 'arrowTop' : 'arrowBottom'}
                  />
                </Button>
              )}
              styles={{
                menu: style.menu,
              }}
            />
          </div>
          <div className={style.filterWrapper}>
            {Object.keys(this.searchParams).some(
              key => this.searchParams[key],
            ) && (
              <div className={style.searchParamsContainer}>
                <span>Applied filters:</span>
                <FilterChip
                  params={this.searchParams}
                  deleteFilter={this.deleteFilter}
                  paramsName={'name'}
                  chipText={`MS name: ${this.searchParams.name}`}
                />
                <FilterChip
                  params={this.searchParams}
                  deleteFilter={this.deleteFilter}
                  paramsName={'syncState'}
                  textTitle={'Sync state: '}
                  options={this.syncStateOptions}
                />
                <FilterChip
                  params={this.searchParams}
                  deleteFilter={this.deleteFilter}
                  paramsName={'artefactStatus'}
                  textTitle={'Artefact status: '}
                  options={this.artefactStatusOptions}
                />
                <Button
                  className={style.btnClearFilters}
                  size={BtnSize.Tiny}
                  styleType={BtnType.Secondary}
                  onClick={this.clearFilters}
                >
                  clear filters
                </Button>
              </div>
            )}
          </div>
          <div
            className={classNames(
              style.tableWrapper,
              this.isSelectMode && style.selectMode,
            )}
          >
            <table className={style.table}>
              <thead className={style.tableHeader}>
                <tr>
                  <th>
                    {!this.isSelectMode && <FormattedMessage id="favorite" />}
                    {this.isSelectMode && (
                      <div className={style.checkAll}>
                        <label>
                          <Checkbox
                            checked={this.isAllChecked}
                            onChange={this.checkAll}
                          />
                        </label>
                      </div>
                    )}
                  </th>
                  <th>
                    {!this.isNameSearchActive && (
                      <div
                        className={style.clickable}
                        onClick={this.onNameSearchActivate}
                      >
                        <FormattedMessage id="environment.microservice.name" />
                        <Icon className={style.filterIcon} type={'search'} />
                      </div>
                    )}
                    <div
                      className={classNames(style.searchNameInputContainer, {
                        [style.display]: this.isNameSearchActive,
                      })}
                    >
                      <input
                        placeholder={'Enter MS name'}
                        ref={r => (this.searchNameInputRef = r)}
                        value={this.searchName}
                        onChange={e => (this.searchName = e.target.value)}
                        onKeyDown={this.handleInputEnter}
                        onBlur={this.onBlurSearchInput}
                      />
                      <Button
                        className={style.btnSearch}
                        size={BtnSize.Tiny}
                        styleType={BtnType.Ghost}
                        onClick={this.handleSearch}
                        disabled={!this.searchName.length}
                      >
                        Search
                      </Button>
                    </div>
                  </th>
                  <th>
                    <CheckboxDropdown
                      options={this.syncStateOptions}
                      unselected={this.searchParams.syncState || []}
                      onClick={this.onClickFilterCheckbox('syncState')}
                      control={
                        <>
                          <FormattedMessage id="environment.microservice.sync.state" />
                          <Icon className={style.filterIcon} type={'filter'} />
                        </>
                      }
                    />
                  </th>
                  <th>
                    <CheckboxDropdown
                      options={this.artefactStatusOptions}
                      unselected={this.searchParams.artefactStatus || []}
                      onClick={this.onClickFilterCheckbox('artefactStatus')}
                      control={
                        <>
                          <FormattedMessage id="environment.microservice.artefact.status" />
                          <Icon className={style.filterIcon} type={'filter'} />
                        </>
                      }
                    />
                  </th>
                  <th>
                    <FormattedMessage id="environment.microservice.runtime.status" />
                  </th>
                  <th>
                    <FormattedMessage id="environment.microservice.image" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody ref={this.tableBodyRef}>
                {this.filteredMicroservices.map(service => (
                  <ServiceItem
                    actionType={this.actionType}
                    ref={ref => (this.servicesRefs[service.name] = ref)}
                    isSelectMode={this.isSelectMode}
                    onSelectMicroservice={this.onSelectMicroservice}
                    selectedMicroservices={this.selectedMicroservices}
                    params={environmentStore.params}
                    key={service.name}
                    service={service}
                    gotoMicroservice={this.gotoMicroservice}
                    addMSToFavorite={environmentStore.addMSToFavorite}
                    tableBody={this.tableBodyRef.current}
                  />
                ))}
              </tbody>
            </table>
          </div>

          {this.isSelectMode &&
            this.actionType === ActionType.UpdateToLastVersion && (
              <BulkActionLayout>
                <span className={style.selectModePanelTextContainer}>
                  <Icon type="ok" className={style.icon2} />
                  <FormattedMessage id={this.actionType} />
                  <div className={style.delemiter} />
                  <span>{this.selectedMicroservices.length} </span>
                  <FormattedMessage id="microservices.selected" />
                </span>
                <span>
                  <Button
                    styleType={BtnType.Ghost}
                    onClick={() => (this.isSelectMode = false)}
                  >
                    <FormattedMessage id="button.cancel" />
                  </Button>
                  <Button
                    disabled={!this.selectedMicroservices.length}
                    className={style.btnUpdate}
                    isLoading={this.isUIblocked}
                    onClick={this.updateMSStoLatest}
                  >
                    <FormattedMessage id="button.update" />
                  </Button>
                </span>
              </BulkActionLayout>
            )}
          {this.isSelectMode && this.actionType === ActionType.SwitchToBranch && (
            <BulkActionLayout>
              <span
                className={classNames(
                  style.selectModePanelTextContainer,
                  style.switchBranchContainer,
                )}
              >
                <Icon type="switchBranch" className={style.icon2} />
                <FormattedMessage id={this.actionType} />
                <div className={style.delemiter} />
                <span>{this.selectedMicroservices.length} </span>
                <FormattedMessage id="microservices.selected" />
                <div className={style.delemiter} />
                <SelectWithInput
                  label={'branch.name'}
                  className={style.branchSelect}
                  value={this.selectedBranch.title}
                  onChange={el => (this.selectedBranch = el)}
                  options={this.branches.map(el => ({
                    id: el.branchName,
                    title: el.branchName,
                  }))}
                  dropUp={true}
                  disabled={!this.branches.length}
                />
                <div className={style.delemiter} />
              </span>
              <span>
                <Button
                  styleType={BtnType.Ghost}
                  onClick={() => (this.isSelectMode = false)}
                >
                  <FormattedMessage id="button.cancel" />
                </Button>
                <Button
                  disabled={
                    !this.selectedMicroservices.length ||
                    !this.selectedBranch.id
                  }
                  className={style.btnUpdate}
                  isLoading={this.isUIblocked}
                  onClick={this.switchToBranch}
                >
                  <FormattedMessage id="button.switch" />
                </Button>
              </span>
            </BulkActionLayout>
          )}
          {this.isSelectMode && this.actionType === ActionType.ApplyMsTo && (
            <BulkActionLayout>
              <span
                className={classNames(
                  style.selectModePanelTextContainer,
                  style.switchBranchContainer,
                )}
              >
                <Icon type="rocket" className={style.icon2} />
                <FormattedMessage id={this.actionType} />
                <div className={style.delemiter} />
                <span>{this.selectedMicroservices.length} </span>
                <FormattedMessage id="microservices.selected" />
                <div className={style.delemiter} />
                <SelectWithInput
                  label={'cluster'}
                  className={classNames(style.select, style.clusterSelect)}
                  value={this.selectedCluster.title}
                  onChange={this.onClusterChange}
                  options={this.clusters.map(el => ({
                    id: el.name,
                    title: el.name,
                  }))}
                  dropUp={true}
                  disabled={!this.clusters.length}
                />
                <WithPopper
                  title={
                    !this.targetCluster ? (
                      <FormattedMessage id="select.cluster" />
                    ) : (
                      ''
                    )
                  }
                  className={style.selectPopper}
                >
                  <SelectWithInput
                    label={'environment'}
                    className={style.select}
                    value={this.selectedEnv.title}
                    onChange={el => this.onEnvChange(el)}
                    options={this.environments.map(el => ({
                      id: el.name,
                      title: el.longName,
                    }))}
                    dropUp={true}
                    disabled={
                      !this.environments.length || !this.selectedCluster.id
                    }
                  />
                </WithPopper>
                <div className={style.delemiter} />
              </span>
              <span>
                <Button
                  styleType={BtnType.Secondary}
                  onClick={() => (this.isSelectMode = false)}
                >
                  <FormattedMessage id="button.cancel" />
                </Button>
                <Button
                  disabled={
                    !this.selectedMicroservices.length ||
                    !this.selectedCluster.id ||
                    !this.targetEnvironment
                  }
                  className={style.btnUpdate}
                  isLoading={this.isUIblocked}
                  onClick={this.compareMSs}
                >
                  <FormattedMessage id="button.next" />
                </Button>
              </span>
            </BulkActionLayout>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(EnvironmentPageServicesList);
