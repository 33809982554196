import React, { FC, InputHTMLAttributes } from 'react';

import classNames from 'classnames';
import style from './Checkbox.module.scss';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: 'default' | 'white';
}

function getClassNameByType(type) {
  switch (type) {
    // TODO: temp name, need design
    case 'white':
      return style.input__white;
    case 'default':
    default:
      return '';
  }
}

const Checkbox: FC<CheckboxProps> = ({
  checked,
  className,
  type,
  ...restProps
}) => (
  <>
    <input
      checked={checked}
      type="checkbox"
      className={classNames(style.hiddenInput, getClassNameByType(type), {
        [style.input__readOnly]: restProps.readOnly,
      })}
      {...restProps}
    />
    <div
      className={classNames(style.checkbox, className, {
        [style.disabled]: restProps.disabled,
      })}
    >
      <svg className={style.icon} viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </svg>
    </div>
  </>
);

export default Checkbox;
