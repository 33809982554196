import { action, computed, observable } from 'mobx';
import { IEnvVariable } from '../models/Service';
import EnvironmentVariableForm from '../components/EnvironmentPageServicesList/CreateMicroservice/EnvironmentVariableForm';

class EnvVariablesTableStore {
  @observable
  environmentVariablesForms: EnvironmentVariableForm[] = [];

  @observable
  isPreviewMode = false;

  constructor(envVariables?: IEnvVariable[], previewMode?: boolean) {
    if (envVariables && envVariables.length) {
      envVariables.forEach(envVariable => {
        const environmentVariablesForm = new EnvironmentVariableForm();
        environmentVariablesForm.setValue('variableName', envVariable.name);
        environmentVariablesForm.setValue('variableValue', envVariable.value);
        environmentVariablesForm.setValue('variableSecure', envVariable.secure);
        environmentVariablesForm.validate();
        this.environmentVariablesForms.push(environmentVariablesForm);
      });
    } else if (!previewMode) {
      this.environmentVariablesForms.push(new EnvironmentVariableForm());
    }
    if (previewMode) {
      this.setPreviewMode(previewMode);
    }
  }

  @computed
  get isEnvVariablesValid() {
    const envVariablesForms = this.environmentVariablesForms.filter(
      form =>
        (form.validated && !form.isFormValid) ||
        (form.variableName && !form.variableValue) ||
        (!form.variableName && form.variableValue),
    );
    return !envVariablesForms.length;
  }

  @computed
  get envVariablesData(): IEnvVariable[] {
    return this.environmentVariablesForms
      .filter(form => form.variableName && form.variableValue)
      .map(form => {
        return {
          name: form.variableName,
          value: form.variableValue,
          secure: form.variableSecure,
        };
      });
  }

  @action.bound
  setPreviewMode = (previewMode: boolean) => {
    this.isPreviewMode = previewMode;
  };

  @action.bound
  addEnvVariable = () => {
    this.environmentVariablesForms.push(new EnvironmentVariableForm());
    this.environmentVariablesForms.forEach(form => {
      if (form.variableName && !form.variableValue) {
        form.setFieldRequiredValid('variableValue', false);
      }
      if (!form.variableName && form.variableValue) {
        form.setFieldRequiredValid('variableName', false);
      }
    });
  };

  @action.bound
  deleteEnvVariable = index => {
    this.environmentVariablesForms =
      this.environmentVariablesForms.length === 1
        ? [new EnvironmentVariableForm()]
        : this.environmentVariablesForms.filter(
            (item, itemIndex) => index !== itemIndex,
          );
  };
}

export default EnvVariablesTableStore;
