import React, { ChangeEvent, InputHTMLAttributes, Component } from 'react';
import classNames from 'classnames';

import style from './TextField.module.scss';
import Icon from 'components/Icon/Icon';
import { Omit } from 'helpers/types';
import { Icons } from 'components/Icon';
import { FormattedMessage } from 'react-intl';

interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  label?: string;
  iconType?: string;
  name?: string;
  type?: string;
  hasErrors?: boolean;
  hasWarnings?: boolean;
  isSucceed?: boolean;
  hasInfo?: boolean;
  onIconClick?: () => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  containerClassName?: string;
  forwardRef?: any;
}

class TextField extends Component<Props> {
  public render(): JSX.Element {
    const {
      label = '',
      name,
      iconType,
      type = 'text',
      hasErrors = false,
      isSucceed = false,
      hasWarnings = false,
      hasInfo = false,
      onIconClick = () => undefined,
      className,
      containerClassName,
      forwardRef,
      ...attrs
    } = this.props;

    const colors = {
      [style.wrapper__info]: hasInfo,
      [style.wrapper__warning]: hasWarnings,
      [style.wrapper__error]: hasErrors,
      [style.wrapper__success]: isSucceed,
    };

    return (
      <div className={classNames(style.mainContainer, containerClassName)}>
        {label && (
          <label
            className={classNames(style.fieldLabel, {
              [style.fieldLabel__active]: Boolean(attrs.value),
              [style.fieldLabel__info]: style.hasInfo,
              [style.fieldLabel__warning]: style.hasWarnings,
              [style.fieldLabel__error]: style.hasErrors,
              [style.fieldLabel__success]: style.isSucceed,
            })}
            htmlFor={name}
          >
            <FormattedMessage id={label} />
          </label>
        )}
        <span className={classNames(style.wrapper, colors, className)}>
          <input
            id={name}
            type={type}
            {...attrs}
            className={style.field}
            onChange={this.props.onChange}
            ref={forwardRef}
          />
          {iconType && (
            <Icon
              className={style.icon}
              type={iconType as keyof typeof Icons}
              onClick={onIconClick}
            />
          )}
        </span>
      </div>
    );
  }
}

export default React.forwardRef((props: Props, ref) => (
  <TextField forwardRef={ref} {...props} />
));
