import { IActionItem } from 'components/ActionMenu/types';
import Icon from 'components/Icon/Icon';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Button, { BtnType } from 'components/Button/Button';
import WithPopper from 'components/WithPopper/WithPopper';

import style from 'components/ActionMenu/ActionMenuItem/ActionMenuItem.module.scss';

interface IProps {
  action: IActionItem;
  onAction: () => void;
  className?: string;
}

export function ActionMenuItem({ action, onAction, className }: IProps) {
  const onMenuItemClick = event => {
    event.stopPropagation();
    onAction();
  };

  return (
    <WithPopper
      title={action.popper ? <FormattedMessage id={action.popper} /> : ''}
      className={style.popper}
    >
      <li>
        <Button
          styleType={BtnType.Text}
          className={classNames(className, style.button)}
          onClick={!action.disabled ? onMenuItemClick : undefined}
          aria-disabled={!!action.disabled}
        >
          {action.icon && (
            <div className={style.iconWrapper}>
              <Icon type={action.icon} />
            </div>
          )}

          {action.label}
        </Button>
      </li>
    </WithPopper>
  );
}
