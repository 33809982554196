import React, { useState, createRef, useMemo, useEffect } from 'react';
import classNames from 'classnames';

import Icon from 'components/Icon/Icon';

import style from './ProviderSearchSelect.module.scss';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { FormattedMessage } from 'react-intl';

interface SelectOption {
  providerId: string;
  providerName: string;
  providerVersion?: string;
  providerTag?: string;
}

interface Props {
  className?: string;
  onChange: (val: string) => void;
  options: SelectOption[];
  value?: string;
  placeholder?: string;
}

export const ProviderSearchSelect: React.FC<Props> = (props: Props) => {
  const {
    options = [],
    className,
    placeholder = '',
    value = '',
    onChange,
  } = props;

  const formattedOptionsList = useMemo(() => {
    return options.map(item => {
      return {
        ...item,
        formattedName: `${item.providerName} - Version (${item.providerVersion}) - ${item.providerTag}`,
      };
    });
  }, [options]);

  const wrapperRef = createRef<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [list, setList] = useState(formattedOptionsList);
  const [selectedOption, setSelectedOption] = useState('');

  const handleClickOutside = () => {
    if (isOpen) {
      setIsOpen(false);
      if (!list.length || !selectedOption) {
        onChange('');
      }
    }
  };

  useClickOutside(wrapperRef, handleClickOutside);

  const getValue = React.useCallback(
    (id: string) => {
      const option = formattedOptionsList.find(item => item.providerId === id);
      return option ? `${option.formattedName}` : '';
    },
    [formattedOptionsList],
  );

  const onOptionSelect = (val: string, e: React.SyntheticEvent) => {
    e.stopPropagation();
    onChange(val);
    setInputValue(getValue(val));
    setSelectedOption(getValue(val));
    setList(formattedOptionsList);
    setIsOpen(false);
  };

  const toggleProjectsList = () => {
    if (isOpen) {
      setIsOpen(false);
      if (!list.length || !selectedOption) {
        onChange('');
      }
    } else {
      setIsOpen(true);
    }
  };

  const filterList = (val: string) => {
    const optionsCopy = [...formattedOptionsList];
    setList(
      optionsCopy.filter(item =>
        item.formattedName.toUpperCase().startsWith(val.toUpperCase()),
      ),
    );
  };

  const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = e.target.value;
    if (!targetValue) {
      onChange('');
      setSelectedOption('');
      setList(formattedOptionsList);
    }
    if (selectedOption) {
      setSelectedOption('');
    }
    setInputValue(targetValue);
    filterList(targetValue);
  };

  useEffect(() => {
    if (value) {
      setInputValue(getValue(value));
      onChange(value);
      setSelectedOption(value);
    }
  }, [value, onChange, getValue]);

  return (
    <>
      <label
        className={classNames(style.fieldLabel, {
          [style.fieldLabel__active]: Boolean(value) || inputValue,
        })}
      >
        <FormattedMessage id={'Provider name'} />
      </label>
      <div
        className={classNames(style.wrapper, className)}
        ref={wrapperRef}
        tabIndex={0}
        onClick={toggleProjectsList}
      >
        <div
          className={classNames(style.field, {
            [style.fieldActive]: isOpen,
          })}
        >
          <input
            className={style.textInput}
            type="text"
            value={inputValue}
            placeholder={placeholder}
            onChange={inputChangeHandler}
          />
        </div>
        <Icon
          className={classNames(style.selectIcon, {
            [style.selectIcon__active]: isOpen,
          })}
          type={isOpen ? 'arrowTop' : 'arrowBottom'}
        />
        {isOpen && (
          <div className={style.menu}>
            <ul>
              {list.map(option => (
                <li
                  key={option.providerId}
                  className={classNames(style.listItem, {
                    [style.active]: inputValue === option.formattedName,
                  })}
                  onClick={onOptionSelect.bind(null, option.providerId)}
                >
                  {`${option.providerName} -`}
                  <span className={style.version}>{` Version `}</span>
                  {`(${option.providerVersion}) - ${option.providerTag}`}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};
