import React, { ComponentType } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { observer } from 'mobx-react';

import RootStore from 'stores/RootStore';

import { ROUTES } from './routes';

const { user } = RootStore;

interface PrivateRouterProps {
  component: ComponentType<any>;
  [name: string]: unknown;
}

@observer
class PrivateRouter extends React.Component<PrivateRouterProps> {
  render() {
    const { component: Component, ...rest } = this.props;
    const access = user.authenticated;

    return (
      <Route
        {...rest}
        render={props =>
          access ? <Component {...props} /> : <Redirect to={ROUTES.login} />
        }
      />
    );
  }
}

export default PrivateRouter;
