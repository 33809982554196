import { action, observable, computed } from 'mobx';
import _ from 'lodash';

import httpFacade, { IntervalFetch } from 'http/httpFacade';
import { IClusterParams } from '../models/Environment';
import {
  StorageProvider,
  StorageProviderInstance,
  StorageProviderStatus,
} from 'models/ExternalStorage';
import { sortByField } from 'helpers/array';

export default class ExrternalStorageStore {
  @observable pending = true;
  @observable providers?: StorageProvider[] = [];
  @observable providerInstances?: StorageProviderInstance[] = [];
  @observable params: IClusterParams;
  @observable externalStorageStatus: boolean = false;
  @observable intervalFetch: IntervalFetch = new IntervalFetch();

  constructor(clusterName: string, projectName: string) {
    this.params = {
      clusterName,
      projectName,
    };
    this.init();
  }

  @computed get groupedProviders() {
    return _.groupBy(this.providers, el => el.providerName);
  }

  clearTimer() {
    this.intervalFetch.disable();
  }

  init = async () => {
    this.pending = true;
    this.intervalFetch.setRequest(() => this.fetchData());
    await this.intervalFetch.enable();
    this.pending = false;
  };

  @action
  async fetchData() {
    await Promise.all([this.fetchExternalStorageStatus()]);
  }

  @action.bound
  async fetchExternalStorageStatus() {
    try {
      const response = await httpFacade.cluster.checkAvailability(
        this.params.projectName,
        this.params.clusterName,
      );
      this.externalStorageStatus = response.data.enabled;
      if (this.externalStorageStatus) {
        this.fetchProviders();
        this.fetchProvidersInstances();
      }
    } catch (error) {
      this.externalStorageStatus = false;
    }
  }

  @action
  async fetchProviders() {
    try {
      const response = await httpFacade.cluster.fetchClusterProviders(
        this.params.projectName,
        this.params.clusterName,
      );
      sortByField(response.data, 'providerVersion', true);
      sortByField(response.data, 'providerName');
      this.providers = response.data;
    } catch (error) {
      this.providers = [];
    }
  }

  @action
  initProblem = (providerInstances: StorageProviderInstance[]) => {
    providerInstances.forEach(providerInstance => {
      providerInstance.isHaveProblem =
        providerInstance.status !== StorageProviderStatus.SUCCESSFUL;
    });
  };

  @action
  async fetchProvidersInstances() {
    try {
      const response = await httpFacade.cluster.fetchClusterProviderInstances(
        this.params.projectName,
        this.params.clusterName,
      );
      this.initProblem(response.data);
      response.data.sort((a, b) => {
        return (
          +b.isHaveProblem - +a.isHaveProblem ||
          +(b.createDate ? new Date(b.createDate) : new Date()) -
            +(a.createDate ? new Date(a.createDate) : new Date())
        );
      });
      this.providerInstances = response.data;
    } catch (error) {
      this.providerInstances = [];
    }
  }
}
