import React from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import {
  IPodContainer,
  IPodParams,
  STATE_TERMINATED_SUCCESS_REASON,
} from 'models/Pod';
import ModalStore from 'stores/ModalStore';

import BorderedText, {
  BorderedTextType,
} from 'components/BorderedText/BorderedText';
import Button, { BtnType } from 'components/Button/Button';
import Checkbox from 'components/Form/Fields/Checkbox/Checkbox';
import Icon from 'components/Icon/Icon';
import { OverflowedText } from 'components/OverflowedText/OverflowedText';
import RestartCountLabel from 'components/RestartCountLabel/RestartCountLabel';
import Status, { StatusType } from 'components/Status/Status';
import ContainerLogs from 'pages/PodsPage/ContainerLogs/ContainerLogs';
import ContainerLastStateModal, {
  IContainerLastState,
} from 'pages/PodsPage/ContainerRow/ContainerLastStateModal/ContainerLastStateModal';

import style from './ContainerRow.module.scss';

const getErrorMessage = (state: { reason?: string; message?: string }) => {
  return state.reason && state.message
    ? `${state.reason}: ${state.message}`
    : state.reason || state.message;
};

const getContainerLastState = (
  container: IPodContainer,
): IContainerLastState => {
  if (container.lastStateRunning) {
    return {
      title: 'running',
      state: container.lastStateRunning,
    };
  }
  if (container.lastStateWaiting) {
    return {
      title: 'waiting',
      state: container.lastStateWaiting,
    };
  }
  if (container.lastStateTerminated) {
    return {
      title: 'terminated',
      state: container.lastStateTerminated,
    };
  }
  return {
    title: '',
    state: {},
  };
};

interface IProps {
  container: IPodContainer;
  podParams: IPodParams;
  isLogsOpened: boolean;
  toggleLogs: () => void;
}

@observer
class ContainerRow extends React.Component<IProps> {
  @observable isLastContainerLogs = false;

  @action
  toggleIsLastContainerLogs = () => {
    this.isLastContainerLogs = !this.isLastContainerLogs;
  };

  viewLastState = () => {
    const { container } = this.props;
    const containerLastState = getContainerLastState(container);

    ModalStore.showModal(ContainerLastStateModal, { containerLastState });
  };

  getContainerState = () => {
    const { container } = this.props;

    if (container.stateRunning) {
      return (
        <div className={style.cellLine}>
          <Status statusType={StatusType.Success} className={style.status} />
          <span className={style.stateText}>
            <FormattedMessage id="container.running" />
          </span>
        </div>
      );
    }
    if (container.stateWaiting) {
      const errorMessage = getErrorMessage(container.stateWaiting);

      return (
        <>
          <div className={style.cellLine}>
            <Status statusType={StatusType.Info} className={style.status} />
            <span className={style.stateText}>
              <FormattedMessage id="container.waiting" />
            </span>
          </div>
          <div>
            {errorMessage && (
              <BorderedText type={BorderedTextType.Error}>
                <OverflowedText title={errorMessage} />
              </BorderedText>
            )}
          </div>
        </>
      );
    }
    if (container.stateTerminated) {
      const errorMessage = getErrorMessage(container.stateTerminated);
      const borderedTextType =
        container.stateTerminated.reason === STATE_TERMINATED_SUCCESS_REASON
          ? BorderedTextType.Success
          : BorderedTextType.Error;

      return (
        <>
          <div className={style.cellLine}>
            <Status statusType={StatusType.Error} className={style.status} />
            <span className={style.stateText}>
              <FormattedMessage id="container.terminated" />
            </span>
          </div>
          <div>
            {errorMessage && (
              <BorderedText type={borderedTextType}>
                <OverflowedText title={errorMessage} />
              </BorderedText>
            )}
          </div>
        </>
      );
    }
  };

  render() {
    const { container, podParams, isLogsOpened, toggleLogs } = this.props;
    const shortImageName = container.image.split('/').pop();
    const isContainerHasLastState =
      container.lastStateRunning ||
      container.lastStateWaiting ||
      container.lastStateTerminated;

    return (
      <>
        <div className={style.containerRow}>
          <div className={classNames(style.cell, style.containerNameCell)}>
            <OverflowedText title={container.containerName} />
          </div>
          <div className={style.cell}>
            {!!container.restartCount && (
              <>
                <div className={style.cellLine}>
                  <div className={style.restartCountWrapper}>
                    <RestartCountLabel restartCount={container.restartCount} />
                  </div>
                  <Button
                    onClick={this.viewLastState}
                    styleType={BtnType.Text}
                    className={style.lastStateBtn}
                    disabled={!isContainerHasLastState}
                  >
                    <FormattedMessage id="container.view.last.state" />
                  </Button>
                </div>
                <label className={style.label}>
                  <Checkbox
                    checked={this.isLastContainerLogs}
                    onChange={this.toggleIsLastContainerLogs}
                    className={style.checkbox}
                  />
                  <FormattedMessage id="container.show.last.container.logs" />
                </label>
              </>
            )}
          </div>
          <div className={classNames(style.cell, style.imageCell)}>
            <OverflowedText
              title={container.image}
              text={shortImageName}
              showTooltipOnlyOnOverflow={false}
            />
          </div>
          <div className={style.cell}>{this.getContainerState()}</div>
          <div
            className={classNames(
              style.cell,
              style.statusCell,
              container.started && style.statusCell__success,
            )}
          >
            <FormattedMessage
              id={
                container.started
                  ? 'container.started'
                  : 'container.not.started'
              }
            />
          </div>
          <div
            className={classNames(
              style.cell,
              style.statusCell,
              container.ready && style.statusCell__success,
            )}
          >
            <FormattedMessage
              id={container.ready ? 'container.ready' : 'container.not.ready'}
            />
          </div>
          <div className={style.cell}>
            <Icon
              className={style.iconArrowContainer}
              type={isLogsOpened ? 'arrowTop' : 'arrowBottom'}
              onClick={toggleLogs}
            />
          </div>
        </div>
        {isLogsOpened && (
          <ContainerLogs
            key={String(this.isLastContainerLogs)}
            containerParams={{
              ...podParams,
              containerName: container.containerName,
            }}
            isLastContainerLogs={this.isLastContainerLogs}
          />
        )}
      </>
    );
  }
}

export default ContainerRow;
