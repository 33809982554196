import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { FormattedMessage } from 'react-intl';

import { ModalProps, DialogActionType } from 'stores/ModalStore';
import CommonModal from '../../Modals/Modal/CommonModal';

import style from './ScaleMSConfirmModal.module.scss';

interface Props extends ModalProps {
  request: () => void;
  onConfirmCancel: () => void;
  msLongName: string;
}

@observer
class ScaleMSConfirmModal extends Component<Props> {
  @observable
  isUIblocked: boolean = false;

  onClose = () => {
    this.props.onClose(DialogActionType.cancel);
    this.props.onConfirmCancel();
  };

  onConfirm = async () => {
    this.isUIblocked = true;
    await this.props.request();
    this.isUIblocked = false;
    this.props.onClose(DialogActionType.cancel);
  };

  render() {
    const { msLongName } = this.props;

    return (
      <CommonModal
        className={style.body}
        bodyContentClassName={style.confirmMSBodyContent}
        title="cluster.modal.shutdown.title"
        headerIcon="modalWarning"
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        isUIblocked={this.isUIblocked}
      >
        <div className={style.text1}>
          <FormattedMessage
            id="scale.ms.confirm.text1"
            values={{ msLongName }}
          />
        </div>
        <div className={style.text2}>
          <FormattedMessage id="scale.ms.confirm.text2" />
        </div>
      </CommonModal>
    );
  }
}

export default ScaleMSConfirmModal;
