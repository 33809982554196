import { action, observable } from 'mobx';
import _ from 'lodash';
import httpFacade from 'http/httpFacade';
import Log from 'helpers/log';
import { ILog, IPodParams, LOG_ID_PREFIX, WithId } from 'models/Pod';

const defaultLogsTail = 1000;

export class PodLogsStore {
  @observable logs: Array<WithId<ILog>> = [];
  @observable isLoading = false;

  podParams: IPodParams;

  constructor(podParams: IPodParams) {
    this.podParams = podParams;
  }

  @action
  fetchPodLogs = async () => {
    try {
      this.isLoading = true;

      const { data } = await httpFacade.pods.fetchPodLogsAndEvents(
        this.podParams,
        {
          tail: defaultLogsTail,
        },
      );

      this.logs = data.map(log => ({
        id: _.uniqueId(LOG_ID_PREFIX),
        ...log,
      }));
    } catch (error) {
      this.logs = [];
      Log.warn(error);
    } finally {
      this.isLoading = false;
    }
  };
}
